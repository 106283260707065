import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { DataPopperRow } from '@cast/design-system';
import { getCurrencyPrecision } from '@cast/utils';

import { nodeTypeColors } from 'common/maps';
import { Money } from 'components/money';
import { CostReportChartTooltip } from 'features/cost-report/components/chart/CostReportChartTooltip';
import { NodeType } from 'types/nodes';

import { useCostOverTime } from '../../../../cost-report/hooks/useCostOverTime';
import { useCostReportContext } from '../../../../cost-report/hooks/useCostReportContext';
import { CostReportChartType } from '../../../../cost-report/types/costOverTime';

type TooltipRowProps = {
  label: string;
  cost: number | null;
  projected?: number;
  type: NodeType;
  hasProjectedCost: boolean;
};

const TooltipRow = ({
  label,
  cost,
  projected,
  type,
  hasProjectedCost,
}: TooltipRowProps) => {
  const costPrecision = cost !== null ? getCurrencyPrecision(cost) : 0;
  const projectedPrecision = getCurrencyPrecision(projected);

  return (
    <DataPopperRow
      indicator={{
        color: nodeTypeColors[type]!,
      }}
      left={<Typography variant="L10R">{label}</Typography>}
      right={
        <Stack direction="row" flexWrap="nowrap" gap={16}>
          <Typography variant="P10R" align="right" minWidth="50px">
            {cost !== null ? (
              <Money
                amount={cost}
                precision={costPrecision}
                fixed={costPrecision}
              />
            ) : (
              '--'
            )}
          </Typography>

          {hasProjectedCost && (
            <Typography
              variant="P10R"
              align="right"
              minWidth="50px"
              sx={{ opacity: 0.5 }}
            >
              <Money
                amount={projected}
                precision={projectedPrecision}
                fixed={projectedPrecision}
              />
            </Typography>
          )}
        </Stack>
      }
    />
  );
};

type ComputeSpendTooltipProps = {
  payload?: any[];
};

export const ComputeSpendTooltip = ({ payload }: ComputeSpendTooltipProps) => {
  const tooltipData = payload?.[0]?.payload || {};
  const {
    timestamp,
    onDemand,
    spot,
    fallback,
    onDemandForecast,
    fallbackForecast,
    spotForecast,
    forecast,
    anomalies,
  } = tooltipData;
  const { chartType } = useCostReportContext();
  const { key, projectEndOfDayCost } = useCostOverTime();

  if (isEmpty(tooltipData)) {
    return null;
  }

  const cost =
    onDemand !== null || spot !== null || fallback !== null
      ? onDemand + spot + fallback
      : null;
  const projectedCost = projectEndOfDayCost
    ? onDemandForecast + spotForecast + fallbackForecast || undefined
    : undefined;
  const date = dayjs(timestamp).format('MMM D');

  const hasAnomalies = !!anomalies?.length;

  const secondaryValue = () => {
    if (key !== 'cluster') {
      return undefined;
    }
    if (!hasAnomalies) {
      return chartType === CostReportChartType.AREA
        ? dayjs(timestamp).format('hh:mmA')
        : undefined;
    }

    if (anomalies.length > 1) {
      return `${anomalies.length} Cost Anomalies ${
        chartType === CostReportChartType.AREA
          ? dayjs(timestamp).format('hh:mmA')
          : ''
      }`;
    }

    return `Cost Anomaly ${dayjs(anomalies[0].timestamp).format('hh:mmA')}`;
  };

  const hasProjectedCost = projectedCost && cost < projectedCost;

  return (
    <CostReportChartTooltip
      primary={date}
      secondary={secondaryValue()}
      secondaryProps={{ color: hasAnomalies ? 'red.500' : 'inherit' }}
      title="Compute cost"
      total={cost}
      projectedCost={projectedCost}
      projected={hasProjectedCost}
      forecast={forecast}
      sx={{ minWidth: 240 }}
    >
      <TooltipRow
        label=" ON-DEMAND"
        cost={onDemand}
        projected={onDemandForecast}
        type={NodeType.ON_DEMAND}
        hasProjectedCost={hasProjectedCost}
      />
      {!forecast && (
        <TooltipRow
          label=" FALLBACK"
          cost={fallback}
          type={NodeType.FALLBACK}
          hasProjectedCost={false}
        />
      )}
      <TooltipRow
        label=" SPOT"
        cost={spot}
        projected={spotForecast}
        type={NodeType.SPOT}
        hasProjectedCost={hasProjectedCost}
      />
    </CostReportChartTooltip>
  );
};
