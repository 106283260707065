export const computedFlags = (partial: Record<string, any>): boolean => {
  if ('value' in partial && 'reason' in partial) {
    return partial.value;
  }

  return Object.values(partial).some((entry) => {
    if (typeof entry === 'boolean') {
      return entry;
    }

    return computedFlags(entry);
  });
};
