import { useMemo } from 'react';

import { Column, TableHeader } from '@cast/design-system';

import { tableCacheKeys } from 'common/tableCacheKeys';
import { SearchableTableRecordsCount } from 'components/search';

import { BillingReportTableContainer } from './BillingReportTableContainer';
import { CellWithPostfix } from './CellWithPostfix';
import { useBillingReportContext } from '../../../BillingReportContext';
import { DailyUsage } from '../../../types';
import { mapBillingReportDataByDay } from '../_utils';

export const BillingReportTotalDailyTable = () => {
  const { dailyUsage } = useBillingReportContext();

  const data = useMemo(() => {
    return mapBillingReportDataByDay(dailyUsage);
  }, [dailyUsage]);

  return (
    <BillingReportTableContainer
      data-hc="success"
      searchProviderProps={{
        data,
      }}
      searchableTableProps={{
        rowKey: 'timestamp',
        maxRows: 31,
        testId: 'total-daily-table',
        cacheKey: tableCacheKeys.BILLING_REPORT.DAILY,
        outerHeader: (
          <TableHeader
            recordsCount={
              <SearchableTableRecordsCount
                outOf={dailyUsage.length}
                title="cluster"
              />
            }
          />
        ),
      }}
    >
      <Column<DailyUsage>
        id="timestamp"
        minWidth={400}
        header="Date"
        sortable
        resizable
      />
      <Column<DailyUsage>
        id="cpuHours"
        header="Cpu hours"
        minWidth={180}
        renderCell={({ cpuHours }) => (
          <CellWithPostfix value={cpuHours} postfix="CPU/h" />
        )}
        sortable
      />
      <Column<DailyUsage>
        id="billableCpus"
        header="Billable CPUS"
        minWidth={180}
        renderCell={({ billableCpus }) => (
          <CellWithPostfix value={billableCpus} postfix="CPU" variant="P12B" />
        )}
        sortable
      />
    </BillingReportTableContainer>
  );
};
