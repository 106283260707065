import { OrganizationRole } from '@cast/types';

export const roleDescriptionMap: Record<OrganizationRole, string> = {
  [OrganizationRole.owner]:
    'Owner role gives full access to clusters, billing and organization management',
  [OrganizationRole.member]:
    'Member role gives access to clusters. View only access to billing',
  [OrganizationRole.analyst]:
    'Full access to cost monitoring. View only access to cluster and billing',
  [OrganizationRole.viewer]:
    'Viewer role gives view only access to clusters and billing',
};
