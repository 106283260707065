import { useContext } from 'react';

import { useOrganizations } from 'hooks/useOrganizations';

import { RBACContext } from '../context';
import { PermissionType, RootFeature } from '../types';
import { checkRBACPermissions } from '../utils';

export const useRBAC = (
  permissionType: PermissionType | PermissionType[],
  feature?: RootFeature
): boolean[] => {
  const featureContext = useContext(RBACContext);
  const { currentOrganization } = useOrganizations();
  const permissionScope = featureContext || feature;

  if (!permissionScope) {
    throw new Error('Permission scope is not defined!');
  }

  return checkRBACPermissions(
    permissionScope,
    permissionType,
    currentOrganization
  );
};
