import { useCallback } from 'react';

import { SxProps } from '@mui/material';

import { PreviewSnippet } from '@cast/design-system';

import { notify } from 'core/snackbars';
import { useOrganizations } from 'hooks/useOrganizations';
import { applyYamlPreview } from 'utils/url';

import { InstallComponentFallback } from '../InstallComponentFallback';

type Props = {
  code?: string;
  sx?: SxProps;
  errorMessage?: string;
  error?: unknown;
  refetch?: () => void;
  testId?: string;
};

export const AgentCodeSnippet = ({
  code,
  sx,
  error,
  refetch,
  errorMessage = 'Failed to load agent script',
  testId,
}: Props) => {
  const { currentOrganization } = useOrganizations();

  const handleRenderPreview = useCallback(
    (_code: string) => {
      return applyYamlPreview(_code, currentOrganization?.id);
    },
    [currentOrganization?.id]
  );

  const handleCopy = useCallback((_code: string) => {
    notify.success('Copied to clipboard');
  }, []);

  if (error) {
    return (
      <InstallComponentFallback message={errorMessage} refetch={refetch} />
    );
  }

  return (
    <PreviewSnippet
      code={code || ''}
      renderPreview={handleRenderPreview}
      onCopy={handleCopy}
      p="16px"
      sx={sx}
      testId={testId}
    />
  );
};
