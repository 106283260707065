import { AxiosInstance } from 'axios';

import { InventoryAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const InventoryApiAdapter = (client: AxiosInstance) => {
  const generatedApi = InventoryAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    getReservations: generatedApi.inventoryAPIGetReservations,
    addReservation: generatedApi.inventoryAPIAddReservation,
    deleteReservation: generatedApi.inventoryAPIDeleteReservation,
    getReservationsBalance: generatedApi.inventoryAPIGetReservationsBalance,
    getResourceUsage: generatedApi.inventoryAPIGetOrganizationResourceUsage,
    overwriteReservations: generatedApi.inventoryAPIOverwriteReservations,
  };
};
