import { styled } from '@mui/material';
import clsx from 'clsx';

import { Button, ButtonProps, buttonVars } from '@cast/design-system';

export interface HeaderButtonProps
  extends Omit<ButtonProps, 'size' | 'variant'> {
  active?: boolean;
}

export const HeaderButton = styled(
  ({ className, active, children, ...rest }: HeaderButtonProps) => {
    return (
      <Button {...rest} className={clsx(className, { active })} size="small">
        {children}
      </Button>
    );
  }
)(({ theme }) => ({
  ...buttonVars.setValues({
    color: theme.palette.grey[600],
    colorHover: theme.palette.grey[600],
    colorActive: theme.palette.blue[600],
    colorDisabled: theme.palette.grey[300],
    background: 'unset',
    backgroundHover: theme.palette.grey[200],
    backgroundActive: theme.palette.blue[100],
    backgroundDisabled: 'unset',
    backgroundLoading: theme.palette.grey[300],
    boxShadowHover: 'unset',
    boxShadowActive: 'unset',
    borderDisabled: 'unset',
    iconSize: '20px',
  }),

  ...theme.typography.P14M,

  '&.active': {
    color: buttonVars.getters.colorActive(),
    background: buttonVars.getters.backgroundActive(),
    boxShadow: buttonVars.getters.boxShadowActive(),
  },
}));
