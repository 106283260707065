import { useState } from 'react';

import { useTheme } from '@mui/material';

import {
  CustomAreaCursor,
  ComposedChartWithEstimate,
  DEFAULT_BAR_SIZE,
  makeCursorWithBarSize,
} from 'components/chart';

import { ComposedChartWithCostReportProps } from '../../types/costOverTime';

const yTickFormatter = (t: string) => `$${t}`;

export const CostReportBarChart = ({
  composedProps,
  xAxisProps,
  yAxisProps,
  data,
  ...chartProps
}: ComposedChartWithCostReportProps) => {
  const { palette } = useTheme();
  const [cursorWidth, setCursorWidth] = useState<number>(DEFAULT_BAR_SIZE);

  return (
    <ComposedChartWithEstimate
      ref={makeCursorWithBarSize(setCursorWidth)}
      isAutoBarResizing={true}
      {...chartProps}
      data={data}
      tooltipCursor={
        <CustomAreaCursor
          cursorWidth={cursorWidth}
          width={cursorWidth}
          color={palette.grey[200]}
        />
      }
      composedProps={{
        ...composedProps,
        margin: {
          top: -1,
        },
      }}
      xAxisProps={{
        padding: { left: 45, right: 45 },
        ...xAxisProps,
      }}
      yAxisProps={{
        ...yAxisProps,
        tickFormatter: yTickFormatter,
      }}
    />
  );
};
