import { PropsWithChildren } from 'react';

import { Card } from '@cast/design-system';

import { ContentContainer } from 'main-layout/ContentContainer';

export const FooterBox = ({
  testId = 'footer-box',
  children,
}: PropsWithChildren<{ testId?: string }>) => {
  return (
    <Card
      sx={{
        display: 'flex',
        bottom: 0,
        borderRadius: 0,
        padding: 0,
        width: '100%',
      }}
      testId={testId}
    >
      <ContentContainer>{children}</ContentContainer>
    </Card>
  );
};
