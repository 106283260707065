import { useEffect } from 'react';

import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import { Alert, DateTypes } from '@cast/design-system';

import { RangePicker } from 'components/date';
import { FailedToLoad } from 'components/messages';
import { orgManagementEvents } from 'core/analytics';
import { useOrganizations } from 'hooks/useOrganizations';
import { PageHeader } from 'main-layout/PageHeader';

import { BillingReportBreadcrumbs } from './_components/BillingReportBreadcrumbs';
import { BillingReportChartSection } from './_components/BillingReportChartSection';
import { BillingReportClustersTableSection } from './_components/BillingReportClustersTableSection';
import { BillingReportFooter } from './_components/BillingReportFooter';
import { CollectingData } from './_components/CollectingData';
import { useBillingReportContext } from './BillingReportContext';
import {
  CPU_USAGE_REPORT_START_DATE,
  BILLING_REPORT_START_DATE,
} from './constants';

const filterPresets = [
  DateTypes.DateRangeFilter.Last24Hours,
  DateTypes.DateRangeFilter.Last7Days,
  DateTypes.DateRangeFilter.Last30Days,
  DateTypes.DateRangeFilter.LastMonth,
  DateTypes.DateRangeFilter.Last1Year,
];

export const BillingReport = () => {
  const location = useLocation();
  const { currentOrganization } = useOrganizations();
  const { isLoading, error, refetch } = useBillingReportContext();

  useEffect(() => {
    if (currentOrganization?.id) {
      orgManagementEvents.visitedBillingReport(
        currentOrganization.id,
        location.hash === '#sidebar'
      );
    }
  }, [currentOrganization?.id, location.hash]);

  return (
    <Stack mt={-30}>
      <BillingReportBreadcrumbs />
      <PageHeader heading="Billing report" pt={12}>
        <Box flexBasis={235}>
          <RangePicker
            testId="date-select"
            triggerInputProps={{
              size: 'medium',
              sx: { minWidth: 'auto' },
            }}
            toDate={(dateRange) => {
              if (
                dateRange?.from &&
                !dateRange?.to &&
                dayjs(dateRange.from).isBefore(BILLING_REPORT_START_DATE)
              ) {
                return dayjs(BILLING_REPORT_START_DATE)
                  .subtract(1, 'day')
                  .toDate();
              }
              return undefined;
            }}
            fromDate={(dateRange) => {
              if (
                dateRange?.from &&
                !dateRange?.to &&
                dayjs(dateRange.from).isSameOrAfter(BILLING_REPORT_START_DATE)
              ) {
                return dayjs(BILLING_REPORT_START_DATE).toDate();
              }

              return dayjs(CPU_USAGE_REPORT_START_DATE).toDate();
            }}
            feedbackComponent={(dateRange) => {
              if (dateRange?.from) {
                return (
                  <Alert
                    size="small"
                    color="advisory"
                    noIcon
                    sx={{ width: 496, ml: 17, mb: 11 }}
                    testId="billing-report-range-alert"
                  >
                    {dayjs(dateRange.from).isBefore(BILLING_REPORT_START_DATE)
                      ? 'June 1st, 2024, is the release date of the updated version of this report. Please select a time range that begins and ends before this date.'
                      : 'The new version of this report was released on June 1st, 2024. Please select a time range that begins and ends after this date.'}
                  </Alert>
                );
              }
            }}
            filterPresets={filterPresets}
          />
        </Box>
      </PageHeader>

      <Stack gap={24} flexGrow={1}>
        {isLoading && <CollectingData />}
        {!!error ? (
          <FailedToLoad refresh={refetch} />
        ) : (
          <>
            <BillingReportChartSection />
            <BillingReportClustersTableSection />
            <BillingReportFooter />
          </>
        )}
      </Stack>
    </Stack>
  );
};
