import { useContext } from 'react';

import { AgentStatus, Cluster } from '@cast/types';
import { isManagedByTerraform } from '@cast/utils';

import { ClusterContext } from 'core/providers';
import { MANAGED_PROVIDERS } from 'features/cluster/constants';
import {
  isClusterOnboardingPhase1,
  isClusterOnboardingPhase2,
} from 'utils/cluster';

type UseClusterHook = {
  cluster: Cluster;
  error: string | undefined;
  isManaged: boolean;
  isLoading: boolean;
  isOnline: boolean;
  isDisconnected: boolean;
  canBeManaged: boolean;
  isTerraformManaged: boolean;
  clusterOnboardingPhase1: boolean;
  clusterOnboardingPhase2: boolean;
};

export const useCluster = (): UseClusterHook => {
  const { cluster, isLoading, error } = useContext(ClusterContext);
  const isManaged = Boolean(cluster?.credentialsId);
  const isOnline = cluster.agentStatus === AgentStatus.ONLINE;
  const isDisconnected = cluster.agentStatus === AgentStatus.DISCONNECTED;
  const canBeManaged = MANAGED_PROVIDERS.includes(cluster.providerType);
  const isTerraformManaged = isManagedByTerraform(cluster.managedBy);
  const clusterOnboardingPhase1 = isClusterOnboardingPhase1(
    cluster.displayStatus,
    isManaged
  );
  const clusterOnboardingPhase2 = isClusterOnboardingPhase2(
    cluster.status,
    isManaged
  );

  return {
    cluster,
    error,
    isLoading,
    isManaged,
    isOnline,
    isDisconnected,
    canBeManaged,
    isTerraformManaged,
    clusterOnboardingPhase1,
    clusterOnboardingPhase2,
  };
};
