import {
  sub,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  endOfYear,
  endOfWeek,
  endOfMonth,
  endOfDay,
} from 'date-fns';

import { DateRangeFilter, Filters } from './types';

export const allFilters: Filters = {
  [DateRangeFilter.ThisMonth]: {
    label: 'This month',
    date: () => ({
      from: startOfMonth(new Date()),
      to: endOfMonth(new Date()),
    }),
  },
  [DateRangeFilter.Last15Mins]: {
    label: 'Last 15 minutes',
    date: () => ({
      from: sub(new Date(), { minutes: 15 }),
      to: new Date(),
    }),
  },
  [DateRangeFilter.Last30Mins]: {
    label: 'Last 30 minutes',
    date: () => ({
      from: sub(new Date(), { minutes: 30 }),
      to: new Date(),
    }),
  },
  [DateRangeFilter.LastHour]: {
    label: 'Last hour',
    date: () => ({
      from: sub(new Date(), { hours: 1 }),
      to: new Date(),
    }),
  },
  [DateRangeFilter.Last24Hours]: {
    label: 'Last 24 hours',
    date: () => ({
      from: sub(new Date(), { hours: 24 }),
      to: new Date(),
    }),
  },
  [DateRangeFilter.TODAY]: {
    label: 'Today',
    date: () => ({
      from: startOfDay(new Date()),
      to: endOfDay(new Date()),
    }),
  },
  [DateRangeFilter.Last7Days]: {
    label: 'Last 7 days',
    date: ({ fromNow } = {}) => ({
      from: fromNow
        ? sub(new Date(), { days: 7 })
        : startOfDay(sub(new Date(), { days: 7 })),
      to: fromNow ? new Date() : endOfDay(new Date()),
    }),
  },
  [DateRangeFilter.Last30Days]: {
    label: 'Last 30 days',
    date: ({ fromNow } = {}) => ({
      from: fromNow
        ? sub(new Date(), { days: 30 })
        : startOfDay(sub(new Date(), { days: 30 })),
      to: fromNow ? new Date() : endOfDay(new Date()),
    }),
  },
  [DateRangeFilter.Last1Year]: {
    label: 'Last 1 year',
    date: () => {
      const from = sub(startOfYear(new Date()), { years: 1 });
      return {
        from,
        to: endOfYear(from),
      };
    },
  },
  [DateRangeFilter.LastMonth]: {
    label: 'Last month',
    date: () => {
      const from = sub(startOfMonth(new Date()), { months: 1 });
      return {
        from,
        to: endOfMonth(from),
      };
    },
  },
  [DateRangeFilter.LastWeek]: {
    label: 'Last week',
    date: () => {
      const from = sub(startOfWeek(new Date(), { weekStartsOn: 1 }), {
        weeks: 1,
      });
      return {
        from,
        to: endOfWeek(from, { weekStartsOn: 1 }),
      };
    },
  },
  [DateRangeFilter.Last2Weeks]: {
    label: 'Last 2 weeks',
    date: () => {
      const from = sub(startOfWeek(new Date(), { weekStartsOn: 1 }), {
        weeks: 2,
      });
      return {
        from,
        to: endOfDay(
          sub(startOfWeek(new Date(), { weekStartsOn: 1 }), { days: 1 })
        ),
      };
    },
  },
};
