import {
  FocusEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Box } from '@mui/material';
import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isValid,
  parse,
  startOfDay,
  sub,
} from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInMonths from 'date-fns/differenceInMonths';
import { DateRange } from 'react-day-picker';

import { DateTimeInput } from './DateTimeInput';
import { InputProps } from '../../controls';
import { DatePickerContext } from '../DatePickerProvider';
import { isRangeDatePicker } from '../types';

const isValidDateRange = (range: DateRange, maxDiffInDays?: number) => {
  if (range.from && range.to && maxDiffInDays) {
    const diffInDays = differenceInDays(range.to, range.from);
    return diffInDays < maxDiffInDays;
  }
  return true;
};

type RangePickerInputProps = {
  setMonth: (date: Date | undefined) => void;
};

const RangePickerInput = ({ setMonth }: RangePickerInputProps) => {
  const ctx = useContext(DatePickerContext);

  const [fromValue, setFromValue] = useState<string>(
    format(
      isRangeDatePicker(ctx) && ctx.selected?.from
        ? ctx.selected.from
        : startOfDay(new Date()),
      ctx.format
    )
  );
  const previousValidFromValue = useRef<string | null>(fromValue);

  const [toValue, setToValue] = useState<string>(
    format(
      isRangeDatePicker(ctx) && ctx.selected?.to
        ? ctx.selected.to
        : startOfDay(new Date()),
      ctx.format
    )
  );
  const previousValidToValue = useRef<string | null>(toValue);

  useEffect(() => {
    if (isRangeDatePicker(ctx)) {
      if (ctx.selected?.from) {
        const fromDateString = format(ctx.selected.from, ctx.format);
        if (!fromValue || fromValue !== fromDateString) {
          setFromValue(fromDateString);
          previousValidFromValue.current = fromDateString;
        }
      }

      if (ctx.selected?.to) {
        const toDateString = format(ctx.selected.to, ctx.format);
        if (!toValue || toValue !== toDateString) {
          setToValue(toDateString);
          previousValidToValue.current = toDateString;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.selected]);

  if (!isRangeDatePicker(ctx)) {
    return null;
  }

  const handleFromChange: InputProps['onChange'] = (e, value) => {
    setFromValue(value);
  };

  const updateCalendarMonthView = (from: Date, to: Date) => {
    let _month: Date;
    if (differenceInMonths(to, from) >= 2) {
      _month = sub(to, { months: 1 });
    } else {
      _month = from;
    }
    setMonth(_month);
  };

  const handleFromBlur: FocusEventHandler<HTMLInputElement> = () => {
    const date = parse(fromValue, ctx.format, new Date());

    let range: DateRange = {
      from: undefined,
      to: undefined,
    };

    if (!isValid(date)) {
      return;
    }

    const toDate =
      typeof ctx.toDate === 'function' ? ctx.toDate(range) : ctx.toDate;
    const fromDate =
      typeof ctx.fromDate === 'function' ? ctx.fromDate(range) : ctx.fromDate;

    if (toDate && date && isAfter(date, toDate)) {
      return;
    }

    if (fromDate && date && isBefore(date, fromDate)) {
      return;
    }

    if (ctx.selected?.to && isAfter(date, ctx.selected.to)) {
      range = {
        from: ctx.allowTimeInput ? ctx.selected.to : endOfDay(ctx.selected.to),
        to: date,
      };
    } else {
      range = {
        from: date,
        to: ctx.selected?.to
          ? ctx.allowTimeInput
            ? ctx.selected.to
            : endOfDay(ctx.selected.to)
          : undefined,
      };
    }

    if (!isValidDateRange(range, ctx.maxNumberOfDays)) {
      if (previousValidFromValue.current) {
        setFromValue(previousValidFromValue.current);
      }
      return;
    }

    if (range.from && range.to) {
      updateCalendarMonthView(range.from, range.to);
    }

    ctx.setSelected(range);
  };

  const handleToChange: InputProps['onChange'] = (_, value) => {
    setToValue(value);
  };

  const handleToBlur: FocusEventHandler<HTMLInputElement> = () => {
    const date = parse(toValue, ctx.format, new Date());

    let range: DateRange = {
      from: undefined,
      to: undefined,
    };

    if (!isValid(date)) {
      return;
    }

    const toDate =
      typeof ctx.toDate === 'function' ? ctx.toDate(range) : ctx.toDate;
    const fromDate =
      typeof ctx.fromDate === 'function' ? ctx.fromDate(range) : ctx.fromDate;

    if (toDate && date && isAfter(date, toDate)) {
      return;
    }

    if (fromDate && date && isBefore(date, fromDate)) {
      return;
    }

    if (ctx.selected?.from && isBefore(date, ctx.selected.from)) {
      range = { from: date, to: ctx.selected.from };
    } else {
      range = { from: ctx.selected?.from, to: date };
    }

    if (!isValidDateRange(range, ctx.maxNumberOfDays)) {
      if (previousValidToValue.current) {
        setToValue(previousValidToValue.current);
      }
      return;
    }

    if (range.from && range.to) {
      updateCalendarMonthView(range.from, range.to);
    }

    ctx.setSelected(range);
  };

  return (
    <Box display="flex" gap="25px" alignItems="flex-end" padding="16px">
      <DateTimeInput
        label="From"
        sx={{ width: '100%' }}
        value={fromValue}
        onChange={handleFromChange}
        onBlur={handleFromBlur}
      />
      <Box lineHeight="32px" fontSize={24}>
        -
      </Box>
      <DateTimeInput
        size="medium"
        label="To"
        sx={{ width: '100%' }}
        value={toValue}
        onChange={handleToChange}
        onBlur={handleToBlur}
      />
    </Box>
  );
};

export default RangePickerInput;
