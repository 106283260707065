import { useCallback, useMemo } from 'react';

import { Popover, PopoverProps } from '@mui/material';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import {
  AutocompleteProps,
  Chip,
  DropdownProps,
  ListItem,
} from '@cast/design-system';

import { FailedToLoad } from 'components/messages';
import { useSearchCriterion } from 'components/search';

type Labels = { label?: string; values?: string[] }[];

export type LabelsAutocompleteProps = Partial<Pick<DropdownProps, 'onOpen'>> & {
  labels: Labels | undefined;
  error?: boolean;
  refetch?: () => void;
  criterion?: string;
  onApply?: (value: string | string[] | null) => void;
};

export const mapLabelsToOptions = (labels: Labels) => {
  return sortBy(
    labels.reduce((acc, current) => {
      return [
        ...acc,
        ...(current.label && current.values
          ? current.values.map((v) => `${current.label}=${v}`)
          : []),
      ];
    }, [] as string[])
  );
};

export const useLabelsAutocompleteProps = ({
  labels,
  error,
  refetch,
  criterion,
  onOpen,
  onApply,
}: LabelsAutocompleteProps): AutocompleteProps<string, string> => {
  const labelsCriterion = useSearchCriterion(criterion);
  const selectedLabels = labelsCriterion?.value;

  const options = useMemo(() => mapLabelsToOptions(labels || []), [labels]);

  const onLabelsChange = useCallback(
    (labels: string | string[] | null) => {
      if (labels && Array.isArray(labels) && labels.length) {
        labelsCriterion.setValue?.(labels);
        onApply?.(labels);
      } else {
        labelsCriterion.clear?.();
      }
    },
    [labelsCriterion, onApply]
  );

  return {
    size: 'medium',
    value: selectedLabels,
    options,
    onChange: onLabelsChange,
    onOpen,
    inputText: 'Labels',
    listSx: {
      '.DsListItem-content': {
        width: 'calc(100% - 32px)',
      },
    },
    PopoverComponent: error
      ? (props: PopoverProps) => (
          <Popover {...props}>
            <FailedToLoad
              title="Failed to load"
              sx={{
                width: (props.anchorEl as HTMLElement)?.offsetWidth,
                minWidth: 200,
                padding: 16,
              }}
              refresh={refetch}
            />
          </Popover>
        )
      : undefined,
    renderOption: (option: any, itemProps: any) => {
      return (
        <ListItem {...itemProps}>
          <Chip
            size="medium"
            sx={{
              maxWidth: '100%',
              backgroundColor: 'grey.600',
              color: 'white',
            }}
            multiline
          >
            {option}
          </Chip>
        </ListItem>
      );
    },
    virtualization: true,
    virtualizationProps: {
      maxWidth: 480,
      minWidth: 480,
    },
    checkable: true,
    multiple: true,
    showSelectAll: true,
    showClearAll: true,
    isOptionEqualToValue: isEqual,
  };
};
