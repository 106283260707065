import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

export const useIntegrationProjectsQuery = (integrationId?: string) => {
  const {
    data: projects,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.SECURITY_INTEGRATION_PROJECTS, integrationId],
    queryFn: async () => {
      const { data } = await apiClient.securityInsights.getIntegrationProjects({
        id: integrationId!,
      });
      return data.items;
    },
    enabled: !!integrationId,
  });
  return { projects, isLoading, error, refetch };
};
