import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import set from 'lodash/set';

import { FeatureFlagResolved } from '@cast/types';

import { getAllKeys } from './get-all-keys';
import { parseFlags } from './parse-flags';
import { Flags } from '../types';

export const mergeFlags = (
  fallbackFlags: Flags,
  remoteFlags?: FeatureFlagResolved[]
) => {
  const flags: Flags = cloneDeep(fallbackFlags);
  const _remoteFlags = parseFlags(remoteFlags) ?? {};
  const _fallbackKeys = getAllKeys(fallbackFlags);

  for (let i = 0; i < _fallbackKeys.length; i++) {
    const key = _fallbackKeys[i];
    const valueInRemoteFlags = get(_remoteFlags, key);

    if (!isEmpty(valueInRemoteFlags) || !isUndefined(valueInRemoteFlags)) {
      set(flags, key, valueInRemoteFlags);
    }
  }

  return flags;
};
