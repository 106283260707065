export const YamlPreviewApiAdapter = (client: any) => {
  return {
    getYaml: (yamlLink: string, token: string) =>
      client.get(`/${yamlLink}`, {
        headers: {
          Authorization: token,
        },
      }),
  };
};
