export const tableCacheKeys = {
  CLUSTERS_TABLE: 'clusters-table',
  ACCESS_KEYS: 'access-keys-table',
  RESERVED_INSTANCES: {
    UTILIZATION: 'reserved-instances-utilization-table',
  },
  BILLING_REPORT: {
    PER_CLUSTER: 'billing-report-per-cluster-table',
    DAILY: 'billing-report-daily-table',
  },
  CPU_USAGE_REPORT: {
    PER_CLUSTER: 'cpu-usage-report-per-cluster-table',
    DAILY: 'cpu-usage-report-daily-table',
  },
  SCHEDULED_REBALANCING: {
    MAIN: 'scheduled-rebalancing',
    CLUSTERS: 'clusters',
  },
  COST_MONITORING: {
    COMPUTE_COST: 'organization-compute-cost-clusters-table',

    CLUSTER: {
      DAILY_COMPUTE_SPEND: 'cluster-daily-compute-spend-details',
      NETWORK_DETAILS: 'daily-network-details-table',
      RESOURCE_EFFICIENCY: 'resource-efficiency-details',
      STORAGE_EFFICIENCY: 'storage-efficiency-details',
    },
    WORKLOADS: {
      COMPUTE_COST: 'workloads-compute-cost-table',
      NETWORK_COST: 'workloads-network-cost-table',
      EFFICIENCY: 'workloads-efficiency-table',
      GPU_UTILIZATION: 'workloads-gpu-utilization-summary-table',
      DETAILS: {
        COMPUTE_SPEND: 'workload-details-compute-spend',
      },
    },
    WORKLOAD: {
      GPU_UTILIZATION: 'workload-gpu-utilization-summary-table',
    },
    NAMESPACES: {
      LIST: 'namespaces-compute-cost-table',
      WORKLOADS_DRAWER: 'namespace-compute-cost-workloads-drawer-table',
      NETWORK_COST: 'namespaces-network-cost-table',
      NETWORK_COST_WORKLOADS_DRAWER: 'workload-network-details-drawer-table',
      COMPUTE_COST_DETAILS: 'namespace-compute-cost-details-table',
      COMPUTE_COST_DETAILS_WORKLOADS_DRAWER:
        'allocation-group-workloads-drawer-table',
    },
    ALLOCATION_GROUPS: {
      COMPUTE_COST: 'allocation-groups-table',
      NETWORK_COST: 'allocation-groups-network-cost-table',
      NETWORK_COST_WORKLOADS_DRAWER: 'workload-network-details-drawer-table',
      EFFICIENCY: 'allocation-groups-efficiency-table',
      EFFICIENCY_WORKLOADS_DRAWER:
        'allocation-group-workloads-efficiency-table',
    },
  },
  COMMITMENTS: {
    LIST: 'commitments-table',
  },
  SECURITY: {
    CHECKS: 'security-insights-compliance-checks-table',
    WORKLOAD_CHECKS: 'workload-security-insights-checks-table',
    IMAGES: 'security-insights-vulnerability-management-images-table',
    WORKLOAD_IMAGES: 'workload-security-insights-images-table',
    AFFECTED_RESOURCES:
      'security-insights-vulnerability-management-affected-resources-table',
    ATTACK_PATH: 'attack-path-table',
    WORKLOAD_ATTACK_PATH: 'workload-attack-path-table',
    ANOMALIES: 'anomalies-table',
    WORKLOAD_ANOMALIES: 'workload-anomalies-table',
    ANOMALY_EVENTS: 'anomaly-events-table',
    RUNTIME_EVENTS: 'runtime-events-table',
    NODE_OS_UPDATES: 'security-insights-node-os-updates-nodes-table',
    POLICY_ENFORCEMENT: 'security-insights-compliance-policy-enforcement-table',
    ATTACK_PATH_IMAGE_VULNERABILITIES:
      'security-insights-attack-path-image-vulnerabilities-table',
    RESOURCE_CHECKS: 'security-insights-resource-checks-table',
    COMPLIANCE_RESOURCES: 'security-insights-compliance-resources-table',
    COMPLIANCE_EXCEPTIONS:
      'security-insights-compliance-exceptions-drawer-resources-table',
    RUNTIME_RULES: 'runtime-rules-table',
  },
  CLUSTER_DASHBOARD: {
    UNSCHEDULED_WORKLOADS: 'cluster-unscheduled-workloads-table',
    WORKLOADS_GPU_UTILIZATION: 'workloads-gpu-utilization-table',
  },
  SAVINGS: {
    SPOT_INSTANCES_DRAWER: 'available-savings-workloads-drawer-table',
    DAILY_SAVINGS_DETAILS: 'daily-savings-details',
  },
  WORKLOAD_OPTIMIZATION: {
    WORKLOADS_LIST: 'workload-optimization-table',
  },
  NODES: {
    LIST: 'node-list-table',
    WORKLOADS: 'node-workloads-table',
  },
  NODE_SELECTION_TEMPLATES: 'node-selection-templates-table',
  NODE_CONFIG_TEMPLATES: 'node-configurations-table',
  REBALANCING: {
    LOG: 'rebalancer-history-table',
    SCHEDULES: 'rebalancer-cluster-schedules-table',
    AFFECTED_NODES: 'rebalancer-affected-nodes-table',
    SELECT_WORKLOADS: 'rebalancer-wizard-select-workloads-table',
    SELECT_NODES: 'rebalancer-wizard-select-nodes-table',
    WORKLOADS_IN_NODE: 'rebalancer-wizard-workloads-in-node-table',
  },
  AUDIT: {
    UNSCHEDULED_PODS: 'audit-unscheduled-pods-table',
  },
  LLM: {
    SAVINGS_BY_CATEGORY: 'api-key-savings-by-category-table',
    OPTIMIZATION_BY_API_KEY: 'llm-optimization-by-api-keys-table',
    SAVINGS_BY_API_KEY: 'category-savings-by-api-key',
    OPTIMIZATION_BY_CATEGORY: 'llm-optimization-by-category-table',
    PROVIDERS: 'llm-providers-table',
  },
  NOTIFICATIONS: 'notifications-table',
};
