import { Breadcrumb, Breadcrumbs } from '@cast/design-system';

import { Link } from 'components/router';
import { useOrganizations } from 'hooks/useOrganizations';

export const BillingReportBreadcrumbs = () => {
  const { currentOrganization } = useOrganizations();

  if (!currentOrganization) {
    return null;
  }

  return (
    <Breadcrumbs>
      <Breadcrumb>
        <Link
          to="/organization"
          style={{ color: 'inherit', textDecoration: 'inherit' }}
        >
          Organization
        </Link>
      </Breadcrumb>
      <Breadcrumb>Billing report</Breadcrumb>
    </Breadcrumbs>
  );
};
