import { AxiosInstance } from 'axios';
import isEmpty from 'lodash/isEmpty';

import { AuditLogEntry, ScrollableList, AuditLogFilter } from '@cast/types';

export const AuditLogApiFactory = (client: AxiosInstance) => ({
  getAuditLog: (filter?: AuditLogFilter) => {
    let labelString = '';
    const { labels, ...restFilter } = filter || {};

    // https://spec.openapis.org/oas/v3.1.0.html#style-values deepObject style
    if (filter && !isEmpty(labels)) {
      labelString += Object.entries(labels)
        .map(([key, value]) => `labels[${key}]=${value}`)
        .join('&');
    }

    return client.get<ScrollableList<AuditLogEntry>>(
      `/audit${labelString ? `?${labelString}` : ''}`,
      {
        params: { 'page.limit': 20, ...restFilter },
      }
    );
  },
});
