import { forwardRef, useCallback } from 'react';

import { Typography } from '@mui/material';

import {
  Button,
  DrawerProps,
  Icons,
  useDrawer,
  useDrawerInstance,
} from '@cast/design-system';

import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { SearchProvider, UseServerData } from 'components/search';
import { RBACGuard } from 'core/rbac';
import { useGetWebhooksQuery } from 'hooks/queries/notifications';

import { AddWebhookDrawer } from './AddWebhookDrawer';
import { WebhooksList } from './WebhooksList';

const useData: UseServerData = ({ sortingState, enabled }) => {
  const { webhooks, isLoading, error, refetch, fetchNextPage, hasNextPage } =
    useGetWebhooksQuery({ sortingState, enabled });
  return {
    data: webhooks?.filter((webhook) => !webhook?.deletedAt),
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage: !isLoading && hasNextPage,
  };
};

export const WebhooksDrawer = forwardRef<HTMLDivElement, DrawerProps>(
  ({ open, ...drawerProps }, ref) => {
    const { close } = useDrawerInstance();
    const { open: openDrawer } = useDrawer();
    const onAddWebhook = useCallback(() => {
      close(() => {
        openDrawer(AddWebhookDrawer, {});
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Drawer
        ref={ref}
        size="xsmall"
        open={open}
        onClose={() => close()}
        {...drawerProps}
        overlayWhenSubdrawerActive
        testId="webhooks-drawer"
      >
        <DrawerHeader
          noDivider
          sx={{ paddingRight: '16px' }}
          actions={
            <RBACGuard permissionType="create">
              {(permission) => (
                <Button
                  onClick={onAddWebhook}
                  disabled={!permission}
                  size="small"
                  startIcon={<Icons.Plus size={16} />}
                  testId="add-webhook-button"
                >
                  Add webhook
                </Button>
              )}
            </RBACGuard>
          }
        >
          <Typography variant="h5" pt="12px" color="grey.900">
            Webhooks
          </Typography>
        </DrawerHeader>
        <DrawerContent
          height="100%"
          sx={{
            padding: 0,
          }}
          contentWrapperProps={{
            sx: {
              height: '100%',
            },
          }}
        >
          <SearchProvider useData={useData} serverSideSort>
            <WebhooksList />
          </SearchProvider>
        </DrawerContent>
      </Drawer>
    );
  }
);

WebhooksDrawer.displayName = 'WebhooksDrawer';
