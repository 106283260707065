import { differenceInMinutes, format } from 'date-fns';
import { DateRange } from 'react-day-picker';

import { defaultDateFormat } from './constants';
import { Filter } from './types';

export const isSelectedFilterPreset = (
  value: DateRange | undefined,
  filter: Filter
): boolean => {
  return (
    !!value?.from &&
    Math.abs(differenceInMinutes(filter.date().from, value.from)) < 5 &&
    !!value?.to &&
    Math.abs(differenceInMinutes(filter.date().to, value.to)) < 5
  );
};

export const formatInputValue = (
  from: Date,
  to: Date,
  _format = defaultDateFormat
): string => {
  return `${format(from, _format)} - ${format(to, _format)}`;
};
