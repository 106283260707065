import { Typography, TypographyProps } from '@mui/material';
import round from 'lodash/round';
import NumberFormat from 'react-number-format';

type Props = {
  value: number;
  postfix: string;
} & TypographyProps;

export const CellWithPostfix = ({ value, postfix, ...rest }: Props) => {
  if (isNaN(value)) {
    return null;
  }
  return (
    <Typography variant="P12R" color="grey.900" {...rest}>
      <NumberFormat
        value={round(value, 2)}
        thousandSeparator
        displayType="text"
        data-testid="value"
      />
      <Typography variant="P10R" color="grey.400" pl="4px" component="span">
        {postfix}
      </Typography>
    </Typography>
  );
};
