import { ThemeOptions } from '@mui/material';

import { DsShadow } from '../../../theme/shadows';
import { activeProps } from '../../../theme/utils';

export const dropdownsTheme: ThemeOptions['components'] = {
  DsDropdown: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&& .DS-DropdownInput-popupOpen > .DS-DropdownInput-inputWrapper': {
          ...activeProps(theme),
        },
        '&.DS-Dropdown-withPrefix': {
          '&.DS-Dropdown-size': {
            '&Small': {
              '& .DS-Dropdown-DropdownPrefix': {
                ...theme.typography.P12R,
                paddingLeft: '8px',
                paddingRight: '8px',
              },
            },
            '&Medium': {
              '& .DS-Dropdown-DropdownPrefix': {
                ...theme.typography.P14R,
                paddingLeft: '10px',
                paddingRight: '10px',
              },
            },
            '&Large': {
              '& .DS-Dropdown-DropdownPrefix': {
                ...theme.typography.P14R,
                paddingLeft: '12px',
                paddingRight: '12px',
              },
            },
          },
          '&.DS-Dropdown-root .MuiInputLabel-root, &.DS-Dropdown-root .MuiFormHelperText-root':
            {
              gridColumn: '1 / -1',
            },
          '& .DS-DropdownInput-inputWrapper.MuiInputBase-root': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
          '& .DS-Input-startOutsideAdornment': {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            border: '1px solid',
            borderColor: theme.palette.grey[200],
            borderRight: 'none',
          },
          '& .DS-Dropdown-DropdownPrefix': {
            width: 'max-content',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.grey[400],
            backgroundColor: 'white',
            borderTopLeftRadius: 'inherit',
            borderBottomLeftRadius: 'inherit',
          },
        },
      }),
      popper: ({ theme }) => ({
        borderRadius: '4px',
        zIndex: theme.zIndex.drawer! + 1,
        backgroundColor: 'white',
        boxShadow: theme.shadows[DsShadow.DROPDOWN],
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: '4px 0',
      }),
      list: ({ theme }) => ({
        '& .DS-DropdownList-clearAllButton': {
          '& .MuiListItemText-root': {
            color: theme.palette.blue[500],
          },
        },
        '& .DS-Option-selected:not(.DS-Option-checkable)': {
          backgroundColor: theme.palette.blue[50],
          color: theme.palette.blue[700],
          '& .MuiListItemText-root': {
            color: 'inherit',
          },
        },
        '& .DS-Option-selected.DS-Option-checkable': {
          backgroundColor: 'inherit',
          color: 'inherit',
        },
        '& .DS-Option .DsListItem-content .DsListItem-root:first-of-type': {
          padding: 0,
        },
      }),
      input: ({ theme }) => ({
        cursor: 'pointer',

        '& .DS-DropdownInput-inputWrapper': {
          '&:not(.Mui-disabled)': {
            cursor: 'inherit',
          },
          '& input': {
            cursor: 'inherit',
            caretColor: 'transparent',
          },
        },
        '& .DS-Dropdown-endAdornmentWrapper': {
          height: '100%',
          alignItems: 'center',
          display: 'flex',
          gap: '10px',
        },
        '&& .DS-Dropdown-arrow': {
          '&:not(.Mui-disabled)': {
            color: theme.palette.grey[500],
          },
          fontSize: '16px',
        },

        '& .DS-Input-startAdornment-root:empty': {
          display: 'none',
        },
        '&.DS-DropdownInput-multipleValueSelected.DS-DropdownInput-popupClosed':
          {
            '& input': {
              opacity: 0,
              display: 'none',
            },
            '& .DS-Input-startAdornment-root': {
              flexGrow: 1,
              paddingRight: 0,
            },
          },
        '& .DS-Dropdown-startAdornmentWrapper': {
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          width: 'max-content',
          '& .DS-Dropdown-multiValueTextWrapper': {
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            color: theme.palette.grey[900],
            '& .DS-Dropdown-multiValueText:not(.disabled):not(.selected)': {
              color: theme.palette.grey[500],
            },
            '& .DS-Dropdown-multiValueText.DS-Dropdown-emptyDropdown:not(.disabled)':
              {
                color: theme.palette.grey[500],
              },
            '& .DS-Dropdown-multiValueText.disabled.selected': {
              color: theme.palette.grey[300],
            },
            '& .DS-Dropdown-multiValueCount': {
              lineHeight: 'calc(var(--ds-input-lineHeight) - 2px)',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px 8px',
              minWidth: '25px',
              borderRadius: '40px',
              boxSizing: 'border-box',
              backgroundColor: theme.palette.indigo[300],
              color: 'white',
              '&.disabled': {
                backgroundColor: theme.palette.grey[600],
              },
            },
          },
        },
      }),
    },
  },
};
