import {
  demoClusterSecurityInsightsBestPractice,
  demoClusterSecurityInsightsBestPractices,
  demoClusterSecurityInsightsBestPracticesFilters,
  demoClusterSecurityInsightsImages,
  demoClusterSecurityInsightsImageDigests,
  demoClusterSecurityInsightsImageDetails,
  demoClusterSecurityInsightsImageVulnerabilities,
  demoClusterSecurityInsightsImageVulnerabilityDetails,
  demoClusterSecurityInsightsImagePackages,
  demoClusterSecurityInsightsImageResources,
  demoOrganizationSecurityInsightsAgents,
  demoOrganizationSecurityInsightsAttackPaths,
  demoOrganizationSecurityInsightsAttackPathDetails,
  demoOrganizationSecurityInsightsResourceDetails,
  demoOrganizationSecurityInsightsIntegrations,
  demoClusterSecurityInsightsBestPracticeResources,
  demoClusterSecurityInsightsBaseImageAlternatives,
  demoOrganizationSecurityInsightsBestPracticeOverview,
  demoOrganizationSecurityInsightsImageSecurityOverview,
  demoOrganizationSecurityInsightsAttackPathsOverview,
  demoOrganizationSecurityInsightsClustersSettings,
  demoOrganizationSecurityInsightsWorkloads,
} from '@cast/fixtures';
import { isDemoMode } from '@cast/utils';

import { SecurityInsightsApiAdapter } from '../api-client/security-insights-api-adapter';
import {
  isDemoClusterRequest,
  rejectDemoClusterRequest,
  resolveDemoClusterRequest,
} from '../utils';

export const SecurityInsightsApiProxy = (
  origin: ReturnType<typeof SecurityInsightsApiAdapter>
): ReturnType<typeof SecurityInsightsApiAdapter> => {
  return {
    ...origin,
    getBestPracticeFilters: async (
      ...args: Parameters<typeof origin.getBestPracticeFilters>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterSecurityInsightsBestPracticesFilters();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getBestPracticeFilters(...args);
    },
    getBestPracticeChecks: async (
      ...args: Parameters<typeof origin.getBestPracticeChecks>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterSecurityInsightsBestPractices();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getBestPracticeChecks(...args);
    },
    getBestPracticeCheck: async (
      ...args: Parameters<typeof origin.getBestPracticeCheck>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterSecurityInsightsBestPractice(
          args[0]?.ruleId
        );
        if (fixture) {
          return resolveDemoClusterRequest(fixture);
        }
        return rejectDemoClusterRequest();
      }

      return origin.getBestPracticeCheck(...args);
    },
    getBestPracticeCheckResources: async (
      ...args: Parameters<typeof origin.getBestPracticeCheckResources>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterSecurityInsightsBestPracticeResources(
          args[0]?.ruleId
        );
        if (fixture) {
          return resolveDemoClusterRequest(fixture);
        }
        return rejectDemoClusterRequest();
      }

      return origin.getBestPracticeCheckResources(...args);
    },
    getAgentsStatuses: async (
      ...args: Parameters<typeof origin.getAgentsStatuses>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoOrganizationSecurityInsightsAgents();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAgentsStatuses(...args);
    },
    getContainerImages: async (
      ...args: Parameters<typeof origin.getContainerImages>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoClusterSecurityInsightsImages();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getContainerImages(...args);
    },
    getImageDetails: async (
      ...args: Parameters<typeof origin.getImageDetails>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoClusterSecurityInsightsImageDetails(
          args[0]?.tagId
        );
        if (fixture) {
          return resolveDemoClusterRequest(fixture);
        }
        return rejectDemoClusterRequest();
      }

      return origin.getImageDetails(...args);
    },
    getImageDigests: async (
      ...args: Parameters<typeof origin.getImageDigests>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoClusterSecurityInsightsImageDigests(
          args[0]?.tagId
        );
        if (fixture) {
          return resolveDemoClusterRequest(fixture);
        }
        return rejectDemoClusterRequest();
      }

      return origin.getImageDigests(...args);
    },
    getImageVulnerabilities: async (
      ...args: Parameters<typeof origin.getImageVulnerabilities>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoClusterSecurityInsightsImageVulnerabilities();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getImageVulnerabilities(...args);
    },
    getImagePackageVulnerabilityDetails: async (
      ...args: Parameters<typeof origin.getImagePackageVulnerabilityDetails>
    ) => {
      if (isDemoMode()) {
        const fixture =
          await demoClusterSecurityInsightsImageVulnerabilityDetails(
            args[0].pkgVulnId
          );
        if (fixture) {
          return resolveDemoClusterRequest(fixture);
        }
        return rejectDemoClusterRequest();
      }

      return origin.getImagePackageVulnerabilityDetails(...args);
    },
    getImagePackages: async (
      ...args: Parameters<typeof origin.getImagePackages>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoClusterSecurityInsightsImagePackages();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getImagePackages(...args);
    },
    getImageResources: async (
      ...args: Parameters<typeof origin.getImageResources>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoClusterSecurityInsightsImageResources();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getImageResources(...args);
    },
    getImageAlternatives: async (
      ...args: Parameters<typeof origin.getImageAlternatives>
    ) => {
      if (isDemoMode()) {
        const fixture =
          await demoClusterSecurityInsightsBaseImageAlternatives();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getImageAlternatives(...args);
    },
    getAttackPaths: async (
      ...args: Parameters<typeof origin.getAttackPaths>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoOrganizationSecurityInsightsAttackPaths();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAttackPaths(...args);
    },
    getAttackPathDetails: async (
      ...args: Parameters<typeof origin.getAttackPathDetails>
    ) => {
      if (isDemoMode()) {
        const fixture =
          await demoOrganizationSecurityInsightsAttackPathDetails();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAttackPathDetails(...args);
    },
    getResourceDetails: async (
      ...args: Parameters<typeof origin.getResourceDetails>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoOrganizationSecurityInsightsResourceDetails(
          args[0]?.id
        );
        if (fixture) {
          return resolveDemoClusterRequest(fixture);
        }
        return rejectDemoClusterRequest();
      }
      return origin.getResourceDetails(...args);
    },
    getIntegrations: async (
      ...args: Parameters<typeof origin.getIntegrations>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoOrganizationSecurityInsightsIntegrations();
        return resolveDemoClusterRequest(fixture);
      }
      return origin.getIntegrations(...args);
    },
    getBestPracticeOverview: async (
      ...args: Parameters<typeof origin.getBestPracticeOverview>
    ) => {
      if (isDemoMode()) {
        const fixture =
          await demoOrganizationSecurityInsightsBestPracticeOverview();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getBestPracticeOverview(...args);
    },
    getImageSecurityOverview: async (
      ...args: Parameters<typeof origin.getImageSecurityOverview>
    ) => {
      if (isDemoMode()) {
        const fixture =
          await demoOrganizationSecurityInsightsImageSecurityOverview();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getImageSecurityOverview(...args);
    },
    getAttackPathsOverview: async (
      ...args: Parameters<typeof origin.getAttackPathsOverview>
    ) => {
      if (isDemoMode()) {
        const fixture =
          await demoOrganizationSecurityInsightsAttackPathsOverview();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAttackPathsOverview(...args);
    },
    getClustersSettings: async (
      ...args: Parameters<typeof origin.getClustersSettings>
    ) => {
      if (isDemoMode()) {
        const fixture =
          await demoOrganizationSecurityInsightsClustersSettings();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getClustersSettings(...args);
    },
    getWorkloads: async (...args: Parameters<typeof origin.getWorkloads>) => {
      if (isDemoMode()) {
        const fixture = await demoOrganizationSecurityInsightsWorkloads();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloads(...args);
    },
  };
};
