import { ElementType } from 'react';

import { Typography, TypographyProps } from '@mui/material';

import { mergeSx } from '@cast/design-system';

export type LinkBehaviourProps<C extends ElementType = any> = TypographyProps<
  C,
  { component?: C; secondaryAction?: boolean; testId?: string }
>;

export const LinkBehaviour = <C extends ElementType = any>({
  secondaryAction,
  sx,
  testId,
  color = 'blue.500',
  ...props
}: LinkBehaviourProps<C>) => {
  return (
    <Typography
      variant="inherit"
      sx={mergeSx(
        { cursor: 'pointer' },
        secondaryAction
          ? {
              '&:hover': {
                color: 'blue.600',
              },
            }
          : {
              // TODO: Should be removed during https://castai.atlassian.net/browse/UI-2139
              '&:hover': {
                textDecoration: 'underline',
              },
            },
        sx
      )}
      color={color}
      data-testid={testId}
      {...props}
    />
  );
};
