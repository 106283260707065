import { useContext } from 'react';

import Input, { InputProps } from '../../controls/input/Input';
import { CalendarBlank } from '../../icons';
import { DatePickerContext } from '../DatePickerProvider';

export const DateTimeInput = (props: InputProps) => {
  const { allowTimeInput } = useContext(DatePickerContext);
  return (
    <Input
      size="medium"
      startAdornment={<CalendarBlank size="20px" />}
      placeholder={`yyyy/mm/dd${allowTimeInput ? ' hh:mm' : ''}`}
      {...props}
    />
  );
};
