import dayjs from 'dayjs';

import { AgentStatus, Cluster } from '@cast/types';

import { SUPPORTS_STORAGE } from 'common/constants';
import { flags } from 'core/flags';

export const isDisconnectedForMonth = (cluster: Cluster) =>
  cluster.agentStatus === AgentStatus.DISCONNECTED &&
  dayjs(cluster.agentSnapshotReceivedAt).isBefore(dayjs().subtract(30, 'day'));

export const isManaged = (cluster: Cluster) => Boolean(cluster.credentialsId);

export const isStorageFlagEnabled = () =>
  flags['standalone']['cost-monitoring-storage'].value;

export const isStorageReportsSupported = ({ providerType }: Cluster) =>
  isStorageFlagEnabled() && SUPPORTS_STORAGE.includes(providerType);
