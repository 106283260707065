import { styled } from '@mui/material';
import { SxProps } from '@mui/system';

import { Chip, ChipProps, Tooltip } from '@cast/design-system';
import { Cluster, ComputedNodeStatus, NodeStatus } from '@cast/types';

import { useCluster } from 'hooks/useCluster';
import { isAKSCluster } from 'types/cluster';
import { isClusterOnboardingPhase2 } from 'utils/cluster';

type OwnerState = {
  status: Props['status'];
};

type Props = ChipProps & {
  status?: NodeStatus | string | ComputedNodeStatus;
  className?: string;
  sx?: SxProps;
  showTooltip?: boolean;
};

const StatusChipBase = styled(Chip, {
  target: 'StatusChip-root',
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<
  ChipProps & {
    ownerState?: OwnerState;
  }
>(({ theme, size, ownerState }) => {
  return [
    {
      color: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[200],
    },
    size === 'small' && {
      ...theme.typography.P10M,
    },
    size === 'medium' && {
      ...theme.typography.P14M,
    },
    ownerState?.status &&
      NodeStatus.ready === ownerState.status && {
        backgroundColor: theme.palette.green[50],
        color: theme.palette.green[600],
      },
    ownerState?.status &&
      NodeStatus.draining === ownerState.status && {
        backgroundColor: theme.palette.red[50],
        color: theme.palette.red[500],
      },
    ownerState?.status &&
      (
        [
          NodeStatus.creating,
          NodeStatus.pending,
          NodeStatus.deleting,
          NodeStatus.deleted,
          NodeStatus.notReady,
          'not-ready',
        ] as any[]
      ).includes(ownerState.status) && {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[600],
      },
    ownerState?.status &&
      ([ComputedNodeStatus.CORDONED, NodeStatus.interrupted] as any[]).includes(
        ownerState.status
      ) && {
        backgroundColor: theme.palette.yellow[50],
        color: theme.palette.yellow[900],
      },
  ];
});

const getStatusInfo = (
  cluster: Cluster,
  isManaged: boolean,
  status?: NodeStatus | ComputedNodeStatus | string
) => {
  const isAks = isAKSCluster(cluster);
  const clusterIsConnecting = isClusterOnboardingPhase2(
    cluster.status,
    isManaged
  );

  switch (status) {
    case NodeStatus.ready:
      return {
        label: 'Ready',
      };
    case NodeStatus.notReady:
    case 'not-ready': // TODO: remove when CORE-2361 is done
      return {
        tooltip:
          isAks && clusterIsConnecting
            ? 'This is a temporary node currently being used to set up CAST AI. It will be automatically removed once the operation finishes.'
            : undefined,
        label: 'Not ready',
      };
    case NodeStatus.creating:
      return {
        label: 'Creating',
      };
    case NodeStatus.pending:
      return {
        label: 'Pending',
      };
    case NodeStatus.deleting:
      return {
        label: 'Deleting',
      };
    case NodeStatus.draining:
      return {
        label: 'Draining',
      };
    case ComputedNodeStatus.CORDONED:
      return {
        tooltip: 'Scheduling disabled',
        label: 'Cordoned',
      };
    case NodeStatus.deleted:
      return {
        label: 'Deleted',
      };
    case NodeStatus.interrupted:
      return {
        label: 'Interrupted',
      };
    case NodeStatus.unknown:
      return {
        label: 'Unknown',
      };
    default:
      return {
        label: status,
      };
  }
};

export const NodeStatusChip = ({
  status,
  className,
  showTooltip = true,
  ...props
}: Props) => {
  const { cluster, isManaged } = useCluster();
  const { label, tooltip } = getStatusInfo(cluster, isManaged, status);

  return (
    <Tooltip title={(showTooltip && tooltip) || ''} placement="top" arrow>
      <StatusChipBase
        className={className}
        size="small"
        ownerState={{ status }}
        {...props}
      >
        {label}
      </StatusChipBase>
    </Tooltip>
  );
};
