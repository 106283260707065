import set from 'lodash/set';

import { FeatureFlagResolved } from '@cast/types';

export const parseFlags = (flags?: FeatureFlagResolved[]) => {
  return flags?.reduce((acc, current) => {
    const key = current.flagName.replace('ui__', '').replace(/--|__/g, '.');
    set(acc, key, {
      value: current.boolean,
      reason: current.reason,
    });
    return acc;
  }, {} as Record<string, { value: boolean | string; reason: string }>);
};
