import { Theme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

type DayPickerColorSchema = {
  typography: TypographyOptions;
  text: string;
  textSecondary: string;
  textDisabled: string;
  label: string;
  accentLight: string;
  accentContrast: string;
  accent: string;
  accentDark: string;
  accentExtraDark: string;
  background: string;
  backgroundTrail: string;
  activeBackground: string;
  disabledBackground: string;
};

export const dayPickerStyles = ({
  typography,
  text,
  textSecondary,
  textDisabled,
  label,
  accent,
  accentLight,
  accentContrast,
  accentDark,
  accentExtraDark,
  background,
  backgroundTrail,
  activeBackground,
  disabledBackground,
}: DayPickerColorSchema): SystemStyleObject<Theme> => ({
  '.rdp': {
    '--rdp-cell-size': '32px',
    '--rdp-bubble-size': '28px',
    '--rdp-text-color': text,
    '--rdp-accent-color': accentLight,
    '--rdp-background-color': background,
    '--rdp-outline': '2px solid var(--rdp-accent-color)',
    '--rdp-outline-selected': '3px solid var(--rdp-accent-color)',
    fontFamily: "'Poppins'",
    margin: '16px',
  },
  '.rdp-vhidden': {
    boxSizing: 'border-box',
    padding: '0 0 !important',
    margin: '0',
    background: 'transparent',
    border: '0  0 !important',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none',
    position: 'absolute !important',
    top: '0',
    width: '1px !important',
    height: '1px !important',
    overflow: 'hidden !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
  },
  '.rdp-button_reset': {
    appearance: 'none',
    position: 'relative',
    margin: '0',
    padding: '0',
    cursor: 'default',
    color: 'inherit',
    background: 'none',
    font: 'inherit',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
  },
  '.rdp-button_reset:focus-visible': { outline: 'none' },
  '.rdp-button[disabled]': { color: 'grey.300' },
  '.rdp-mode_single': {
    [`& .rdp-day-container--last_day_of_week,
      & .rdp-day-container--hover_trail_end:not(.rdp-day-container__selected--hover_trail),
      & .rdp-day-container__selected--hover_trail_end:not(.rdp-day-container--hover_trail),
      & .rdp-day-container--hover_trail.rdp-day-container__selected--hover_trail_end.rdp-day-container--hover_trail_end`]:
      {
        width: '28px',
      },

    '& .rdp-day-container--hover_trail': {
      background: backgroundTrail,
    },
    '& .rdp-day-container__selected--hover_trail': {
      background: accentLight,
    },

    '& .rdp-day-container--hover_trail.rdp-day-container__selected--hover_trail_start':
      {
        background: `linear-gradient(90deg, ${backgroundTrail} 50%, ${accentLight} 50%)`,
      },
    '& .rdp-day-container--hover_trail.rdp-day-container__selected--hover_trail_end:not(.rdp-day-container--hover_trail_end)':
      {
        background: `linear-gradient(90deg, ${accentLight} 50%, ${backgroundTrail} 50%)`,
        '& .rdp-button': {
          background: accentLight,
        },
      },
    [`& .rdp-day-container--hovered.rdp-day-container--hover_trail_start:not(.rdp-day-container__selected--hover_trail),
      & .rdp-day-container_selected:not(.rdp-day-container--hover_trail),
      & .rdp-day-container_selected.rdp-day-container--hover_trail_start,  
      & .rdp-day-container--first_day_of_week`]: {
      borderTopLeftRadius: '100%',
      borderBottomLeftRadius: '100%',
    },
    [`& .rdp-day-container--hover_trail_end:not(.rdp-day-container__selected--hover_trail),
      & .rdp-day-container__selected--hover_trail_end:not(.rdp-day-container--hover_trail), 
      & .rdp-day-container__selected--hover_trail_end.rdp-day-container--hover_trail_end,
      & .rdp-day-container--last_day_of_week`]: {
      borderTopRightRadius: '100%',
      borderBottomRightRadius: '100%',
    },
    '& .rdp-day-container--hovered:not(:active)': {
      '& .rdp-button:not([disabled]):hover': {
        background: accentLight,
      },
      '&.rdp-day-container__selected--hover_trail': {
        '& .rdp-button:hover': {
          background: accentContrast,
        },
      },
    },
  },
  '.rdp-button:not([disabled])': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'var(--rdp-background-color)',
    },
    '&:active, &:focus-visible': {
      backgroundColor: activeBackground,
    },
  },

  '.rdp-months': { display: 'flex' },
  '.rdp-month': { margin: '0 25px' },
  '.rdp-month:first-of-type': { marginLeft: '0' },
  '.rdp-month:last-child': { marginRight: '0' },
  '.rdp-table': {
    margin: '0',
    maxWidth: 'calc(var(--rdp-cell-size) * 7)',
    borderSpacing: '0px 2px',
  },
  '.rdp-with_weeknumber .rdp-table': {
    maxWidth: 'calc(var(--rdp-cell-size) * 8)',
  },
  '.rdp-caption': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0',
    textAlign: 'left',
    marginBottom: '11px',
  },
  '.rdp-multiple_months .rdp-caption': {
    position: 'relative',
    display: 'block',
    textAlign: 'center',
  },
  '.rdp-caption_dropdowns': { position: 'relative', display: 'inline-flex' },
  '.rdp-caption_label': {
    position: 'relative',
    zIndex: 1,
    display: 'inline-flex',
    alignItems: 'center',
    margin: '0',
    padding: '0 0.25em',
    whiteSpace: 'nowrap',
    ...typography.P12B,
    color: label,
  },
  '.rdp-nav': {
    whiteSpace: 'nowrap',
    '& svg': { width: '12px', height: '12px' },
  },
  '.rdp-multiple_months .rdp-caption_start .rdp-nav': {
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
  },
  '.rdp-multiple_months .rdp-caption_end .rdp-nav': {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translateY(-50%)',
  },
  '.rdp-nav_button': {
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(var(--rdp-cell-size) * 0.8)',
    height: 'calc(var(--rdp-cell-size) * 0.8)',
    padding: '0.25em',
    borderRadius: '5px',

    '&:not([disabled]):active': {
      backgroundColor: textDisabled,
    },
  },
  '.rdp-dropdown_year,\n.rdp-dropdown_month': {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
  },
  '.rdp-dropdown': {
    appearance: 'none',
    position: 'absolute',
    zIndex: 2,
    top: '0',
    bottom: '0',
    left: '0',
    width: '100%',
    margin: '0',
    padding: '0',
    cursor: 'inherit',
    opacity: 0,
    border: 'none',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  '.rdp-dropdown[disabled]': { opacity: 'unset', color: 'unset' },
  '.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label, .rdp-dropdown:active:not([disabled]) + .rdp-caption_label':
    {
      backgroundColor: 'var(--rdp-background-color)',
      border: 'var(--rdp-outline)',
      borderRadius: '6px',
    },
  '.rdp-dropdown_icon': { margin: '0 0 0 5px' },
  '.rdp-head': { border: '0' },
  '.rdp-head_row,\n.rdp-row': { height: '100%' },
  '.rdp-head_cell': {
    verticalAlign: 'middle',
    textAlign: 'center',
    height: '100% var(--rdp-cell-size)',
    padding: '0',
    ...typography.P10R,
    color: textSecondary,
  },
  '.rdp-tbody': { border: '0' },
  '.rdp-tfoot': { margin: '0.5em' },
  '.rdp-cell': {
    width: 'var(--rdp-cell-size)',
    height: '100% var(--rdp-cell-size)',
    padding: '2px 0',
    textAlign: 'center',

    '&:first-of-type': {
      '& .rdp-day-container:before': {
        background: 'none',
      },
      '& button': {
        borderBottomLeftRadius: '100%',
        borderTopLeftRadius: '100%',
      },
    },

    '&:last-child': {
      '& .rdp-day-container:after': {
        display: 'none',
      },
      '& button': {
        borderBottomRightRadius: '100%',
        borderTopRightRadius: '100%',
      },
    },
  },

  '.rdp-weeknumber': { fontSize: '0.75em' },
  '.rdp-weeknumber, .rdp-day': {
    color: text,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: 'var(--rdp-cell-size)',
    maxWidth: 'var(--rdp-cell-size)',
    height: 'var(--rdp-cell-size)',
    margin: '0',
    borderRadius: '100%',
    ...typography.P12R,
  },
  '.rdp-day_outside': {
    color: textDisabled,
  },
  '.rdp-day_today_selected': {
    border: 'none',
  },
  '.rdp-day_today:not(.rdp-day_outside)': {
    overflow: 'visible',
    '&:before': {
      outlineOffset: '1px',
      outline: `1px solid ${accentContrast}`,
    },
  },
  '.rdp-day_selected': {
    background: 'padding-box',
    borderLeft: '0px',
    borderRight: '0px',
  },

  ".rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end)": {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  ".rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start)": {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  ".rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end)": {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  ".rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start)": {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  '.rdp-day_range_end.rdp-day_range_start': { borderRadius: '100%' },
  '.rdp-day_range_middle': { borderRadius: '0' },

  '.rdp-day': {
    width: 'var(--rdp-bubble-size)',
    height: 'var(--rdp-bubble-size)',
    border: 'none',
    borderRadius: '100%',

    '&_today:not(.rdp-day_range_end):not(.rdp-day_range_start)': {
      '&:after': {
        position: 'absolute',
        inset: '0',
        borderRadius: '100%',
        border: `1px solid ${accent}`,
        zIndex: 2,
        display: 'flex',
        content: '""',
      },
    },
  },
  '.rdp-mode_range .rdp-day-container': {
    display: 'flex',
    justifyContent: 'center',

    '&_selected': {
      '&:before': {
        backgroundColor: 'var(--rdp-accent-color)',
      },
      '&:after': {
        backgroundColor: 'var(--rdp-accent-color)',
      },
    },
    '&_range_start': {
      '&:before': {
        background: 'none',
      },
    },
    '&_range_end': {
      '&:after,': {
        background: 'none',
      },
    },
  },

  '.rdp-day_range_start, .rdp-day_range_end, .rdp-mode_multiple .rdp-day_selected, .rdp-mode_single .rdp-day_selected':
    {
      zIndex: 0,
      color: 'white',
      '&:before': {
        content: '""',
        borderRadius: '100%',
        inset: 0,
        zIndex: -1,
        display: 'block',
        position: 'absolute',
        backgroundColor: accentDark,
        width: '100%',
      },
    },

  // Multiple, Single
  '.rdp-mode_multiple, .rdp-mode_single': {
    '.rdp-day': {
      overflow: 'visible',

      // Not selected
      '&:not(.rdp-day_selected)': {
        '&:hover': {
          '&:before': {
            background: accentExtraDark,
            color: 'white',
          },
        },

        '&:active': {
          background: accentDark,
          fontWeight: 600,
          color: 'white',
        },

        '&.rdp-day_disabled': {
          color: textDisabled,

          '&:after': {
            borderColor: disabledBackground,
          },
        },
      },
    },

    // Selected
    '.rdp-day_selected': {
      fontWeight: 600,
      color: 'white',

      '&:hover': {
        '&:before': {
          background: accentExtraDark,
          color: 'white',
        },
      },

      '&:focus': {
        '&:before': {
          background: accentDark,
          color: 'white',
        },
      },

      '&:active': {
        '&:before': {
          background: accentExtraDark,
          fontWeight: 600,
          color: 'white',
        },
      },

      '&.rdp-day_disabled': {
        color: textDisabled,

        '&:after': {
          border: 'none',
        },

        '&:before': {
          background: `${disabledBackground}!important`,
          outline: `1px solid ${textDisabled}`,
        },
      },
    },
  },

  // Range
  '.rdp-mode_range': {
    '.rdp-day': {
      overflow: 'visible',
    },

    '.rdp-day-container': {
      '&.rdp-day-container--first_day_of_week': {
        width: '28px',
        borderRadius: '100% 0 0 100%',
        marginLeft: 'auto',

        '&:before, &:after': {
          display: 'none',
        },
      },
      '&.rdp-day-container--last_day_of_week': {
        width: '28px',
        borderRadius: '0 100% 100% 0',

        '&:before, &:after': {
          display: 'none',
        },
      },
      '&.rdp-day-container_range_start:not(.rdp-day-container_range_end)': {
        borderRadius: '100% 0 0 100%',
      },
      '&.rdp-day-container_range_end:not(.rdp-day-container_range_start)': {
        borderRadius: '0 100% 100% 0',
      },
      '&.rdp-day-container_range_start.rdp-day-container_range_end': {
        borderRadius: '100%',
      },

      // Not selected
      '&:not(.rdp-day-container_selected)': {
        '.rdp-day': {
          '&:focus': {
            background: accentLight,
          },

          '&:active': {
            background: accentDark,
            fontWeight: 600,
            color: 'white',
          },
        },

        '.rdp-day_disabled': {
          color: textDisabled,

          '&:after': {
            borderColor: disabledBackground,
          },
        },
      },

      // Selected
      '&.rdp-day-container_selected': {
        background: 'var(--rdp-accent-color)',

        '&.rdp-day-container_range_start': {
          background:
            'linear-gradient( to right, transparent 0% 50%, var(--rdp-accent-color) 50% 100% )',
        },

        '&.rdp-day-container_range_end': {
          background:
            'linear-gradient( to left, transparent 0% 50%, var(--rdp-accent-color) 50% 100% )',
        },

        '&.rdp-day-container_range_start, &.rdp-day-container_range_end': {
          '.rdp-day': {
            fontWeight: 600,
            overflow: 'visible',
            background: accentLight,

            '&:hover': {
              '&:before': {
                background: activeBackground,
              },
            },

            '&:focus': {
              '&:before': {
                background: accentDark,
              },
            },

            '&:active': {
              '&:before': {
                background: accentExtraDark,
              },
            },
          },
        },

        '.rdp-day_disabled': {
          color: textDisabled,

          '&:before': {
            background: `${disabledBackground}!important`,
            outline: `1px solid ${textDisabled}`,
          },
        },
      },
    },

    '.rdp-day_selected:not(.rdp-day_range_start):not(.rdp-day_range_end)': {
      '&:hover': {
        background: accentContrast,
        borderRadius: '100%',
      },
    },
  },
});
