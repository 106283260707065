import { BillingReportClusterUsage, DateRange, SetState } from '@cast/types';

import { ChartType } from 'types/charts';
import {
  TopMostExpensiveGroup,
  TopMostExpensiveGroupDatapoint,
} from 'types/top-items';

export type BillingReportState = {
  error?: unknown;
  refetch: () => void;
  dateRange: DateRange;
  selectedClusters: string[];
  setSelectedClusters: SetState<string[]>;
  currentTab: BillingReportTable;
  setCurrentTab: (tab: BillingReportTable) => void;
  isLoading: boolean;
  totalBillableCpus: number;
  dailyUsage: NormalizedBillingReportClusterUsage[];
  topMostExpensiveClusters: TopMostExpensiveGroup[];
  topMostExpensiveClustersDatapoints: TopMostExpensiveGroupDatapoint;
  showEmptyComponents: boolean;
};

export const BillingReportChartTypes = {
  AREA: ChartType.AREA,
  BAR: ChartType.BAR,
};

export type BillingReportChartTypes =
  (typeof BillingReportChartTypes)[keyof typeof BillingReportChartTypes];

export type BillingReportTable = 'perCluster' | 'totalDaily';

export type DailyUsage = {
  billableCpus: number;
  cpuHours: number;
  timestamp: string;
};

export type NormalizedBillingReportClusterUsage = {
  cluster: BillingReportClusterUsage['cluster'];
  cumulativeBillableCpus: number;
  cumulativeCpuHours: number;
  dailyUsages: DailyUsage[];
};
