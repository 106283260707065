/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { InsightsAPIConfigureClusterSecuritySettingsRequest } from '../models';
// @ts-ignore
import { InsightsAPICreateTicketRequest } from '../models';
// @ts-ignore
import { InsightsAPIEnforceCheckPolicyRequest } from '../models';
// @ts-ignore
import { InsightsAPIGetCheckResourcesRequest } from '../models';
// @ts-ignore
import { InsightsAPIPostAgentTelemetryRequest } from '../models';
// @ts-ignore
import { InsightsV1AgentSyncStateFilter } from '../models';
// @ts-ignore
import { InsightsV1CheckExceptionsPayload } from '../models';
// @ts-ignore
import { InsightsV1CreateIntegrationRequestPayload } from '../models';
// @ts-ignore
import { InsightsV1CreateIntegrationResponse } from '../models';
// @ts-ignore
import { InsightsV1CreateTicketResponse } from '../models';
// @ts-ignore
import { InsightsV1DeleteImagesRequest } from '../models';
// @ts-ignore
import { InsightsV1EnforceCheckPolicyResponse } from '../models';
// @ts-ignore
import { InsightsV1GetAgentStatusResponse } from '../models';
// @ts-ignore
import { InsightsV1GetAgentSyncStateResponse } from '../models';
// @ts-ignore
import { InsightsV1GetAgentsStatusRequest } from '../models';
// @ts-ignore
import { InsightsV1GetAgentsStatusResponse } from '../models';
// @ts-ignore
import { InsightsV1GetAttackPathDetailsResponse } from '../models';
// @ts-ignore
import { InsightsV1GetAttackPathsOverviewResponse } from '../models';
// @ts-ignore
import { InsightsV1GetAttackPathsResponse } from '../models';
// @ts-ignore
import { InsightsV1GetBestPracticesCheckDetailsResponse } from '../models';
// @ts-ignore
import { InsightsV1GetBestPracticesOverviewResponse } from '../models';
// @ts-ignore
import { InsightsV1GetBestPracticesReportFiltersResponse } from '../models';
// @ts-ignore
import { InsightsV1GetBestPracticesReportResponse } from '../models';
// @ts-ignore
import { InsightsV1GetCheckExceptionsResponse } from '../models';
// @ts-ignore
import { InsightsV1GetCheckResourcesResponse } from '../models';
// @ts-ignore
import { InsightsV1GetChecksResourcesRequest } from '../models';
// @ts-ignore
import { InsightsV1GetChecksResourcesResponse } from '../models';
// @ts-ignore
import { InsightsV1GetClusterSecuritySettingsResponse } from '../models';
// @ts-ignore
import { InsightsV1GetContainerImageDetailsResponse } from '../models';
// @ts-ignore
import { InsightsV1GetContainerImageDigestsResponse } from '../models';
// @ts-ignore
import { InsightsV1GetContainerImagePackageVulnerabilityDetailsResponse } from '../models';
// @ts-ignore
import { InsightsV1GetContainerImagePackagesResponse } from '../models';
// @ts-ignore
import { InsightsV1GetContainerImageResourcesResponse } from '../models';
// @ts-ignore
import { InsightsV1GetContainerImageVulnerabilitiesResponse } from '../models';
// @ts-ignore
import { InsightsV1GetContainerImagesFiltersResponse } from '../models';
// @ts-ignore
import { InsightsV1GetContainerImagesResponse } from '../models';
// @ts-ignore
import { InsightsV1GetContainerImagesSummaryResponse } from '../models';
// @ts-ignore
import { InsightsV1GetDailySummaryResponse } from '../models';
// @ts-ignore
import { InsightsV1GetExceptedChecksResponse } from '../models';
// @ts-ignore
import { InsightsV1GetImageSecurityOverviewResponse } from '../models';
// @ts-ignore
import { InsightsV1GetIntegrationResponse } from '../models';
// @ts-ignore
import { InsightsV1GetNodesOverviewResponse } from '../models';
// @ts-ignore
import { InsightsV1GetProjectsResponse } from '../models';
// @ts-ignore
import { InsightsV1GetPublicImageAlternativesResponse } from '../models';
// @ts-ignore
import { InsightsV1GetResourceDetailsResponse } from '../models';
// @ts-ignore
import { InsightsV1ImageExceptionChangesRequest } from '../models';
// @ts-ignore
import { InsightsV1ImageExceptionChangesResponse } from '../models';
// @ts-ignore
import { InsightsV1ListClusterSecuritySettingsResponse } from '../models';
// @ts-ignore
import { InsightsV1ListIntegrationsResponse } from '../models';
// @ts-ignore
import { InsightsV1ListWorkloadsChecksResponse } from '../models';
// @ts-ignore
import { InsightsV1ListWorkloadsResponse } from '../models';
// @ts-ignore
import { InsightsV1LogEvent } from '../models';
// @ts-ignore
import { InsightsV1PostAgentTelemetryResponse } from '../models';
// @ts-ignore
import { InsightsV1PreviewExceptionGroupsResourcesResponse } from '../models';
// @ts-ignore
import { InsightsV1ScheduleBestPracticesScanRequest } from '../models';
// @ts-ignore
import { InsightsV1UpdateIntegrationRequestPayload } from '../models';
// @ts-ignore
import { InsightsV1UpdateIntegrationResponse } from '../models';
/**
 * InsightsAPIApi - axios parameter creator
 * @export
 */
export const InsightsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ConfigureClusterSecuritySettings allows to configure security settings on a cluster.
         * @param {string} id The ID of the cluster to configure
         * @param {InsightsAPIConfigureClusterSecuritySettingsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIConfigureClusterSecuritySettings: async (id: string, body: InsightsAPIConfigureClusterSecuritySettingsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insightsAPIConfigureClusterSecuritySettings', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPIConfigureClusterSecuritySettings', 'body', body)
            const localVarPath = `/v1/security/settings/clusters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create integration.
         * @param {InsightsV1CreateIntegrationRequestPayload} payload (required) integration payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPICreateIntegration: async (payload: InsightsV1CreateIntegrationRequestPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('insightsAPICreateIntegration', 'payload', payload)
            const localVarPath = `/v1/security/integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CreateTicket in the ticketing system setup through integrations.
         * @param {string} id integration id
         * @param {InsightsAPICreateTicketRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPICreateTicket: async (id: string, body: InsightsAPICreateTicketRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insightsAPICreateTicket', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPICreateTicket', 'body', body)
            const localVarPath = `/v1/security/integrations/{id}/ticket`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteImages should be used for deleting old images
         * @param {InsightsV1DeleteImagesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIDeleteImages: async (body: InsightsV1DeleteImagesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPIDeleteImages', 'body', body)
            const localVarPath = `/v1/security/insights/images/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete integration.
         * @param {string} id (required) integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIDeleteIntegration: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insightsAPIDeleteIntegration', 'id', id)
            const localVarPath = `/v1/security/integrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete policy enforcement.
         * @param {string} enforcementId (required) enforcement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIDeletePolicyEnforcement: async (enforcementId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enforcementId' is not null or undefined
            assertParamExists('insightsAPIDeletePolicyEnforcement', 'enforcementId', enforcementId)
            const localVarPath = `/v1/security/insights/best-practices/enforcements/{enforcementId}`
                .replace(`{${"enforcementId"}}`, encodeURIComponent(String(enforcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enforce linter rule as policy.
         * @param {string} ruleId (required) rule id to enforce
         * @param {InsightsAPIEnforceCheckPolicyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIEnforceCheckPolicy: async (ruleId: string, body: InsightsAPIEnforceCheckPolicyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('insightsAPIEnforceCheckPolicy', 'ruleId', ruleId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPIEnforceCheckPolicy', 'body', body)
            const localVarPath = `/v1/security/insights/best-practices/checks/{ruleId}/enforce`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get status of security agent
         * @param {string} clusterId (required) cluster id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAgentStatus: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('insightsAPIGetAgentStatus', 'clusterId', clusterId)
            const localVarPath = `/v1/security/insights/{clusterId}/agent`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Agent calls this endpoint to sync state for scanned images.
         * @param {string} clusterId (required) cluster id
         * @param {InsightsV1AgentSyncStateFilter} filter (required) sync state filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAgentSyncState: async (clusterId: string, filter: InsightsV1AgentSyncStateFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('insightsAPIGetAgentSyncState', 'clusterId', clusterId)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('insightsAPIGetAgentSyncState', 'filter', filter)
            const localVarPath = `/v1/security/insights/{clusterId}/sync-state`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get status of security agents
         * @param {InsightsV1GetAgentsStatusRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAgentsStatus: async (body: InsightsV1GetAgentsStatusRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPIGetAgentsStatus', 'body', body)
            const localVarPath = `/v1/security/insights/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get attack path details including graph.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAttackPathDetails: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insightsAPIGetAttackPathDetails', 'id', id)
            const localVarPath = `/v1/security/insights/attack-paths/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get attack paths list.
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {string} [resourceId] (optional) resource_id filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAttackPaths: async (clusterIds?: Array<string>, resourceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/attack-paths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetAttackPathsOverview returns a high-level overview of attack paths.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAttackPathsOverview: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/overview/attack-paths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single check information and objects that matched the rule in the last best practices report.
         * @param {string} ruleId (required) rule id
         * @param {InsightsAPIGetBestPracticesCheckDetailsStandardEnum} [standard] (optional) standard to use   - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetBestPracticesCheckDetails: async (ruleId: string, standard?: InsightsAPIGetBestPracticesCheckDetailsStandardEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('insightsAPIGetBestPracticesCheckDetails', 'ruleId', ruleId)
            const localVarPath = `/v1/security/insights/best-practices/checks/{ruleId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (standard !== undefined) {
                localVarQueryParameter['standard'] = standard;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetBestPracticesOverview returns a high-level overview of best practices.
         * @param {InsightsAPIGetBestPracticesOverviewStandardEnum} [standard]  - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetBestPracticesOverview: async (standard?: InsightsAPIGetBestPracticesOverviewStandardEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/overview/best-practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (standard !== undefined) {
                localVarQueryParameter['standard'] = standard;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest best practices report.
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {Array<string>} [namespaces] (optional) namespace filter
         * @param {string} [category] (optional) threat category filter
         * @param {Array<string>} [labels] (optional) labels filter
         * @param {Array<InsightsAPIGetBestPracticesReportSeverityLevelsEnum>} [severityLevels] (optional) severity filter
         * @param {InsightsAPIGetBestPracticesReportStandardEnum} [standard] (optional) standard to display rules in   - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {boolean} [readonlyClusters] display read only clusters
         * @param {string} [resourceIdFilter] (optional) filter by resource K8s id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetBestPracticesReport: async (clusterIds?: Array<string>, namespaces?: Array<string>, category?: string, labels?: Array<string>, severityLevels?: Array<InsightsAPIGetBestPracticesReportSeverityLevelsEnum>, standard?: InsightsAPIGetBestPracticesReportStandardEnum, readonlyClusters?: boolean, resourceIdFilter?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/best-practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (namespaces) {
                localVarQueryParameter['namespaces'] = namespaces;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (severityLevels) {
                localVarQueryParameter['severityLevels'] = severityLevels;
            }

            if (standard !== undefined) {
                localVarQueryParameter['standard'] = standard;
            }

            if (readonlyClusters !== undefined) {
                localVarQueryParameter['readonlyClusters'] = readonlyClusters;
            }

            if (resourceIdFilter !== undefined) {
                localVarQueryParameter['resourceIdFilter'] = resourceIdFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of possible filters for best practices report.
         * @param {Array<string>} [clusterIds] (optional) return filters available for specific clusters
         * @param {boolean} [filtersv2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetBestPracticesReportFilters: async (clusterIds?: Array<string>, filtersv2?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/best-practices/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (filtersv2 !== undefined) {
                localVarQueryParameter['filtersv2'] = filtersv2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active exceptions for selected rule
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetCheckExceptions: async (ruleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('insightsAPIGetCheckExceptions', 'ruleId', ruleId)
            const localVarPath = `/v1/security/insights/best-practices/checks/{ruleId}/exceptions`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get resources for provided check and filtering POST due to possible amount of parameters
         * @param {string} ruleId 
         * @param {InsightsAPIGetCheckResourcesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetCheckResources: async (ruleId: string, body: InsightsAPIGetCheckResourcesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('insightsAPIGetCheckResources', 'ruleId', ruleId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPIGetCheckResources', 'body', body)
            const localVarPath = `/v1/security/insights/best-practices/checks/{ruleId}/resources`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get resources for provided checks and filtering POST due to possible amount of parameters
         * @param {InsightsV1GetChecksResourcesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetChecksResources: async (body: InsightsV1GetChecksResourcesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPIGetChecksResources', 'body', body)
            const localVarPath = `/v1/security/insights/best-practices/checks/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetClusterSecuritySettings returns security settings for a specific cluster.
         * @param {string} id The ID of the cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetClusterSecuritySettings: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insightsAPIGetClusterSecuritySettings', 'id', id)
            const localVarPath = `/v1/security/settings/clusters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details for container image by tag
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImageDetails: async (tagId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('insightsAPIGetContainerImageDetails', 'tagId', tagId)
            const localVarPath = `/v1/security/insights/images/{tagId}/details`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get container image digests for organization by tag id
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImageDigests: async (tagId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('insightsAPIGetContainerImageDigests', 'tagId', tagId)
            const localVarPath = `/v1/security/insights/images/{tagId}/digests`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get image vulnerability for a specific package
         * @param {string} tagId 
         * @param {string} pkgVulnId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImagePackageVulnerabilityDetails: async (tagId: string, pkgVulnId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('insightsAPIGetContainerImagePackageVulnerabilityDetails', 'tagId', tagId)
            // verify required parameter 'pkgVulnId' is not null or undefined
            assertParamExists('insightsAPIGetContainerImagePackageVulnerabilityDetails', 'pkgVulnId', pkgVulnId)
            const localVarPath = `/v1/security/insights/images/{tagId}/vulnerabilities/{pkgVulnId}/details`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)))
                .replace(`{${"pkgVulnId"}}`, encodeURIComponent(String(pkgVulnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of image packages
         * @param {string} tagId 
         * @param {InsightsAPIGetContainerImagePackagesExceptionFilterEnum} [exceptionFilter]  - all: return all resources  - active: return only not excepted resources  - excepted: return only excepted resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImagePackages: async (tagId: string, exceptionFilter?: InsightsAPIGetContainerImagePackagesExceptionFilterEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('insightsAPIGetContainerImagePackages', 'tagId', tagId)
            const localVarPath = `/v1/security/insights/images/{tagId}/packages`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exceptionFilter !== undefined) {
                localVarQueryParameter['exceptionFilter'] = exceptionFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cluster resources for container image
         * @param {string} tagId 
         * @param {Array<string>} [labels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImageResources: async (tagId: string, labels?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('insightsAPIGetContainerImageResources', 'tagId', tagId)
            const localVarPath = `/v1/security/insights/images/{tagId}/resources`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of image vulnerabilities
         * @param {string} tagId 
         * @param {string} [pkgId] (optional) Filter by package ID.
         * @param {InsightsAPIGetContainerImageVulnerabilitiesExceptionFilterEnum} [exceptionFilter]  - all: return all resources  - active: return only not excepted resources  - excepted: return only excepted resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImageVulnerabilities: async (tagId: string, pkgId?: string, exceptionFilter?: InsightsAPIGetContainerImageVulnerabilitiesExceptionFilterEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('insightsAPIGetContainerImageVulnerabilities', 'tagId', tagId)
            const localVarPath = `/v1/security/insights/images/{tagId}/vulnerabilities`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pkgId !== undefined) {
                localVarQueryParameter['pkgId'] = pkgId;
            }

            if (exceptionFilter !== undefined) {
                localVarQueryParameter['exceptionFilter'] = exceptionFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of container images for organization
         * @param {string} [status] (optional) status filter
         * @param {Array<string>} [cves] (optional) cves filter
         * @param {Array<string>} [packages] (optional) packages filter
         * @param {Array<string>} [namespaces] (optional) namespaces filter
         * @param {Array<string>} [clusterIds] (optional) clusters filter
         * @param {Array<string>} [labels] (optional) labels filter
         * @param {InsightsAPIGetContainerImagesExceptionFilterEnum} [exceptionFilter] (optional) exception filter   - all: return all resources  - active: return only not excepted resources  - excepted: return only excepted resources
         * @param {string} [resourceIdFilter] (optional) filter image by resource_id
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {InsightsAPIGetContainerImagesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImages: async (status?: string, cves?: Array<string>, packages?: Array<string>, namespaces?: Array<string>, clusterIds?: Array<string>, labels?: Array<string>, exceptionFilter?: InsightsAPIGetContainerImagesExceptionFilterEnum, resourceIdFilter?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: InsightsAPIGetContainerImagesSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (cves) {
                localVarQueryParameter['cves'] = cves;
            }

            if (packages) {
                localVarQueryParameter['packages'] = packages;
            }

            if (namespaces) {
                localVarQueryParameter['namespaces'] = namespaces;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (exceptionFilter !== undefined) {
                localVarQueryParameter['exceptionFilter'] = exceptionFilter;
            }

            if (resourceIdFilter !== undefined) {
                localVarQueryParameter['resourceIdFilter'] = resourceIdFilter;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of possible filters for images endpoint
         * @param {Array<string>} [clusterIds] (optional) return filters available for specific clusters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImagesFilters: async (clusterIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/images/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get container image vulnerability summary for organization
         * @param {string} [clusterId] (optional) ID of cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImagesSummary: async (clusterId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/images/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetDailySummary returns the data of the daily vulnerability notification.
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetDailySummary: async (date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('insightsAPIGetDailySummary', 'date', date)
            const localVarPath = `/v1/security/insights/daily-summary/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checks that have not passed but are excepted
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {Array<string>} [namespaces] (optional) namespace filter
         * @param {string} [category] (optional) threat category filter
         * @param {Array<InsightsAPIGetExceptedChecksSeverityLevelsEnum>} [severityLevels] (optional) severity filter
         * @param {InsightsAPIGetExceptedChecksStandardEnum} [standard] (optional) standard to display rules in   - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetExceptedChecks: async (clusterIds?: Array<string>, namespaces?: Array<string>, category?: string, severityLevels?: Array<InsightsAPIGetExceptedChecksSeverityLevelsEnum>, standard?: InsightsAPIGetExceptedChecksStandardEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/exceptions/best-practices/checks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (namespaces) {
                localVarQueryParameter['namespaces'] = namespaces;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (severityLevels) {
                localVarQueryParameter['severityLevels'] = severityLevels;
            }

            if (standard !== undefined) {
                localVarQueryParameter['standard'] = standard;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetImageSecurityOverview returns a high-level overview of image security.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetImageSecurityOverview: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/overview/image-security`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get integration.
         * @param {string} id (required) integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetIntegration: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insightsAPIGetIntegration', 'id', id)
            const localVarPath = `/v1/security/integrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetManagedNodesOverview returns a high-level overview of managed nodes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetNodesOverview: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/overview/nodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetProjects of the ticketing system setup through integrations.
         * @param {string} id integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetProjects: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insightsAPIGetProjects', 'id', id)
            const localVarPath = `/v1/security/integrations/{id}/projects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get alternative images for public image
         * @param {string} tagId 
         * @param {string} [language] (optional) return distroless recommendation for language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetPublicImageAlternatives: async (tagId: string, language?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('insightsAPIGetPublicImageAlternatives', 'tagId', tagId)
            const localVarPath = `/v1/security/insights/images/{tagId}/alternatives`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetResourceDetails for display in attack path nodes
         * @param {string} id 
         * @param {InsightsAPIGetResourceDetailsStandardEnum} standard  - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetResourceDetails: async (id: string, standard: InsightsAPIGetResourceDetailsStandardEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insightsAPIGetResourceDetails', 'id', id)
            // verify required parameter 'standard' is not null or undefined
            assertParamExists('insightsAPIGetResourceDetails', 'standard', standard)
            const localVarPath = `/v1/security/insights/resources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (standard !== undefined) {
                localVarQueryParameter['standard'] = standard;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a diff of changes (x exceptions added, y exceptions removed) for excepted images
         * @param {InsightsV1ImageExceptionChangesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIImageExceptionChanges: async (body: InsightsV1ImageExceptionChangesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPIImageExceptionChanges', 'body', body)
            const localVarPath = `/v1/security/insights/images/exceptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ingest log sent by Security Agent
         * @param {string} clusterId 
         * @param {InsightsV1LogEvent} log 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIIngestAgentLog: async (clusterId: string, log: InsightsV1LogEvent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('insightsAPIIngestAgentLog', 'clusterId', clusterId)
            // verify required parameter 'log' is not null or undefined
            assertParamExists('insightsAPIIngestAgentLog', 'log', log)
            const localVarPath = `/v1/security/insights/{clusterId}/log`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(log, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListClusterSecuritySettings returns a list of clusters for the organization with their security settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIListClusterSecuritySettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/settings/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of integrations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIListIntegrations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListWorkloads lists workloads in the organization
         * @param {string} [search] (optional) name filter
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {Array<string>} [namespaces] (optional) namespace filter
         * @param {Array<string>} [labels] (optional) labels filter
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {InsightsAPIListWorkloadsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIListWorkloads: async (search?: string, clusterIds?: Array<string>, namespaces?: Array<string>, labels?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: InsightsAPIListWorkloadsSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/workloads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (namespaces) {
                localVarQueryParameter['namespaces'] = namespaces;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListWorkloadsChecks generate workloads x checks report matrix
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {Array<string>} [namespaces] (optional) namespace filter
         * @param {Array<string>} [labels] (optional) labels filter
         * @param {InsightsAPIListWorkloadsChecksStandardEnum} [standard]  - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIListWorkloadsChecks: async (clusterIds?: Array<string>, namespaces?: Array<string>, labels?: Array<string>, standard?: InsightsAPIListWorkloadsChecksStandardEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/insights/workloads/checks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (namespaces) {
                localVarQueryParameter['namespaces'] = namespaces;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (standard !== undefined) {
                localVarQueryParameter['standard'] = standard;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Agent posts telemetry data to this endpoint.
         * @param {string} clusterId (required) cluster id
         * @param {InsightsAPIPostAgentTelemetryRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIPostAgentTelemetry: async (clusterId: string, body: InsightsAPIPostAgentTelemetryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('insightsAPIPostAgentTelemetry', 'clusterId', clusterId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPIPostAgentTelemetry', 'body', body)
            const localVarPath = `/v1/security/insights/{clusterId}/telemetry`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a preview of resources that will be excepted with exception groups
         * @param {string} ruleId 
         * @param {InsightsV1CheckExceptionsPayload} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIPreviewExceptionGroupsResources: async (ruleId: string, payload: InsightsV1CheckExceptionsPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('insightsAPIPreviewExceptionGroupsResources', 'ruleId', ruleId)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('insightsAPIPreviewExceptionGroupsResources', 'payload', payload)
            const localVarPath = `/v1/security/insights/best-practices/checks/{ruleId}/exceptions/preview`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send check exceptions.
         * @param {string} ruleId 
         * @param {InsightsV1CheckExceptionsPayload} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPISaveCheckExceptions: async (ruleId: string, payload: InsightsV1CheckExceptionsPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('insightsAPISaveCheckExceptions', 'ruleId', ruleId)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('insightsAPISaveCheckExceptions', 'payload', payload)
            const localVarPath = `/v1/security/insights/best-practices/checks/{ruleId}/exceptions`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule best practices scan for cluster.
         * @param {InsightsV1ScheduleBestPracticesScanRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIScheduleBestPracticesScan: async (body: InsightsV1ScheduleBestPracticesScanRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('insightsAPIScheduleBestPracticesScan', 'body', body)
            const localVarPath = `/v1/security/insights/best-practices/schedule-scan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update integration.
         * @param {string} id (required) integration id
         * @param {InsightsV1UpdateIntegrationRequestPayload} payload (required) integration payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIUpdateIntegration: async (id: string, payload: InsightsV1UpdateIntegrationRequestPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insightsAPIUpdateIntegration', 'id', id)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('insightsAPIUpdateIntegration', 'payload', payload)
            const localVarPath = `/v1/security/integrations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsightsAPIApi - functional programming interface
 * @export
 */
export const InsightsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsightsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ConfigureClusterSecuritySettings allows to configure security settings on a cluster.
         * @param {string} id The ID of the cluster to configure
         * @param {InsightsAPIConfigureClusterSecuritySettingsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIConfigureClusterSecuritySettings(id: string, body: InsightsAPIConfigureClusterSecuritySettingsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIConfigureClusterSecuritySettings(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIConfigureClusterSecuritySettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create integration.
         * @param {InsightsV1CreateIntegrationRequestPayload} payload (required) integration payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPICreateIntegration(payload: InsightsV1CreateIntegrationRequestPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1CreateIntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPICreateIntegration(payload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPICreateIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary CreateTicket in the ticketing system setup through integrations.
         * @param {string} id integration id
         * @param {InsightsAPICreateTicketRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPICreateTicket(id: string, body: InsightsAPICreateTicketRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1CreateTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPICreateTicket(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPICreateTicket']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DeleteImages should be used for deleting old images
         * @param {InsightsV1DeleteImagesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIDeleteImages(body: InsightsV1DeleteImagesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIDeleteImages(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIDeleteImages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete integration.
         * @param {string} id (required) integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIDeleteIntegration(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIDeleteIntegration(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIDeleteIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete policy enforcement.
         * @param {string} enforcementId (required) enforcement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIDeletePolicyEnforcement(enforcementId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIDeletePolicyEnforcement(enforcementId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIDeletePolicyEnforcement']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enforce linter rule as policy.
         * @param {string} ruleId (required) rule id to enforce
         * @param {InsightsAPIEnforceCheckPolicyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIEnforceCheckPolicy(ruleId: string, body: InsightsAPIEnforceCheckPolicyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1EnforceCheckPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIEnforceCheckPolicy(ruleId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIEnforceCheckPolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get status of security agent
         * @param {string} clusterId (required) cluster id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetAgentStatus(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetAgentStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetAgentStatus(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetAgentStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Agent calls this endpoint to sync state for scanned images.
         * @param {string} clusterId (required) cluster id
         * @param {InsightsV1AgentSyncStateFilter} filter (required) sync state filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetAgentSyncState(clusterId: string, filter: InsightsV1AgentSyncStateFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetAgentSyncStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetAgentSyncState(clusterId, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetAgentSyncState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get status of security agents
         * @param {InsightsV1GetAgentsStatusRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetAgentsStatus(body: InsightsV1GetAgentsStatusRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetAgentsStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetAgentsStatus(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetAgentsStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get attack path details including graph.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetAttackPathDetails(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetAttackPathDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetAttackPathDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetAttackPathDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get attack paths list.
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {string} [resourceId] (optional) resource_id filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetAttackPaths(clusterIds?: Array<string>, resourceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetAttackPathsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetAttackPaths(clusterIds, resourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetAttackPaths']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetAttackPathsOverview returns a high-level overview of attack paths.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetAttackPathsOverview(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetAttackPathsOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetAttackPathsOverview(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetAttackPathsOverview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get single check information and objects that matched the rule in the last best practices report.
         * @param {string} ruleId (required) rule id
         * @param {InsightsAPIGetBestPracticesCheckDetailsStandardEnum} [standard] (optional) standard to use   - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetBestPracticesCheckDetails(ruleId: string, standard?: InsightsAPIGetBestPracticesCheckDetailsStandardEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetBestPracticesCheckDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetBestPracticesCheckDetails(ruleId, standard, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetBestPracticesCheckDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetBestPracticesOverview returns a high-level overview of best practices.
         * @param {InsightsAPIGetBestPracticesOverviewStandardEnum} [standard]  - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetBestPracticesOverview(standard?: InsightsAPIGetBestPracticesOverviewStandardEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetBestPracticesOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetBestPracticesOverview(standard, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetBestPracticesOverview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get latest best practices report.
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {Array<string>} [namespaces] (optional) namespace filter
         * @param {string} [category] (optional) threat category filter
         * @param {Array<string>} [labels] (optional) labels filter
         * @param {Array<InsightsAPIGetBestPracticesReportSeverityLevelsEnum>} [severityLevels] (optional) severity filter
         * @param {InsightsAPIGetBestPracticesReportStandardEnum} [standard] (optional) standard to display rules in   - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {boolean} [readonlyClusters] display read only clusters
         * @param {string} [resourceIdFilter] (optional) filter by resource K8s id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetBestPracticesReport(clusterIds?: Array<string>, namespaces?: Array<string>, category?: string, labels?: Array<string>, severityLevels?: Array<InsightsAPIGetBestPracticesReportSeverityLevelsEnum>, standard?: InsightsAPIGetBestPracticesReportStandardEnum, readonlyClusters?: boolean, resourceIdFilter?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetBestPracticesReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetBestPracticesReport(clusterIds, namespaces, category, labels, severityLevels, standard, readonlyClusters, resourceIdFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetBestPracticesReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve a list of possible filters for best practices report.
         * @param {Array<string>} [clusterIds] (optional) return filters available for specific clusters
         * @param {boolean} [filtersv2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetBestPracticesReportFilters(clusterIds?: Array<string>, filtersv2?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetBestPracticesReportFiltersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetBestPracticesReportFilters(clusterIds, filtersv2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetBestPracticesReportFilters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get active exceptions for selected rule
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetCheckExceptions(ruleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetCheckExceptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetCheckExceptions(ruleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetCheckExceptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get resources for provided check and filtering POST due to possible amount of parameters
         * @param {string} ruleId 
         * @param {InsightsAPIGetCheckResourcesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetCheckResources(ruleId: string, body: InsightsAPIGetCheckResourcesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetCheckResourcesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetCheckResources(ruleId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetCheckResources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get resources for provided checks and filtering POST due to possible amount of parameters
         * @param {InsightsV1GetChecksResourcesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetChecksResources(body: InsightsV1GetChecksResourcesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetChecksResourcesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetChecksResources(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetChecksResources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetClusterSecuritySettings returns security settings for a specific cluster.
         * @param {string} id The ID of the cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetClusterSecuritySettings(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetClusterSecuritySettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetClusterSecuritySettings(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetClusterSecuritySettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get details for container image by tag
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetContainerImageDetails(tagId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetContainerImageDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetContainerImageDetails(tagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetContainerImageDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get container image digests for organization by tag id
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetContainerImageDigests(tagId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetContainerImageDigestsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetContainerImageDigests(tagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetContainerImageDigests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get image vulnerability for a specific package
         * @param {string} tagId 
         * @param {string} pkgVulnId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetContainerImagePackageVulnerabilityDetails(tagId: string, pkgVulnId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetContainerImagePackageVulnerabilityDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetContainerImagePackageVulnerabilityDetails(tagId, pkgVulnId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetContainerImagePackageVulnerabilityDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a list of image packages
         * @param {string} tagId 
         * @param {InsightsAPIGetContainerImagePackagesExceptionFilterEnum} [exceptionFilter]  - all: return all resources  - active: return only not excepted resources  - excepted: return only excepted resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetContainerImagePackages(tagId: string, exceptionFilter?: InsightsAPIGetContainerImagePackagesExceptionFilterEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetContainerImagePackagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetContainerImagePackages(tagId, exceptionFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetContainerImagePackages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get cluster resources for container image
         * @param {string} tagId 
         * @param {Array<string>} [labels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetContainerImageResources(tagId: string, labels?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetContainerImageResourcesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetContainerImageResources(tagId, labels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetContainerImageResources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a list of image vulnerabilities
         * @param {string} tagId 
         * @param {string} [pkgId] (optional) Filter by package ID.
         * @param {InsightsAPIGetContainerImageVulnerabilitiesExceptionFilterEnum} [exceptionFilter]  - all: return all resources  - active: return only not excepted resources  - excepted: return only excepted resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetContainerImageVulnerabilities(tagId: string, pkgId?: string, exceptionFilter?: InsightsAPIGetContainerImageVulnerabilitiesExceptionFilterEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetContainerImageVulnerabilitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetContainerImageVulnerabilities(tagId, pkgId, exceptionFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetContainerImageVulnerabilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a list of container images for organization
         * @param {string} [status] (optional) status filter
         * @param {Array<string>} [cves] (optional) cves filter
         * @param {Array<string>} [packages] (optional) packages filter
         * @param {Array<string>} [namespaces] (optional) namespaces filter
         * @param {Array<string>} [clusterIds] (optional) clusters filter
         * @param {Array<string>} [labels] (optional) labels filter
         * @param {InsightsAPIGetContainerImagesExceptionFilterEnum} [exceptionFilter] (optional) exception filter   - all: return all resources  - active: return only not excepted resources  - excepted: return only excepted resources
         * @param {string} [resourceIdFilter] (optional) filter image by resource_id
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {InsightsAPIGetContainerImagesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetContainerImages(status?: string, cves?: Array<string>, packages?: Array<string>, namespaces?: Array<string>, clusterIds?: Array<string>, labels?: Array<string>, exceptionFilter?: InsightsAPIGetContainerImagesExceptionFilterEnum, resourceIdFilter?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: InsightsAPIGetContainerImagesSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetContainerImagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetContainerImages(status, cves, packages, namespaces, clusterIds, labels, exceptionFilter, resourceIdFilter, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetContainerImages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a list of possible filters for images endpoint
         * @param {Array<string>} [clusterIds] (optional) return filters available for specific clusters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetContainerImagesFilters(clusterIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetContainerImagesFiltersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetContainerImagesFilters(clusterIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetContainerImagesFilters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get container image vulnerability summary for organization
         * @param {string} [clusterId] (optional) ID of cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetContainerImagesSummary(clusterId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetContainerImagesSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetContainerImagesSummary(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetContainerImagesSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetDailySummary returns the data of the daily vulnerability notification.
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetDailySummary(date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetDailySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetDailySummary(date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetDailySummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get checks that have not passed but are excepted
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {Array<string>} [namespaces] (optional) namespace filter
         * @param {string} [category] (optional) threat category filter
         * @param {Array<InsightsAPIGetExceptedChecksSeverityLevelsEnum>} [severityLevels] (optional) severity filter
         * @param {InsightsAPIGetExceptedChecksStandardEnum} [standard] (optional) standard to display rules in   - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetExceptedChecks(clusterIds?: Array<string>, namespaces?: Array<string>, category?: string, severityLevels?: Array<InsightsAPIGetExceptedChecksSeverityLevelsEnum>, standard?: InsightsAPIGetExceptedChecksStandardEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetExceptedChecksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetExceptedChecks(clusterIds, namespaces, category, severityLevels, standard, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetExceptedChecks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetImageSecurityOverview returns a high-level overview of image security.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetImageSecurityOverview(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetImageSecurityOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetImageSecurityOverview(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetImageSecurityOverview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get integration.
         * @param {string} id (required) integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetIntegration(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetIntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetIntegration(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetManagedNodesOverview returns a high-level overview of managed nodes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetNodesOverview(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetNodesOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetNodesOverview(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetNodesOverview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetProjects of the ticketing system setup through integrations.
         * @param {string} id integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetProjects(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetProjects(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get alternative images for public image
         * @param {string} tagId 
         * @param {string} [language] (optional) return distroless recommendation for language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetPublicImageAlternatives(tagId: string, language?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetPublicImageAlternativesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetPublicImageAlternatives(tagId, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetPublicImageAlternatives']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetResourceDetails for display in attack path nodes
         * @param {string} id 
         * @param {InsightsAPIGetResourceDetailsStandardEnum} standard  - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIGetResourceDetails(id: string, standard: InsightsAPIGetResourceDetailsStandardEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1GetResourceDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIGetResourceDetails(id, standard, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIGetResourceDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send a diff of changes (x exceptions added, y exceptions removed) for excepted images
         * @param {InsightsV1ImageExceptionChangesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIImageExceptionChanges(body: InsightsV1ImageExceptionChangesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1ImageExceptionChangesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIImageExceptionChanges(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIImageExceptionChanges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Ingest log sent by Security Agent
         * @param {string} clusterId 
         * @param {InsightsV1LogEvent} log 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIIngestAgentLog(clusterId: string, log: InsightsV1LogEvent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIIngestAgentLog(clusterId, log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIIngestAgentLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListClusterSecuritySettings returns a list of clusters for the organization with their security settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIListClusterSecuritySettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1ListClusterSecuritySettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIListClusterSecuritySettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIListClusterSecuritySettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of integrations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIListIntegrations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1ListIntegrationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIListIntegrations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIListIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListWorkloads lists workloads in the organization
         * @param {string} [search] (optional) name filter
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {Array<string>} [namespaces] (optional) namespace filter
         * @param {Array<string>} [labels] (optional) labels filter
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {InsightsAPIListWorkloadsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIListWorkloads(search?: string, clusterIds?: Array<string>, namespaces?: Array<string>, labels?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: InsightsAPIListWorkloadsSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1ListWorkloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIListWorkloads(search, clusterIds, namespaces, labels, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIListWorkloads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListWorkloadsChecks generate workloads x checks report matrix
         * @param {Array<string>} [clusterIds] (optional) cluster_id filter
         * @param {Array<string>} [namespaces] (optional) namespace filter
         * @param {Array<string>} [labels] (optional) labels filter
         * @param {InsightsAPIListWorkloadsChecksStandardEnum} [standard]  - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIListWorkloadsChecks(clusterIds?: Array<string>, namespaces?: Array<string>, labels?: Array<string>, standard?: InsightsAPIListWorkloadsChecksStandardEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1ListWorkloadsChecksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIListWorkloadsChecks(clusterIds, namespaces, labels, standard, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIListWorkloadsChecks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Agent posts telemetry data to this endpoint.
         * @param {string} clusterId (required) cluster id
         * @param {InsightsAPIPostAgentTelemetryRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIPostAgentTelemetry(clusterId: string, body: InsightsAPIPostAgentTelemetryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1PostAgentTelemetryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIPostAgentTelemetry(clusterId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIPostAgentTelemetry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a preview of resources that will be excepted with exception groups
         * @param {string} ruleId 
         * @param {InsightsV1CheckExceptionsPayload} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIPreviewExceptionGroupsResources(ruleId: string, payload: InsightsV1CheckExceptionsPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1PreviewExceptionGroupsResourcesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIPreviewExceptionGroupsResources(ruleId, payload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIPreviewExceptionGroupsResources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send check exceptions.
         * @param {string} ruleId 
         * @param {InsightsV1CheckExceptionsPayload} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPISaveCheckExceptions(ruleId: string, payload: InsightsV1CheckExceptionsPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPISaveCheckExceptions(ruleId, payload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPISaveCheckExceptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Schedule best practices scan for cluster.
         * @param {InsightsV1ScheduleBestPracticesScanRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIScheduleBestPracticesScan(body: InsightsV1ScheduleBestPracticesScanRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIScheduleBestPracticesScan(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIScheduleBestPracticesScan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update integration.
         * @param {string} id (required) integration id
         * @param {InsightsV1UpdateIntegrationRequestPayload} payload (required) integration payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insightsAPIUpdateIntegration(id: string, payload: InsightsV1UpdateIntegrationRequestPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightsV1UpdateIntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insightsAPIUpdateIntegration(id, payload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsightsAPIApi.insightsAPIUpdateIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InsightsAPIApi - factory interface
 * @export
 */
export const InsightsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsightsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary ConfigureClusterSecuritySettings allows to configure security settings on a cluster.
         * @param {InsightsAPIApiInsightsAPIConfigureClusterSecuritySettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIConfigureClusterSecuritySettings(requestParameters: InsightsAPIApiInsightsAPIConfigureClusterSecuritySettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.insightsAPIConfigureClusterSecuritySettings(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create integration.
         * @param {InsightsAPIApiInsightsAPICreateIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPICreateIntegration(requestParameters: InsightsAPIApiInsightsAPICreateIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1CreateIntegrationResponse> {
            return localVarFp.insightsAPICreateIntegration(requestParameters.payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CreateTicket in the ticketing system setup through integrations.
         * @param {InsightsAPIApiInsightsAPICreateTicketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPICreateTicket(requestParameters: InsightsAPIApiInsightsAPICreateTicketRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1CreateTicketResponse> {
            return localVarFp.insightsAPICreateTicket(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteImages should be used for deleting old images
         * @param {InsightsAPIApiInsightsAPIDeleteImagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIDeleteImages(requestParameters: InsightsAPIApiInsightsAPIDeleteImagesRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.insightsAPIDeleteImages(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete integration.
         * @param {InsightsAPIApiInsightsAPIDeleteIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIDeleteIntegration(requestParameters: InsightsAPIApiInsightsAPIDeleteIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.insightsAPIDeleteIntegration(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete policy enforcement.
         * @param {InsightsAPIApiInsightsAPIDeletePolicyEnforcementRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIDeletePolicyEnforcement(requestParameters: InsightsAPIApiInsightsAPIDeletePolicyEnforcementRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.insightsAPIDeletePolicyEnforcement(requestParameters.enforcementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enforce linter rule as policy.
         * @param {InsightsAPIApiInsightsAPIEnforceCheckPolicyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIEnforceCheckPolicy(requestParameters: InsightsAPIApiInsightsAPIEnforceCheckPolicyRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1EnforceCheckPolicyResponse> {
            return localVarFp.insightsAPIEnforceCheckPolicy(requestParameters.ruleId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get status of security agent
         * @param {InsightsAPIApiInsightsAPIGetAgentStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAgentStatus(requestParameters: InsightsAPIApiInsightsAPIGetAgentStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetAgentStatusResponse> {
            return localVarFp.insightsAPIGetAgentStatus(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Agent calls this endpoint to sync state for scanned images.
         * @param {InsightsAPIApiInsightsAPIGetAgentSyncStateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAgentSyncState(requestParameters: InsightsAPIApiInsightsAPIGetAgentSyncStateRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetAgentSyncStateResponse> {
            return localVarFp.insightsAPIGetAgentSyncState(requestParameters.clusterId, requestParameters.filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get status of security agents
         * @param {InsightsAPIApiInsightsAPIGetAgentsStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAgentsStatus(requestParameters: InsightsAPIApiInsightsAPIGetAgentsStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetAgentsStatusResponse> {
            return localVarFp.insightsAPIGetAgentsStatus(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get attack path details including graph.
         * @param {InsightsAPIApiInsightsAPIGetAttackPathDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAttackPathDetails(requestParameters: InsightsAPIApiInsightsAPIGetAttackPathDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetAttackPathDetailsResponse> {
            return localVarFp.insightsAPIGetAttackPathDetails(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get attack paths list.
         * @param {InsightsAPIApiInsightsAPIGetAttackPathsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAttackPaths(requestParameters: InsightsAPIApiInsightsAPIGetAttackPathsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetAttackPathsResponse> {
            return localVarFp.insightsAPIGetAttackPaths(requestParameters.clusterIds, requestParameters.resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetAttackPathsOverview returns a high-level overview of attack paths.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetAttackPathsOverview(options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetAttackPathsOverviewResponse> {
            return localVarFp.insightsAPIGetAttackPathsOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get single check information and objects that matched the rule in the last best practices report.
         * @param {InsightsAPIApiInsightsAPIGetBestPracticesCheckDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetBestPracticesCheckDetails(requestParameters: InsightsAPIApiInsightsAPIGetBestPracticesCheckDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetBestPracticesCheckDetailsResponse> {
            return localVarFp.insightsAPIGetBestPracticesCheckDetails(requestParameters.ruleId, requestParameters.standard, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetBestPracticesOverview returns a high-level overview of best practices.
         * @param {InsightsAPIApiInsightsAPIGetBestPracticesOverviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetBestPracticesOverview(requestParameters: InsightsAPIApiInsightsAPIGetBestPracticesOverviewRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetBestPracticesOverviewResponse> {
            return localVarFp.insightsAPIGetBestPracticesOverview(requestParameters.standard, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest best practices report.
         * @param {InsightsAPIApiInsightsAPIGetBestPracticesReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetBestPracticesReport(requestParameters: InsightsAPIApiInsightsAPIGetBestPracticesReportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetBestPracticesReportResponse> {
            return localVarFp.insightsAPIGetBestPracticesReport(requestParameters.clusterIds, requestParameters.namespaces, requestParameters.category, requestParameters.labels, requestParameters.severityLevels, requestParameters.standard, requestParameters.readonlyClusters, requestParameters.resourceIdFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of possible filters for best practices report.
         * @param {InsightsAPIApiInsightsAPIGetBestPracticesReportFiltersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetBestPracticesReportFilters(requestParameters: InsightsAPIApiInsightsAPIGetBestPracticesReportFiltersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetBestPracticesReportFiltersResponse> {
            return localVarFp.insightsAPIGetBestPracticesReportFilters(requestParameters.clusterIds, requestParameters.filtersv2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active exceptions for selected rule
         * @param {InsightsAPIApiInsightsAPIGetCheckExceptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetCheckExceptions(requestParameters: InsightsAPIApiInsightsAPIGetCheckExceptionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetCheckExceptionsResponse> {
            return localVarFp.insightsAPIGetCheckExceptions(requestParameters.ruleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get resources for provided check and filtering POST due to possible amount of parameters
         * @param {InsightsAPIApiInsightsAPIGetCheckResourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetCheckResources(requestParameters: InsightsAPIApiInsightsAPIGetCheckResourcesRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetCheckResourcesResponse> {
            return localVarFp.insightsAPIGetCheckResources(requestParameters.ruleId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get resources for provided checks and filtering POST due to possible amount of parameters
         * @param {InsightsAPIApiInsightsAPIGetChecksResourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetChecksResources(requestParameters: InsightsAPIApiInsightsAPIGetChecksResourcesRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetChecksResourcesResponse> {
            return localVarFp.insightsAPIGetChecksResources(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetClusterSecuritySettings returns security settings for a specific cluster.
         * @param {InsightsAPIApiInsightsAPIGetClusterSecuritySettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetClusterSecuritySettings(requestParameters: InsightsAPIApiInsightsAPIGetClusterSecuritySettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetClusterSecuritySettingsResponse> {
            return localVarFp.insightsAPIGetClusterSecuritySettings(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details for container image by tag
         * @param {InsightsAPIApiInsightsAPIGetContainerImageDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImageDetails(requestParameters: InsightsAPIApiInsightsAPIGetContainerImageDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetContainerImageDetailsResponse> {
            return localVarFp.insightsAPIGetContainerImageDetails(requestParameters.tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get container image digests for organization by tag id
         * @param {InsightsAPIApiInsightsAPIGetContainerImageDigestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImageDigests(requestParameters: InsightsAPIApiInsightsAPIGetContainerImageDigestsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetContainerImageDigestsResponse> {
            return localVarFp.insightsAPIGetContainerImageDigests(requestParameters.tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get image vulnerability for a specific package
         * @param {InsightsAPIApiInsightsAPIGetContainerImagePackageVulnerabilityDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImagePackageVulnerabilityDetails(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagePackageVulnerabilityDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetContainerImagePackageVulnerabilityDetailsResponse> {
            return localVarFp.insightsAPIGetContainerImagePackageVulnerabilityDetails(requestParameters.tagId, requestParameters.pkgVulnId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of image packages
         * @param {InsightsAPIApiInsightsAPIGetContainerImagePackagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImagePackages(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagePackagesRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetContainerImagePackagesResponse> {
            return localVarFp.insightsAPIGetContainerImagePackages(requestParameters.tagId, requestParameters.exceptionFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cluster resources for container image
         * @param {InsightsAPIApiInsightsAPIGetContainerImageResourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImageResources(requestParameters: InsightsAPIApiInsightsAPIGetContainerImageResourcesRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetContainerImageResourcesResponse> {
            return localVarFp.insightsAPIGetContainerImageResources(requestParameters.tagId, requestParameters.labels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of image vulnerabilities
         * @param {InsightsAPIApiInsightsAPIGetContainerImageVulnerabilitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImageVulnerabilities(requestParameters: InsightsAPIApiInsightsAPIGetContainerImageVulnerabilitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetContainerImageVulnerabilitiesResponse> {
            return localVarFp.insightsAPIGetContainerImageVulnerabilities(requestParameters.tagId, requestParameters.pkgId, requestParameters.exceptionFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of container images for organization
         * @param {InsightsAPIApiInsightsAPIGetContainerImagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImages(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetContainerImagesResponse> {
            return localVarFp.insightsAPIGetContainerImages(requestParameters.status, requestParameters.cves, requestParameters.packages, requestParameters.namespaces, requestParameters.clusterIds, requestParameters.labels, requestParameters.exceptionFilter, requestParameters.resourceIdFilter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of possible filters for images endpoint
         * @param {InsightsAPIApiInsightsAPIGetContainerImagesFiltersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImagesFilters(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagesFiltersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetContainerImagesFiltersResponse> {
            return localVarFp.insightsAPIGetContainerImagesFilters(requestParameters.clusterIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get container image vulnerability summary for organization
         * @param {InsightsAPIApiInsightsAPIGetContainerImagesSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetContainerImagesSummary(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagesSummaryRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetContainerImagesSummaryResponse> {
            return localVarFp.insightsAPIGetContainerImagesSummary(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetDailySummary returns the data of the daily vulnerability notification.
         * @param {InsightsAPIApiInsightsAPIGetDailySummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetDailySummary(requestParameters: InsightsAPIApiInsightsAPIGetDailySummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetDailySummaryResponse> {
            return localVarFp.insightsAPIGetDailySummary(requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checks that have not passed but are excepted
         * @param {InsightsAPIApiInsightsAPIGetExceptedChecksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetExceptedChecks(requestParameters: InsightsAPIApiInsightsAPIGetExceptedChecksRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetExceptedChecksResponse> {
            return localVarFp.insightsAPIGetExceptedChecks(requestParameters.clusterIds, requestParameters.namespaces, requestParameters.category, requestParameters.severityLevels, requestParameters.standard, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetImageSecurityOverview returns a high-level overview of image security.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetImageSecurityOverview(options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetImageSecurityOverviewResponse> {
            return localVarFp.insightsAPIGetImageSecurityOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get integration.
         * @param {InsightsAPIApiInsightsAPIGetIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetIntegration(requestParameters: InsightsAPIApiInsightsAPIGetIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetIntegrationResponse> {
            return localVarFp.insightsAPIGetIntegration(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetManagedNodesOverview returns a high-level overview of managed nodes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetNodesOverview(options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetNodesOverviewResponse> {
            return localVarFp.insightsAPIGetNodesOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetProjects of the ticketing system setup through integrations.
         * @param {InsightsAPIApiInsightsAPIGetProjectsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetProjects(requestParameters: InsightsAPIApiInsightsAPIGetProjectsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetProjectsResponse> {
            return localVarFp.insightsAPIGetProjects(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get alternative images for public image
         * @param {InsightsAPIApiInsightsAPIGetPublicImageAlternativesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetPublicImageAlternatives(requestParameters: InsightsAPIApiInsightsAPIGetPublicImageAlternativesRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetPublicImageAlternativesResponse> {
            return localVarFp.insightsAPIGetPublicImageAlternatives(requestParameters.tagId, requestParameters.language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetResourceDetails for display in attack path nodes
         * @param {InsightsAPIApiInsightsAPIGetResourceDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIGetResourceDetails(requestParameters: InsightsAPIApiInsightsAPIGetResourceDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1GetResourceDetailsResponse> {
            return localVarFp.insightsAPIGetResourceDetails(requestParameters.id, requestParameters.standard, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a diff of changes (x exceptions added, y exceptions removed) for excepted images
         * @param {InsightsAPIApiInsightsAPIImageExceptionChangesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIImageExceptionChanges(requestParameters: InsightsAPIApiInsightsAPIImageExceptionChangesRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1ImageExceptionChangesResponse> {
            return localVarFp.insightsAPIImageExceptionChanges(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ingest log sent by Security Agent
         * @param {InsightsAPIApiInsightsAPIIngestAgentLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIIngestAgentLog(requestParameters: InsightsAPIApiInsightsAPIIngestAgentLogRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.insightsAPIIngestAgentLog(requestParameters.clusterId, requestParameters.log, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListClusterSecuritySettings returns a list of clusters for the organization with their security settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIListClusterSecuritySettings(options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1ListClusterSecuritySettingsResponse> {
            return localVarFp.insightsAPIListClusterSecuritySettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of integrations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIListIntegrations(options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1ListIntegrationsResponse> {
            return localVarFp.insightsAPIListIntegrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListWorkloads lists workloads in the organization
         * @param {InsightsAPIApiInsightsAPIListWorkloadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIListWorkloads(requestParameters: InsightsAPIApiInsightsAPIListWorkloadsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1ListWorkloadsResponse> {
            return localVarFp.insightsAPIListWorkloads(requestParameters.search, requestParameters.clusterIds, requestParameters.namespaces, requestParameters.labels, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListWorkloadsChecks generate workloads x checks report matrix
         * @param {InsightsAPIApiInsightsAPIListWorkloadsChecksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIListWorkloadsChecks(requestParameters: InsightsAPIApiInsightsAPIListWorkloadsChecksRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1ListWorkloadsChecksResponse> {
            return localVarFp.insightsAPIListWorkloadsChecks(requestParameters.clusterIds, requestParameters.namespaces, requestParameters.labels, requestParameters.standard, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Agent posts telemetry data to this endpoint.
         * @param {InsightsAPIApiInsightsAPIPostAgentTelemetryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIPostAgentTelemetry(requestParameters: InsightsAPIApiInsightsAPIPostAgentTelemetryRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1PostAgentTelemetryResponse> {
            return localVarFp.insightsAPIPostAgentTelemetry(requestParameters.clusterId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a preview of resources that will be excepted with exception groups
         * @param {InsightsAPIApiInsightsAPIPreviewExceptionGroupsResourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIPreviewExceptionGroupsResources(requestParameters: InsightsAPIApiInsightsAPIPreviewExceptionGroupsResourcesRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1PreviewExceptionGroupsResourcesResponse> {
            return localVarFp.insightsAPIPreviewExceptionGroupsResources(requestParameters.ruleId, requestParameters.payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send check exceptions.
         * @param {InsightsAPIApiInsightsAPISaveCheckExceptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPISaveCheckExceptions(requestParameters: InsightsAPIApiInsightsAPISaveCheckExceptionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.insightsAPISaveCheckExceptions(requestParameters.ruleId, requestParameters.payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule best practices scan for cluster.
         * @param {InsightsAPIApiInsightsAPIScheduleBestPracticesScanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIScheduleBestPracticesScan(requestParameters: InsightsAPIApiInsightsAPIScheduleBestPracticesScanRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.insightsAPIScheduleBestPracticesScan(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update integration.
         * @param {InsightsAPIApiInsightsAPIUpdateIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insightsAPIUpdateIntegration(requestParameters: InsightsAPIApiInsightsAPIUpdateIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsightsV1UpdateIntegrationResponse> {
            return localVarFp.insightsAPIUpdateIntegration(requestParameters.id, requestParameters.payload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for insightsAPIConfigureClusterSecuritySettings operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIConfigureClusterSecuritySettingsRequest
 */
export interface InsightsAPIApiInsightsAPIConfigureClusterSecuritySettingsRequest {
    /**
     * The ID of the cluster to configure
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIConfigureClusterSecuritySettings
     */
    readonly id: string

    /**
     * 
     * @type {InsightsAPIConfigureClusterSecuritySettingsRequest}
     * @memberof InsightsAPIApiInsightsAPIConfigureClusterSecuritySettings
     */
    readonly body: InsightsAPIConfigureClusterSecuritySettingsRequest
}

/**
 * Request parameters for insightsAPICreateIntegration operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPICreateIntegrationRequest
 */
export interface InsightsAPIApiInsightsAPICreateIntegrationRequest {
    /**
     * (required) integration payload
     * @type {InsightsV1CreateIntegrationRequestPayload}
     * @memberof InsightsAPIApiInsightsAPICreateIntegration
     */
    readonly payload: InsightsV1CreateIntegrationRequestPayload
}

/**
 * Request parameters for insightsAPICreateTicket operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPICreateTicketRequest
 */
export interface InsightsAPIApiInsightsAPICreateTicketRequest {
    /**
     * integration id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPICreateTicket
     */
    readonly id: string

    /**
     * 
     * @type {InsightsAPICreateTicketRequest}
     * @memberof InsightsAPIApiInsightsAPICreateTicket
     */
    readonly body: InsightsAPICreateTicketRequest
}

/**
 * Request parameters for insightsAPIDeleteImages operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIDeleteImagesRequest
 */
export interface InsightsAPIApiInsightsAPIDeleteImagesRequest {
    /**
     * 
     * @type {InsightsV1DeleteImagesRequest}
     * @memberof InsightsAPIApiInsightsAPIDeleteImages
     */
    readonly body: InsightsV1DeleteImagesRequest
}

/**
 * Request parameters for insightsAPIDeleteIntegration operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIDeleteIntegrationRequest
 */
export interface InsightsAPIApiInsightsAPIDeleteIntegrationRequest {
    /**
     * (required) integration id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIDeleteIntegration
     */
    readonly id: string
}

/**
 * Request parameters for insightsAPIDeletePolicyEnforcement operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIDeletePolicyEnforcementRequest
 */
export interface InsightsAPIApiInsightsAPIDeletePolicyEnforcementRequest {
    /**
     * (required) enforcement id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIDeletePolicyEnforcement
     */
    readonly enforcementId: string
}

/**
 * Request parameters for insightsAPIEnforceCheckPolicy operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIEnforceCheckPolicyRequest
 */
export interface InsightsAPIApiInsightsAPIEnforceCheckPolicyRequest {
    /**
     * (required) rule id to enforce
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIEnforceCheckPolicy
     */
    readonly ruleId: string

    /**
     * 
     * @type {InsightsAPIEnforceCheckPolicyRequest}
     * @memberof InsightsAPIApiInsightsAPIEnforceCheckPolicy
     */
    readonly body: InsightsAPIEnforceCheckPolicyRequest
}

/**
 * Request parameters for insightsAPIGetAgentStatus operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetAgentStatusRequest
 */
export interface InsightsAPIApiInsightsAPIGetAgentStatusRequest {
    /**
     * (required) cluster id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetAgentStatus
     */
    readonly clusterId: string
}

/**
 * Request parameters for insightsAPIGetAgentSyncState operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetAgentSyncStateRequest
 */
export interface InsightsAPIApiInsightsAPIGetAgentSyncStateRequest {
    /**
     * (required) cluster id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetAgentSyncState
     */
    readonly clusterId: string

    /**
     * (required) sync state filter
     * @type {InsightsV1AgentSyncStateFilter}
     * @memberof InsightsAPIApiInsightsAPIGetAgentSyncState
     */
    readonly filter: InsightsV1AgentSyncStateFilter
}

/**
 * Request parameters for insightsAPIGetAgentsStatus operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetAgentsStatusRequest
 */
export interface InsightsAPIApiInsightsAPIGetAgentsStatusRequest {
    /**
     * 
     * @type {InsightsV1GetAgentsStatusRequest}
     * @memberof InsightsAPIApiInsightsAPIGetAgentsStatus
     */
    readonly body: InsightsV1GetAgentsStatusRequest
}

/**
 * Request parameters for insightsAPIGetAttackPathDetails operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetAttackPathDetailsRequest
 */
export interface InsightsAPIApiInsightsAPIGetAttackPathDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetAttackPathDetails
     */
    readonly id: string
}

/**
 * Request parameters for insightsAPIGetAttackPaths operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetAttackPathsRequest
 */
export interface InsightsAPIApiInsightsAPIGetAttackPathsRequest {
    /**
     * (optional) cluster_id filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetAttackPaths
     */
    readonly clusterIds?: Array<string>

    /**
     * (optional) resource_id filter
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetAttackPaths
     */
    readonly resourceId?: string
}

/**
 * Request parameters for insightsAPIGetBestPracticesCheckDetails operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetBestPracticesCheckDetailsRequest
 */
export interface InsightsAPIApiInsightsAPIGetBestPracticesCheckDetailsRequest {
    /**
     * (required) rule id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesCheckDetails
     */
    readonly ruleId: string

    /**
     * (optional) standard to use   - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
     * @type {'cast' | 'cisAks12' | 'cisEks12' | 'cisGke13' | 'cisAks13' | 'cisEks13' | 'cisGke14' | 'cisAks14'}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesCheckDetails
     */
    readonly standard?: InsightsAPIGetBestPracticesCheckDetailsStandardEnum
}

/**
 * Request parameters for insightsAPIGetBestPracticesOverview operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetBestPracticesOverviewRequest
 */
export interface InsightsAPIApiInsightsAPIGetBestPracticesOverviewRequest {
    /**
     *  - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
     * @type {'cast' | 'cisAks12' | 'cisEks12' | 'cisGke13' | 'cisAks13' | 'cisEks13' | 'cisGke14' | 'cisAks14'}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesOverview
     */
    readonly standard?: InsightsAPIGetBestPracticesOverviewStandardEnum
}

/**
 * Request parameters for insightsAPIGetBestPracticesReport operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetBestPracticesReportRequest
 */
export interface InsightsAPIApiInsightsAPIGetBestPracticesReportRequest {
    /**
     * (optional) cluster_id filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReport
     */
    readonly clusterIds?: Array<string>

    /**
     * (optional) namespace filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReport
     */
    readonly namespaces?: Array<string>

    /**
     * (optional) threat category filter
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReport
     */
    readonly category?: string

    /**
     * (optional) labels filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReport
     */
    readonly labels?: Array<string>

    /**
     * (optional) severity filter
     * @type {Array<'any' | 'none' | 'low' | 'medium' | 'high' | 'critical' | 'notAvailable'>}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReport
     */
    readonly severityLevels?: Array<InsightsAPIGetBestPracticesReportSeverityLevelsEnum>

    /**
     * (optional) standard to display rules in   - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
     * @type {'cast' | 'cisAks12' | 'cisEks12' | 'cisGke13' | 'cisAks13' | 'cisEks13' | 'cisGke14' | 'cisAks14'}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReport
     */
    readonly standard?: InsightsAPIGetBestPracticesReportStandardEnum

    /**
     * display read only clusters
     * @type {boolean}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReport
     */
    readonly readonlyClusters?: boolean

    /**
     * (optional) filter by resource K8s id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReport
     */
    readonly resourceIdFilter?: string
}

/**
 * Request parameters for insightsAPIGetBestPracticesReportFilters operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetBestPracticesReportFiltersRequest
 */
export interface InsightsAPIApiInsightsAPIGetBestPracticesReportFiltersRequest {
    /**
     * (optional) return filters available for specific clusters
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReportFilters
     */
    readonly clusterIds?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof InsightsAPIApiInsightsAPIGetBestPracticesReportFilters
     */
    readonly filtersv2?: boolean
}

/**
 * Request parameters for insightsAPIGetCheckExceptions operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetCheckExceptionsRequest
 */
export interface InsightsAPIApiInsightsAPIGetCheckExceptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetCheckExceptions
     */
    readonly ruleId: string
}

/**
 * Request parameters for insightsAPIGetCheckResources operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetCheckResourcesRequest
 */
export interface InsightsAPIApiInsightsAPIGetCheckResourcesRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetCheckResources
     */
    readonly ruleId: string

    /**
     * 
     * @type {InsightsAPIGetCheckResourcesRequest}
     * @memberof InsightsAPIApiInsightsAPIGetCheckResources
     */
    readonly body: InsightsAPIGetCheckResourcesRequest
}

/**
 * Request parameters for insightsAPIGetChecksResources operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetChecksResourcesRequest
 */
export interface InsightsAPIApiInsightsAPIGetChecksResourcesRequest {
    /**
     * 
     * @type {InsightsV1GetChecksResourcesRequest}
     * @memberof InsightsAPIApiInsightsAPIGetChecksResources
     */
    readonly body: InsightsV1GetChecksResourcesRequest
}

/**
 * Request parameters for insightsAPIGetClusterSecuritySettings operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetClusterSecuritySettingsRequest
 */
export interface InsightsAPIApiInsightsAPIGetClusterSecuritySettingsRequest {
    /**
     * The ID of the cluster
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetClusterSecuritySettings
     */
    readonly id: string
}

/**
 * Request parameters for insightsAPIGetContainerImageDetails operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetContainerImageDetailsRequest
 */
export interface InsightsAPIApiInsightsAPIGetContainerImageDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImageDetails
     */
    readonly tagId: string
}

/**
 * Request parameters for insightsAPIGetContainerImageDigests operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetContainerImageDigestsRequest
 */
export interface InsightsAPIApiInsightsAPIGetContainerImageDigestsRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImageDigests
     */
    readonly tagId: string
}

/**
 * Request parameters for insightsAPIGetContainerImagePackageVulnerabilityDetails operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetContainerImagePackageVulnerabilityDetailsRequest
 */
export interface InsightsAPIApiInsightsAPIGetContainerImagePackageVulnerabilityDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImagePackageVulnerabilityDetails
     */
    readonly tagId: string

    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImagePackageVulnerabilityDetails
     */
    readonly pkgVulnId: string
}

/**
 * Request parameters for insightsAPIGetContainerImagePackages operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetContainerImagePackagesRequest
 */
export interface InsightsAPIApiInsightsAPIGetContainerImagePackagesRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImagePackages
     */
    readonly tagId: string

    /**
     *  - all: return all resources  - active: return only not excepted resources  - excepted: return only excepted resources
     * @type {'all' | 'active' | 'excepted'}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImagePackages
     */
    readonly exceptionFilter?: InsightsAPIGetContainerImagePackagesExceptionFilterEnum
}

/**
 * Request parameters for insightsAPIGetContainerImageResources operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetContainerImageResourcesRequest
 */
export interface InsightsAPIApiInsightsAPIGetContainerImageResourcesRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImageResources
     */
    readonly tagId: string

    /**
     * 
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImageResources
     */
    readonly labels?: Array<string>
}

/**
 * Request parameters for insightsAPIGetContainerImageVulnerabilities operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetContainerImageVulnerabilitiesRequest
 */
export interface InsightsAPIApiInsightsAPIGetContainerImageVulnerabilitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImageVulnerabilities
     */
    readonly tagId: string

    /**
     * (optional) Filter by package ID.
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImageVulnerabilities
     */
    readonly pkgId?: string

    /**
     *  - all: return all resources  - active: return only not excepted resources  - excepted: return only excepted resources
     * @type {'all' | 'active' | 'excepted'}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImageVulnerabilities
     */
    readonly exceptionFilter?: InsightsAPIGetContainerImageVulnerabilitiesExceptionFilterEnum
}

/**
 * Request parameters for insightsAPIGetContainerImages operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetContainerImagesRequest
 */
export interface InsightsAPIApiInsightsAPIGetContainerImagesRequest {
    /**
     * (optional) status filter
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly status?: string

    /**
     * (optional) cves filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly cves?: Array<string>

    /**
     * (optional) packages filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly packages?: Array<string>

    /**
     * (optional) namespaces filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly namespaces?: Array<string>

    /**
     * (optional) clusters filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly clusterIds?: Array<string>

    /**
     * (optional) labels filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly labels?: Array<string>

    /**
     * (optional) exception filter   - all: return all resources  - active: return only not excepted resources  - excepted: return only excepted resources
     * @type {'all' | 'active' | 'excepted'}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly exceptionFilter?: InsightsAPIGetContainerImagesExceptionFilterEnum

    /**
     * (optional) filter image by resource_id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly resourceIdFilter?: string

    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImages
     */
    readonly sortOrder?: InsightsAPIGetContainerImagesSortOrderEnum
}

/**
 * Request parameters for insightsAPIGetContainerImagesFilters operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetContainerImagesFiltersRequest
 */
export interface InsightsAPIApiInsightsAPIGetContainerImagesFiltersRequest {
    /**
     * (optional) return filters available for specific clusters
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImagesFilters
     */
    readonly clusterIds?: Array<string>
}

/**
 * Request parameters for insightsAPIGetContainerImagesSummary operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetContainerImagesSummaryRequest
 */
export interface InsightsAPIApiInsightsAPIGetContainerImagesSummaryRequest {
    /**
     * (optional) ID of cluster
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetContainerImagesSummary
     */
    readonly clusterId?: string
}

/**
 * Request parameters for insightsAPIGetDailySummary operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetDailySummaryRequest
 */
export interface InsightsAPIApiInsightsAPIGetDailySummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetDailySummary
     */
    readonly date: string
}

/**
 * Request parameters for insightsAPIGetExceptedChecks operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetExceptedChecksRequest
 */
export interface InsightsAPIApiInsightsAPIGetExceptedChecksRequest {
    /**
     * (optional) cluster_id filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetExceptedChecks
     */
    readonly clusterIds?: Array<string>

    /**
     * (optional) namespace filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIGetExceptedChecks
     */
    readonly namespaces?: Array<string>

    /**
     * (optional) threat category filter
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetExceptedChecks
     */
    readonly category?: string

    /**
     * (optional) severity filter
     * @type {Array<'any' | 'none' | 'low' | 'medium' | 'high' | 'critical' | 'notAvailable'>}
     * @memberof InsightsAPIApiInsightsAPIGetExceptedChecks
     */
    readonly severityLevels?: Array<InsightsAPIGetExceptedChecksSeverityLevelsEnum>

    /**
     * (optional) standard to display rules in   - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
     * @type {'cast' | 'cisAks12' | 'cisEks12' | 'cisGke13' | 'cisAks13' | 'cisEks13' | 'cisGke14' | 'cisAks14'}
     * @memberof InsightsAPIApiInsightsAPIGetExceptedChecks
     */
    readonly standard?: InsightsAPIGetExceptedChecksStandardEnum
}

/**
 * Request parameters for insightsAPIGetIntegration operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetIntegrationRequest
 */
export interface InsightsAPIApiInsightsAPIGetIntegrationRequest {
    /**
     * (required) integration id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetIntegration
     */
    readonly id: string
}

/**
 * Request parameters for insightsAPIGetProjects operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetProjectsRequest
 */
export interface InsightsAPIApiInsightsAPIGetProjectsRequest {
    /**
     * integration id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetProjects
     */
    readonly id: string
}

/**
 * Request parameters for insightsAPIGetPublicImageAlternatives operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetPublicImageAlternativesRequest
 */
export interface InsightsAPIApiInsightsAPIGetPublicImageAlternativesRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetPublicImageAlternatives
     */
    readonly tagId: string

    /**
     * (optional) return distroless recommendation for language
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetPublicImageAlternatives
     */
    readonly language?: string
}

/**
 * Request parameters for insightsAPIGetResourceDetails operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIGetResourceDetailsRequest
 */
export interface InsightsAPIApiInsightsAPIGetResourceDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIGetResourceDetails
     */
    readonly id: string

    /**
     *  - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
     * @type {'cast' | 'cisAks12' | 'cisEks12' | 'cisGke13' | 'cisAks13' | 'cisEks13' | 'cisGke14' | 'cisAks14'}
     * @memberof InsightsAPIApiInsightsAPIGetResourceDetails
     */
    readonly standard: InsightsAPIGetResourceDetailsStandardEnum
}

/**
 * Request parameters for insightsAPIImageExceptionChanges operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIImageExceptionChangesRequest
 */
export interface InsightsAPIApiInsightsAPIImageExceptionChangesRequest {
    /**
     * 
     * @type {InsightsV1ImageExceptionChangesRequest}
     * @memberof InsightsAPIApiInsightsAPIImageExceptionChanges
     */
    readonly body: InsightsV1ImageExceptionChangesRequest
}

/**
 * Request parameters for insightsAPIIngestAgentLog operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIIngestAgentLogRequest
 */
export interface InsightsAPIApiInsightsAPIIngestAgentLogRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIIngestAgentLog
     */
    readonly clusterId: string

    /**
     * 
     * @type {InsightsV1LogEvent}
     * @memberof InsightsAPIApiInsightsAPIIngestAgentLog
     */
    readonly log: InsightsV1LogEvent
}

/**
 * Request parameters for insightsAPIListWorkloads operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIListWorkloadsRequest
 */
export interface InsightsAPIApiInsightsAPIListWorkloadsRequest {
    /**
     * (optional) name filter
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIListWorkloads
     */
    readonly search?: string

    /**
     * (optional) cluster_id filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIListWorkloads
     */
    readonly clusterIds?: Array<string>

    /**
     * (optional) namespace filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIListWorkloads
     */
    readonly namespaces?: Array<string>

    /**
     * (optional) labels filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIListWorkloads
     */
    readonly labels?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIListWorkloads
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIListWorkloads
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIListWorkloads
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof InsightsAPIApiInsightsAPIListWorkloads
     */
    readonly sortOrder?: InsightsAPIListWorkloadsSortOrderEnum
}

/**
 * Request parameters for insightsAPIListWorkloadsChecks operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIListWorkloadsChecksRequest
 */
export interface InsightsAPIApiInsightsAPIListWorkloadsChecksRequest {
    /**
     * (optional) cluster_id filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIListWorkloadsChecks
     */
    readonly clusterIds?: Array<string>

    /**
     * (optional) namespace filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIListWorkloadsChecks
     */
    readonly namespaces?: Array<string>

    /**
     * (optional) labels filter
     * @type {Array<string>}
     * @memberof InsightsAPIApiInsightsAPIListWorkloadsChecks
     */
    readonly labels?: Array<string>

    /**
     *  - cast: default standard  - cisAks12: cis aks 12  - cisEks12: cis eks 12  - cisGke13: cis gke 13  - cisAks13: cis aks 13  - cisEks13: cis eks 13  - cisGke14: cis gke 14  - cisAks14: cis aks 14
     * @type {'cast' | 'cisAks12' | 'cisEks12' | 'cisGke13' | 'cisAks13' | 'cisEks13' | 'cisGke14' | 'cisAks14'}
     * @memberof InsightsAPIApiInsightsAPIListWorkloadsChecks
     */
    readonly standard?: InsightsAPIListWorkloadsChecksStandardEnum
}

/**
 * Request parameters for insightsAPIPostAgentTelemetry operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIPostAgentTelemetryRequest
 */
export interface InsightsAPIApiInsightsAPIPostAgentTelemetryRequest {
    /**
     * (required) cluster id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIPostAgentTelemetry
     */
    readonly clusterId: string

    /**
     * 
     * @type {InsightsAPIPostAgentTelemetryRequest}
     * @memberof InsightsAPIApiInsightsAPIPostAgentTelemetry
     */
    readonly body: InsightsAPIPostAgentTelemetryRequest
}

/**
 * Request parameters for insightsAPIPreviewExceptionGroupsResources operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIPreviewExceptionGroupsResourcesRequest
 */
export interface InsightsAPIApiInsightsAPIPreviewExceptionGroupsResourcesRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIPreviewExceptionGroupsResources
     */
    readonly ruleId: string

    /**
     * 
     * @type {InsightsV1CheckExceptionsPayload}
     * @memberof InsightsAPIApiInsightsAPIPreviewExceptionGroupsResources
     */
    readonly payload: InsightsV1CheckExceptionsPayload
}

/**
 * Request parameters for insightsAPISaveCheckExceptions operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPISaveCheckExceptionsRequest
 */
export interface InsightsAPIApiInsightsAPISaveCheckExceptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPISaveCheckExceptions
     */
    readonly ruleId: string

    /**
     * 
     * @type {InsightsV1CheckExceptionsPayload}
     * @memberof InsightsAPIApiInsightsAPISaveCheckExceptions
     */
    readonly payload: InsightsV1CheckExceptionsPayload
}

/**
 * Request parameters for insightsAPIScheduleBestPracticesScan operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIScheduleBestPracticesScanRequest
 */
export interface InsightsAPIApiInsightsAPIScheduleBestPracticesScanRequest {
    /**
     * 
     * @type {InsightsV1ScheduleBestPracticesScanRequest}
     * @memberof InsightsAPIApiInsightsAPIScheduleBestPracticesScan
     */
    readonly body: InsightsV1ScheduleBestPracticesScanRequest
}

/**
 * Request parameters for insightsAPIUpdateIntegration operation in InsightsAPIApi.
 * @export
 * @interface InsightsAPIApiInsightsAPIUpdateIntegrationRequest
 */
export interface InsightsAPIApiInsightsAPIUpdateIntegrationRequest {
    /**
     * (required) integration id
     * @type {string}
     * @memberof InsightsAPIApiInsightsAPIUpdateIntegration
     */
    readonly id: string

    /**
     * (required) integration payload
     * @type {InsightsV1UpdateIntegrationRequestPayload}
     * @memberof InsightsAPIApiInsightsAPIUpdateIntegration
     */
    readonly payload: InsightsV1UpdateIntegrationRequestPayload
}

/**
 * InsightsAPIApi - object-oriented interface
 * @export
 * @class InsightsAPIApi
 * @extends {BaseAPI}
 */
export class InsightsAPIApi extends BaseAPI {
    /**
     * 
     * @summary ConfigureClusterSecuritySettings allows to configure security settings on a cluster.
     * @param {InsightsAPIApiInsightsAPIConfigureClusterSecuritySettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIConfigureClusterSecuritySettings(requestParameters: InsightsAPIApiInsightsAPIConfigureClusterSecuritySettingsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIConfigureClusterSecuritySettings(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create integration.
     * @param {InsightsAPIApiInsightsAPICreateIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPICreateIntegration(requestParameters: InsightsAPIApiInsightsAPICreateIntegrationRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPICreateIntegration(requestParameters.payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CreateTicket in the ticketing system setup through integrations.
     * @param {InsightsAPIApiInsightsAPICreateTicketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPICreateTicket(requestParameters: InsightsAPIApiInsightsAPICreateTicketRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPICreateTicket(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteImages should be used for deleting old images
     * @param {InsightsAPIApiInsightsAPIDeleteImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIDeleteImages(requestParameters: InsightsAPIApiInsightsAPIDeleteImagesRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIDeleteImages(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete integration.
     * @param {InsightsAPIApiInsightsAPIDeleteIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIDeleteIntegration(requestParameters: InsightsAPIApiInsightsAPIDeleteIntegrationRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIDeleteIntegration(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete policy enforcement.
     * @param {InsightsAPIApiInsightsAPIDeletePolicyEnforcementRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIDeletePolicyEnforcement(requestParameters: InsightsAPIApiInsightsAPIDeletePolicyEnforcementRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIDeletePolicyEnforcement(requestParameters.enforcementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enforce linter rule as policy.
     * @param {InsightsAPIApiInsightsAPIEnforceCheckPolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIEnforceCheckPolicy(requestParameters: InsightsAPIApiInsightsAPIEnforceCheckPolicyRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIEnforceCheckPolicy(requestParameters.ruleId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get status of security agent
     * @param {InsightsAPIApiInsightsAPIGetAgentStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetAgentStatus(requestParameters: InsightsAPIApiInsightsAPIGetAgentStatusRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetAgentStatus(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Agent calls this endpoint to sync state for scanned images.
     * @param {InsightsAPIApiInsightsAPIGetAgentSyncStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetAgentSyncState(requestParameters: InsightsAPIApiInsightsAPIGetAgentSyncStateRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetAgentSyncState(requestParameters.clusterId, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get status of security agents
     * @param {InsightsAPIApiInsightsAPIGetAgentsStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetAgentsStatus(requestParameters: InsightsAPIApiInsightsAPIGetAgentsStatusRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetAgentsStatus(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get attack path details including graph.
     * @param {InsightsAPIApiInsightsAPIGetAttackPathDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetAttackPathDetails(requestParameters: InsightsAPIApiInsightsAPIGetAttackPathDetailsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetAttackPathDetails(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get attack paths list.
     * @param {InsightsAPIApiInsightsAPIGetAttackPathsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetAttackPaths(requestParameters: InsightsAPIApiInsightsAPIGetAttackPathsRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetAttackPaths(requestParameters.clusterIds, requestParameters.resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetAttackPathsOverview returns a high-level overview of attack paths.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetAttackPathsOverview(options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetAttackPathsOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get single check information and objects that matched the rule in the last best practices report.
     * @param {InsightsAPIApiInsightsAPIGetBestPracticesCheckDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetBestPracticesCheckDetails(requestParameters: InsightsAPIApiInsightsAPIGetBestPracticesCheckDetailsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetBestPracticesCheckDetails(requestParameters.ruleId, requestParameters.standard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetBestPracticesOverview returns a high-level overview of best practices.
     * @param {InsightsAPIApiInsightsAPIGetBestPracticesOverviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetBestPracticesOverview(requestParameters: InsightsAPIApiInsightsAPIGetBestPracticesOverviewRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetBestPracticesOverview(requestParameters.standard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest best practices report.
     * @param {InsightsAPIApiInsightsAPIGetBestPracticesReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetBestPracticesReport(requestParameters: InsightsAPIApiInsightsAPIGetBestPracticesReportRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetBestPracticesReport(requestParameters.clusterIds, requestParameters.namespaces, requestParameters.category, requestParameters.labels, requestParameters.severityLevels, requestParameters.standard, requestParameters.readonlyClusters, requestParameters.resourceIdFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of possible filters for best practices report.
     * @param {InsightsAPIApiInsightsAPIGetBestPracticesReportFiltersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetBestPracticesReportFilters(requestParameters: InsightsAPIApiInsightsAPIGetBestPracticesReportFiltersRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetBestPracticesReportFilters(requestParameters.clusterIds, requestParameters.filtersv2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active exceptions for selected rule
     * @param {InsightsAPIApiInsightsAPIGetCheckExceptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetCheckExceptions(requestParameters: InsightsAPIApiInsightsAPIGetCheckExceptionsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetCheckExceptions(requestParameters.ruleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get resources for provided check and filtering POST due to possible amount of parameters
     * @param {InsightsAPIApiInsightsAPIGetCheckResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetCheckResources(requestParameters: InsightsAPIApiInsightsAPIGetCheckResourcesRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetCheckResources(requestParameters.ruleId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get resources for provided checks and filtering POST due to possible amount of parameters
     * @param {InsightsAPIApiInsightsAPIGetChecksResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetChecksResources(requestParameters: InsightsAPIApiInsightsAPIGetChecksResourcesRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetChecksResources(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetClusterSecuritySettings returns security settings for a specific cluster.
     * @param {InsightsAPIApiInsightsAPIGetClusterSecuritySettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetClusterSecuritySettings(requestParameters: InsightsAPIApiInsightsAPIGetClusterSecuritySettingsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetClusterSecuritySettings(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details for container image by tag
     * @param {InsightsAPIApiInsightsAPIGetContainerImageDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetContainerImageDetails(requestParameters: InsightsAPIApiInsightsAPIGetContainerImageDetailsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetContainerImageDetails(requestParameters.tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get container image digests for organization by tag id
     * @param {InsightsAPIApiInsightsAPIGetContainerImageDigestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetContainerImageDigests(requestParameters: InsightsAPIApiInsightsAPIGetContainerImageDigestsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetContainerImageDigests(requestParameters.tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get image vulnerability for a specific package
     * @param {InsightsAPIApiInsightsAPIGetContainerImagePackageVulnerabilityDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetContainerImagePackageVulnerabilityDetails(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagePackageVulnerabilityDetailsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetContainerImagePackageVulnerabilityDetails(requestParameters.tagId, requestParameters.pkgVulnId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of image packages
     * @param {InsightsAPIApiInsightsAPIGetContainerImagePackagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetContainerImagePackages(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagePackagesRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetContainerImagePackages(requestParameters.tagId, requestParameters.exceptionFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cluster resources for container image
     * @param {InsightsAPIApiInsightsAPIGetContainerImageResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetContainerImageResources(requestParameters: InsightsAPIApiInsightsAPIGetContainerImageResourcesRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetContainerImageResources(requestParameters.tagId, requestParameters.labels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of image vulnerabilities
     * @param {InsightsAPIApiInsightsAPIGetContainerImageVulnerabilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetContainerImageVulnerabilities(requestParameters: InsightsAPIApiInsightsAPIGetContainerImageVulnerabilitiesRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetContainerImageVulnerabilities(requestParameters.tagId, requestParameters.pkgId, requestParameters.exceptionFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of container images for organization
     * @param {InsightsAPIApiInsightsAPIGetContainerImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetContainerImages(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagesRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetContainerImages(requestParameters.status, requestParameters.cves, requestParameters.packages, requestParameters.namespaces, requestParameters.clusterIds, requestParameters.labels, requestParameters.exceptionFilter, requestParameters.resourceIdFilter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of possible filters for images endpoint
     * @param {InsightsAPIApiInsightsAPIGetContainerImagesFiltersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetContainerImagesFilters(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagesFiltersRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetContainerImagesFilters(requestParameters.clusterIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get container image vulnerability summary for organization
     * @param {InsightsAPIApiInsightsAPIGetContainerImagesSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetContainerImagesSummary(requestParameters: InsightsAPIApiInsightsAPIGetContainerImagesSummaryRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetContainerImagesSummary(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetDailySummary returns the data of the daily vulnerability notification.
     * @param {InsightsAPIApiInsightsAPIGetDailySummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetDailySummary(requestParameters: InsightsAPIApiInsightsAPIGetDailySummaryRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetDailySummary(requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checks that have not passed but are excepted
     * @param {InsightsAPIApiInsightsAPIGetExceptedChecksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetExceptedChecks(requestParameters: InsightsAPIApiInsightsAPIGetExceptedChecksRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetExceptedChecks(requestParameters.clusterIds, requestParameters.namespaces, requestParameters.category, requestParameters.severityLevels, requestParameters.standard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetImageSecurityOverview returns a high-level overview of image security.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetImageSecurityOverview(options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetImageSecurityOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get integration.
     * @param {InsightsAPIApiInsightsAPIGetIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetIntegration(requestParameters: InsightsAPIApiInsightsAPIGetIntegrationRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetIntegration(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetManagedNodesOverview returns a high-level overview of managed nodes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetNodesOverview(options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetNodesOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetProjects of the ticketing system setup through integrations.
     * @param {InsightsAPIApiInsightsAPIGetProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetProjects(requestParameters: InsightsAPIApiInsightsAPIGetProjectsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetProjects(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get alternative images for public image
     * @param {InsightsAPIApiInsightsAPIGetPublicImageAlternativesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetPublicImageAlternatives(requestParameters: InsightsAPIApiInsightsAPIGetPublicImageAlternativesRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetPublicImageAlternatives(requestParameters.tagId, requestParameters.language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetResourceDetails for display in attack path nodes
     * @param {InsightsAPIApiInsightsAPIGetResourceDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIGetResourceDetails(requestParameters: InsightsAPIApiInsightsAPIGetResourceDetailsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIGetResourceDetails(requestParameters.id, requestParameters.standard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a diff of changes (x exceptions added, y exceptions removed) for excepted images
     * @param {InsightsAPIApiInsightsAPIImageExceptionChangesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIImageExceptionChanges(requestParameters: InsightsAPIApiInsightsAPIImageExceptionChangesRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIImageExceptionChanges(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ingest log sent by Security Agent
     * @param {InsightsAPIApiInsightsAPIIngestAgentLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIIngestAgentLog(requestParameters: InsightsAPIApiInsightsAPIIngestAgentLogRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIIngestAgentLog(requestParameters.clusterId, requestParameters.log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListClusterSecuritySettings returns a list of clusters for the organization with their security settings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIListClusterSecuritySettings(options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIListClusterSecuritySettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of integrations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIListIntegrations(options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIListIntegrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListWorkloads lists workloads in the organization
     * @param {InsightsAPIApiInsightsAPIListWorkloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIListWorkloads(requestParameters: InsightsAPIApiInsightsAPIListWorkloadsRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIListWorkloads(requestParameters.search, requestParameters.clusterIds, requestParameters.namespaces, requestParameters.labels, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListWorkloadsChecks generate workloads x checks report matrix
     * @param {InsightsAPIApiInsightsAPIListWorkloadsChecksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIListWorkloadsChecks(requestParameters: InsightsAPIApiInsightsAPIListWorkloadsChecksRequest = {}, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIListWorkloadsChecks(requestParameters.clusterIds, requestParameters.namespaces, requestParameters.labels, requestParameters.standard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Agent posts telemetry data to this endpoint.
     * @param {InsightsAPIApiInsightsAPIPostAgentTelemetryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIPostAgentTelemetry(requestParameters: InsightsAPIApiInsightsAPIPostAgentTelemetryRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIPostAgentTelemetry(requestParameters.clusterId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a preview of resources that will be excepted with exception groups
     * @param {InsightsAPIApiInsightsAPIPreviewExceptionGroupsResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIPreviewExceptionGroupsResources(requestParameters: InsightsAPIApiInsightsAPIPreviewExceptionGroupsResourcesRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIPreviewExceptionGroupsResources(requestParameters.ruleId, requestParameters.payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send check exceptions.
     * @param {InsightsAPIApiInsightsAPISaveCheckExceptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPISaveCheckExceptions(requestParameters: InsightsAPIApiInsightsAPISaveCheckExceptionsRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPISaveCheckExceptions(requestParameters.ruleId, requestParameters.payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule best practices scan for cluster.
     * @param {InsightsAPIApiInsightsAPIScheduleBestPracticesScanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIScheduleBestPracticesScan(requestParameters: InsightsAPIApiInsightsAPIScheduleBestPracticesScanRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIScheduleBestPracticesScan(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update integration.
     * @param {InsightsAPIApiInsightsAPIUpdateIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsAPIApi
     */
    public insightsAPIUpdateIntegration(requestParameters: InsightsAPIApiInsightsAPIUpdateIntegrationRequest, options?: RawAxiosRequestConfig) {
        return InsightsAPIApiFp(this.configuration).insightsAPIUpdateIntegration(requestParameters.id, requestParameters.payload, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const InsightsAPIGetBestPracticesCheckDetailsStandardEnum = {
    cast: 'cast',
    cisAks12: 'cisAks12',
    cisEks12: 'cisEks12',
    cisGke13: 'cisGke13',
    cisAks13: 'cisAks13',
    cisEks13: 'cisEks13',
    cisGke14: 'cisGke14',
    cisAks14: 'cisAks14'
} as const;
export type InsightsAPIGetBestPracticesCheckDetailsStandardEnum = typeof InsightsAPIGetBestPracticesCheckDetailsStandardEnum[keyof typeof InsightsAPIGetBestPracticesCheckDetailsStandardEnum];
/**
 * @export
 */
export const InsightsAPIGetBestPracticesOverviewStandardEnum = {
    cast: 'cast',
    cisAks12: 'cisAks12',
    cisEks12: 'cisEks12',
    cisGke13: 'cisGke13',
    cisAks13: 'cisAks13',
    cisEks13: 'cisEks13',
    cisGke14: 'cisGke14',
    cisAks14: 'cisAks14'
} as const;
export type InsightsAPIGetBestPracticesOverviewStandardEnum = typeof InsightsAPIGetBestPracticesOverviewStandardEnum[keyof typeof InsightsAPIGetBestPracticesOverviewStandardEnum];
/**
 * @export
 */
export const InsightsAPIGetBestPracticesReportSeverityLevelsEnum = {
    any: 'any',
    none: 'none',
    low: 'low',
    medium: 'medium',
    high: 'high',
    critical: 'critical',
    notAvailable: 'notAvailable'
} as const;
export type InsightsAPIGetBestPracticesReportSeverityLevelsEnum = typeof InsightsAPIGetBestPracticesReportSeverityLevelsEnum[keyof typeof InsightsAPIGetBestPracticesReportSeverityLevelsEnum];
/**
 * @export
 */
export const InsightsAPIGetBestPracticesReportStandardEnum = {
    cast: 'cast',
    cisAks12: 'cisAks12',
    cisEks12: 'cisEks12',
    cisGke13: 'cisGke13',
    cisAks13: 'cisAks13',
    cisEks13: 'cisEks13',
    cisGke14: 'cisGke14',
    cisAks14: 'cisAks14'
} as const;
export type InsightsAPIGetBestPracticesReportStandardEnum = typeof InsightsAPIGetBestPracticesReportStandardEnum[keyof typeof InsightsAPIGetBestPracticesReportStandardEnum];
/**
 * @export
 */
export const InsightsAPIGetContainerImagePackagesExceptionFilterEnum = {
    all: 'all',
    active: 'active',
    excepted: 'excepted'
} as const;
export type InsightsAPIGetContainerImagePackagesExceptionFilterEnum = typeof InsightsAPIGetContainerImagePackagesExceptionFilterEnum[keyof typeof InsightsAPIGetContainerImagePackagesExceptionFilterEnum];
/**
 * @export
 */
export const InsightsAPIGetContainerImageVulnerabilitiesExceptionFilterEnum = {
    all: 'all',
    active: 'active',
    excepted: 'excepted'
} as const;
export type InsightsAPIGetContainerImageVulnerabilitiesExceptionFilterEnum = typeof InsightsAPIGetContainerImageVulnerabilitiesExceptionFilterEnum[keyof typeof InsightsAPIGetContainerImageVulnerabilitiesExceptionFilterEnum];
/**
 * @export
 */
export const InsightsAPIGetContainerImagesExceptionFilterEnum = {
    all: 'all',
    active: 'active',
    excepted: 'excepted'
} as const;
export type InsightsAPIGetContainerImagesExceptionFilterEnum = typeof InsightsAPIGetContainerImagesExceptionFilterEnum[keyof typeof InsightsAPIGetContainerImagesExceptionFilterEnum];
/**
 * @export
 */
export const InsightsAPIGetContainerImagesSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type InsightsAPIGetContainerImagesSortOrderEnum = typeof InsightsAPIGetContainerImagesSortOrderEnum[keyof typeof InsightsAPIGetContainerImagesSortOrderEnum];
/**
 * @export
 */
export const InsightsAPIGetExceptedChecksSeverityLevelsEnum = {
    any: 'any',
    none: 'none',
    low: 'low',
    medium: 'medium',
    high: 'high',
    critical: 'critical',
    notAvailable: 'notAvailable'
} as const;
export type InsightsAPIGetExceptedChecksSeverityLevelsEnum = typeof InsightsAPIGetExceptedChecksSeverityLevelsEnum[keyof typeof InsightsAPIGetExceptedChecksSeverityLevelsEnum];
/**
 * @export
 */
export const InsightsAPIGetExceptedChecksStandardEnum = {
    cast: 'cast',
    cisAks12: 'cisAks12',
    cisEks12: 'cisEks12',
    cisGke13: 'cisGke13',
    cisAks13: 'cisAks13',
    cisEks13: 'cisEks13',
    cisGke14: 'cisGke14',
    cisAks14: 'cisAks14'
} as const;
export type InsightsAPIGetExceptedChecksStandardEnum = typeof InsightsAPIGetExceptedChecksStandardEnum[keyof typeof InsightsAPIGetExceptedChecksStandardEnum];
/**
 * @export
 */
export const InsightsAPIGetResourceDetailsStandardEnum = {
    cast: 'cast',
    cisAks12: 'cisAks12',
    cisEks12: 'cisEks12',
    cisGke13: 'cisGke13',
    cisAks13: 'cisAks13',
    cisEks13: 'cisEks13',
    cisGke14: 'cisGke14',
    cisAks14: 'cisAks14'
} as const;
export type InsightsAPIGetResourceDetailsStandardEnum = typeof InsightsAPIGetResourceDetailsStandardEnum[keyof typeof InsightsAPIGetResourceDetailsStandardEnum];
/**
 * @export
 */
export const InsightsAPIListWorkloadsSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type InsightsAPIListWorkloadsSortOrderEnum = typeof InsightsAPIListWorkloadsSortOrderEnum[keyof typeof InsightsAPIListWorkloadsSortOrderEnum];
/**
 * @export
 */
export const InsightsAPIListWorkloadsChecksStandardEnum = {
    cast: 'cast',
    cisAks12: 'cisAks12',
    cisEks12: 'cisEks12',
    cisGke13: 'cisGke13',
    cisAks13: 'cisAks13',
    cisEks13: 'cisEks13',
    cisGke14: 'cisGke14',
    cisAks14: 'cisAks14'
} as const;
export type InsightsAPIListWorkloadsChecksStandardEnum = typeof InsightsAPIListWorkloadsChecksStandardEnum[keyof typeof InsightsAPIListWorkloadsChecksStandardEnum];
