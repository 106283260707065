import { PropsWithChildren, ReactNode } from 'react';

import { Stack, StackProps, Typography } from '@mui/material';

import { mergeSx } from '@cast/design-system';

import { InitiateTour, InitiateTourProps } from 'core/tour';

type Props = {
  prefix?: ReactNode;
  heading: ReactNode;
  postfix?: ReactNode;
  initiateTour?: InitiateTourProps;
  testId?: string;
} & StackProps;

export type PageHeaderProps = PropsWithChildren<Props>;

export const PageHeader = ({
  children,
  prefix,
  heading,
  postfix,
  initiateTour,
  testId,
  ...boxProps
}: PageHeaderProps) => {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      pb={24}
      flexGrow={0}
      {...boxProps}
      data-testid={testId ? testId : 'page-header'}
    >
      <Stack direction="row" gap={4} flexGrow={1}>
        {prefix}
        {typeof heading === 'string' ? (
          <Typography variant="h3" color="black">
            {heading}
            {initiateTour && (
              <InitiateTour
                {...initiateTour}
                sx={mergeSx(
                  { position: 'absolute', display: 'inline-block' },
                  initiateTour.sx
                )}
              />
            )}
          </Typography>
        ) : (
          heading
        )}
        {postfix}
      </Stack>
      {children}
    </Stack>
  );
};
