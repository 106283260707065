import { HTMLAttributes, PropsWithChildren } from 'react';

import clsx from 'clsx';

export type BreadcrumbProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & { disabled?: boolean }
>;

export const Breadcrumb = ({
  children,
  disabled,
  className,
  ...rest
}: BreadcrumbProps) => {
  return (
    <div
      className={clsx(
        'DS-Breadcrumb-root',
        disabled && 'Mui-disabled',
        className
      )}
      {...(disabled ? { 'aria-disabled': true } : { tabIndex: 1 })}
      {...rest}
    >
      {children}
    </div>
  );
};
