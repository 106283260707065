import { useQuery, useQueryClient } from '@tanstack/react-query';
import uniq from 'lodash/uniq';

import { ClusterDisplayStatus, ClusterResponse } from '@cast/types';
import { isDemoCluster, removeDemoMode, setDemoMode } from '@cast/utils';

import {
  AFFILIATE_ID_COOKIE_KEY,
  IS_PROD,
  VISIT_ID_COOKIE_KEY,
} from 'common/constants';
import { userId } from 'core/auth';
import { apiClient, QueryKeys } from 'core/react-query';
import { enhanceCluster } from 'utils/api-utils';
import { isClusterOnboardingPhase1 } from 'utils/cluster';
import { getCookie } from 'utils/cookie';
import { readStorage, writeStorage } from 'utils/storage';

import { useReferralMutation } from '../../mutations/marketing';

const CONNECTING_CLUSTERS_STORAGE = 'connecting-clusters-before';
const CONNECTED_CLUSTERS_STORAGE = 'connected-clusters-before';

export const useClustersQuery = (polling = false, enabled = true) => {
  const queryClient = useQueryClient();
  const { mutate } = useReferralMutation();

  const {
    data: clusters,
    error,
    refetch: refetchClusters,
    isLoading,
  } = useQuery({
    queryKey: [QueryKeys.EXTERNAL_CLUSTERS],
    queryFn: async () => {
      return await apiClient.clusters
        .getClusters()
        .then((response) => {
          if (response.data.items) {
            const hasDemoCluster = response.data.items.some(
              (cluster) => cluster && isDemoCluster(cluster.id!)
            );

            if (hasDemoCluster || !response.data.items.length) {
              setDemoMode();
            } else {
              removeDemoMode();
            }
          }

          return response;
        })
        .then(({ data }) => {
          const enhancedList = data.items?.map((cluster) => {
            const enhancedData = enhanceCluster(cluster as ClusterResponse);

            queryClient.setQueryData(
              [QueryKeys.EXTERNAL_CLUSTER, enhancedData.id],
              enhancedData
            );

            return enhancedData;
          });

          // TODO: delete all of this once backend starts communicating with refer portal
          try {
            if (enhancedList) {
              const connectedBefore = readStorage(
                CONNECTED_CLUSTERS_STORAGE,
                []
              );
              const connectingBefore = readStorage(
                CONNECTING_CLUSTERS_STORAGE,
                []
              );
              const affiliate_id = getCookie(AFFILIATE_ID_COOKIE_KEY);
              const visit_id = getCookie(VISIT_ID_COOKIE_KEY);
              const trackConnection =
                IS_PROD && Boolean(affiliate_id && visit_id);

              const connectingClusters = enhancedList
                .filter((cluster) =>
                  isClusterOnboardingPhase1(
                    cluster.displayStatus,
                    Boolean(cluster.credentialsId)
                  )
                )
                .map((c) => c.id);

              const connectedClusters = enhancedList
                .filter(
                  (cluster) =>
                    cluster.displayStatus === ClusterDisplayStatus.READ_ONLY
                )
                .map((c) => c.id);

              connectedClusters.forEach((id) => {
                // send event only if cookies exist and new connection was found (reconnection does not count)
                if (
                  trackConnection &&
                  connectingBefore.includes(id) &&
                  !connectedBefore.includes(id)
                ) {
                  mutate({
                    event: 'cluster-connect',
                    visit_id: parseInt(visit_id!),
                    affiliate_id: parseInt(affiliate_id!),
                    reference: `${userId()}/${id}`,
                  });
                }
              });
              writeStorage(CONNECTING_CLUSTERS_STORAGE, connectingClusters);
              writeStorage(
                CONNECTED_CLUSTERS_STORAGE,
                uniq([...connectedClusters, ...connectedBefore])
              );
            }
          } catch (e) {
            console.error(e);
          }

          return enhancedList;
        });
    },
    enabled,
    refetchInterval: polling ? 20_000 : false,
  });

  return { clusters, error, isLoading, refetchClusters };
};
