import { UIEvent } from 'react';

import { Button, ButtonProps } from '@cast/design-system';

import { useSearchContext } from './hooks';

export const ClearAllButton = (props: ButtonProps) => {
  const searchState = useSearchContext();
  return (
    <Button
      variant="tertiary"
      onClick={(event: UIEvent) => {
        if (searchState) {
          searchState.resetInitial(event);
        }
      }}
      disabled={searchState && !searchState.valuesChanged}
      testId="clear-all-button"
      {...props}
    >
      Clear all
    </Button>
  );
};
