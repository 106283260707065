import {
  K8sProvider,
  BestPracticeCheckStandard,
  SecurityInsightsContainerImage,
} from '@cast/types';
import { SortBy } from '@cast/utils';

import { AgeGroup } from './types';

export const nonNamespacedLabel = '-no namespace-';

export const checkDetailsDrawerUrlKey = 'compliance-check-details';

export const vulnerabilityManagementDetailsTabsUrlKey =
  'vulnerability-management-details-tabs';
export const vulnerabilityManagementDetailsVulnerabilitiesTabsUrlKey =
  'vulnerability-management-details-vulnerabilities-tabs';
export const imageVulnerabilityDetailsDrawerUrlKey =
  'image-vulnerability-details';
export const imagePackageDetailsDrawerUrlKey = 'image-package-details';

export const cisByProvider = {
  [K8sProvider.EKS]: {
    title: 'CIS EKS Benchmark 1.3',
    value: BestPracticeCheckStandard.cisEks13,
  },
  [K8sProvider.AKS]: {
    title: 'CIS AKS Benchmark 1.3',
    value: BestPracticeCheckStandard.cisAks13,
  },
  [K8sProvider.GKE]: {
    title: 'CIS GKE Benchmark 1.4',
    value: BestPracticeCheckStandard.cisGke14,
  },
};

export const vulnerabilityManagementSecondarySort: SortBy<SecurityInsightsContainerImage>[] =
  [
    {
      accessor: (image) => image.vulnerabilitiesBySeverityLevel?.critical ?? 0,
      direction: 'desc',
    },
    {
      accessor: (image) => image.vulnerabilitiesBySeverityLevel?.high ?? 0,
      direction: 'desc',
    },
    {
      accessor: (image) => image.vulnerabilitiesBySeverityLevel?.medium ?? 0,
      direction: 'desc',
    },
    {
      accessor: (image) => image.vulnerabilitiesBySeverityLevel?.low ?? 0,
      direction: 'desc',
    },
    {
      accessor: (image) => image.vulnerabilitiesBySeverityLevel?.none ?? 0,
      direction: 'desc',
    },
    {
      accessor: (image) =>
        image.vulnerabilitiesBySeverityLevel?.notAvailable ?? 0,
      direction: 'desc',
    },
  ];

export const nodeColorByAgeGroup = {
  [AgeGroup.UP_TO_WEEK]: 'green.600',
  [AgeGroup.UP_TO_MONTH]: 'yellow.700',
  [AgeGroup.MORE_THAN_MONTH]: 'red.400',
};
