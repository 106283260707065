import { AxiosInstance } from 'axios';

import { InsightsAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const SecurityInsightsApiAdapter = (client: AxiosInstance) => {
  const generatedApi = InsightsAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  // prettier-ignore
  return {
    getAgentsStatuses: generatedApi.insightsAPIGetAgentsStatus,
    getAgentStatus: generatedApi.insightsAPIGetAgentStatus,
    getBestPracticeFilters: generatedApi.insightsAPIGetBestPracticesReportFilters,
    getBestPractices: generatedApi.insightsAPIGetBestPracticesReport,
    getBestPracticeChecks: generatedApi.insightsAPIGetBestPracticesReport,
    getBestPracticeCheck: generatedApi.insightsAPIGetBestPracticesCheckDetails,
    getContainerImages: generatedApi.insightsAPIGetContainerImages,
    getContainerImagesFilters: generatedApi.insightsAPIGetContainerImagesFilters,
    getImageDetails: generatedApi.insightsAPIGetContainerImageDetails,
    getImageDigests: generatedApi.insightsAPIGetContainerImageDigests,
    getImageResources: generatedApi.insightsAPIGetContainerImageResources,
    getImageVulnerabilities: generatedApi.insightsAPIGetContainerImageVulnerabilities,
    getImagePackageVulnerabilityDetails: generatedApi.insightsAPIGetContainerImagePackageVulnerabilityDetails,
    getImagePackages: generatedApi.insightsAPIGetContainerImagePackages,
    changeImageExceptions: generatedApi.insightsAPIImageExceptionChanges,
    scheduleBestPracticesScan: generatedApi.insightsAPIScheduleBestPracticesScan,
    enforceCheckPolicy: generatedApi.insightsAPIEnforceCheckPolicy,
    deleteCheckPolicyEnforcement: generatedApi.insightsAPIDeletePolicyEnforcement,
    getCheckExceptions: generatedApi.insightsAPIGetCheckExceptions,
    saveCheckExceptions: generatedApi.insightsAPISaveCheckExceptions,
    getCheckExceptionsResources: generatedApi.insightsAPIPreviewExceptionGroupsResources,
    deleteImages: generatedApi.insightsAPIDeleteImages,
    getAttackPaths: generatedApi.insightsAPIGetAttackPaths,
    getAttackPathDetails: generatedApi.insightsAPIGetAttackPathDetails,
    getResourceDetails: generatedApi.insightsAPIGetResourceDetails,
    getIntegrations: generatedApi.insightsAPIListIntegrations,
    getIntegrationProjects: generatedApi.insightsAPIGetProjects,
    createIntegration: generatedApi.insightsAPICreateIntegration,
    deleteIntegration: generatedApi.insightsAPIDeleteIntegration,
    createTicket: generatedApi.insightsAPICreateTicket,
    getBestPracticeCheckResources: generatedApi.insightsAPIGetCheckResources,
    getImageAlternatives: generatedApi.insightsAPIGetPublicImageAlternatives,
    getBestPracticeOverview: generatedApi.insightsAPIGetBestPracticesOverview,
    getImageSecurityOverview: generatedApi.insightsAPIGetImageSecurityOverview,
    getAttackPathsOverview: generatedApi.insightsAPIGetAttackPathsOverview,
    getWorkloads: generatedApi.insightsAPIListWorkloads,
    getClustersSettings: generatedApi.insightsAPIListClusterSecuritySettings,
    changeClusterSettings: generatedApi.insightsAPIConfigureClusterSecuritySettings,
  };
};
