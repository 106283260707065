import {
  IconButton,
  IconButtonProps,
  Icons,
  mergeSx,
  useDrawerInstance,
} from '@cast/design-system';

type Props = IconButtonProps & {
  icon?: 'arrows' | 'x';
  positioned?: boolean;
  showGuard?: boolean;
};

// TODO (Justas): Remove this one and use the one from DS during drawers cleanup
export const CloseDrawerButton = ({
  icon,
  positioned = true,
  showGuard,
  sx,
  ...props
}: Props) => {
  const drawerInstance = useDrawerInstance();
  icon = icon ?? (!!drawerInstance.parentId ? 'x' : 'arrows');
  const Icon = icon === 'arrows' ? Icons.CaretDoubleRight : Icons.X;
  return (
    <IconButton
      variant="ghost"
      size="small"
      sx={mergeSx(
        positioned && [
          { position: 'absolute' },
          icon === 'arrows' && { top: 26, left: 16 },
          icon === 'x' && { top: 16, right: 16 },
        ],
        sx
      )}
      onClick={() => {
        if (showGuard) {
          drawerInstance.setShouldRenderGuard(true);
        } else {
          drawerInstance.close();
        }
      }}
      testId="close-drawer"
      {...props}
    >
      <Icon />
    </IconButton>
  );
};
