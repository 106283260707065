import { ReactNode, useState } from 'react';

import {
  Box,
  Divider,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useMatch } from 'react-router-dom';

import {
  Badge,
  Button,
  DsShadow,
  Icons,
  ShadowDivider,
  useDrawerInstance,
} from '@cast/design-system';

import { Link } from 'components/router';
import { useGetNotificationsQuery } from 'hooks/queries/notifications';
import { HeaderIconButton } from 'main-layout/header/HeaderIconButton';
import { getThemeColor } from 'utils/theme';

import { NoNotifications, NotificationRow } from './_components';
import { useNotificationDrawer } from '../hooks/useNotificationDrawer';

export const NotificationsBell = () => {
  const theme = useTheme();
  const isInNotifications = !!useMatch('/notifications');
  const { openNotificationDrawer } = useNotificationDrawer();
  const notificationDrawerRef = useDrawerInstance();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = !!anchorEl && !notificationDrawerRef.id;

  const {
    notificationsResponse,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
    error,
  } = useGetNotificationsQuery(
    { onlyUnresolved: true, isExpired: false },
    undefined,
    true,
    !isOpen
  );

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: '0px 0px 200px 0px',
  });

  const unseen = notificationsResponse.countUnacked;
  const total = notificationsResponse.count;
  const notifications = notificationsResponse.items;

  if (error) {
    return null;
  }

  let content: ReactNode;

  if (isLoading) {
    content = <NotificationRow isLoading />;
  } else {
    if ((total || 0) === 0) {
      content = (
        <Stack direction="row" justifyContent="center" width="100%">
          <NoNotifications sx={{ width: '177px', pt: '20px', pb: '40px' }} />
        </Stack>
      );
    } else {
      if ((total || 0) > 0 && unseen === 0) {
        content = (
          <Stack direction="row" justifyContent="center" width="100%">
            <NoNotifications
              allResolved
              sx={{ width: '240px', pt: '20px', pb: '40px' }}
            />
          </Stack>
        );
      } else {
        content = (
          <Stack>
            {notifications.map((notification, index) => (
              <NotificationRow
                onClick={() => {
                  openNotificationDrawer({
                    notification,
                    openedFrom: 'bell',
                  });
                }}
                key={notification.id || index}
                notification={notification as any}
              />
            ))}
            <div ref={infiniteRef}>
              {isFetching && (
                <>
                  <ShadowDivider
                    opacity={0.6}
                    color="grey.200"
                    direction="bottom"
                  />
                  <NotificationRow isLoading />
                </>
              )}
            </div>
          </Stack>
        );
      }
    }
  }

  return (
    <>
      <HeaderIconButton
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        active={isOpen}
        testId="notifications-header-menu-btn"
      >
        {unseen ? (
          <Badge
            count={unseen}
            horizontal="right"
            size="small"
            variant="primary"
            vertical="top"
          >
            <Icons.BellSimple
              size={16}
              weight="fill"
              color={getThemeColor(theme, 'grey.600')}
            />
          </Badge>
        ) : (
          <Icons.BellSimple
            size={16}
            weight="fill"
            color={getThemeColor(theme, 'grey.600')}
          />
        )}
      </HeaderIconButton>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: '7px',
            zIndex: (theme) => theme.zIndex.drawer - 1,
            boxShadow: (theme) => theme.shadows[DsShadow.DRAWER],
            borderRadius: '4px',
          },
        }}
        disableScrollLock
      >
        <Stack
          width={360}
          maxHeight={500}
          data-testid="notifications-header-menu"
        >
          <Stack
            direction="row"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
            py={8}
            px={12}
          >
            <Typography variant="P14B" color="grey.900">
              Notifications
            </Typography>
            <Stack justifyContent="space-between">
              <Link
                to="/notifications"
                style={{
                  textDecoration: 'none',
                  display: isInNotifications ? 'none' : undefined,
                }}
                onClick={() => setAnchorEl(null)}
              >
                <Button
                  variant="text"
                  size="medium"
                  endIcon={<Icons.ArrowRight size={16} />}
                >
                  View all
                </Button>
              </Link>
            </Stack>
          </Stack>
          <Box
            flex={1}
            sx={{
              overflowY: 'auto',
            }}
            ref={rootRef}
          >
            <Divider color={getThemeColor(theme, 'grey.100')} />
            {content}
          </Box>
        </Stack>
      </Popover>
    </>
  );
};
