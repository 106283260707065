import {
  demoWoopScalingPoliciesList,
  demoWoopWorkloadDetails,
  demoWoopWorkloadsList,
  demoWoopWorkloadsSummary,
} from '@cast/fixtures';
import {
  OptimizationWorkloadDetailsResponse,
  OptimizationWorkloadsListResponse,
  WorkloadOptimizationAgentStatus,
  WorkloadOptimizationAgentStatusResponse,
} from '@cast/types';
import { isDemoMode } from '@cast/utils';

import { WorkloadOptimizationApiAdapter } from '../api-client/workload-optimization-api-adapter';
import { rejectDemoClusterRequest, resolveDemoClusterRequest } from '../utils';

export const WorkloadOptimizationApiProxy = (
  origin: ReturnType<typeof WorkloadOptimizationApiAdapter>
) => {
  return {
    ...origin,
    getAgentStatus: (...args: Parameters<typeof origin.getAgentStatus>) => {
      if (isDemoMode()) {
        return resolveDemoClusterRequest<WorkloadOptimizationAgentStatusResponse>(
          {
            status: WorkloadOptimizationAgentStatus.RUNNING,
            currentVersion: '1',
            latestVersion: '1',
          }
        );
      }
      return origin.getAgentStatus(...args);
    },
    getWorkloads: async (...args: Parameters<typeof origin.getWorkloads>) => {
      if (isDemoMode()) {
        const fixture = await demoWoopWorkloadsList();
        return resolveDemoClusterRequest(
          fixture as OptimizationWorkloadsListResponse
        );
      }
      return origin.getWorkloads(...args);
    },
    getWorkload: async (...args: Parameters<typeof origin.getWorkload>) => {
      if (isDemoMode()) {
        const fixture: OptimizationWorkloadDetailsResponse[] =
          await demoWoopWorkloadDetails();
        const workloadId = args[0]?.workloadId;
        const workloadDetails = fixture.find(
          ({ workload }) => workload.id === workloadId
        );
        return resolveDemoClusterRequest<OptimizationWorkloadDetailsResponse>(
          workloadDetails!
        );
      }
      return origin.getWorkload(...args);
    },
    updateWorkload: async (
      ...args: Parameters<typeof origin.updateWorkload>
    ) => {
      if (isDemoMode()) {
        return rejectDemoClusterRequest();
      }
      return origin.updateWorkload(...args);
    },
    updateWorkload2: async (
      ...args: Parameters<typeof origin.updateWorkload2>
    ) => {
      if (isDemoMode()) {
        return rejectDemoClusterRequest();
      }
      return origin.updateWorkload2(...args);
    },
    getWorkloadOptimizationPolicies: async (
      ...args: Parameters<typeof origin.getWorkloadOptimizationPolicies>
    ) => {
      if (isDemoMode()) {
        const policies = await demoWoopScalingPoliciesList();
        return resolveDemoClusterRequest(policies);
      }
      return origin.getWorkloadOptimizationPolicies(...args);
    },
    getWorkloadsOptimizationSummary: async (
      ...args: Parameters<typeof origin.getWorkloadsOptimizationSummary>
    ) => {
      if (isDemoMode()) {
        const summary = await demoWoopWorkloadsSummary();
        return resolveDemoClusterRequest(summary);
      }
      return origin.getWorkloadsOptimizationSummary(...args);
    },
  };
};
