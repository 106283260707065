import { Divider, styled } from '@mui/material';

import { IconButtonProps, Icons } from '@cast/design-system';
import { AgentStatus, Cluster, ClusterStatus } from '@cast/types';
import { isManagedByTerraform } from '@cast/utils';

import {
  AutoscalerVisualSvg,
  LogoSmallSvg,
  SecurityVisualSvg,
} from 'assets/svg';
import { EntityAction, EntityActions } from 'components/common';
import { PermissionType, useRBAC } from 'core/rbac';
import {
  useAssignCredentialsDialog,
  useAssignSecurityCredentialsDialog,
  useDisconnectPhase1ClusterDialog,
  useDisconnectPhase2ClusterDialog,
  useReconnectClusterDialog,
} from 'hooks/dialogs';
import { useAssignAutoscalerCredentialsDialog } from 'hooks/dialogs/cluster/assign-credentials/useAssignAutoscalerCredentialsDialog';
import {
  useReconcileClusterMutation,
  useDeleteClusterMutation,
} from 'hooks/mutations';
import { useDeleteCluster } from 'hooks/useDeleteCluster';
import { useGetClusterActionsVisibility } from 'hooks/useGetClusterActionsVisibility';

type ClusterActionsMenuProps = {
  cluster: Cluster;
  triggerButtonProps?: IconButtonProps;
};

export const ClusterActionsMenu = ({
  cluster,
  triggerButtonProps,
}: ClusterActionsMenuProps) => {
  const { mutate: deleteClusterMutation } = useDeleteClusterMutation(cluster);

  const { mutate: reconcileClusterMutation } = useReconcileClusterMutation(
    cluster.id
  );

  const { openAssignCredentialsDialog } = useAssignCredentialsDialog({
    cluster,
  });
  const { openAssignAutoscalerCredentialsDialog } =
    useAssignAutoscalerCredentialsDialog({ cluster });
  const { openAssignSecurityCredentialsDialog } =
    useAssignSecurityCredentialsDialog({ cluster });

  const isTerraformManaged = isManagedByTerraform(cluster.managedBy);

  const [canEditRBAC, canDeleteRBAC] = useRBAC([
    PermissionType.EDIT,
    PermissionType.DELETE,
  ]);

  const canEdit = canEditRBAC && !isTerraformManaged;

  const canDelete =
    [AgentStatus.DISCONNECTED, AgentStatus.CONNECTING].includes(
      cluster.agentStatus
    ) &&
    ![
      ClusterStatus.DELETING,
      ClusterStatus.PAUSING,
      ClusterStatus.RESUMING,
    ].includes(cluster.status);

  const canDisconnect =
    [AgentStatus.ONLINE, AgentStatus.NOT_RESPONDING].includes(
      cluster.agentStatus
    ) && !isTerraformManaged;

  const { openReconnectDialog } = useReconnectClusterDialog();
  const { openDisconnectPhase1ClusterDialog } =
    useDisconnectPhase1ClusterDialog();
  const { openDisconnectPhase2ClusterDialog } =
    useDisconnectPhase2ClusterDialog();
  const { deleteCluster } = useDeleteCluster(cluster);

  const clusterActionVisibility = useGetClusterActionsVisibility(cluster);
  const noVisibleActions = Object.values(clusterActionVisibility).every(
    (action) => !action
  );

  if (noVisibleActions) {
    return null;
  }

  const isManaged = Boolean(cluster.credentialsId);
  const {
    disconnect: showDisconnect,
    delete: showDelete,
    reconnect: showReconnect,
    reconcile: showReconcile,
    remove: showRemove,
    connectPhase2: showConnectPhase2,
    connectAutoscaler: showConnectAutoscaler,
    connectSecurity: showConnectSecurity,
  } = clusterActionVisibility;

  const handleReconnectCluster = () => {
    openReconnectDialog(cluster, isManaged);
  };

  const handleReconcileCluster = () => {
    reconcileClusterMutation();
  };

  const handleDeleteCluster = () => {
    deleteCluster();
  };

  const handleDisconnectCluster = () => {
    if (isManaged) {
      openDisconnectPhase2ClusterDialog(cluster);
    } else {
      openDisconnectPhase1ClusterDialog(cluster);
    }
  };

  const handleRemoveFromCastAi = () => {
    deleteClusterMutation();
  };

  return (
    <EntityActions
      triggerButtonProps={triggerButtonProps}
      testId="cluster-actions-select"
    >
      {showConnectPhase2 && (
        <EntityAction
          disabled={!canEditRBAC}
          onClick={openAssignCredentialsDialog}
          startAdornment={
            <CastLogoWrapper>
              <LogoSmallSvg />
            </CastLogoWrapper>
          }
          testId="connect-phase2-cluster-action"
        >
          Enable CAST AI
        </EntityAction>
      )}

      {showConnectAutoscaler && (
        <EntityAction
          disabled={!canEditRBAC}
          onClick={openAssignAutoscalerCredentialsDialog}
          startAdornment={
            <CastLogoWrapper>
              <AutoscalerVisualSvg />
            </CastLogoWrapper>
          }
          testId="connect-autoscaler-cluster-action"
        >
          Enable optimization & monitoring
        </EntityAction>
      )}

      {showConnectSecurity && (
        <EntityAction
          disabled={!canEditRBAC}
          onClick={openAssignSecurityCredentialsDialog}
          startAdornment={
            <CastLogoWrapper>
              <SecurityVisualSvg />
            </CastLogoWrapper>
          }
          testId="connect-security-cluster-action"
        >
          Enable security
        </EntityAction>
      )}

      {showReconnect && (
        <EntityAction
          tooltipProps={{
            title: 'To perform this action please use Terraform',
            placement: 'left',
            sx: { width: 196 },
            disableHoverListener: !isTerraformManaged,
          }}
          disabled={!canEdit}
          onClick={handleReconnectCluster}
          startAdornment={<Icons.PlugsConnected />}
          testId="reconnect-cluster-action"
        >
          Reconnect
        </EntityAction>
      )}

      {showReconcile && (
        <EntityAction
          disabled={!canEditRBAC}
          onClick={handleReconcileCluster}
          startAdornment={<Icons.ArrowCounterClockwise />}
        >
          Trigger reconcile
        </EntityAction>
      )}

      <Divider variant="fullWidth" />

      {showDisconnect && (
        <EntityAction
          tooltipProps={{
            title: 'To perform this action please use Terraform',
            placement: 'left',
            sx: { width: 196 },
            disableHoverListener: !isTerraformManaged,
          }}
          disabled={!canDisconnect || !canDeleteRBAC}
          onClick={handleDisconnectCluster}
          startAdornment={<Icons.XCircle />}
          disruptive
          testId="disconnect-cluster-action"
        >
          Disconnect cluster
        </EntityAction>
      )}

      {showDelete && (
        <EntityAction
          disabled={!canDelete || !canDeleteRBAC}
          onClick={handleDeleteCluster}
          startAdornment={<Icons.TrashSimple />}
          disruptive
          testId="delete-cluster-action"
        >
          Remove cluster
        </EntityAction>
      )}

      {showRemove && (
        <EntityAction
          disabled={!canDeleteRBAC}
          onClick={handleRemoveFromCastAi}
          startAdornment={<Icons.TrashSimple />}
          disruptive
        >
          Remove from CAST AI
        </EntityAction>
      )}
    </EntityActions>
  );
};

const CastLogoWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 16,
  height: 16,
  padding: '2px 3px',
});
