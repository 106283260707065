import { Stack, Typography, useTheme } from '@mui/material';

import { Button, Icons } from '@cast/design-system';

type InstallComponentFallbackProps = {
  message: string;
  refetch?: () => void;
};

export const InstallComponentFallback = ({
  message,
  refetch,
}: InstallComponentFallbackProps) => {
  const { palette } = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ border: 'red.500', borderRadius: '4px' }}
      p="8px 12px"
      data-testid="install-agent-fallback"
    >
      <Stack direction="row" gap="4px">
        <Icons.XCircle color={palette.red[500]} weight="fill" />
        <Typography variant="P12M">{message}</Typography>
      </Stack>

      {!!refetch && (
        <Button
          variant="text"
          endIcon={<Icons.ArrowsCounterClockwise />}
          onClick={refetch}
        >
          Refetch
        </Button>
      )}
    </Stack>
  );
};
