import { AxiosInstance } from 'axios';

import {
  AIOptimizerAPIApiFactory,
  AIOptimizerProvidersAPIApiFactory,
  AIOptimizerSettingsAPIApiFactory,
} from '@cast/openapi';
import {
  LLMOptimizationTestRunRequestParams,
  LLMOptimizationTestRunResponse,
} from '@cast/types';

import { AXIOS_CONFIGURATION } from './constants';

export const LLMOptimizationApiFactory = (client: AxiosInstance) => {
  const optimizerGeneratedApi = AIOptimizerAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );
  const settingsGeneratedApi = AIOptimizerSettingsAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );
  const optimizerProvidersGeneratedApi = AIOptimizerProvidersAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  return {
    getTestChatCompletion: (
      {
        apiKey,
        model = 'gpt-3.5-turbo-0125',
      }: LLMOptimizationTestRunRequestParams,
      baseURL: string
    ) => {
      return client.post<LLMOptimizationTestRunResponse>(
        '/v1/chat/completions',
        {
          model,
          messages: [
            {
              role: 'user',
              content:
                'What kind of instance types to use in GCP for running training AI model?',
            },
          ],
        },
        {
          baseURL,
          headers: {
            'X-API-Key': apiKey,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
    },
    getApiKeyChatCompletionApiKeysSavings:
      optimizerGeneratedApi.aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport,
    getApiKeyChatCompletionRecommendations:
      optimizerGeneratedApi.aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport,
    getApiKeyChatCompletionUsage:
      optimizerGeneratedApi.aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport,
    getCategoryChatCompletionRecommendations:
      optimizerGeneratedApi.aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport,
    getCategoryChatCompletionUsage:
      optimizerGeneratedApi.aIOptimizerAPIGetChatCompletionsCategoryUsageReport,
    getChatCompletionRecommendations:
      optimizerGeneratedApi.aIOptimizerAPIGetChatCompletionsRecommendationsReport,
    getChatCompletionUsage:
      optimizerGeneratedApi.aIOptimizerAPIGetChatCompletionsUsageReport,
    getSupportedProviders:
      optimizerGeneratedApi.aIOptimizerAPIGetSupportedProviders,
    getModels: optimizerGeneratedApi.aIOptimizerAPIGetModels,
    getSettings: settingsGeneratedApi.aIOptimizerSettingsAPIGetSettings,
    upsertSettings: settingsGeneratedApi.aIOptimizerSettingsAPIUpsertSettings,
    registerProvider:
      optimizerProvidersGeneratedApi.aIOptimizerProvidersAPIRegisterProviders,
    getProviders:
      optimizerProvidersGeneratedApi.aIOptimizerProvidersAPIGetProviders,
    updateProvider:
      optimizerProvidersGeneratedApi.aIOptimizerProvidersAPIUpdateProvider,
    deleteProvider:
      optimizerProvidersGeneratedApi.aIOptimizerProvidersAPIDeleteProvider,
  };
};
