import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useFlagsQuery } from 'hooks/queries/useFlagsQuery';

import { mergeFlags } from './_utils/merge-flags';
import { fallbackFlags } from './fallbackFlags';
import { Flags } from './types';

type FlagsState = {
  isLoading: boolean;
  hasError?: boolean;
  flags: Flags;
};

const FlagsContext = createContext<FlagsState>(undefined as never);

export let flags: Flags = fallbackFlags;

export const FlagsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { isLoading, error, data } = useFlagsQuery({ flagNamePrefix: 'ui__' });
  const _flags = useMemo(() => mergeFlags(fallbackFlags, data), [data]);

  flags = _flags;

  return (
    <FlagsContext.Provider
      value={{ isLoading: isLoading, hasError: !!error, flags: _flags }}
    >
      {children}
    </FlagsContext.Provider>
  );
};

export const useFlags = () => {
  return useContext(FlagsContext);
};
