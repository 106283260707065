/** A module. Its name is module:cast/nodes.
 * @module cast/nodes
 */

import type { ExternalclusterV1Node as NodeResponse } from '@cast/openapi';
import { ExternalclusterV1NodeType as NodeRoles } from '@cast/openapi';

import {
  WorkloadMigrationStatusMigrationStatusEnum,
  NodeMigrationStatusMigrationStatusEnum,
} from './rebalance';

export enum NodeStatus {
  unknown = 'unknown',
  pending = 'pending',
  creating = 'creating',
  ready = 'ready',
  notReady = 'notReady',
  draining = 'draining',
  deleting = 'deleting',
  deleted = 'deleted',
  interrupted = 'interrupted',
}

export enum ComputedNodeStatus {
  CORDONED = 'cordoned',
}

export enum NodeResourceOffering {
  ON_DEMAND = 'on-demand',
  FALLBACK = 'fallback',
  SPOT = 'spot',
}

export type NodeIssue = 'failedToDrain';

export type Node = Omit<NodeResponse, 'state'> & {
  state: {
    phase?: NodeStatus | ComputedNodeStatus;
  };
  resourceOffering: NodeResourceOffering;
  unremovable: boolean;
  regionLabel?: string;
  castNodeId?: string;
  issues: NodeIssue[];
};

export type WorkloadBaseRunningInNode = {
  name: string;
  namespace: string;
  workloadType: string;
  nodeId: string;
  nodeName: string;
  replicasPerNode: number;
  resourcesPerNode: {
    requested: {
      milliCpu: number;
      memoryBytes: number;
    };
  };
  migrationStatus: WorkloadMigrationStatusMigrationStatusEnum;
};

export type NodeWithWorkloads = Node & {
  workloads: WorkloadBaseRunningInNode[];
  migrationStatus: NodeMigrationStatusMigrationStatusEnum;
};

export type { ExternalclusterV1Taint as NodeTaint } from '@cast/openapi';
export type { NodeResponse };
export { NodeRoles };
