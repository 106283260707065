import dayjs from 'dayjs';

import { DateTypes } from '@cast/design-system';

// new billing report api should be used after this date
export const BILLING_REPORT_START_DATE = '2024-06-01';

// cpu usage report api feature was released on this date
export const CPU_USAGE_REPORT_START_DATE = '2022-12-31';

const BILLING_REPORT_FILTERS = [
  { range: DateTypes.DateRangeFilter.Last7Days, startDate: '2024-06-08' },
  { range: DateTypes.DateRangeFilter.LastWeek, startDate: '2024-06-10' },
  { range: DateTypes.DateRangeFilter.Last2Weeks, startDate: '2024-06-17' },
  { range: DateTypes.DateRangeFilter.Last30Days, startDate: '2024-07-01' },
];

export const getFilters = () => {
  const today = dayjs().startOf('day');

  return [
    DateTypes.DateRangeFilter.ThisMonth,
    ...BILLING_REPORT_FILTERS.filter((filter) =>
      dayjs(filter.startDate).isSameOrBefore(today)
    ).map((f) => f.range),
    DateTypes.DateRangeFilter.LastMonth,
  ];
};
