import { DateTypes } from '@cast/design-system';
import { DateRange } from '@cast/types';
import { useCompoundReducer } from '@cast/utils';

import { useQueryParams } from 'hooks/useQueryParams';

import { State, RangePickerProviderProps } from '../providers/types';
import { RangePickerQueryParamKeys } from '../types';
import {
  createDefaultDateRange,
  getDateRangeFilterFromQuery,
  getDateRangeFilterFromValue,
  checkIfDefaultFilter,
  defaultFilters,
  defaultQueryParamKeys,
} from '../utils';

const createInitialState = (
  queryParams: URLSearchParams,
  props: RangePickerProviderProps,
  queryParamKeys: RangePickerQueryParamKeys,
  dateRange?: DateRange
): State => {
  const currentDateRange = createDefaultDateRange(
    queryParams,
    props.defaultFilterPreset,
    props.filterPresets,
    queryParamKeys,
    dateRange
  );
  return {
    filterPresets: props.filterPresets,
    currentDateRange,
    currentDateRangeFilter:
      getDateRangeFilterFromQuery(
        queryParams,
        props.filterPresets,
        queryParamKeys
      ) || getDateRangeFilterFromValue(currentDateRange, props.filterPresets),
    isDefaultFilter: checkIfDefaultFilter(
      currentDateRange,
      props.defaultFilterPreset
    ),
  };
};

export type Props = {
  filterPresets?: DateTypes.DateRangeFilter[];
  defaultFilterPreset?: DateTypes.DateRangeFilter;
  queryParamKeys?: RangePickerQueryParamKeys;
  applyQueryParams?: boolean;
  dateRange?: DateRange;
};

const handler = {
  setFilterPresets: (state: State, value: any) => {
    return {
      ...state,
      filterPresets: value,
    };
  },
  setCurrentDateRange: (state: State, value: any) => {
    return {
      ...state,
      currentDateRange: value,
    };
  },
  setCurrentDateRangeFilter: (state: State, value: any) => {
    return {
      ...state,
      currentDateRangeFilter: value,
    };
  },
  setIsDefaultFilter: (state: State, value: any) => {
    return {
      ...state,
      isDefaultFilter: value,
    };
  },
};

export const useRangePickerStateHandler = ({
  filterPresets = defaultFilters,
  defaultFilterPreset = DateTypes.DateRangeFilter.ThisMonth,
  queryParamKeys = defaultQueryParamKeys,
  dateRange,
}: Props) => {
  const queryParams = useQueryParams();

  const { state, dispatcher } = useCompoundReducer(
    handler,
    createInitialState(
      queryParams,
      { filterPresets, defaultFilterPreset },
      queryParamKeys,
      dateRange
    )
  );

  return { ...state, ...dispatcher };
};
