import { useTheme, PopperProps } from '@mui/material';
import { SxProps } from '@mui/system';

import { Icons } from '@cast/design-system';

import { EntityAction, EntityActions } from 'components/common';
import { FormMode } from 'core/forms/rff';
import { PermissionType, RBACGuard, useRBAC } from 'core/rbac';
import { getThemeColor } from 'utils/theme';

type Props = {
  formMode?: FormMode;
  onEdit?: () => void;
  onDelete?: () => void;
  sx?: SxProps;
  container?: PopperProps['container'];
};

export const WebhookFormSubdrawerActions = ({
  formMode,
  onEdit,
  onDelete,
  sx,
  container,
}: Props) => {
  const theme = useTheme();
  const showEditButton = formMode === 'view';
  const [canEdit] = useRBAC(PermissionType.EDIT);
  const [canDelete] = useRBAC(PermissionType.DELETE);
  return (
    <RBACGuard permissionType={['edit', 'delete']}>
      {(permission) => (
        <EntityActions
          triggerButtonProps={{
            disabled: !permission,
            sx,
          }}
          container={container}
          testId="webhook-form-subdrawer-actions"
        >
          {showEditButton && (
            <EntityAction
              onClick={(e) => {
                e.stopPropagation();
                onEdit?.();
              }}
              disabled={!canEdit}
              startAdornment={
                <Icons.PencilSimpleLine
                  color={getThemeColor(theme, 'grey.400')}
                />
              }
            >
              Edit
            </EntityAction>
          )}
          <EntityAction
            onClick={(e) => {
              e.stopPropagation();
              onDelete?.();
            }}
            disabled={!canDelete}
            startAdornment={<Icons.TrashSimple />}
            disruptive
          >
            Delete
          </EntityAction>
        </EntityActions>
      )}
    </RBACGuard>
  );
};
