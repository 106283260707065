import {
  buildFilterParams,
  buildLinkToReport,
  ClusterId,
  COST_MONITORING_PATHS,
  getLinkToReport,
  LinkFilterParams,
  LinkParams,
  Namespace,
  WorkloadName,
  WorkloadType,
} from './navigation';
import {
  workloadsTableKey,
  namespacesTableKey,
} from '../../cost-report/constants';

type WorkloadsFilterParams = LinkFilterParams<
  'freeText' | 'Workload name' | 'Workload type'
>;

type NamespacesFilterParams = LinkFilterParams<'freeText'>;

type AllocationGroupsFilterParams = LinkFilterParams<'freeText'>;

export const makeClusterCostMonitoringLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ object: 'cluster' }, params, queryParams);
};

export const makeCostMonitoringComputeCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ reportType: 'computeCost' }, params, queryParams);
};

export const makeCostMonitoringNetworkCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ reportType: 'networkCost' }, params, queryParams);
};

export const makeCostMonitoringEfficiencyLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ reportType: 'efficiency' }, params, queryParams);
};

export const makeCostMonitoringGpuUtilizationLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ reportType: 'gpuUtilization' }, params, queryParams);
};

export const makeClusterComputeCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'cluster', reportType: 'computeCost' },
    params,
    queryParams
  );
};
export const makeClusterNetworkCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'cluster', reportType: 'networkCost' },
    params,
    queryParams
  );
};
export const makeClusterEfficiencyLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'cluster', reportType: 'efficiency' },
    params,
    queryParams
  );
};
export const makeWorkloadsCostMonitoringLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: WorkloadsFilterParams = {}
) => {
  return getLinkToReport(
    { object: 'workloads' },
    params,
    buildFilterParams(workloadsTableKey, queryParams, filterParams)
  );
};
export const makeWorkloadsComputeCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: WorkloadsFilterParams = {}
) => {
  return getLinkToReport(
    {
      object: 'workloads',
      reportType: 'computeCost',
    },
    params,
    buildFilterParams(workloadsTableKey, queryParams, filterParams)
  );
};
export const makeWorkloadsNetworkCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: WorkloadsFilterParams = {}
) => {
  return getLinkToReport(
    {
      object: 'workloads',
      reportType: 'networkCost',
    },
    params,
    buildFilterParams(workloadsTableKey, queryParams, filterParams)
  );
};
export const makeWorkloadsEfficiencyLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: WorkloadsFilterParams = {}
) => {
  return getLinkToReport(
    { object: 'workloads', reportType: 'efficiency' },
    params,
    buildFilterParams(workloadsTableKey, queryParams, filterParams)
  );
};
export const makeWorkloadsGpuUtilizationLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: WorkloadsFilterParams = {}
) => {
  return getLinkToReport(
    { object: 'workloads', reportType: 'gpuUtilization' },
    params,
    buildFilterParams(workloadsTableKey, queryParams, filterParams)
  );
};

export const makeWorkloadCostMonitoringLink = (
  params: LinkParams<ClusterId | WorkloadName | Namespace | WorkloadType> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ object: 'workload' }, params, queryParams);
};
export const makeWorkloadComputeCostLink = (
  params: LinkParams<ClusterId | WorkloadName | Namespace | WorkloadType> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'workload', reportType: 'computeCost' },
    params,
    queryParams
  );
};
export const makeWorkloadNetworkCostLink = (
  params: LinkParams<ClusterId | WorkloadName | Namespace | WorkloadType> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'workload', reportType: 'networkCost' },
    params,
    queryParams
  );
};
export const makeWorkloadEfficiencyLink = (
  params: LinkParams<ClusterId | WorkloadName | Namespace | WorkloadType> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport(
    { object: 'workload', reportType: 'efficiency' },
    params,
    queryParams
  );
};
export const makeNamespacesCostMonitoringLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: NamespacesFilterParams = {}
) => {
  return getLinkToReport(
    { object: 'namespaces' },
    params,
    buildFilterParams(namespacesTableKey, queryParams, filterParams)
  );
};
export const makeNamespacesComputeCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: NamespacesFilterParams = {}
) => {
  return getLinkToReport(
    {
      object: 'namespaces',
      reportType: 'computeCost',
    },
    params,
    buildFilterParams(namespacesTableKey, queryParams, filterParams)
  );
};
export const makeNamespaceCostMonitoringLink = (
  params: LinkParams<ClusterId | Namespace> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return getLinkToReport({ object: 'namespace' }, params, queryParams);
};
export const makeNamespacesNetworkCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: NamespacesFilterParams = {}
) => {
  return getLinkToReport(
    {
      object: 'namespaces',
      reportType: 'networkCost',
    },
    params,
    buildFilterParams(namespacesTableKey, queryParams, filterParams)
  );
};
export const makeNamespacesEfficiencyLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: NamespacesFilterParams = {}
) => {
  return getLinkToReport(
    {
      object: 'namespaces',
      reportType: 'efficiency',
    },
    params,
    buildFilterParams(namespacesTableKey, queryParams, filterParams)
  );
};
export const makeAllocationGroupsCostMonitoringLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: AllocationGroupsFilterParams = {}
) => {
  return getLinkToReport(
    { object: 'allocationGroups' },
    params,
    buildFilterParams('', queryParams, filterParams)
  );
};
export const makeAllocationGroupsComputeCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: AllocationGroupsFilterParams = {}
) => {
  return getLinkToReport(
    {
      object: 'allocationGroups',
      reportType: 'computeCost',
    },
    params,
    buildFilterParams('', queryParams, filterParams)
  );
};
export const makeAllocationGroupsNetworkCostLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: AllocationGroupsFilterParams = {}
) => {
  return getLinkToReport(
    {
      object: 'allocationGroups',
      reportType: 'networkCost',
    },
    params,
    buildFilterParams('', queryParams, filterParams)
  );
};
export const makeAllocationGroupsEfficiencyLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: AllocationGroupsFilterParams = {}
) => {
  return getLinkToReport(
    {
      object: 'allocationGroups',
      reportType: 'efficiency',
    },
    params,
    buildFilterParams('', queryParams, filterParams)
  );
};
export const makeCostComparisonLink = (
  params: LinkParams<ClusterId> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  return buildLinkToReport(
    COST_MONITORING_PATHS.comparison,
    params,
    queryParams
  );
};
