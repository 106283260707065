import dayjs from 'dayjs';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';

import { DateRange, DateTime, TimeUnit } from '@cast/types';
import { dateRangeIterator } from '@cast/utils';

import { TimeSeries } from 'types/metrics';

type FillGapsArgs<
  V,
  U extends string | number | DateTime,
  T extends TimeSeries<V, U>
> = {
  timeSeries: T;
  dateRange: DateRange;
  filler: Omit<V, 'timestamp'>;
  unit: TimeUnit;
  step?: number;
  getKey?: (timestamp: U | DateTime) => string;
};
export const fillGaps = <
  V,
  U extends string | number | DateTime,
  T extends TimeSeries<V, U>
>({
  timeSeries,
  dateRange: [from, to],
  filler,
  unit,
  step,
  getKey = (day) => dayjs(day).toISOString(),
}: FillGapsArgs<V, U, T>): T => {
  const timeSeriesByTimestamp: Record<string, any> = keyBy(
    timeSeries,
    ({ timestamp }) => {
      return getKey(timestamp);
    }
  );

  for (const date of dateRangeIterator(from, to, unit, step)) {
    const formattedDate = getKey(date);
    if (!timeSeriesByTimestamp[formattedDate]) {
      timeSeriesByTimestamp[formattedDate] = {
        timestamp: formattedDate,
      };
    }
  }

  return Object.values(timeSeriesByTimestamp)
    .map((d) => {
      return merge({}, filler, d);
    })
    .sort((a, b) => (dayjs(a.timestamp).isAfter(b.timestamp) ? 1 : -1)) as T;
};
