import {
  demoOrganizationInventoryReservations,
  demoOrganizationInventoryReservationsBalance,
} from '@cast/fixtures';
import { isDemoMode } from '@cast/utils';

import { InventoryApiAdapter } from '../api-client/inventory-api-adapter';
import { rejectDemoOrgRequest, resolveDemoClusterRequest } from '../utils';

export const InventoryApiProxy = (
  origin: ReturnType<typeof InventoryApiAdapter>
) => {
  return {
    ...origin,
    getReservations: async (
      ...args: Parameters<typeof origin.getReservations>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoOrganizationInventoryReservations();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getReservations(...args);
    },
    getReservationsBalance: async (
      ...args: Parameters<typeof origin.getReservationsBalance>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoOrganizationInventoryReservationsBalance();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getReservationsBalance(...args);
    },
    overwriteReservations: async (
      ...args: Parameters<typeof origin.overwriteReservations>
    ) => {
      if (isDemoMode()) {
        return rejectDemoOrgRequest();
      }

      return origin.overwriteReservations(...args);
    },
  };
};
