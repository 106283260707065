import { Typography, Stack } from '@mui/material';

import { Icons } from '@cast/design-system';

import { HexIconNotification } from 'components/hex-icons';

export const CollectingData = () => {
  return (
    <Stack direction="row" flexWrap="nowrap" gap={16}>
      <HexIconNotification
        size={50}
        icon={<Icons.HourglassHigh weight="fill" />}
        color="grey.400"
      />
      <Stack>
        <Typography variant="h5">Collecting data</Typography>
        <Typography variant="P14R" color="grey.500">
          Data about billable CPUs is being collected. The report will be
          populated as soon as possible.
        </Typography>
      </Stack>
    </Stack>
  );
};
