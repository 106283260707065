import { Skeleton } from '@cast/design-system';

import { AgentCodeSnippet } from 'components/code';

type InstallAgentScriptProps = {
  isLoading: boolean;
  script?: string;
  refetch: () => void;
  error?: unknown;
  disabled?: boolean;
};

export const InstallAgentScript = ({
  isLoading,
  script,
  refetch,
  error,
  disabled,
}: InstallAgentScriptProps) => {
  if (isLoading || !script) {
    return <Skeleton height="104px" width="100%" />;
  }

  return (
    <AgentCodeSnippet
      code={script}
      sx={disabled ? { opacity: 0.5 } : undefined}
      refetch={refetch}
      error={error}
      testId="install-agent-script"
    />
  );
};
