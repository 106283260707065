import { Organization } from 'types/organization';

import { config } from './config';
import { PermissionType, RootFeature } from './types';

export const checkRBACPermissions = (
  feature: RootFeature,
  permissionType: PermissionType | PermissionType[],
  organization?: Organization
) => {
  const permissionTypes = Array.isArray(permissionType)
    ? permissionType
    : [permissionType];

  if (!organization || !('role' in organization)) {
    return permissionTypes.map(() => false);
  }

  const roleConfig = config[organization.role];

  if (!roleConfig || !(feature in roleConfig)) {
    return permissionTypes.map(() => false);
  }

  return permissionTypes.map((permission) => roleConfig[feature][permission]);
};
