/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AIOptimizerProvidersAPIUpdateProviderRequest } from '../models';
// @ts-ignore
import { AioptimizerV1GetProvidersResponse } from '../models';
// @ts-ignore
import { AioptimizerV1RegisterProvidersRequest } from '../models';
/**
 * AIOptimizerProvidersAPIApi - axios parameter creator
 * @export
 */
export const AIOptimizerProvidersAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes LLM provider.
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerProvidersAPIDeleteProvider: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('aIOptimizerProvidersAPIDeleteProvider', 'providerId', providerId)
            const localVarPath = `/v1/llm/providers/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of registered LLM providers. If the `category` query parameter is provided, returns the providers that can be used for routing for the given category.
         * @param {string} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerProvidersAPIGetProviders: async (category?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers LLM providers.
         * @param {AioptimizerV1RegisterProvidersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerProvidersAPIRegisterProviders: async (body: AioptimizerV1RegisterProvidersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aIOptimizerProvidersAPIRegisterProviders', 'body', body)
            const localVarPath = `/v1/llm/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the registered LLM provider.
         * @param {string} providerId 
         * @param {AIOptimizerProvidersAPIUpdateProviderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerProvidersAPIUpdateProvider: async (providerId: string, body: AIOptimizerProvidersAPIUpdateProviderRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('aIOptimizerProvidersAPIUpdateProvider', 'providerId', providerId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aIOptimizerProvidersAPIUpdateProvider', 'body', body)
            const localVarPath = `/v1/llm/providers/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIOptimizerProvidersAPIApi - functional programming interface
 * @export
 */
export const AIOptimizerProvidersAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIOptimizerProvidersAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes LLM provider.
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerProvidersAPIDeleteProvider(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerProvidersAPIDeleteProvider(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerProvidersAPIApi.aIOptimizerProvidersAPIDeleteProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of registered LLM providers. If the `category` query parameter is provided, returns the providers that can be used for routing for the given category.
         * @param {string} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerProvidersAPIGetProviders(category?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerProvidersAPIGetProviders(category, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerProvidersAPIApi.aIOptimizerProvidersAPIGetProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Registers LLM providers.
         * @param {AioptimizerV1RegisterProvidersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerProvidersAPIRegisterProviders(body: AioptimizerV1RegisterProvidersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerProvidersAPIRegisterProviders(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerProvidersAPIApi.aIOptimizerProvidersAPIRegisterProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the registered LLM provider.
         * @param {string} providerId 
         * @param {AIOptimizerProvidersAPIUpdateProviderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerProvidersAPIUpdateProvider(providerId: string, body: AIOptimizerProvidersAPIUpdateProviderRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerProvidersAPIUpdateProvider(providerId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerProvidersAPIApi.aIOptimizerProvidersAPIUpdateProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AIOptimizerProvidersAPIApi - factory interface
 * @export
 */
export const AIOptimizerProvidersAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIOptimizerProvidersAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes LLM provider.
         * @param {AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIDeleteProviderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerProvidersAPIDeleteProvider(requestParameters: AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIDeleteProviderRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIOptimizerProvidersAPIDeleteProvider(requestParameters.providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of registered LLM providers. If the `category` query parameter is provided, returns the providers that can be used for routing for the given category.
         * @param {AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIGetProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerProvidersAPIGetProviders(requestParameters: AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIGetProvidersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetProvidersResponse> {
            return localVarFp.aIOptimizerProvidersAPIGetProviders(requestParameters.category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers LLM providers.
         * @param {AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIRegisterProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerProvidersAPIRegisterProviders(requestParameters: AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIRegisterProvidersRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIOptimizerProvidersAPIRegisterProviders(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the registered LLM provider.
         * @param {AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIUpdateProviderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerProvidersAPIUpdateProvider(requestParameters: AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIUpdateProviderRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIOptimizerProvidersAPIUpdateProvider(requestParameters.providerId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aIOptimizerProvidersAPIDeleteProvider operation in AIOptimizerProvidersAPIApi.
 * @export
 * @interface AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIDeleteProviderRequest
 */
export interface AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIDeleteProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIDeleteProvider
     */
    readonly providerId: string
}

/**
 * Request parameters for aIOptimizerProvidersAPIGetProviders operation in AIOptimizerProvidersAPIApi.
 * @export
 * @interface AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIGetProvidersRequest
 */
export interface AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIGetProvidersRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIGetProviders
     */
    readonly category?: string
}

/**
 * Request parameters for aIOptimizerProvidersAPIRegisterProviders operation in AIOptimizerProvidersAPIApi.
 * @export
 * @interface AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIRegisterProvidersRequest
 */
export interface AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIRegisterProvidersRequest {
    /**
     * 
     * @type {AioptimizerV1RegisterProvidersRequest}
     * @memberof AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIRegisterProviders
     */
    readonly body: AioptimizerV1RegisterProvidersRequest
}

/**
 * Request parameters for aIOptimizerProvidersAPIUpdateProvider operation in AIOptimizerProvidersAPIApi.
 * @export
 * @interface AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIUpdateProviderRequest
 */
export interface AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIUpdateProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIUpdateProvider
     */
    readonly providerId: string

    /**
     * 
     * @type {AIOptimizerProvidersAPIUpdateProviderRequest}
     * @memberof AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIUpdateProvider
     */
    readonly body: AIOptimizerProvidersAPIUpdateProviderRequest
}

/**
 * AIOptimizerProvidersAPIApi - object-oriented interface
 * @export
 * @class AIOptimizerProvidersAPIApi
 * @extends {BaseAPI}
 */
export class AIOptimizerProvidersAPIApi extends BaseAPI {
    /**
     * 
     * @summary Deletes LLM provider.
     * @param {AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIDeleteProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerProvidersAPIApi
     */
    public aIOptimizerProvidersAPIDeleteProvider(requestParameters: AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIDeleteProviderRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerProvidersAPIApiFp(this.configuration).aIOptimizerProvidersAPIDeleteProvider(requestParameters.providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of registered LLM providers. If the `category` query parameter is provided, returns the providers that can be used for routing for the given category.
     * @param {AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIGetProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerProvidersAPIApi
     */
    public aIOptimizerProvidersAPIGetProviders(requestParameters: AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIGetProvidersRequest = {}, options?: RawAxiosRequestConfig) {
        return AIOptimizerProvidersAPIApiFp(this.configuration).aIOptimizerProvidersAPIGetProviders(requestParameters.category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers LLM providers.
     * @param {AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIRegisterProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerProvidersAPIApi
     */
    public aIOptimizerProvidersAPIRegisterProviders(requestParameters: AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIRegisterProvidersRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerProvidersAPIApiFp(this.configuration).aIOptimizerProvidersAPIRegisterProviders(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the registered LLM provider.
     * @param {AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIUpdateProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerProvidersAPIApi
     */
    public aIOptimizerProvidersAPIUpdateProvider(requestParameters: AIOptimizerProvidersAPIApiAIOptimizerProvidersAPIUpdateProviderRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerProvidersAPIApiFp(this.configuration).aIOptimizerProvidersAPIUpdateProvider(requestParameters.providerId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

