import {
  ComponentType,
  CSSProperties,
  MouseEvent,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
} from 'react';

import {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from '@hello-pangea/dnd';
import { CSSObject, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { VirtuosoHandle } from 'react-virtuoso';

import { Accessor, SortBy, SortingType } from '@cast/utils';

import { TooltipProps } from '../tooltip';

export type SortDirection = 'asc' | 'desc';
export type SortingState = {
  columnId: string;
  sortBy: SortBy;
};
export type RowKey = string | number;

export type TreeUiConfig = {
  iconSize: number;
  leftExpanderPadding: number;
  distanceFromLineToIcon: number;
  firstCellRightPadding: number;
};

export type OnRowReorderedArgs<T = any> = {
  newIndex: number;
  rowKey: RowKey;
  tableApi: TableApi<T>;
};

export type TreeUiUtils = {
  getExpanderIconIndentation: (level: number) => number;
  getVerticalLineIndentation: (level: number) => number;
  getHorizontalLineIndentation: (level: number) => number;
  getHorizontalLineToExpanderWidth: (level: number) => number;
  getHorizontalLineToCellWidth: (level: number) => number;
  getRowIndentation: (level: number) => number;
};

type Sorter = ComponentType<{
  api: TableApi;
  columnModel: ColumnModel;
  SortIcon: ComponentType<{
    sortDirection?: SortDirection;
  }>;
}>;

export type ReorderRow = { rowKey: RowKey; newIndex: number };

type RenderCell<T> = (row: T, state: RowState<T>) => ReactNode;

export type TableComponents<T = any> = {
  /**
   * Sort icon to be shown in header
   */
  SortIcon?: ComponentType<{
    sortDirection?: SortDirection;
    onClick?: (event: MouseEvent) => void;
  }>;
  /**
   * Sorter component which contains sort icon and react to its events.
   * Useful for customizing sorting behavior.
   * Example 'multi sorting' (MultiSorter)
   */
  Sorter?: Sorter;
  /**
   * Icon used for resizing columns
   */
  ResizerIcon?: ComponentType<{ className?: string }>;
  /**
   * Icon used for displaying column info in tooltip
   */
  InfoIcon?: ComponentType<{ className?: string }>;
  /**
   * HeaderRowComponent, enables to override header
   */
  HeaderRowComponent?: ComponentType<{ className?: string }>;
  /**
   * FooterRowComponent, enables to override footer
   */
  FooterRowComponent?: ComponentType;
  /**
   * BodyRowComponent, enables to override body row.
   * It is responsible for expandable rows and columns rendering.
   * Use it if you need to override entire row (wrapper, row, expandable panel).
   * If you need to override only BodyRow (parent of all the cells of the row), use renderRow
   */
  BodyRowComponent?: ComponentType<BodyRowProps<T>>;
};

type StartResizingArgs = {
  column: ColumnModel;
  startingPoint: number;
  onResizingEnded: (newSize: number) => void;
};
export type ResizerLineApi = {
  startResizing: (args: StartResizingArgs) => void;
};

export type OverlayState = {
  root: HTMLDivElement | null;
  resizerLine: ResizerLineApi | null;
};

export type GroupedColumnOrdering = { id: string; columns?: string[] };

/*
  User changeable column settings.
 */
export type ColumnSettings = {
  /**
   * Width overrides of resizeable columns. If column is resized using resizer, its width is recorded to this dictionary.
   */
  resizedColumns: Record<string, number>;
  /**
   * Ids of hidden columns
   */
  hiddenColumns: Record<string, boolean>;
  /**
   * Ids of locked columns
   */
  lockedColumns: Record<string, boolean>;
  /**
   * Order of the grouped columns
   */
  columnsOrder: GroupedColumnOrdering[];
};

export type TableState<T = any> = {
  /**
   * Column settings
   */
  columnSettings: ColumnSettings;
  /**
   * Loaded child rows of tree table.
   */
  childRows: Record<RowKey, RowState>;
  /**
   * Keys of expanded rows.
   */
  expandedRows: RowKey[];
  /**
   * Keys of expanded tree rows.
   */
  expandedTreeRows: RowKey[];
  /**
   * Same as data provided for table, just with applied sorting.
   */
  sortedData?: T[];
  /**
   * Same as data provided for table, just with applied reordering.
   */
  reorderedData?: T[];
  /**
   * Current sorting state of the table.
   */
  sortingState?: SortingState;
};

export type SetBodyHeight = (
  height: number | ((height: number) => number),
  repaint?: boolean
) => void;

export type TableInfo = {
  bodyHeight: number;
  setBodyHeight: SetBodyHeight;
};

export type ColumnsInfo = {
  /**
   * ColumnProps as defined in html (ignoring ColumnGroup - flattened)
   */
  columnsProps: ColumnProps[];
  /**
   * ColumnProps as defined in html (including ColumnGroup)
   */
  groupedColumnsProps: GroupedColumnProps[];
  /**
   * Computed grouped columns. Filtered invisible columns and ordered. Displayed in group headers row
   */
  groupModels?: ColumnGroupModel[];
  /**
   * Computed column models. Filtered invisible columns and ordered. Single source of truth for table columns
   */
  columnModels: ColumnModel[];
  /**
   * Computed width of the table (sum of computedWidth of all column models).
   */
  tableWidth: number;
  /**
   * Does any of the columns has footer
   */
  hasFooter: boolean;
  /**
   * Default sorting state of the table
   */
  defaultSortingState?: SortingState;
  /**
   * Computed column styles
   */
  columnStyles: Record<string, CSSObject>;
  /**
   * Computed column widths
   */
  columnWidths: Record<string, number>;
  /**
   * Overall width of locked columns
   */
  lockedColumnsWidth: number;
  /**
   * Scrollable columns with
   */
  scrollableColumnsWidth: number;
  /**
   * Floating column of the table
   */
  floatingColumn?: ColumnModel;
};

export type ColumnSettingsProps = {
  /**
   * Is locked
   */
  locked?: boolean;
  /**
   * Will be locked at the start of the table if any columns are locked and cannot be reordered/hidden
   */
  frozen?: boolean;
  /**
   * Will be hidden in settings
   */
  excludeFromSettings?: boolean;
  /**
   * Is visible
   */
  visible?: boolean;
  /**
   * Is floating
   */
  floating?: boolean;
  /**
   * Should visibility setting be disabled
   */
  visibilitySettingDisabled?: boolean;
  /**
   * Tooltip to show on visibility setting component in table settings
   */
  visibilitySettingTooltip?: string;
  /**
   * Should lock setting be disabled
   */
  lockSettingDisabled?: boolean;
  /**
   * Tooltip to show on lock setting component in table settings
   */
  lockSettingTooltip?: string;
  /**
   * Should reordering setting be disabled
   */
  reorderSettingDisabled?: boolean;
  /**
   * Tooltip to show on reordering setting component in table settings
   */
  reorderSettingTooltip?: string;
  /**
   * Title to be displayed in settings component. If not provided, header will be displayed
   */
  settingsTitle?: ReactNode;
};

export type ColumnGroupProps = {
  /**
   * Column group id.
   * Can be omitted as it will be generated internally if not provided
   */
  id: string;
  /**
   * Content of column group header
   */
  title: ReactNode;
  /**
   * Same as InfoIcon in Table props, just for single column group.
   */
  InfoIcon?: TableComponents['InfoIcon'];
  /**
   * Content of the info tooltip. If provided, InfoIcon will be shown.
   */
  infoTooltip?: ReactNode;
  /**
   * Props to be passed for info tooltip.
   */
  infoTooltipProps?: TooltipProps;
  /**
   * Props used by settings component (could be generic if needed)
   */
  sx?: SxProps;
  testId?: string;
} & ColumnSettingsProps;

export type ColumnGroupPropsWithChildren = PropsWithChildren<ColumnGroupProps>;
export type ColumnGroupModel = ColumnGroupProps & {
  /**
   * Offset from the left corner of table to the left corner of the column group
   */
  startOffset: number;
  /**
   * Offset from the left corner of table to the right corner of the column group
   */
  endOffset: number;
  /**
   * Columns of the group
   */
  columns: ColumnModel[];
};

export type ColumnProps<T = any> = {
  /**
   * ID of the column.
   * Should only include symbols that are valid to use in html class as it will be included in cells classes.
   */
  id: string;
  /**
   * Content to be used in header (child of HeaderTitle). Does not affect sorting and info icon.
   */
  header?: ReactNode;
  /**
   * Same as Sorter in Table props, just for single column.
   */
  Sorter?: TableComponents['Sorter'];
  /**
   * Same as SortIcon in Table props, just for single column.
   */
  SortIcon?: TableComponents['SortIcon'];
  /**
   * Same as InfoIcon in Table props, just for single column.
   */
  InfoIcon?: TableComponents['InfoIcon'];
  /**
   * Content of the info tooltip. If provided, InfoIcon will be shown.
   */
  infoTooltip?: ReactNode;
  /**
   * Props to be passed for info tooltip.
   */
  infoTooltipProps?: Partial<TooltipProps>;
  /**
   * Used to override entire HeaderCell (together with sorting and info icons).
   * Resizer is not affected as it is rendered outside of column header cell, to customize resizer, use ResizerIcon.
   */
  HeaderCellComponent?: ComponentType<{ className?: string }>;
  /**
   * Renders content of body cell
   * When string is provided, it is considered as a path to value needed to display
   */
  renderCell?: RenderCell<T> | string;
  /**
   * Used to render header cell title (overrides HeaderTitle).
   * Sorting icon, info icon and expander are not affected.
   */
  renderHeader?: (api: TableApi) => ReactNode;
  /**
   * Used to render footer cell title.
   */
  renderFooter?: (api: TableApi) => ReactNode;
  /**
   * Resizer icon will be shown at the right of the header cell.
   */
  resizable?: boolean;
  /**
   * Concrete column width in pixels.
   * Column will always be of this with - resizing entire table or individual columns will not affect this column.
   */
  width?: number;
  /**
   * Minimum width of the column in pixels.
   * Column width will be proportional to other columns with minWidth property but will be between minWidth and maxWidth (if specified).
   * Works similar to flexGrow css rule.
   */
  minWidth?: number;
  /**
   * Maximum width of the column in pixels.
   */
  maxWidth?: number;
  /**
   * Resizer will not be allowed to resize column below specified value in pixels.
   * @default 32
   */
  minResizeWidth?: number;
  /**
   * Resizer will not be allowed to resize column above specified value in pixels.
   * @default 2000
   */
  maxResizeWidth?: number;
  /**
   * Default sort direction of the column.
   * Used to initially sort the table.
   */
  defaultSort?: SortDirection;
  /**
   * Indicates whether the column is sortable.
   * No sort icon will be shown in the header, though column is still allowed to be sorted using table api.
   * @default false
   */
  sortable?: boolean;
  /**
   * If provided, value will be converted to provided type before sorting
   */
  sortingType?: SortingType;
  /**
   * Accessor used retrieve the value from row data to sort table by column.
   * If no accessor is provided, but sortable = true, id will be used as accessor.
   * If row data is nested object, path can be provided instead of prop name.
   * @see https://lodash.com/docs/4.17.15#get
   * If callback is provided, it receives row data and have to return value which will be used for sorting.
   * Keep in mind, that if value is a string, it will not try to parse a number from it to sort as number.
   */
  accessor?: Accessor<T>;
  /**
   * sx to be applied on HeaderCellWrapper
   */
  headerCellWrapperSx?: SxProps;
  /**
   * sx to be applied on HeaderCell
   */
  headerCellSx?: SxProps;
  /**
   * sx to be applied on BodyCellWrapper
   */
  bodyCellWrapperSx?: SxProps;
  /**
   * sx to be applied on BodyCell
   */
  bodyCellSx?: SxProps;
  /**
   * sx to be applied on FooterCellWrapper
   */
  footerCellWrapperSx?: SxProps;
  /**
   * sx to be applied on FooterCell
   */
  footerCellSx?: SxProps;
  /**
   * sx to be applied on HeaderCellWrapper, BodyCellWrapper, FooterCellWrapper.
   * Is overridable by more specific cell wrapper sx.
   */
  cellWrapperSx?: SxProps;
  /**
   * sx to be applied on HeaderCell, BodyCell, FooterCell.
   * Is overridable by more specific cell sx.
   */
  cellSx?: SxProps;
  /**
   * Used in tree row sub-rows, when single child row column needs to span multiple main row columns.
   */
  childRowColumnSpan?: number;
  /**
   * Same as childRowColumnSpan, just can be used not only in tree tables and can span columns of main row too.
   * Using this it is possible to control columns span of any cell in any row.
   * If undefined is returned, default span (1) is used.
   */
  getColumnSpan?: (row: T, state: RowState<T>) => number | undefined;
  /**
   * Stops click propagation when body cell is clicked.
   * This way `onRowClick` will not be called when cell is clicked.
   */
  stopClickPropagation?: boolean;
} & ColumnSettingsProps;

export type ParsedGroupProps = ColumnGroupProps & {
  columns: ColumnProps[];
};
export type GroupedColumnProps = ColumnProps | ParsedGroupProps;

export const isColumnsGroup = (
  groupedColumnProps: GroupedColumnProps
): groupedColumnProps is ParsedGroupProps =>
  !!(groupedColumnProps as ParsedGroupProps).columns;

export type ColumnModel<T = any> = Omit<ColumnProps<T>, 'renderCell'> & {
  /**
   * Render cell callback
   */
  renderCell: RenderCell<T>;
  /**
   * Actual column width.
   */
  computedWidth: number;
  /**
   * Offset from table left corner, to left corner of the column.
   */
  startOffset: number;
  /**
   * Offset from table left corner, to right corner of the column.
   */
  endOffset: number;
  /**
   * Same as accessor prop of ColumnProps.
   * The only difference is that if the accessor was not provided in ColumnProps, id will be set here if column is sortable.
   */
  accessor?: Accessor;
  /**
   * Determines if the column is first of the group columns.
   */
  isFirstGroupColumn: boolean;
  /**
   * Determines if the column is last of the group columns.
   */
  isLastGroupColumn: boolean;
  /**
   * Group props of the column
   */
  groupProps?: ColumnGroupProps;
};

export type SetColumnSettings = (
  settings:
    | ColumnSettings
    | ((currentSettings: ColumnSettings) => ColumnSettings)
) => void;

export type TableApi<T = any> = {
  /**
   * Table state
   */
  state: TableState;
  /**
   * Set table state
   */
  setState: SetTableState;
  /**
   * Resize column
   * @param columnId
   * @param newSize
   */
  resizeColumn: (columnId: string, newSize: number) => void;
  /**
   * @param settings either new settings or function to update settings
   */
  setColumnSettings: SetColumnSettings;
  /**
   * Sort column. Passing undefined will set original sort as data is ordered in array
   * @param sortingState
   */
  sortColumn: (sortingState?: SortingState) => void;
  /**
   * Open expander panel of the row
   * @param row
   */
  expandRow: (row: T) => boolean;
  /**
   * Collapse expander panel
   * @param row
   */
  collapseRow: (row: T) => boolean;
  /**
   * Toggle expander panel
   * @param row
   */
  toggleIsRowExpanded: (row: T) => void;
  /**
   * Get subtree of tree root row
   */
  getTreeRows: (
    row: T,
    childRows: TableProps['childRows'],
    rowState: RowState
  ) => Promise<T[]>;
  /**
   * Expand tree
   */
  expandTreeRow: (row: T) => boolean;
  /**
   * Collapse tree
   */
  collapseTreeRow: (row: T) => boolean;
  /**
   * Toggle tree
   */
  toggleIsTreeRowExpanded: (row: T) => void;
  /**
   * Columns info
   */
  columnsInfo: ColumnsInfo;
  /**
   * Set new index for row
   */
  reorderRow: (reorderedRow: ReorderRow) => void;
  /**
   * Restore default table settings
   */
  restoreDefaults: () => void;
  tableInfo: TableInfo;
  log: (payload: LogPayload) => void;
};

export type GetRowKey<T = any, K extends RowKey = any> = (row: T) => K;
export type SetTableState = (
  tableState: TableState | ((tableState: TableState) => TableState),
  repaint?: boolean
) => void;

export type RowState<T = any> = {
  /**
   * Row data
   */
  row: T;
  /**
   * row data index in data array
   */
  index: number;
  /**
   * Table api object
   */
  tableApi: TableApi<T>;
  /**
   * Row key
   */
  rowKey: string | number;
  /**
   * Is row expanded
   */
  isExpanded?: boolean;
  /**
   * Is tree row expanded
   */
  isTreeExpanded?: boolean;
  /**
   * Row depth in tree table
   */
  level: number;
  /**
   * Draggable state of the row
   */
  draggableState?: DraggableState;
};

export type SortFn<T = any> = (data: T[]) => T[];

export type Size = 'xl' | 'l' | 'm' | 's';

export type LogPayload = {
  message: string;
  data?: any;
  logLevel: 'info' | 'warning' | 'error';
};

export type RowAttributes = {
  className?: string;
  styles?: CSSProperties;
};

export type TableProps<T = any, K extends RowKey = any> = PropsWithChildren<
  {
    sizing?: Size;
    /**
     * Use either one of predefined row height or pixel value. If not used, row will be as high as the highest cell.
     * @default 'm'
     */
    rowHeight?: number;
    /**
     * Ref object which is attached with table api when it is initialized. Convenient to use when need to control table without maintaining its state.
     */
    apiRef?: MutableRefObject<TableApi | undefined>;
    /**
     * Data which will be displayed in table.
     */
    data?: T[];
    /**
     * Outer header to display above the table. It's height is included in height calculations
     */
    outerHeader?: ReactNode;
    /**
     * If set to true, ResizeObserver will be attached on direct parent of the table and table will be sized accordingly.
     * For this to work correctly, table needs to be the only child.
     * Table will be the height of the parent (including header, and footer), but not higher than there are rows.
     * @default false
     */
    fillParentHeight?: boolean;
    /**
     * Rows virtualization is disabled
     * @default false
     */
    disableVirtualization?: boolean;
    /**
     * This is dedicated for scroll related performance and should not be used in most of the cases
     * When set to true, table won't attach ResizeObserver to every row.
     * If true - Virtuoso will assume that all the rows are of the same height (rowHeight prop).
     * Set it to true only when you are sure that rows will be the same height (which is the most common case).
     * If true is set and rows are of significantly different heights, rows will 'jump'. The more different heights are, the more noticeable jumping.
     * @link https://virtuoso.dev/virtuoso-api-reference
     * (in virtuoso it is called fixedItemHeight)
     * @default false
     */
    useFixedRowHeight?: boolean;
    /**
     * Min height in pixels.
     */
    minHeightPx?: number;
    /**
     * Max height in pixels.
     */
    maxHeightPx?: number;
    /**
     * Max height in vh. This attaches ResizeObserver to body.
     */
    maxHeightVh?: number;
    /**
     * Max height. Can be any valid css expression (e.g. calc(......), 100px etc...)
     */
    maxHeight?: string;
    /**
     * Max visible rows
     */
    maxRows?: number;
    /**
     * rowKey used to identify rows
     * @default id
     */
    rowKey?: RowKey | symbol | GetRowKey<K>;
    /**
     * Secondary sort instructions to apply after user sorts the column (or default sort is applied)
     */
    secondarySort?: SortBy<T> | SortBy<T>[];
    /**
     * When sorting is controlled, table will not sort the data and will only emit sort events
     */
    controlledSorting?: boolean;
    /**
     * Event emitted when user sorts the column.
     */
    onDataSort?: (sortingState?: SortingState) => void;
    /**
     * Callback invoked when sorts the column, but actual sorting should be performed above in components hierarchy.
     * Example use case is search optimisation - enables to not sort the data after every user input in search field and prevents unnecessary rerender.
     * @param sortData function which does actual sorting and returns sorted data
     */
    onSortingChanged?: (sortData: SortFn<T>) => void;
    /**
     * sortingState when controlledSorting = true
     */
    sortingState?: SortingState;
    /**
     * sx props applied on table root
     */
    sx?: SxProps<Theme>;
    /**
     * Buffer space to render rows that doesn't fit into available height.
     * Setting it too small may add flickering when scrolling.
     * Setting it too high might impact table performance when there is a lot of expensive rows.
     * Default value (500) should be sufficient
     * @default 500
     */
    overscan?: number;
    /**
     * When set to true, table will render as tree.
     * @default false
     */
    treeTable?: boolean;
    /**
     * Callback used to determine if row has children.
     * If false is returned, tree expander icon will not be shown.
     * If not provided and childRows is provided as string, it will be used to determine if rows have children
     * @param row data of the row
     * @param rowState row state
     */
    hasChildren?: (row: T, rowState: RowState<T>) => boolean;
    /**
     * When keyof T - property name of row data which stores child rows of the row.
     * When callback - return child rows from it (can be returned as Promise)
     */
    childRows?:
      | keyof T
      | ((row: T, rowState?: RowState) => any[] | Promise<T> | undefined);
    /**
     * Class to be applied on row wrapper
     */
    getRowClass?: (row: T, rowState: RowState<T>) => string | undefined;
    /**
     * Get row attributes
     */
    getRowAttributes?: (
      row: T,
      rowState: RowState<T>
    ) => RowAttributes | undefined;
    /**
     * If provided, expander toggle will be shown and rows will be expandable
     */
    getExpandedPanel?: (row: T, rowState: RowState<T>) => ReactNode;
    /**
     * Render entire row (BodyRowWrapper and expanded panel are not affected, if you need to override those, use BodyRowComponent)
     */
    renderRow?: (row: T, rowState: RowState<T>) => ReactNode | undefined;
    /**
     * onClick event for table row
     */
    onRowClick?: (event: MouseEvent, rowState: RowState<T>) => void;
    /**
     * onClick event for tree expander icon
     */
    onTreeIconClick?: (event: MouseEvent, rowState: RowState<T>) => void;
    /**
     *  Instructions for drawing tree lines and indentations
     */
    treeUiConfig?: TreeUiConfig;
    /**
     *  Callbacks for drawing tree lines and indentations
     */
    treeUiUtils?: TreeUiUtils;
    /**
     * Key for storing state in url
     */
    urlKey?: string;
    /**
     * displays loadingRowSkeleton when isLoading = true
     */
    isLoading?: boolean;
    /**
     * displays loadingRowSkeleton at the end of the table when isFetching = true
     */
    isFetching?: boolean;
    /**
     * skeleton to show when isLoading = true
     */
    loadingRowSkeleton?: ReactNode;
    /**
     * callback on body rendered
     */
    onBodyRendered?: (api: TableApi) => void;
    /**
     * callback on Virtuoso endReached
     */
    onLoadMore?: () => void;
    /**
     * Footer to display below table
     */
    footer?: ReactNode;
    /**
     * Test id to assign to row wrapper
     */
    getRowTestId?: (row: T, rowState: RowState<T>) => string;
    /**
     * Custom log function
     */
    log?: (payload: LogPayload) => void;
    /**
     * Test id assigned to table root and used as prefix to table component's test ids
     */
    testId?: string;

    /**
     * Cache key for table state to be stored in storage
     */
    cacheKey?: string;

    /**
     * Message to display on loading more component
     */
    loadingMoreText?: string;
    /**
     * Allow rows reordering
     */
    reorderableRows?: boolean;
    /**
     * Callback called when row is reordered
     */
    onRowReordered?: (reordering: OnRowReorderedArgs<T>) => void;
    /**
     * Mappings between row key and marker color
     */
    cssVarsProp?: string;
    /**
     * Scrolls to index once after data is being loaded
     */
    scrollToIndex?: Parameters<VirtuosoHandle['scrollToIndex']>[0];
  } & TableComponents
>;

export type NormalizedTableProps<T = any> = Omit<
  TableProps<T>,
  | 'rowKey'
  | keyof TableComponents
  | 'sortType'
  | 'overscan'
  | 'rowHeight'
  | 'sizing'
  | 'log'
> & {
  rowKey: GetRowKey<T>;
  overscan: number;
  rowHeight: number;
  sizing: Size;
  sysId: string;
  log: (payload: LogPayload) => void;
} & Required<TableComponents<T>>;

export type TableContextState<T = any> = {
  /**
   * Data passed to the table
   */
  data?: T[];
  /**
   * Props passed to the table
   */
  tableProps: NormalizedTableProps<T>;
  /**
   * Table api ref
   */
  api: MutableRefObject<TableApi<T>>;
  /**
   * Root element of the table
   */
  rootRef?: HTMLDivElement;
  /**
   * Root width and height of the table. This changes often when table is resized (by resizing window, etc..)
   */
  rootRect?: { width: number; height: number };
  /**
   * Group headers height + header height + footer height
   */
  peripheralsHeight?: number;
  /**
   * Overlay API
   */
  overlay: OverlayState;
  isFloatingColumnSticky: boolean;
  setIsFloatingColumnSticky: (isSticky: boolean) => void;
};

export type DraggableState = {
  provided: DraggableProvided;
  draggableSnapshot?: DraggableStateSnapshot;
  droppableSnapshot?: DroppableStateSnapshot;
  rubric?: DraggableRubric;
};

export type BodyRowProps<T = any> = {
  row: T;
  index: number;
  onClick?: (event: MouseEvent, rowState: RowState<T>) => void;
  draggableState?: DraggableState;
};
