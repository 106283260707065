import { useContext } from 'react';

import { Stack, Theme } from '@mui/material';

import { Button, Icons } from '@cast/design-system';

import { analyticsEvents } from 'core/analytics';

import { GlobalTourStepProps, TourContext } from '../providers';

type Props = {
  steps: string[];
  type: string;
  globalTourStepProps?: Record<string, GlobalTourStepProps>;
};

export const TourFooterInitiate = ({
  steps,
  type,
  globalTourStepProps,
}: Props) => {
  const { isInitActive, startTour, dismiss } = useContext(TourContext);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      gap="16px"
    >
      {isInitActive && (
        <Button
          variant="text"
          onClick={() => dismiss(type)}
          sx={(theme: Theme) => ({
            color: 'aqua.300',
            opacity: 0.4,
            ...theme.typography.P12M,
            px: 0,

            '&:hover': {
              opacity: 1,
              color: 'aqua.100',
            },

            '&:active': {
              color: 'aqua.600',
            },
          })}
          testId="dismiss-btn"
        >
          Close
        </Button>
      )}

      <Button
        variant="text"
        onClick={() => {
          startTour(type, steps, globalTourStepProps);
          analyticsEvents.tourHasBeenStarted();
        }}
        endIcon={<Icons.ArrowRight />}
        sx={(theme: Theme) => ({
          color: 'aqua.300',
          ...theme.typography.P12M,

          '& .MuiButton-endIcon': {
            marginLeft: 0,
          },

          '&:hover': {
            color: 'aqua.100',
          },
          '&:active': {
            color: 'aqua.600',
          },
        })}
        testId="start-btn"
      >
        Start the tour
      </Button>
    </Stack>
  );
};
