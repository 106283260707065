import { AxiosInstance } from 'axios';

import { UsersAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const UsersApiAdapter = (client: AxiosInstance) => {
  const generatedApi = UsersAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    currentUserProfile: generatedApi.usersAPICurrentUserProfile,
    listOrganizations: generatedApi.usersAPIListOrganizations,
    createOrganization: generatedApi.usersAPICreateOrganization,
    updateOrganization: generatedApi.usersAPIEditOrganization,
    deleteOrganization: generatedApi.usersAPIDeleteOrganization,
    listInvitations: generatedApi.usersAPIListInvitations,
    claimInvitation: generatedApi.usersAPIClaimInvitation,
    createInvitation: generatedApi.usersAPICreateInvitations,
    deleteInvitation: generatedApi.usersAPIDeleteInvitation,
    getOrganizationUsers: generatedApi.usersAPIListOrganizationUsers,
    updateOrganizationUser: generatedApi.usersAPIUpdateOrganizationUser,
    deleteOrganizationUser: generatedApi.usersAPIRemoveUserFromOrganization,
  };
};
