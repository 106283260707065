import capitalize from 'lodash/capitalize';
import { bindToggle } from 'material-ui-popup-state/core';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';

import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withProps,
  Icons,
} from '@cast/design-system';

import { ProfileAvatar } from 'components/avatars';
import { LogoutWrapper } from 'components/router';

import { HeaderButton, HeaderPopover } from './_components';

export type ProfileMenuProps = {
  user: string;
  email: string;
};

const ProfileListItemText = withProps(ListItemText, {
  primaryTypographyProps: { variant: 'P12M' },
  secondaryTypographyProps: { variant: 'P10R' },
});

export const ProfileMenu = ({ user, email }: ProfileMenuProps) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'profile-menu',
  });

  return (
    <>
      <HeaderButton
        {...bindToggle(popupState)}
        active={popupState.isOpen}
        startIcon={<Icons.UserCircle />}
        testId="profile-menu-btn"
      >
        {capitalize(user)}
      </HeaderButton>

      <HeaderPopover {...bindPopover(popupState)} testId="profile-menu">
        <List size="medium" disablePadding sx={{ minWidth: '190px' }}>
          <ListItem
            startAdornment={
              <ListItemIcon>
                <ProfileAvatar name={user} email={email} size="m" />
              </ListItemIcon>
            }
            sx={(theme) => ({
              '&, &:hover': {
                borderBottom: `1px solid ${theme.palette.grey[100]}`,
                backgroundColor: 'grey.50',
                backgroundImage: `linear-gradient(0deg, rgba(8, 41, 57, 0.05) 0%, rgba(8, 41, 57, 0) 25%)`,
              },
            })}
            multiline
            disableHover
          >
            <ProfileListItemText primary={capitalize(user)} secondary={email} />
          </ListItem>

          <LogoutWrapper>
            <ListItem
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              component={Button}
              variant="text"
              fullWidth
              startIcon={<Icons.SignOut weight="regular" />}
              testId="log-out-btn"
            >
              <ProfileListItemText>Log out</ProfileListItemText>
            </ListItem>
          </LogoutWrapper>
        </List>
      </HeaderPopover>
    </>
  );
};
