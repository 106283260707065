import { forwardRef } from 'react';

import { Box, Typography } from '@mui/material';

import { Drawer, DrawerProps } from '@cast/design-system';
import { SecurityInsightsTicketItemType } from '@cast/types';

import {
  CloseDrawerButton,
  DestroyDrawerFormGuard,
  DrawerContent,
} from 'components/drawer/ds';

import { TicketForm } from './_components/TicketForm';
import { CreateJiraTicketDrawerProps } from './types';

type Props = CreateJiraTicketDrawerProps & Omit<DrawerProps, 'testId'>;

export const CreateJiraTicketDrawer = forwardRef<HTMLDivElement, Props>(
  (
    { itemType, itemName, itemId, onSuccess, additionalData, ...drawerProps },
    ref
  ) => {
    const getItemTypeLabel = () => {
      switch (itemType) {
        case SecurityInsightsTicketItemType.Check:
          return 'check';
        case SecurityInsightsTicketItemType.Image:
          return 'image repository';
        case SecurityInsightsTicketItemType.Package:
          return 'package';
        case SecurityInsightsTicketItemType.Vulnerability:
          return 'vulnerability';
        default:
          return 'item';
      }
    };
    return (
      <Drawer
        ref={ref}
        size="small"
        {...drawerProps}
        testId="create-jira-ticket-drawer"
      >
        <CloseDrawerButton />
        <DrawerContent sx={{ pt: 62, mb: 24 }}>
          <Typography variant="h5">Create Jira ticket</Typography>
        </DrawerContent>
        <Box
          padding="16px 32px"
          bgcolor="grey.50"
          borderTop="grey.200"
          borderBottom="grey.200"
          mb={24}
        >
          <Typography variant="P14B">Selected {getItemTypeLabel()}:</Typography>
          <Typography variant="P12M">{itemName}</Typography>
        </Box>

        <DrawerContent>
          <TicketForm
            itemType={itemType}
            itemName={itemName}
            itemId={itemId}
            onSuccess={onSuccess}
            additionalData={additionalData}
          />
        </DrawerContent>
        <DestroyDrawerFormGuard />
      </Drawer>
    );
  }
);

CreateJiraTicketDrawer.displayName = 'CreateJiraTicketDrawer';
