import {
  createContext,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';

import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import { matchPath, useLocation } from 'react-router-dom';

import { Cluster, K8sProvider } from '@cast/types';
import { DEMO_CLUSTER_ID, usePrevious } from '@cast/utils';

import { premiumFeaturePaths } from 'common/constants';
import { analyticsEvents } from 'core/analytics';
import { useClustersQuery } from 'hooks/queries/cluster';
import { useNavigate } from 'hooks/useNavigate';
import { getReconnectCluster } from 'utils/cluster';

import { useClusterConnectionTimeout } from './_hooks/useClusterConnectionTimeout';
import { OnboardClusterContextValue, OnboardingState } from './types';

const DEFAULT_PROVIDER = K8sProvider.EKS;

export const OnboardClusterContext = createContext<OnboardClusterContextValue>(
  {} as never
);

type Props = {
  provider?: K8sProvider;
  isSecurityFlow?: boolean;
};

export const OnboardClusterProvider = ({
  children,
  provider: mostUsedProvider,
  isSecurityFlow = false,
}: PropsWithChildren<Props>) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [clusterScanTimeFrom, setClusterScanTimeFrom] = useState(dayjs());
  const [provider, setProvider] = useState<K8sProvider>(
    mostUsedProvider || DEFAULT_PROVIDER
  );

  const isPremiumPath = premiumFeaturePaths.some((path) =>
    matchPath(path, location.pathname)
  );
  const [state, setState] = useState<OnboardingState>();
  useClusterConnectionTimeout(state, setState);

  const previousClustersMap = useRef<
    Partial<Record<typeof provider, typeof clusters>>
  >({});
  const { clusters, refetchClusters } = useClustersQuery(
    state === 'connecting'
  );

  previousClustersMap.current[provider] = usePrevious<Cluster[]>(
    clusters as Cluster[]
  );
  const previousClusters = previousClustersMap.current[provider];
  const [newConnections, setNewConnections] = useState<Cluster[]>([]);

  // Previous value must be reset after provider has changed
  useEffect(() => {
    setNewConnections([]);
    if (previousClusters) {
      delete previousClustersMap.current[provider];
    }
    // eslint-disable-next-line
  }, [provider]);

  useEffect(() => {
    if (!clusters) return;

    // Show cluster connected after clusterScanTimeFrom is set
    const newClusters = clusters.filter(
      (cluster) => clusterScanTimeFrom.diff(dayjs(cluster.createdAt)) <= 0
    );

    // Show cluster which were reconnected
    let reconnectedClusters: Cluster[] = [];
    if (previousClusters) {
      reconnectedClusters = getReconnectCluster(previousClusters, clusters);
    }

    setNewConnections((current) => {
      return uniqBy(
        [...current, ...reconnectedClusters, ...newClusters],
        'id'
      ).filter((cluster) => cluster.providerType === provider);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusters, provider, previousClusters]);

  const handleWaitForConnection = () => {
    setState('connecting');
    analyticsEvents.ranThescript(provider);
    refetchClusters();
  };

  const handleResetState = () => {
    setState(undefined);
  };

  useEffect(() => {
    if (newConnections.length > 0 && state === 'connecting') {
      if (location.pathname.includes(DEMO_CLUSTER_ID)) {
        if (isPremiumPath) {
          navigate('/dashboard');
        } else {
          navigate(
            location.pathname.replace(DEMO_CLUSTER_ID, newConnections[0].id)
          );
        }
      }

      setState('connected');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newConnections, state]);

  return (
    <OnboardClusterContext.Provider
      value={{
        isFirstCluster: !mostUsedProvider,
        isSecurityFlow,
        state,
        handleWaitForConnection,
        handleResetState,
        provider,
        setProvider,
        newConnections,
        setClusterScanTimeFrom,
      }}
    >
      {children}
    </OnboardClusterContext.Provider>
  );
};
