import { PropsWithChildren } from 'react';

import { RBACContext } from './context';
import { RootFeature } from './types';

export type RBACProviderProps = {
  feature: `${RootFeature}`;
};

export const RBACProvider = ({
  children,
  feature,
}: PropsWithChildren<RBACProviderProps>) => {
  return (
    <RBACContext.Provider value={feature as RootFeature}>
      {children}
    </RBACContext.Provider>
  );
};
