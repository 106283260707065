import { K8sProvider } from '@cast/types';

import { ReactComponent as AKSIcon } from 'assets/icons/aks.svg';
import { ReactComponent as EKSIcon } from 'assets/icons/eks.svg';
import { ReactComponent as GKEIcon } from 'assets/icons/gke.svg';
import { ReactComponent as KopsIcon } from 'assets/icons/kops.svg';
import { ReactComponent as KubernetesIcon } from 'assets/icons/kubernetes-logo.svg';
import { ReactComponent as ROSAIcon } from 'assets/icons/rosa.svg';

type Props = {
  provider?: `${K8sProvider}`;
  size?: number;
  className?: string;
};

export const K8sProviderIcon = ({ provider, className, size = 30 }: Props) => {
  switch (provider) {
    case K8sProvider.EKS:
      return (
        <EKSIcon
          height={size}
          width={size}
          data-provider={K8sProvider.EKS}
          className={className}
        />
      );
    case K8sProvider.GKE:
      return (
        <GKEIcon
          height={size}
          width={size}
          data-provider={K8sProvider.GKE}
          className={className}
        />
      );
    case K8sProvider.AKS:
      return (
        <AKSIcon
          height={size}
          width={size}
          data-provider={K8sProvider.AKS}
          className={className}
        />
      );
    case K8sProvider.KOPS:
      return (
        <KopsIcon
          height={size}
          width={size}
          data-provider={K8sProvider.KOPS}
          className={className}
        />
      );
    case K8sProvider.ROSA:
      return (
        <ROSAIcon
          height={size}
          width={size}
          data-provider={K8sProvider.ROSA}
          className={className}
        />
      );
    default:
      return (
        <KubernetesIcon height={size} width={size} className={className} />
      );
  }
};
