export enum PermissionType {
  VIEW = 'view',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}

// NOTE: here __ (double underscores) indicates sub features, which will be not included in rbac/config.ts
export enum Feature {
  ORGANIZATION = 'ORGANIZATION',

  ORGANIZATION_SECURITY = 'ORGANIZATION_SECURITY',
  ORGANIZATION_SECURITY__BEST_PRACTICES = 'ORGANIZATION_SECURITY__BEST_PRACTICES',
  ORGANIZATION_SECURITY__NODE_REPLACEMENT = 'ORGANIZATION_SECURITY__NODE_REPLACEMENT',

  ORGANIZATION_COST_REPORT = 'ORGANIZATION_COST_REPORT',
  ORGANIZATION_COST_REPORT__ALLOCATION_GROUPS = 'ORGANIZATION_COST_REPORT__ALLOCATION_GROUPS',
  ORGANIZATION_COST_REPORT__CLUSTERS_REPORT = 'ORGANIZATION_COST_REPORT__CLUSTERS_REPORT',

  BILLING = 'BILLING',

  CLUSTER = 'CLUSTER',
  CLUSTER__AUDIT_LOG = 'CLUSTER__AUDIT_LOG',
  CLUSTER__AUTOSCALER = 'CLUSTER__AUTOSCALER',
  CLUSTER__AUTOSCALER_KARPENTER = 'CLUSTER__AUTOSCALER_KARPENTER',
  CLUSTER__REBALANCING = 'CLUSTER__REBALANCING',
  CLUSTER__NODES = 'CLUSTER__NODES',
  CLUSTER__SAVINGS = 'CLUSTER__SAVINGS',
  CLUSTER__SAVINGS_PROGRESS = 'CLUSTER__SAVINGS_PROGRESS',
  CLUSTER__COST_REPORT = 'CLUSTER__COST_REPORT',
  CLUSTER__COST_REPORT_CLUSTER_EFFICIENCY = 'CLUSTER__COST_REPORT_CLUSTER_EFFICIENCY',
  CLUSTER__COST_REPORT_WORKLOADS = 'CLUSTER__COST_REPORT_WORKLOADS',
  CLUSTER__COST_REPORT_NAMESPACES = 'CLUSTER__COST_REPORT_NAMESPACES',
  CLUSTER__COST_REPORT_ALLOCATION_GROUPS = 'CLUSTER__COST_REPORT_ALLOCATION_GROUPS',
  CLUSTER__COST_REPORT_COMPARISON = 'CLUSTER__COST_REPORT_COMPARISON',

  CLUSTER__NETWORK_COST_REPORT_CLUSTER = 'CLUSTER__NETWORK_COST_REPORT_CLUSTER',
  CLUSTER__NETWORK_COST_REPORT_WORKLOADS = 'CLUSTER__NETWORK_COST_REPORT_WORKLOADS',
  CLUSTER__NETWORK_COST_REPORT_WORKLOAD = 'CLUSTER__NETWORK_COST_REPORT_WORKLOAD',
  CLUSTER__NETWORK_COST_REPORT_NAMESPACES = 'CLUSTER__NETWORK_COST_REPORT_NAMESPACES',
  CLUSTER__NETWORK_COST_REPORT_ALLOCATION_GROUPS = 'CLUSTER__NETWORK_COST_REPORT_ALLOCATION_GROUPS',

  CREDENTIALS = 'CREDENTIALS',

  ACCESS_KEYS = 'ACCESS_KEYS',

  REPORTS = 'REPORTS',

  NOTIFICATIONS = 'NOTIFICATIONS',

  SCHEDULED_REBALANCING = 'SCHEDULED_REBALANCING',

  RESERVED_INSTANCES = 'RESERVED_INSTANCES',

  COMMITMENTS = 'COMMITMENTS',

  WORKLOAD_OPTIMIZATION = 'WORKLOAD_OPTIMIZATION',

  LLM_OPTIMIZER = 'LLM_OPTIMIZER',
}

type KeysOfFeature = keyof typeof Feature;

type KeysWithoutDoubleUnderscore<T extends string> =
  T extends `${string}__${string}` ? never : T;

type KeysWithDoubleUnderscore<T extends string> =
  T extends `${string}__${string}` ? T : never;

export type RootFeatureKey = KeysWithoutDoubleUnderscore<KeysOfFeature>;

export type RootFeatureValues = (typeof Feature)[RootFeatureKey];

export type RootSubFeatureKey = KeysWithDoubleUnderscore<KeysOfFeature>;

export type RootSubFeatureValues = (typeof Feature)[RootSubFeatureKey];

export type RootFeature = Exclude<
  `${keyof Record<Feature, string>}`,
  `${keyof Record<
    Exclude<RootSubFeatureValues, 'CLUSTER__COST_REPORT'>,
    string
  >}`
>;
