import { useCallback, useContext, useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

import { mergeSx } from '@cast/design-system';
import { isDemoMode } from '@cast/utils';

import { analyticsEvents } from 'core/analytics';
import { userId } from 'core/auth';

import { TourTooltip, Beacon, TourFooterInitiate } from './_components';
import { GlobalTourStepProps, TourContext } from './providers';
import { wasDismissed } from './utils';

export type InitiateTourProps = {
  type: string;
  steps: string[];
  autoInit?: boolean;
  globalTourStepProps?: Record<string, GlobalTourStepProps>;
  endTourOnDestroy?: boolean;
  message?: string;
  dismissedMessage?: string;
  sx?: SxProps;
};

export const InitiateTour = ({
  type,
  steps,
  autoInit,
  globalTourStepProps,
  endTourOnDestroy = true,
  message = 'Take the tour to learn more about this page. Are you ready?',
  dismissedMessage = 'Go back to the tour to learn more by clicking on the bubble.',
  sx,
}: InitiateTourProps) => {
  const {
    isActive,
    isInitActive,
    setIsInitActive,
    isDismissedOnce,
    finishTour,
    finishTourImmediately,
  } = useContext(TourContext);

  const storageKey = `dismissed-tour-${type}-${userId()}`;

  const wasDismissedBefore = useMemo(() => {
    return wasDismissed(type);
  }, [type]);

  if (wasDismissedBefore) {
    autoInit = false;
  }

  const openInitTooltip = () => {
    setIsInitActive(true);
  };

  useEffect(() => {
    if (autoInit && !isInitActive && !isActive) {
      const timeoutId = setTimeout(openInitTooltip, 1200);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoInit, storageKey]);

  useEffect(() => {
    return () => {
      if (endTourOnDestroy) {
        finishTour();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickAway = useCallback(() => {
    if (isDismissedOnce) {
      finishTourImmediately();
    }
  }, [isDismissedOnce, finishTourImmediately]);

  if (isActive || !isDemoMode()) {
    return null;
  }

  return (
    <TourTooltip
      open={isInitActive}
      text={isDismissedOnce ? dismissedMessage : message}
      footer={
        !isDismissedOnce ? (
          <TourFooterInitiate
            type={type}
            steps={steps}
            globalTourStepProps={globalTourStepProps}
          />
        ) : null
      }
      placement="bottom-start"
      popperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-10, 0],
            },
          },
        ],
      }}
      onClickAway={handleClickAway}
      testId="initiate-tour"
    >
      <Box sx={mergeSx({ height: 'max-content' }, sx)}>
        <Beacon
          init
          isActive={isInitActive}
          onClick={() => {
            openInitTooltip();
            analyticsEvents.initiateTourHasBeenOpened();
          }}
          testId="initiate-tour-beacon"
        />
      </Box>
    </TourTooltip>
  );
};
