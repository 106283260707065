/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ExternalclusterV1AddNodeResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1CloudEvent } from '../models';
// @ts-ignore
import { ExternalclusterV1Cluster } from '../models';
// @ts-ignore
import { ExternalclusterV1ClusterUpdate } from '../models';
// @ts-ignore
import { ExternalclusterV1CreateAssumeRolePrincipalResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1CreateClusterTokenResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1DeleteNodeResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1DisconnectConfig } from '../models';
// @ts-ignore
import { ExternalclusterV1DrainConfig } from '../models';
// @ts-ignore
import { ExternalclusterV1DrainNodeResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1GetAssumeRolePrincipalResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1GetAssumeRoleUserResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1GetCleanupScriptResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1GetCredentialsScriptResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1GetListNodesFiltersResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1ListClustersResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1ListNodesResponse } from '../models';
// @ts-ignore
import { ExternalclusterV1Node } from '../models';
// @ts-ignore
import { ExternalclusterV1NodeConfig } from '../models';
// @ts-ignore
import { ExternalclusterV1RegisterClusterRequest } from '../models';
/**
 * ExternalClusterAPIApi - axios parameter creator
 * @export
 */
export const ExternalClusterAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a node to the cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {ExternalclusterV1NodeConfig} config New node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIAddNode: async (clusterId: string, config: ExternalclusterV1NodeConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIAddNode', 'clusterId', clusterId)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('externalClusterAPIAddNode', 'config', config)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/nodes`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create AWS cross role user for assume role.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPICreateAssumeRolePrincipal: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPICreateAssumeRolePrincipal', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/assume-role-principal`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns cluster token that is used for agent and cluster controller.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPICreateClusterToken: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPICreateClusterToken', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/token`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete AWS cross role user for assume role.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDeleteAssumeRolePrincipal: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIDeleteAssumeRolePrincipal', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/assume-role-principal`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cluster can be removed only if it was disconnected or is in failed state.
         * @summary Deletes the cluster from CAST console. It won\'t delete the actual cluster from cloud provider\'s console.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDeleteCluster: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIDeleteCluster', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the specified cluster node.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} nodeId The ID of the node.
         * @param {string} [drainTimeout] Node drain timeout in seconds. Defaults to 600s if not set.
         * @param {boolean} [forceDelete] If set to true, node will be deleted even if node fails to be drained gracefully.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDeleteNode: async (clusterId: string, nodeId: string, drainTimeout?: string, forceDelete?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIDeleteNode', 'clusterId', clusterId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('externalClusterAPIDeleteNode', 'nodeId', nodeId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/nodes/{nodeId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (drainTimeout !== undefined) {
                localVarQueryParameter['drainTimeout'] = drainTimeout;
            }

            if (forceDelete !== undefined) {
                localVarQueryParameter['forceDelete'] = forceDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CAST provisioned nodes are deleted only when `deleteProvisionedNodes` flag is set. Cloud resources used by CAST provisioned nodes are deleted only if nodes are deleted.
         * @summary Disconnects cluster by removing all CAST dependencies from cloud provider\'s account.
         * @param {string} clusterId The ID of the cluster.
         * @param {ExternalclusterV1DisconnectConfig} config Disconnect cluster configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDisconnectCluster: async (clusterId: string, config: ExternalclusterV1DisconnectConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIDisconnectCluster', 'clusterId', clusterId)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('externalClusterAPIDisconnectCluster', 'config', config)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/disconnect`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Drains the specified cluster node.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} nodeId The ID of the node.
         * @param {ExternalclusterV1DrainConfig} config Node drain configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDrainNode: async (clusterId: string, nodeId: string, config: ExternalclusterV1DrainConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIDrainNode', 'clusterId', clusterId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('externalClusterAPIDrainNode', 'nodeId', nodeId)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('externalClusterAPIDrainNode', 'config', config)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/nodes/{nodeId}/drain`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return AWS cross role user for assume role.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetAssumeRolePrincipal: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIGetAssumeRolePrincipal', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/assume-role-principal`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns AWS user arn used for assume role.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetAssumeRoleUser: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIGetAssumeRoleUser', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/assume-role-user`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns single command for external cluster cleanup script
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCleanupScript: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIGetCleanupScript', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/cleanup-script`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns cleanup script for the specified provider.
         * @param {string} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCleanupScriptTemplate: async (provider: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('externalClusterAPIGetCleanupScriptTemplate', 'provider', provider)
            const localVarPath = `/v1/scripts/{provider}/cleanup.sh`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the details of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCluster: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIGetCluster', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns single command for external cluster credentials onboarding.
         * @param {string} clusterId The ID of the cluster.
         * @param {boolean} [crossRole] Whether an AWS CrossRole should be used for authentication.
         * @param {boolean} [nvidiaDevicePlugin] Whether NVIDIA device plugin DaemonSet should be installed during Phase 2 on-boarding.
         * @param {boolean} [installSecurityAgent] Whether CAST AI Security Insights agent should be installed
         * @param {boolean} [installAutoscalerAgent] Whether CAST AI Autoscaler components should be installed. To enable backwards compatibility, when the field is omitted, it is defaulted to true.
         * @param {boolean} [installGpuMetricsExporter] Whether CAST AI GPU metrics exporter should be installed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCredentialsScript: async (clusterId: string, crossRole?: boolean, nvidiaDevicePlugin?: boolean, installSecurityAgent?: boolean, installAutoscalerAgent?: boolean, installGpuMetricsExporter?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIGetCredentialsScript', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/credentials-script`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (crossRole !== undefined) {
                localVarQueryParameter['crossRole'] = crossRole;
            }

            if (nvidiaDevicePlugin !== undefined) {
                localVarQueryParameter['nvidiaDevicePlugin'] = nvidiaDevicePlugin;
            }

            if (installSecurityAgent !== undefined) {
                localVarQueryParameter['installSecurityAgent'] = installSecurityAgent;
            }

            if (installAutoscalerAgent !== undefined) {
                localVarQueryParameter['installAutoscalerAgent'] = installAutoscalerAgent;
            }

            if (installGpuMetricsExporter !== undefined) {
                localVarQueryParameter['installGpuMetricsExporter'] = installGpuMetricsExporter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns credentials onboarding script for the specified provider.
         * @param {string} provider 
         * @param {boolean} [crossRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCredentialsScriptTemplate: async (provider: string, crossRole?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('externalClusterAPIGetCredentialsScriptTemplate', 'provider', provider)
            const localVarPath = `/v1/scripts/{provider}/onboarding.sh`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (crossRole !== undefined) {
                localVarQueryParameter['crossRole'] = crossRole;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of available filters for nodes list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetListNodesFilters: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/kubernetes/external-clusters/filters/nodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a single node by ID.
         * @param {string} clusterId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetNode: async (clusterId: string, nodeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIGetNode', 'clusterId', clusterId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('externalClusterAPIGetNode', 'nodeId', nodeId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/nodes/{nodeId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handles cloud spot interruption events for a cluster.
         * @param {string} clusterId 
         * @param {ExternalclusterV1CloudEvent} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIHandleCloudEvent: async (clusterId: string, event: ExternalclusterV1CloudEvent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIHandleCloudEvent', 'clusterId', clusterId)
            // verify required parameter 'event' is not null or undefined
            assertParamExists('externalClusterAPIHandleCloudEvent', 'event', event)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/events`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(event, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists clusters for current user\'s organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIListClusters: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/kubernetes/external-clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists the nodes for a cluster.
         * @param {string} clusterId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [nodeId] 
         * @param {ExternalClusterAPIListNodesNodeStatusEnum} [nodeStatus] 
         * @param {string} [instanceType] 
         * @param {ExternalClusterAPIListNodesLifecycleTypeEnum} [lifecycleType] 
         * @param {boolean} [removalDisabled] 
         * @param {boolean} [unschedulable] 
         * @param {string} [zone] 
         * @param {string} [nodeConfigurationName] 
         * @param {string} [nodeConfigurationVersion] 
         * @param {string} [nodeTemplateName] 
         * @param {string} [nodeTemplateVersion] 
         * @param {string} [nodeName] 
         * @param {boolean} [excludeDeleting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIListNodes: async (clusterId: string, pageLimit?: string, pageCursor?: string, nodeId?: string, nodeStatus?: ExternalClusterAPIListNodesNodeStatusEnum, instanceType?: string, lifecycleType?: ExternalClusterAPIListNodesLifecycleTypeEnum, removalDisabled?: boolean, unschedulable?: boolean, zone?: string, nodeConfigurationName?: string, nodeConfigurationVersion?: string, nodeTemplateName?: string, nodeTemplateVersion?: string, nodeName?: string, excludeDeleting?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIListNodes', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/nodes`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }

            if (nodeStatus !== undefined) {
                localVarQueryParameter['nodeStatus'] = nodeStatus;
            }

            if (instanceType !== undefined) {
                localVarQueryParameter['instanceType'] = instanceType;
            }

            if (lifecycleType !== undefined) {
                localVarQueryParameter['lifecycleType'] = lifecycleType;
            }

            if (removalDisabled !== undefined) {
                localVarQueryParameter['removalDisabled'] = removalDisabled;
            }

            if (unschedulable !== undefined) {
                localVarQueryParameter['unschedulable'] = unschedulable;
            }

            if (zone !== undefined) {
                localVarQueryParameter['zone'] = zone;
            }

            if (nodeConfigurationName !== undefined) {
                localVarQueryParameter['nodeConfigurationName'] = nodeConfigurationName;
            }

            if (nodeConfigurationVersion !== undefined) {
                localVarQueryParameter['nodeConfigurationVersion'] = nodeConfigurationVersion;
            }

            if (nodeTemplateName !== undefined) {
                localVarQueryParameter['nodeTemplateName'] = nodeTemplateName;
            }

            if (nodeTemplateVersion !== undefined) {
                localVarQueryParameter['nodeTemplateVersion'] = nodeTemplateVersion;
            }

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }

            if (excludeDeleting !== undefined) {
                localVarQueryParameter['excludeDeleting'] = excludeDeleting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reconciles cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIReconcileCluster: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIReconcileCluster', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/reconcile`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers new external cluster.
         * @param {ExternalclusterV1RegisterClusterRequest} body RegisterClusterRequest registers cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIRegisterCluster: async (body: ExternalclusterV1RegisterClusterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('externalClusterAPIRegisterCluster', 'body', body)
            const localVarPath = `/v1/kubernetes/external-clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates configuration of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {ExternalclusterV1ClusterUpdate} update Cluster update configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIUpdateCluster: async (clusterId: string, update: ExternalclusterV1ClusterUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIUpdateCluster', 'clusterId', clusterId)
            // verify required parameter 'update' is not null or undefined
            assertParamExists('externalClusterAPIUpdateCluster', 'update', update)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(update, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates tags of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {{ [key: string]: string; }} tags New cluster tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIUpdateClusterTags: async (clusterId: string, tags: { [key: string]: string; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('externalClusterAPIUpdateClusterTags', 'clusterId', clusterId)
            // verify required parameter 'tags' is not null or undefined
            assertParamExists('externalClusterAPIUpdateClusterTags', 'tags', tags)
            const localVarPath = `/v1/kubernetes/external-clusters/{clusterId}/tags`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tags, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalClusterAPIApi - functional programming interface
 * @export
 */
export const ExternalClusterAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalClusterAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a node to the cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {ExternalclusterV1NodeConfig} config New node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIAddNode(clusterId: string, config: ExternalclusterV1NodeConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1AddNodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIAddNode(clusterId, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIAddNode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create AWS cross role user for assume role.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPICreateAssumeRolePrincipal(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1CreateAssumeRolePrincipalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPICreateAssumeRolePrincipal(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPICreateAssumeRolePrincipal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns cluster token that is used for agent and cluster controller.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPICreateClusterToken(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1CreateClusterTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPICreateClusterToken(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPICreateClusterToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete AWS cross role user for assume role.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIDeleteAssumeRolePrincipal(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIDeleteAssumeRolePrincipal(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIDeleteAssumeRolePrincipal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cluster can be removed only if it was disconnected or is in failed state.
         * @summary Deletes the cluster from CAST console. It won\'t delete the actual cluster from cloud provider\'s console.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIDeleteCluster(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIDeleteCluster(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIDeleteCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes the specified cluster node.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} nodeId The ID of the node.
         * @param {string} [drainTimeout] Node drain timeout in seconds. Defaults to 600s if not set.
         * @param {boolean} [forceDelete] If set to true, node will be deleted even if node fails to be drained gracefully.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIDeleteNode(clusterId: string, nodeId: string, drainTimeout?: string, forceDelete?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1DeleteNodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIDeleteNode(clusterId, nodeId, drainTimeout, forceDelete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIDeleteNode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * CAST provisioned nodes are deleted only when `deleteProvisionedNodes` flag is set. Cloud resources used by CAST provisioned nodes are deleted only if nodes are deleted.
         * @summary Disconnects cluster by removing all CAST dependencies from cloud provider\'s account.
         * @param {string} clusterId The ID of the cluster.
         * @param {ExternalclusterV1DisconnectConfig} config Disconnect cluster configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIDisconnectCluster(clusterId: string, config: ExternalclusterV1DisconnectConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1Cluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIDisconnectCluster(clusterId, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIDisconnectCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Drains the specified cluster node.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} nodeId The ID of the node.
         * @param {ExternalclusterV1DrainConfig} config Node drain configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIDrainNode(clusterId: string, nodeId: string, config: ExternalclusterV1DrainConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1DrainNodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIDrainNode(clusterId, nodeId, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIDrainNode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return AWS cross role user for assume role.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIGetAssumeRolePrincipal(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1GetAssumeRolePrincipalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIGetAssumeRolePrincipal(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIGetAssumeRolePrincipal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns AWS user arn used for assume role.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIGetAssumeRoleUser(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1GetAssumeRoleUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIGetAssumeRoleUser(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIGetAssumeRoleUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns single command for external cluster cleanup script
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIGetCleanupScript(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1GetCleanupScriptResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIGetCleanupScript(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIGetCleanupScript']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns cleanup script for the specified provider.
         * @param {string} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIGetCleanupScriptTemplate(provider: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIGetCleanupScriptTemplate(provider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIGetCleanupScriptTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the details of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIGetCluster(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1Cluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIGetCluster(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIGetCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns single command for external cluster credentials onboarding.
         * @param {string} clusterId The ID of the cluster.
         * @param {boolean} [crossRole] Whether an AWS CrossRole should be used for authentication.
         * @param {boolean} [nvidiaDevicePlugin] Whether NVIDIA device plugin DaemonSet should be installed during Phase 2 on-boarding.
         * @param {boolean} [installSecurityAgent] Whether CAST AI Security Insights agent should be installed
         * @param {boolean} [installAutoscalerAgent] Whether CAST AI Autoscaler components should be installed. To enable backwards compatibility, when the field is omitted, it is defaulted to true.
         * @param {boolean} [installGpuMetricsExporter] Whether CAST AI GPU metrics exporter should be installed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIGetCredentialsScript(clusterId: string, crossRole?: boolean, nvidiaDevicePlugin?: boolean, installSecurityAgent?: boolean, installAutoscalerAgent?: boolean, installGpuMetricsExporter?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1GetCredentialsScriptResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIGetCredentialsScript(clusterId, crossRole, nvidiaDevicePlugin, installSecurityAgent, installAutoscalerAgent, installGpuMetricsExporter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIGetCredentialsScript']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns credentials onboarding script for the specified provider.
         * @param {string} provider 
         * @param {boolean} [crossRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIGetCredentialsScriptTemplate(provider: string, crossRole?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIGetCredentialsScriptTemplate(provider, crossRole, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIGetCredentialsScriptTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns list of available filters for nodes list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIGetListNodesFilters(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1GetListNodesFiltersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIGetListNodesFilters(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIGetListNodesFilters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a single node by ID.
         * @param {string} clusterId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIGetNode(clusterId: string, nodeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1Node>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIGetNode(clusterId, nodeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIGetNode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Handles cloud spot interruption events for a cluster.
         * @param {string} clusterId 
         * @param {ExternalclusterV1CloudEvent} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIHandleCloudEvent(clusterId: string, event: ExternalclusterV1CloudEvent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIHandleCloudEvent(clusterId, event, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIHandleCloudEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Lists clusters for current user\'s organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIListClusters(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1ListClustersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIListClusters(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIListClusters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Lists the nodes for a cluster.
         * @param {string} clusterId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [nodeId] 
         * @param {ExternalClusterAPIListNodesNodeStatusEnum} [nodeStatus] 
         * @param {string} [instanceType] 
         * @param {ExternalClusterAPIListNodesLifecycleTypeEnum} [lifecycleType] 
         * @param {boolean} [removalDisabled] 
         * @param {boolean} [unschedulable] 
         * @param {string} [zone] 
         * @param {string} [nodeConfigurationName] 
         * @param {string} [nodeConfigurationVersion] 
         * @param {string} [nodeTemplateName] 
         * @param {string} [nodeTemplateVersion] 
         * @param {string} [nodeName] 
         * @param {boolean} [excludeDeleting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIListNodes(clusterId: string, pageLimit?: string, pageCursor?: string, nodeId?: string, nodeStatus?: ExternalClusterAPIListNodesNodeStatusEnum, instanceType?: string, lifecycleType?: ExternalClusterAPIListNodesLifecycleTypeEnum, removalDisabled?: boolean, unschedulable?: boolean, zone?: string, nodeConfigurationName?: string, nodeConfigurationVersion?: string, nodeTemplateName?: string, nodeTemplateVersion?: string, nodeName?: string, excludeDeleting?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1ListNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIListNodes(clusterId, pageLimit, pageCursor, nodeId, nodeStatus, instanceType, lifecycleType, removalDisabled, unschedulable, zone, nodeConfigurationName, nodeConfigurationVersion, nodeTemplateName, nodeTemplateVersion, nodeName, excludeDeleting, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIListNodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reconciles cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIReconcileCluster(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIReconcileCluster(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIReconcileCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Registers new external cluster.
         * @param {ExternalclusterV1RegisterClusterRequest} body RegisterClusterRequest registers cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIRegisterCluster(body: ExternalclusterV1RegisterClusterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1Cluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIRegisterCluster(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIRegisterCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates configuration of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {ExternalclusterV1ClusterUpdate} update Cluster update configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIUpdateCluster(clusterId: string, update: ExternalclusterV1ClusterUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalclusterV1Cluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIUpdateCluster(clusterId, update, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIUpdateCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates tags of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {{ [key: string]: string; }} tags New cluster tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalClusterAPIUpdateClusterTags(clusterId: string, tags: { [key: string]: string; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalClusterAPIUpdateClusterTags(clusterId, tags, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalClusterAPIApi.externalClusterAPIUpdateClusterTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExternalClusterAPIApi - factory interface
 * @export
 */
export const ExternalClusterAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalClusterAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a node to the cluster.
         * @param {ExternalClusterAPIApiExternalClusterAPIAddNodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIAddNode(requestParameters: ExternalClusterAPIApiExternalClusterAPIAddNodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1AddNodeResponse> {
            return localVarFp.externalClusterAPIAddNode(requestParameters.clusterId, requestParameters.config, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create AWS cross role user for assume role.
         * @param {ExternalClusterAPIApiExternalClusterAPICreateAssumeRolePrincipalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPICreateAssumeRolePrincipal(requestParameters: ExternalClusterAPIApiExternalClusterAPICreateAssumeRolePrincipalRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1CreateAssumeRolePrincipalResponse> {
            return localVarFp.externalClusterAPICreateAssumeRolePrincipal(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns cluster token that is used for agent and cluster controller.
         * @param {ExternalClusterAPIApiExternalClusterAPICreateClusterTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPICreateClusterToken(requestParameters: ExternalClusterAPIApiExternalClusterAPICreateClusterTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1CreateClusterTokenResponse> {
            return localVarFp.externalClusterAPICreateClusterToken(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete AWS cross role user for assume role.
         * @param {ExternalClusterAPIApiExternalClusterAPIDeleteAssumeRolePrincipalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDeleteAssumeRolePrincipal(requestParameters: ExternalClusterAPIApiExternalClusterAPIDeleteAssumeRolePrincipalRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.externalClusterAPIDeleteAssumeRolePrincipal(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Cluster can be removed only if it was disconnected or is in failed state.
         * @summary Deletes the cluster from CAST console. It won\'t delete the actual cluster from cloud provider\'s console.
         * @param {ExternalClusterAPIApiExternalClusterAPIDeleteClusterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDeleteCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIDeleteClusterRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.externalClusterAPIDeleteCluster(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the specified cluster node.
         * @param {ExternalClusterAPIApiExternalClusterAPIDeleteNodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDeleteNode(requestParameters: ExternalClusterAPIApiExternalClusterAPIDeleteNodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1DeleteNodeResponse> {
            return localVarFp.externalClusterAPIDeleteNode(requestParameters.clusterId, requestParameters.nodeId, requestParameters.drainTimeout, requestParameters.forceDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * CAST provisioned nodes are deleted only when `deleteProvisionedNodes` flag is set. Cloud resources used by CAST provisioned nodes are deleted only if nodes are deleted.
         * @summary Disconnects cluster by removing all CAST dependencies from cloud provider\'s account.
         * @param {ExternalClusterAPIApiExternalClusterAPIDisconnectClusterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDisconnectCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIDisconnectClusterRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1Cluster> {
            return localVarFp.externalClusterAPIDisconnectCluster(requestParameters.clusterId, requestParameters.config, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Drains the specified cluster node.
         * @param {ExternalClusterAPIApiExternalClusterAPIDrainNodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIDrainNode(requestParameters: ExternalClusterAPIApiExternalClusterAPIDrainNodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1DrainNodeResponse> {
            return localVarFp.externalClusterAPIDrainNode(requestParameters.clusterId, requestParameters.nodeId, requestParameters.config, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return AWS cross role user for assume role.
         * @param {ExternalClusterAPIApiExternalClusterAPIGetAssumeRolePrincipalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetAssumeRolePrincipal(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetAssumeRolePrincipalRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1GetAssumeRolePrincipalResponse> {
            return localVarFp.externalClusterAPIGetAssumeRolePrincipal(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns AWS user arn used for assume role.
         * @param {ExternalClusterAPIApiExternalClusterAPIGetAssumeRoleUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetAssumeRoleUser(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetAssumeRoleUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1GetAssumeRoleUserResponse> {
            return localVarFp.externalClusterAPIGetAssumeRoleUser(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns single command for external cluster cleanup script
         * @param {ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCleanupScript(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1GetCleanupScriptResponse> {
            return localVarFp.externalClusterAPIGetCleanupScript(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns cleanup script for the specified provider.
         * @param {ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCleanupScriptTemplate(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptTemplateRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.externalClusterAPIGetCleanupScriptTemplate(requestParameters.provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the details of a specific cluster.
         * @param {ExternalClusterAPIApiExternalClusterAPIGetClusterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetClusterRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1Cluster> {
            return localVarFp.externalClusterAPIGetCluster(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns single command for external cluster credentials onboarding.
         * @param {ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCredentialsScript(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1GetCredentialsScriptResponse> {
            return localVarFp.externalClusterAPIGetCredentialsScript(requestParameters.clusterId, requestParameters.crossRole, requestParameters.nvidiaDevicePlugin, requestParameters.installSecurityAgent, requestParameters.installAutoscalerAgent, requestParameters.installGpuMetricsExporter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns credentials onboarding script for the specified provider.
         * @param {ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetCredentialsScriptTemplate(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptTemplateRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.externalClusterAPIGetCredentialsScriptTemplate(requestParameters.provider, requestParameters.crossRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of available filters for nodes list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetListNodesFilters(options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1GetListNodesFiltersResponse> {
            return localVarFp.externalClusterAPIGetListNodesFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a single node by ID.
         * @param {ExternalClusterAPIApiExternalClusterAPIGetNodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIGetNode(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetNodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1Node> {
            return localVarFp.externalClusterAPIGetNode(requestParameters.clusterId, requestParameters.nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handles cloud spot interruption events for a cluster.
         * @param {ExternalClusterAPIApiExternalClusterAPIHandleCloudEventRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIHandleCloudEvent(requestParameters: ExternalClusterAPIApiExternalClusterAPIHandleCloudEventRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.externalClusterAPIHandleCloudEvent(requestParameters.clusterId, requestParameters.event, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists clusters for current user\'s organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIListClusters(options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1ListClustersResponse> {
            return localVarFp.externalClusterAPIListClusters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists the nodes for a cluster.
         * @param {ExternalClusterAPIApiExternalClusterAPIListNodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIListNodes(requestParameters: ExternalClusterAPIApiExternalClusterAPIListNodesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1ListNodesResponse> {
            return localVarFp.externalClusterAPIListNodes(requestParameters.clusterId, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.nodeId, requestParameters.nodeStatus, requestParameters.instanceType, requestParameters.lifecycleType, requestParameters.removalDisabled, requestParameters.unschedulable, requestParameters.zone, requestParameters.nodeConfigurationName, requestParameters.nodeConfigurationVersion, requestParameters.nodeTemplateName, requestParameters.nodeTemplateVersion, requestParameters.nodeName, requestParameters.excludeDeleting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reconciles cluster.
         * @param {ExternalClusterAPIApiExternalClusterAPIReconcileClusterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIReconcileCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIReconcileClusterRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.externalClusterAPIReconcileCluster(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers new external cluster.
         * @param {ExternalClusterAPIApiExternalClusterAPIRegisterClusterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIRegisterCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIRegisterClusterRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1Cluster> {
            return localVarFp.externalClusterAPIRegisterCluster(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates configuration of a specific cluster.
         * @param {ExternalClusterAPIApiExternalClusterAPIUpdateClusterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIUpdateCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIUpdateClusterRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalclusterV1Cluster> {
            return localVarFp.externalClusterAPIUpdateCluster(requestParameters.clusterId, requestParameters.update, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates tags of a specific cluster.
         * @param {ExternalClusterAPIApiExternalClusterAPIUpdateClusterTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalClusterAPIUpdateClusterTags(requestParameters: ExternalClusterAPIApiExternalClusterAPIUpdateClusterTagsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.externalClusterAPIUpdateClusterTags(requestParameters.clusterId, requestParameters.tags, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for externalClusterAPIAddNode operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIAddNodeRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIAddNodeRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIAddNode
     */
    readonly clusterId: string

    /**
     * New node configuration.
     * @type {ExternalclusterV1NodeConfig}
     * @memberof ExternalClusterAPIApiExternalClusterAPIAddNode
     */
    readonly config: ExternalclusterV1NodeConfig
}

/**
 * Request parameters for externalClusterAPICreateAssumeRolePrincipal operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPICreateAssumeRolePrincipalRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPICreateAssumeRolePrincipalRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPICreateAssumeRolePrincipal
     */
    readonly clusterId: string
}

/**
 * Request parameters for externalClusterAPICreateClusterToken operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPICreateClusterTokenRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPICreateClusterTokenRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPICreateClusterToken
     */
    readonly clusterId: string
}

/**
 * Request parameters for externalClusterAPIDeleteAssumeRolePrincipal operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIDeleteAssumeRolePrincipalRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIDeleteAssumeRolePrincipalRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDeleteAssumeRolePrincipal
     */
    readonly clusterId: string
}

/**
 * Request parameters for externalClusterAPIDeleteCluster operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIDeleteClusterRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIDeleteClusterRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDeleteCluster
     */
    readonly clusterId: string
}

/**
 * Request parameters for externalClusterAPIDeleteNode operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIDeleteNodeRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIDeleteNodeRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDeleteNode
     */
    readonly clusterId: string

    /**
     * The ID of the node.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDeleteNode
     */
    readonly nodeId: string

    /**
     * Node drain timeout in seconds. Defaults to 600s if not set.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDeleteNode
     */
    readonly drainTimeout?: string

    /**
     * If set to true, node will be deleted even if node fails to be drained gracefully.
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDeleteNode
     */
    readonly forceDelete?: boolean
}

/**
 * Request parameters for externalClusterAPIDisconnectCluster operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIDisconnectClusterRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIDisconnectClusterRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDisconnectCluster
     */
    readonly clusterId: string

    /**
     * Disconnect cluster configuration.
     * @type {ExternalclusterV1DisconnectConfig}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDisconnectCluster
     */
    readonly config: ExternalclusterV1DisconnectConfig
}

/**
 * Request parameters for externalClusterAPIDrainNode operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIDrainNodeRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIDrainNodeRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDrainNode
     */
    readonly clusterId: string

    /**
     * The ID of the node.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDrainNode
     */
    readonly nodeId: string

    /**
     * Node drain configuration.
     * @type {ExternalclusterV1DrainConfig}
     * @memberof ExternalClusterAPIApiExternalClusterAPIDrainNode
     */
    readonly config: ExternalclusterV1DrainConfig
}

/**
 * Request parameters for externalClusterAPIGetAssumeRolePrincipal operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIGetAssumeRolePrincipalRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIGetAssumeRolePrincipalRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetAssumeRolePrincipal
     */
    readonly clusterId: string
}

/**
 * Request parameters for externalClusterAPIGetAssumeRoleUser operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIGetAssumeRoleUserRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIGetAssumeRoleUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetAssumeRoleUser
     */
    readonly clusterId: string
}

/**
 * Request parameters for externalClusterAPIGetCleanupScript operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCleanupScript
     */
    readonly clusterId: string
}

/**
 * Request parameters for externalClusterAPIGetCleanupScriptTemplate operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptTemplateRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptTemplate
     */
    readonly provider: string
}

/**
 * Request parameters for externalClusterAPIGetCluster operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIGetClusterRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIGetClusterRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCluster
     */
    readonly clusterId: string
}

/**
 * Request parameters for externalClusterAPIGetCredentialsScript operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCredentialsScript
     */
    readonly clusterId: string

    /**
     * Whether an AWS CrossRole should be used for authentication.
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCredentialsScript
     */
    readonly crossRole?: boolean

    /**
     * Whether NVIDIA device plugin DaemonSet should be installed during Phase 2 on-boarding.
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCredentialsScript
     */
    readonly nvidiaDevicePlugin?: boolean

    /**
     * Whether CAST AI Security Insights agent should be installed
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCredentialsScript
     */
    readonly installSecurityAgent?: boolean

    /**
     * Whether CAST AI Autoscaler components should be installed. To enable backwards compatibility, when the field is omitted, it is defaulted to true.
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCredentialsScript
     */
    readonly installAutoscalerAgent?: boolean

    /**
     * Whether CAST AI GPU metrics exporter should be installed
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCredentialsScript
     */
    readonly installGpuMetricsExporter?: boolean
}

/**
 * Request parameters for externalClusterAPIGetCredentialsScriptTemplate operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptTemplateRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptTemplate
     */
    readonly provider: string

    /**
     * 
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptTemplate
     */
    readonly crossRole?: boolean
}

/**
 * Request parameters for externalClusterAPIGetNode operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIGetNodeRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIGetNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetNode
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIGetNode
     */
    readonly nodeId: string
}

/**
 * Request parameters for externalClusterAPIHandleCloudEvent operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIHandleCloudEventRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIHandleCloudEventRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIHandleCloudEvent
     */
    readonly clusterId: string

    /**
     * 
     * @type {ExternalclusterV1CloudEvent}
     * @memberof ExternalClusterAPIApiExternalClusterAPIHandleCloudEvent
     */
    readonly event: ExternalclusterV1CloudEvent
}

/**
 * Request parameters for externalClusterAPIListNodes operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIListNodesRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIListNodesRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly pageCursor?: string

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly nodeId?: string

    /**
     * 
     * @type {'node_status_unspecified' | 'unknown' | 'pending' | 'creating' | 'ready' | 'not_ready' | 'draining' | 'deleting' | 'deleted' | 'interrupted' | 'cordoned'}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly nodeStatus?: ExternalClusterAPIListNodesNodeStatusEnum

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly instanceType?: string

    /**
     * 
     * @type {'lifecycle_type_unspecified' | 'on_demand' | 'fallback' | 'spot'}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly lifecycleType?: ExternalClusterAPIListNodesLifecycleTypeEnum

    /**
     * 
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly removalDisabled?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly unschedulable?: boolean

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly zone?: string

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly nodeConfigurationName?: string

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly nodeConfigurationVersion?: string

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly nodeTemplateName?: string

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly nodeTemplateVersion?: string

    /**
     * 
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly nodeName?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExternalClusterAPIApiExternalClusterAPIListNodes
     */
    readonly excludeDeleting?: boolean
}

/**
 * Request parameters for externalClusterAPIReconcileCluster operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIReconcileClusterRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIReconcileClusterRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIReconcileCluster
     */
    readonly clusterId: string
}

/**
 * Request parameters for externalClusterAPIRegisterCluster operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIRegisterClusterRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIRegisterClusterRequest {
    /**
     * RegisterClusterRequest registers cluster.
     * @type {ExternalclusterV1RegisterClusterRequest}
     * @memberof ExternalClusterAPIApiExternalClusterAPIRegisterCluster
     */
    readonly body: ExternalclusterV1RegisterClusterRequest
}

/**
 * Request parameters for externalClusterAPIUpdateCluster operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIUpdateClusterRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIUpdateClusterRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIUpdateCluster
     */
    readonly clusterId: string

    /**
     * Cluster update configuration.
     * @type {ExternalclusterV1ClusterUpdate}
     * @memberof ExternalClusterAPIApiExternalClusterAPIUpdateCluster
     */
    readonly update: ExternalclusterV1ClusterUpdate
}

/**
 * Request parameters for externalClusterAPIUpdateClusterTags operation in ExternalClusterAPIApi.
 * @export
 * @interface ExternalClusterAPIApiExternalClusterAPIUpdateClusterTagsRequest
 */
export interface ExternalClusterAPIApiExternalClusterAPIUpdateClusterTagsRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ExternalClusterAPIApiExternalClusterAPIUpdateClusterTags
     */
    readonly clusterId: string

    /**
     * New cluster tags
     * @type {{ [key: string]: string; }}
     * @memberof ExternalClusterAPIApiExternalClusterAPIUpdateClusterTags
     */
    readonly tags: { [key: string]: string; }
}

/**
 * ExternalClusterAPIApi - object-oriented interface
 * @export
 * @class ExternalClusterAPIApi
 * @extends {BaseAPI}
 */
export class ExternalClusterAPIApi extends BaseAPI {
    /**
     * 
     * @summary Adds a node to the cluster.
     * @param {ExternalClusterAPIApiExternalClusterAPIAddNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIAddNode(requestParameters: ExternalClusterAPIApiExternalClusterAPIAddNodeRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIAddNode(requestParameters.clusterId, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create AWS cross role user for assume role.
     * @param {ExternalClusterAPIApiExternalClusterAPICreateAssumeRolePrincipalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPICreateAssumeRolePrincipal(requestParameters: ExternalClusterAPIApiExternalClusterAPICreateAssumeRolePrincipalRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPICreateAssumeRolePrincipal(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns cluster token that is used for agent and cluster controller.
     * @param {ExternalClusterAPIApiExternalClusterAPICreateClusterTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPICreateClusterToken(requestParameters: ExternalClusterAPIApiExternalClusterAPICreateClusterTokenRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPICreateClusterToken(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete AWS cross role user for assume role.
     * @param {ExternalClusterAPIApiExternalClusterAPIDeleteAssumeRolePrincipalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIDeleteAssumeRolePrincipal(requestParameters: ExternalClusterAPIApiExternalClusterAPIDeleteAssumeRolePrincipalRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIDeleteAssumeRolePrincipal(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cluster can be removed only if it was disconnected or is in failed state.
     * @summary Deletes the cluster from CAST console. It won\'t delete the actual cluster from cloud provider\'s console.
     * @param {ExternalClusterAPIApiExternalClusterAPIDeleteClusterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIDeleteCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIDeleteClusterRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIDeleteCluster(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the specified cluster node.
     * @param {ExternalClusterAPIApiExternalClusterAPIDeleteNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIDeleteNode(requestParameters: ExternalClusterAPIApiExternalClusterAPIDeleteNodeRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIDeleteNode(requestParameters.clusterId, requestParameters.nodeId, requestParameters.drainTimeout, requestParameters.forceDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CAST provisioned nodes are deleted only when `deleteProvisionedNodes` flag is set. Cloud resources used by CAST provisioned nodes are deleted only if nodes are deleted.
     * @summary Disconnects cluster by removing all CAST dependencies from cloud provider\'s account.
     * @param {ExternalClusterAPIApiExternalClusterAPIDisconnectClusterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIDisconnectCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIDisconnectClusterRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIDisconnectCluster(requestParameters.clusterId, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Drains the specified cluster node.
     * @param {ExternalClusterAPIApiExternalClusterAPIDrainNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIDrainNode(requestParameters: ExternalClusterAPIApiExternalClusterAPIDrainNodeRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIDrainNode(requestParameters.clusterId, requestParameters.nodeId, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return AWS cross role user for assume role.
     * @param {ExternalClusterAPIApiExternalClusterAPIGetAssumeRolePrincipalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIGetAssumeRolePrincipal(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetAssumeRolePrincipalRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIGetAssumeRolePrincipal(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns AWS user arn used for assume role.
     * @param {ExternalClusterAPIApiExternalClusterAPIGetAssumeRoleUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIGetAssumeRoleUser(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetAssumeRoleUserRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIGetAssumeRoleUser(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns single command for external cluster cleanup script
     * @param {ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIGetCleanupScript(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIGetCleanupScript(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns cleanup script for the specified provider.
     * @param {ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIGetCleanupScriptTemplate(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetCleanupScriptTemplateRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIGetCleanupScriptTemplate(requestParameters.provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the details of a specific cluster.
     * @param {ExternalClusterAPIApiExternalClusterAPIGetClusterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIGetCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetClusterRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIGetCluster(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns single command for external cluster credentials onboarding.
     * @param {ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIGetCredentialsScript(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIGetCredentialsScript(requestParameters.clusterId, requestParameters.crossRole, requestParameters.nvidiaDevicePlugin, requestParameters.installSecurityAgent, requestParameters.installAutoscalerAgent, requestParameters.installGpuMetricsExporter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns credentials onboarding script for the specified provider.
     * @param {ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIGetCredentialsScriptTemplate(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetCredentialsScriptTemplateRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIGetCredentialsScriptTemplate(requestParameters.provider, requestParameters.crossRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of available filters for nodes list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIGetListNodesFilters(options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIGetListNodesFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a single node by ID.
     * @param {ExternalClusterAPIApiExternalClusterAPIGetNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIGetNode(requestParameters: ExternalClusterAPIApiExternalClusterAPIGetNodeRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIGetNode(requestParameters.clusterId, requestParameters.nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handles cloud spot interruption events for a cluster.
     * @param {ExternalClusterAPIApiExternalClusterAPIHandleCloudEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIHandleCloudEvent(requestParameters: ExternalClusterAPIApiExternalClusterAPIHandleCloudEventRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIHandleCloudEvent(requestParameters.clusterId, requestParameters.event, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists clusters for current user\'s organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIListClusters(options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIListClusters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists the nodes for a cluster.
     * @param {ExternalClusterAPIApiExternalClusterAPIListNodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIListNodes(requestParameters: ExternalClusterAPIApiExternalClusterAPIListNodesRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIListNodes(requestParameters.clusterId, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.nodeId, requestParameters.nodeStatus, requestParameters.instanceType, requestParameters.lifecycleType, requestParameters.removalDisabled, requestParameters.unschedulable, requestParameters.zone, requestParameters.nodeConfigurationName, requestParameters.nodeConfigurationVersion, requestParameters.nodeTemplateName, requestParameters.nodeTemplateVersion, requestParameters.nodeName, requestParameters.excludeDeleting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reconciles cluster.
     * @param {ExternalClusterAPIApiExternalClusterAPIReconcileClusterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIReconcileCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIReconcileClusterRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIReconcileCluster(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers new external cluster.
     * @param {ExternalClusterAPIApiExternalClusterAPIRegisterClusterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIRegisterCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIRegisterClusterRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIRegisterCluster(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates configuration of a specific cluster.
     * @param {ExternalClusterAPIApiExternalClusterAPIUpdateClusterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIUpdateCluster(requestParameters: ExternalClusterAPIApiExternalClusterAPIUpdateClusterRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIUpdateCluster(requestParameters.clusterId, requestParameters.update, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates tags of a specific cluster.
     * @param {ExternalClusterAPIApiExternalClusterAPIUpdateClusterTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalClusterAPIApi
     */
    public externalClusterAPIUpdateClusterTags(requestParameters: ExternalClusterAPIApiExternalClusterAPIUpdateClusterTagsRequest, options?: RawAxiosRequestConfig) {
        return ExternalClusterAPIApiFp(this.configuration).externalClusterAPIUpdateClusterTags(requestParameters.clusterId, requestParameters.tags, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ExternalClusterAPIListNodesNodeStatusEnum = {
    node_status_unspecified: 'node_status_unspecified',
    unknown: 'unknown',
    pending: 'pending',
    creating: 'creating',
    ready: 'ready',
    not_ready: 'not_ready',
    draining: 'draining',
    deleting: 'deleting',
    deleted: 'deleted',
    interrupted: 'interrupted',
    cordoned: 'cordoned'
} as const;
export type ExternalClusterAPIListNodesNodeStatusEnum = typeof ExternalClusterAPIListNodesNodeStatusEnum[keyof typeof ExternalClusterAPIListNodesNodeStatusEnum];
/**
 * @export
 */
export const ExternalClusterAPIListNodesLifecycleTypeEnum = {
    lifecycle_type_unspecified: 'lifecycle_type_unspecified',
    on_demand: 'on_demand',
    fallback: 'fallback',
    spot: 'spot'
} as const;
export type ExternalClusterAPIListNodesLifecycleTypeEnum = typeof ExternalClusterAPIListNodesLifecycleTypeEnum[keyof typeof ExternalClusterAPIListNodesLifecycleTypeEnum];
