import { ReactNode } from 'react';

import { useRBAC } from './hooks';
import { PermissionType } from './types';

type Props = {
  permissionType: `${PermissionType}` | `${PermissionType}`[];
  fallback?: ReactNode;
  children: ((permission?: boolean) => ReactNode) | ReactNode;
};

export const RBACGuard = ({ permissionType, fallback, children }: Props) => {
  const permission = useRBAC(
    permissionType as PermissionType | PermissionType[]
  );
  const hasPermission = Array.isArray(permission)
    ? permission.includes(true)
    : permission;

  if (fallback !== undefined && !hasPermission) {
    return <>{fallback}</>;
  }

  if (typeof children === 'function') {
    return children(hasPermission);
  }

  if (hasPermission) {
    return <>{children}</>;
  }
};
