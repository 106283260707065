import flatMap from 'lodash/flatMap';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';

export const getAllKeys = (obj: any): string[] => {
  return flatMap(keys(obj), (key) => {
    if (isObject(obj[key])) {
      return [
        ...getAllKeys(obj[key]).map(
          (nestedKey: string) => `${key}.${nestedKey}`
        ),
      ];
    }
    return key;
  });
};
