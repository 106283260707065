import { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';

import { NodeType } from 'types/nodes';
import { readStorage, writeStorage } from 'utils/storage';

import { ComputeSpendMode } from '../../../cost-report/types/costOverTime';

const STORE_KEY_BASE = 'cost-report.cluster.compute-spend';

type ReducerState = {
  chartNodeType: Record<NodeType, boolean>;
  chartMode: ComputeSpendMode;
};

type ReducerAction =
  | { type: 'toggleNodeType'; chartNodeType: NodeType }
  | { type: 'changeMode'; chartMode: ComputeSpendMode };

type Props = PropsWithChildren<unknown>;

const initialState: ReducerState = {
  chartNodeType: readStorage(`${STORE_KEY_BASE}.chart-type`, {
    [NodeType.ON_DEMAND]: true,
    [NodeType.FALLBACK]: true,
    [NodeType.SPOT]: true,
  }),
  chartMode: readStorage(
    `${STORE_KEY_BASE}.chart-mode`,
    ComputeSpendMode.DAILY_COST
  ),
};

type ComputeSpendContextValue = [ReducerState, Dispatch<ReducerAction>];

export const ComputeSpendContext = createContext<ComputeSpendContextValue>(
  [] as never
);

function reducer(state: ReducerState, action: ReducerAction) {
  switch (action.type) {
    case 'toggleNodeType': {
      const chartNodeType = {
        ...state.chartNodeType,
        [action.chartNodeType]: !state.chartNodeType[action.chartNodeType],
      };
      writeStorage(`${STORE_KEY_BASE}.chart-type`, chartNodeType);
      return {
        ...state,
        chartNodeType,
      };
    }
    case 'changeMode': {
      writeStorage(`${STORE_KEY_BASE}.chart-mode`, action.chartMode);
      return {
        ...state,
        chartMode: action.chartMode,
      };
    }
  }
}

export const ComputeSpendProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ComputeSpendContext.Provider value={[state, dispatch]}>
      {children}
    </ComputeSpendContext.Provider>
  );
};
