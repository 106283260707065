import {
  Children,
  isValidElement,
  cloneElement,
  forwardRef,
  Fragment,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { Box } from '@mui/material';
import clsx from 'clsx';

import {
  mergeSx,
  Drawer as DsDrawer,
  DrawerProps,
  DrawerOverlay,
} from '@cast/design-system';
import { useSearchParamState } from '@cast/utils';

import { useChatVisibilityToggle } from 'hooks/useChatVisibilityToggle';

import { DrawerContent } from './DrawerContent';
import { DrawerContext } from './DrawerContext';
import { DrawerFooter } from './DrawerFooter';
import { DrawerHeader, DrawerHeaderProps } from './DrawerHeader';
import { DrawerScroller } from './DrawerScroller';
import { SubDrawer } from './SubDrawer';

type ActiveSubdrawerState = { id: string; args?: string };

type Props = DrawerProps & {
  isLoading?: boolean;
  persistSubdrawersToUrl?: string;
  overlayWhenSubdrawerActive?: boolean;
  testId?: string;
};

export const Drawer = forwardRef<HTMLDivElement, Props>(
  (
    {
      open,
      children,
      sx,
      className,
      onClose,
      isLoading,
      persistSubdrawersToUrl,
      overlayWhenSubdrawerActive,
      ...props
    }: Props,
    ref
  ) => {
    useChatVisibilityToggle(!!open);
    const [isScrolledContent, setIsScrolledContent] = useState(false);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    let isHeaderSticky = true;
    let drawerHeader: ReactElement | null = null;
    let drawerContent: ReactElement | null = null;
    let drawerFooter: ReactElement | null = null;
    let drawerOverlay: ReactElement | null = null;
    let stickyFooter = false;
    const subDrawers: Record<string, ReactElement> = {};
    Children.forEach(children, (c) => {
      if (isValidElement(c)) {
        if (c.type === DrawerHeader) {
          drawerHeader = cloneElement(c, { onClose } as DrawerHeaderProps);
          isHeaderSticky =
            typeof c.props.sticky === 'undefined' ? true : !!c.props.sticky;
        } else if (c.type === DrawerContent) {
          drawerContent = c;
        } else if (c.type === DrawerFooter) {
          drawerFooter = c;
          stickyFooter = c.props.sticky;
        } else if (c.type === DrawerOverlay) {
          drawerOverlay = c;
        } else if (c.type === SubDrawer) {
          subDrawers[c.props.id] = c;
        }
      }
    });

    const { state: subdrawerState, setState } =
      useSearchParamState<ActiveSubdrawerState>(
        persistSubdrawersToUrl,
        undefined,
        'object'
      );

    const setActiveSubDrawer = (subdrawerId?: string, args?: string) => {
      if (!subdrawerId) {
        setState(undefined);
      } else {
        const body: ActiveSubdrawerState = { id: subdrawerId };
        if (args) {
          body.args = args;
        }
        setState(body);
      }
    };

    const [isOpen, setIsOpen] = useState(false);

    // Fix feature/bug https://github.com/mui/material-ui/issues/10587
    useEffect(() => setIsOpen(!!open), [open]);

    const overlayMainDrawer = subdrawerState?.id && overlayWhenSubdrawerActive;

    return (
      <DsDrawer
        ref={ref}
        variant="temporary"
        anchor="right"
        BackdropProps={{ invisible: true }}
        open={isOpen}
        sx={mergeSx(
          {
            '& .MuiPaper-root': {
              display: 'flex',
              flexDirection: 'row',
              overflow: 'hidden',
              transition: 'width 500ms',
            },
          },
          sx
        )}
        className={clsx(
          'Drawer-root',
          stickyFooter && 'Drawer-stickyFooter',
          isScrolledContent && 'Drawer-scrolledContent',
          isScrolledToBottom && 'Drawer-scrolledToBottom',
          className
        )}
        onClose={(a, b) => {
          setActiveSubDrawer(undefined);
          onClose?.(a, b);
        }}
        {...props}
        size={!subDrawers.length ? props.size : undefined}
      >
        <DrawerContext.Provider
          value={{
            isLoading,
            activeSubDrawer: subdrawerState?.id,
            activeSubDrawerArgs: subdrawerState?.args,
            setActiveSubDrawer,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            position={overlayMainDrawer ? 'relative' : undefined}
            onClick={() => subdrawerState?.id && setActiveSubDrawer(undefined)}
          >
            {isHeaderSticky && drawerHeader}
            <Box width="100%" flexGrow={1} minHeight={0}>
              {stickyFooter ? (
                <Box height="100%" display="flex" flexDirection="column">
                  <DrawerScroller
                    flex="0 1 auto"
                    minHeight={0}
                    setIsScrolledContent={setIsScrolledContent}
                    setIsScrolledToBottom={setIsScrolledToBottom}
                  >
                    {!isHeaderSticky && drawerHeader}
                    {drawerContent}
                  </DrawerScroller>
                  {drawerFooter}
                </Box>
              ) : (
                <DrawerScroller
                  height="100%"
                  setIsScrolledContent={setIsScrolledContent}
                  setIsScrolledToBottom={setIsScrolledToBottom}
                >
                  {!isHeaderSticky && drawerHeader}
                  {drawerContent}
                  {drawerFooter}
                </DrawerScroller>
              )}
            </Box>
            {overlayMainDrawer && (
              <Box
                className="Drawer-clickOverlay"
                position="absolute"
                sx={{ inset: 0 }}
                bgcolor="transparent"
              />
            )}
          </Box>
          {Object.values(subDrawers).map((value) => (
            <Fragment key={value.props.id}>{value}</Fragment>
          ))}
          {drawerOverlay}
        </DrawerContext.Provider>
      </DsDrawer>
    );
  }
);

Drawer.displayName = 'Drawer';
