import { SxProps } from '@mui/material';

import { IconButton, Icons, mergeSx } from '@cast/design-system';

type Props = {
  onClose?: () => void;
  sx?: SxProps;
};

export const CloseDialogButton = ({ onClose, sx }: Props) => {
  if (!onClose) {
    return null;
  }

  return (
    <IconButton
      variant="ghost"
      size="small"
      onClick={onClose}
      sx={mergeSx({ position: 'absolute', top: 10, right: 8 }, sx)}
      testId="close-dialog-button"
    >
      <Icons.X />
    </IconButton>
  );
};
