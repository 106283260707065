import { useEffect, useMemo, useRef } from 'react';

import { SxProps } from '@mui/system';
import isEqual from 'lodash/isEqual';

import {
  Autocomplete,
  ListItem,
  ListItemProps,
  ListItemText,
  Skeleton,
  mergeSx,
} from '@cast/design-system';

import { useSearchCriterion } from 'components/search';

import { nonNamespacedLabel } from '../../constants';

export type NamespaceSelectBaseProps = {
  namespaces: string[];
  isLoading?: boolean;
  sx?: SxProps;
  listSx?: SxProps;
  criterion?: string;
  analyticsHandler?: (values: string[]) => void;
  initialValue?: string[];
};

export const NamespaceSelectBase = ({
  namespaces,
  isLoading,
  sx,
  listSx,
  criterion = 'namespaces',
  analyticsHandler,
  initialValue,
}: NamespaceSelectBaseProps) => {
  const { setValue, value, clear } = useSearchCriterion(criterion);

  const options = useMemo(() => {
    return namespaces.map((namespace) => namespace || nonNamespacedLabel);
  }, [namespaces]);

  const handleChange = (value: string | string[] | undefined) => {
    if (Array.isArray(value) && value.length > 0) {
      setValue?.(value);
      analyticsHandler?.(value);
    } else {
      clear?.();
    }
  };

  const wasInitialized = useRef(false);
  useEffect(() => {
    if (!initialValue || wasInitialized.current) {
      return;
    }
    setValue?.(initialValue);
    wasInitialized.current = true;
  }, [initialValue, setValue]);

  if (isLoading) {
    return <Skeleton height={32} sx={mergeSx({ width: '100%' }, sx)} />;
  }

  return (
    <Autocomplete
      testId="namespace-select"
      size="medium"
      sx={mergeSx({ width: '100%' }, sx)}
      listSx={listSx}
      inputText="Namespaces"
      checkable
      multiple
      showSelectAll
      showClearAll
      value={value}
      onChange={handleChange}
      options={options}
      renderOption={(namespace, listItemProps: ListItemProps) => {
        return (
          <ListItem
            {...listItemProps}
            sx={{
              background: 'none!important',
              pointerEvents: 'auto!important',
            }}
            testId={
              'ns-option-' + (namespace !== '' ? namespace : nonNamespacedLabel)
            }
          >
            <ListItemText primary={namespace} />
          </ListItem>
        );
      }}
      isOptionEqualToValue={(namespace: string, value: string) => {
        return isEqual(namespace, value);
      }}
      applySortingWhileSearching={false}
      showSelectedOptionsFirst
    />
  );
};
