import seedrandom from 'seedrandom';

import { ConvertableToDateTime, TimeUnit } from '@cast/types';

import { timeRange } from './date';
import { shiftNumber } from './number';

let currentCount = 1;

export const pseudoUnique = () => {
  if (Number.MAX_SAFE_INTEGER === currentCount) {
    currentCount = 1;
    return currentCount;
  }
  return ++currentCount;
};

export type CreateRandomizerArgs = {
  token?: string;
  options?: {
    min?: number;
    max?: number;
    fractional?: boolean;
  };
};

export const createRandomizer = ({
  token = 'seed',
  options: { min = 0, max = 1, fractional } = {},
}: CreateRandomizerArgs = {}) => {
  const random = seedrandom(token);

  const validate = (min: number, max: number) => {
    if (min > max) {
      throw Error('"Min" value cannot exceed or be higher than the "Max"');
    }
  };

  const generateRandom = (
    options?: Pick<
      Required<CreateRandomizerArgs>['options'],
      'min' | 'max' | 'fractional'
    >
  ) => {
    const _min = options?.min || min;
    const _max = options?.max || max;
    validate(_min, _max);

    const distance = Math.abs(_min - _max);
    const isFractional = options?.fractional ?? fractional;
    const num = random() * distance + _min;
    if (isFractional) {
      return num;
    }
    return Math.round(num);
  };

  return {
    random: generateRandom,
    randomElement: <T>(array: T[]): T => {
      if (!array.length) {
        return undefined as never;
      }
      return array[generateRandom({ min: 0, max: array.length - 1 })];
    },
    // TODO (Justas): remove this. We have shiftNumber for that
    randomWithBase: (
      base: number,
      offset: number,
      options?: CreateRandomizerArgs['options']
    ) => {
      const _min = options?.min || min;
      const _max = options?.max || max;
      validate(_min, _max);

      const offsetDirection = random() > 0.5 ? 1 : -1;
      const generatedOffset = random() * offset * offsetDirection;
      const newValue = base + generatedOffset;

      if (_min !== undefined && newValue < _min) {
        return _min;
      }

      if (_max !== undefined && _max < newValue) {
        return _max;
      }

      return newValue;
    },
  };
};

type GenerateTimeSeriesArgs = {
  from: ConvertableToDateTime;
  to: ConvertableToDateTime;
  initial: Record<string, number>;
  nextNumber: (val: number, key: string) => number;
  unit: TimeUnit;
  step?: number;
};

export const generateTimeSeries = ({
  from,
  to,
  unit,
  initial,
  nextNumber,
  step = 1,
}: GenerateTimeSeriesArgs) => {
  return timeRange(from, to, unit, step).map((date, index) => {
    if (!index) {
      return {
        timestamp: date.toString(),
        ...initial,
      };
    }
    const shifted = Object.keys(initial).reduce((acc, curr: string) => {
      acc[curr] = nextNumber(initial[curr as keyof typeof initial], curr);
      return acc;
    }, {} as Record<string, number>);
    const result = {
      timestamp: date.toISOString(),
      ...shifted,
    };
    initial = shifted;
    return result;
  });
};

type RandomNumberSequenceArgs = {
  token: string;
  initialNumber?: number;
  offset?: number;
  percentageOffset?: number;
  min?: number;
  max?: number;
};

export function* randomNumberSequence({
  initialNumber,
  token,
  offset,
  percentageOffset,
  min,
  max,
}: RandomNumberSequenceArgs) {
  const { random } = createRandomizer({ token, options: { min, max } });
  let current = initialNumber ?? random();
  while (true) {
    const result = current;
    current = shiftNumber({
      value: current,
      min,
      max,
      random,
      offset,
      percentageOffset,
    });
    yield result;
  }
}
