import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { AvailableSavingsRecommendations } from '@cast/types';

import { clusterEvents } from 'core/analytics';
import { useAvailableSavingsQuery } from 'hooks/queries/available-savings';
import { useCluster } from 'hooks/useCluster';
import { usePersistentState } from 'hooks/usePersistentState';

import { useAvailableSavingsPartitionedWorkloads } from '../_hooks/useAvailableSavingsPartitionedWorkloads';
import { useCurrentPrice } from '../_hooks/useCurrentPrice';
import { SpotAnalysisMode, WorkloadRow } from '../types';
import { nonNegativeGuard } from '../utils';

export type SpotSavingsState = {
  isNegativeSavings: boolean;
  spotAnalysisEnabled: boolean;
  toggleSpotAnalysis: () => void;
  changeSpotAnalysisMode: (mode: SpotAnalysisMode) => void;
  spotAnalysisMode: SpotAnalysisMode;
  workloadsBySpotAnalysisMode: WorkloadRow[];
  spotInstanceSavings: number;
  availableSpotSavingsPercentage: number;
  priceAfterWithSpotSavings: number;
};

const Context = createContext<SpotSavingsState>(undefined as never);

const getRecommendationsData = (details?: AvailableSavingsRecommendations) => {
  const priceBefore = nonNegativeGuard(details?.monthly?.priceBefore);
  const priceAfter = nonNegativeGuard(details?.monthly?.priceAfter);
  const isNegativeSavings = priceBefore < priceAfter;
  const savingsPercentage = nonNegativeGuard(details?.savingsPercentage);
  return { priceAfter, isNegativeSavings, savingsPercentage };
};

export const SpotAnalysisProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const partitionedWorkloads = useAvailableSavingsPartitionedWorkloads();
  const { cluster } = useCluster();
  const { data: availableSavingsResponse } = useAvailableSavingsQuery(
    cluster.id
  );
  const { currentMonthlyPrice } = useCurrentPrice();

  const availableSavingsPercentage = nonNegativeGuard(
    availableSavingsResponse?.recommendations?.Layman?.savingsPercentage
  );

  const [spotAnalysisEnabled, setSpotAnalysisEnabled] =
    usePersistentState<boolean>('available-savings.spot.enabled', true);
  const [spotAnalysisMode, changeSpotAnalysisMode] =
    usePersistentState<SpotAnalysisMode>(
      'available-savings.spot.mode',
      SpotAnalysisMode.SPOT_FRIENDLY_WORKLOADS
    );

  const [
    isNegativeSavings,
    availableSpotSavingsPercentage,
    priceAfterWithSpotSavings,
  ] = useMemo(() => {
    if (spotAnalysisEnabled) {
      const recommendations =
        spotAnalysisMode === SpotAnalysisMode.SPOT_FRIENDLY_WORKLOADS
          ? availableSavingsResponse?.recommendations?.SpotInstances
          : availableSavingsResponse?.recommendations?.SpotOnly;
      const { isNegativeSavings, priceAfter, savingsPercentage } =
        getRecommendationsData(recommendations);
      return [isNegativeSavings, savingsPercentage, priceAfter];
    }
    const { isNegativeSavings, priceAfter, savingsPercentage } =
      getRecommendationsData(availableSavingsResponse?.recommendations?.Layman);
    if (!isNegativeSavings && savingsPercentage && priceAfter) {
      return [isNegativeSavings, savingsPercentage, priceAfter];
    }
    return [true, 0, currentMonthlyPrice];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotAnalysisEnabled, spotAnalysisMode, availableSavingsResponse]);

  const workloadsBySpotAnalysisMode = spotAnalysisEnabled
    ? spotAnalysisMode === SpotAnalysisMode.ALL_WORKLOADS
      ? partitionedWorkloads?.spotOnlyWorkloads
      : partitionedWorkloads?.spotWorkloads
    : partitionedWorkloads?.workloads;

  const spotInstanceSavings = nonNegativeGuard(
    availableSpotSavingsPercentage - availableSavingsPercentage
  );

  const toggleSpotAnalysis = () => {
    setSpotAnalysisEnabled(!spotAnalysisEnabled);
    clusterEvents.toggledUseSpotInstances(cluster, !spotAnalysisEnabled);
  };

  return (
    <Context.Provider
      value={{
        isNegativeSavings,
        spotAnalysisEnabled,
        toggleSpotAnalysis,
        spotAnalysisMode,
        changeSpotAnalysisMode,
        workloadsBySpotAnalysisMode: workloadsBySpotAnalysisMode || [],
        spotInstanceSavings,
        priceAfterWithSpotSavings,
        availableSpotSavingsPercentage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

/**
 * @returns calculations made from spot analysis view settings
 */
export const useAvailableSavingsSpotAnalysis = () => {
  return useContext(Context);
};
