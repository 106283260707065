import { useQuery } from '@tanstack/react-query';

import { BestPracticeCheckStandard } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type UseBestPracticeCheckQuery = {
  clusterId?: string;
  ruleId?: string;
  standard?: BestPracticeCheckStandard;
};

export const useBestPracticeCheckQuery = ({
  clusterId,
  ruleId,
  standard,
}: UseBestPracticeCheckQuery = {}) => {
  const {
    isLoading,
    data: check,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      QueryKeys.SECURITY_BEST_PRACTICE_CHECK,
      clusterId,
      ruleId,
      standard,
    ],
    queryFn: async () => {
      const { data } = await apiClient.securityInsights.getBestPracticeCheck({
        ruleId: ruleId!,
        standard,
      });
      return data;
    },
    enabled: !!ruleId,
    refetchInterval: false,
  });

  return { isLoading, check, error, refetch };
};
