import Big from 'big.js';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import { DailyUsage, NormalizedBillingReportClusterUsage } from '../../types';

export const mapBillingReportDataByDay = (
  data: NormalizedBillingReportClusterUsage[]
): DailyUsage[] => {
  const allUsages = flatMap(data, (cluster) => cluster.dailyUsages);

  const groupedByTimestamp = groupBy(allUsages, 'timestamp');

  const aggregated = map(groupedByTimestamp, (usages, timestamp) => {
    const totalBillableCpus = usages.reduce(
      (sum, usage) => sum.plus(usage.billableCpus),
      Big(0)
    );
    const totalCpuHours = usages.reduce(
      (sum, usage) => sum.plus(usage.cpuHours),
      Big(0)
    );

    return {
      billableCpus: totalBillableCpus.toNumber(),
      cpuHours: totalCpuHours.toNumber(),
      timestamp: timestamp,
    };
  });

  return aggregated;
};
