import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';

import { Input, InputProps } from '../../controls/input';
import { CalendarBlank, CaretUp, CaretDown } from '../../icons';

type Props = {
  inputProps: InputProps;
  isOpen: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  sx?: SxProps;
  testId: string;
};

export const DatePickerTrigger = ({
  isOpen,
  onClick,
  inputProps,
  sx,
  testId = 'date-picker',
}: Props) => {
  return (
    <Box onClick={onClick} sx={sx} data-testid={testId}>
      <Input
        placeholder="Select date"
        sx={{ pointerEvents: 'none', backgroundColor: 'white' }}
        startAdornment={<CalendarBlank size="20px" />}
        endAdornment={isOpen ? <CaretUp /> : <CaretDown />}
        {...inputProps}
      />
    </Box>
  );
};
