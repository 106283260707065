import { PropsWithChildren } from 'react';

import { Stack } from '@mui/material';

import { Icons } from '@cast/design-system';

import {
  NoData,
  NoResults,
  Search,
  SearchableTable,
  SearchableTableProps,
  SearchProvider,
  SearchProviderProps,
} from 'components/search';

type Props<T extends unknown> = PropsWithChildren<{
  searchProviderProps: SearchProviderProps<T>;
  searchableTableProps: SearchableTableProps;
}>;

export const BillingReportTableContainer = <T extends unknown>({
  searchProviderProps,
  searchableTableProps,
  children,
}: Props<T>) => {
  return (
    <SearchProvider urlKey="billing-report-table" {...searchProviderProps}>
      <Stack gap={8} data-hc="success">
        <Search />
        <SearchableTable
          components={{
            noData: (
              <NoData icon={<Icons.MagnifyingGlass />} entity="cluster" />
            ),
            noResults: (
              <NoResults icon={<Icons.MagnifyingGlass />} entity="cluster" />
            ),
          }}
          {...searchableTableProps}
        >
          {children}
        </SearchableTable>
      </Stack>
    </SearchProvider>
  );
};
