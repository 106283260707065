import { BrowserTracing, BrowserProfilingIntegration } from '@sentry/browser';
import { ExtraErrorData, HttpClient, Dedupe } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import { IS_PROD } from 'common/constants';
import { flags } from 'core/flags';

import { isE2E } from '../utils/isE2E';

const isHeadless = () => {
  const userAgent = window.navigator?.userAgent.toLowerCase();
  return (
    navigator?.webdriver ||
    userAgent.includes('headless') ||
    userAgent.includes('phantomjs')
  );
};

const beforeBreadcrumb = (
  breadcrumb: Sentry.Breadcrumb,
  hint: Sentry.BreadcrumbHint | undefined
) => {
  const ariaLabel = hint?.event?.target?.ariaLabel;

  if (breadcrumb.category === 'ui.click' && ariaLabel) {
    breadcrumb.message = ariaLabel;
  }

  return breadcrumb;
};

const beforeSend = (event: Sentry.Event, hint?: Sentry.EventHint) => {
  const isResizeObserverLoop1 = event.message?.includes(
    'ResizeObserver loop completed with undelivered notifications'
  );
  const isResizeObserverLoop2 = event.message?.includes(
    'ResizeObserver loop limit exceeded'
  );
  const is401 =
    hint?.originalException instanceof Error &&
    hint?.originalException?.message?.includes('401');

  if (isResizeObserverLoop1 || isResizeObserverLoop2 || is401) {
    event.level = 'warning';
  }
  return event;
};

if (flags['external-features'].sentry.value) {
  Sentry.init({
    enabled: !isE2E() && !isHeadless() && IS_PROD,
    integrations: [
      new Dedupe(),
      new BrowserTracing(),
      new BrowserProfilingIntegration(),
      new ExtraErrorData(),
      new HttpClient(),
    ],
    dsn: window.env.VITE_SENTRY_CLIENT_DSN,
    release: window.env.VITE_VERSION,
    environment: window?.env?.VITE_ENV,
    normalizeDepth: 10,
    beforeBreadcrumb,
    beforeSend,
    tracesSampler: (samplingContext) => {
      if (samplingContext.transactionContext.op === 'pageLoad') {
        return 0.5;
      }
      return 0.1;
    },
    profilesSampleRate: 0.1,
  });
}
