import { useMemo, useState } from 'react';

import { Stack, Typography, Box } from '@mui/material';

import { DATE_SIMPLE } from '@cast/constants';
import { DateRange } from '@cast/types';
import { dateRangeIterator } from '@cast/utils';

import {
  ChartTypeToggle,
  chartTypeToggleOptions,
  ComposedChartWithEstimate,
} from 'components/chart';
import { useChartProps } from 'hooks/useChartProps';
import { ChartType, ChartTypeToggleOptions } from 'types/charts';
import { TopMostExpensiveGroupDatapoint } from 'types/top-items';

import { ChartTooltip } from './ChartTooltip';
import { useBillingReportContext } from '../../../BillingReportContext';
import { BillingReportChartTypes } from '../../../types';

const generateEmptyChartData = ([from, to]: DateRange) => {
  const result: TopMostExpensiveGroupDatapoint = [];
  for (const date of dateRangeIterator(from, to)) {
    result.push({
      isForecast: false,
      timestamp: date.format(DATE_SIMPLE),
      values: {},
    });
  }

  return result;
};

export const BillingReportChart = () => {
  const chartTypeOptions: ChartTypeToggleOptions =
    chartTypeToggleOptions.filter(({ value }) =>
      Object.values(BillingReportChartTypes).includes(
        value as unknown as BillingReportChartTypes
      )
    );
  const [chartType, setChartType] = useState(BillingReportChartTypes.AREA);

  const {
    topMostExpensiveClustersDatapoints,
    topMostExpensiveClusters,
    showEmptyComponents,
    dateRange,
  } = useBillingReportContext();

  const computedChartProps = useChartProps(
    showEmptyComponents
      ? generateEmptyChartData(dateRange)
      : topMostExpensiveClustersDatapoints
  );

  const chartConfig = useMemo(() => {
    if (!topMostExpensiveClustersDatapoints) {
      return undefined;
    }
    const type =
      chartType === BillingReportChartTypes.AREA
        ? ChartType.AREA
        : ChartType.BAR;

    return topMostExpensiveClusters.map((cluster) => ({
      type,
      enabled: true,
      stackId: 'a',
      config: {
        type: 'linear',
        dataKey: (d: TopMostExpensiveGroupDatapoint[number]) => {
          return d.values[cluster.id];
        },
        fill: cluster.color,
      },
    }));
  }, [chartType, topMostExpensiveClusters, topMostExpensiveClustersDatapoints]);

  return (
    <Stack data-testid={`billing-report-chart-${chartType}`}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pl="24px"
        pr="18px"
      >
        <Typography variant="L10B">Daily Billable CPUs</Typography>
        <ChartTypeToggle
          active={chartType}
          options={chartTypeOptions}
          onChange={setChartType}
        />
      </Stack>
      {chartConfig && !!computedChartProps.data?.length && (
        <Box minHeight={230} height={230} width="100%">
          <ComposedChartWithEstimate
            data={computedChartProps.data}
            chartConfig={chartConfig}
            yAxisProps={{
              tickCount: 5,
              tickMargin: 5,
              tickFormatter: (value, index) => {
                return `${value} ${index === 4 ? 'CPU' : ''}`;
              },
            }}
            xAxisProps={{
              padding:
                chartType === BillingReportChartTypes.BAR
                  ? { left: 60, right: 20 }
                  : undefined,
              ...computedChartProps.xAxisProps,
              interval: 0,
            }}
            composedProps={{
              margin: { top: -1, left: 0, right: 0 },
              reverseStackOrder: chartType === BillingReportChartTypes.AREA,
            }}
            hideXAxisOuterTicks={chartType === BillingReportChartTypes.AREA}
            TooltipComponent={(props: {
              payload?: { payload: TopMostExpensiveGroupDatapoint[number] }[];
            }) => (
              <ChartTooltip
                payload={props.payload}
                topItems={topMostExpensiveClusters}
              />
            )}
          />
        </Box>
      )}
    </Stack>
  );
};
