export const getRowRankClass = (rank?: number) => {
  if (rank === undefined || rank < 0) {
    return undefined;
  }
  return `rankedRow rank-${rank}`;
};

export const makeRowMarker =
  <T extends any>(propName: keyof T) =>
  (row: T) =>
    getRowRankClass(row[propName]);
