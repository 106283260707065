import { useState } from 'react';

import { Box, Link, Stack, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';

import {
  Column,
  ColumnGroup,
  Icons,
  TableActionButton,
  TableBulkActions,
  TableHeader,
  Tooltip,
} from '@cast/design-system';
import {
  ImageScanStatus,
  ImageStatus,
  SecurityInsightsContainerImage,
  VulnerabilitySeverity,
} from '@cast/types';
import { SortBy, isDemoMode } from '@cast/utils';

import { tableCacheKeys } from 'common/tableCacheKeys';
import { EntityAction, EntityActions, LinkBehaviour } from 'components/common';
import { FailedToLoad } from 'components/messages';
import { Link as RouterLink } from 'components/router';
import {
  NoData,
  NoResults,
  SearchableTable,
  SearchableTableRecordsCount,
  useSearchContext,
} from 'components/search';
import { makeMultiSorter } from 'components/table';
import { flags } from 'core/flags';
import { PermissionType, useRBAC } from 'core/rbac';
import { TourStep } from 'core/tour';
import {
  availableSeverityLevels,
  severityToColorMap,
  severityToTextMap,
  severityToTooltipMap,
} from 'features/security-insights';
import { makeActionsColumn } from 'utils/table';

import { vulnerabilityManagementTourSteps } from '../../_tours/vulnerabilityManagementTour';
import { vulnerabilityManagementSecondarySort } from '../../constants';
import { makeImageDetailsPath } from '../../navigation';
import { getImageName, getImageTagId } from '../../utils';
import { ImageScanStatusChip } from '../_components/ImageScanStatusChip';
import { ImageUsageIndicator } from '../_components/ImageUsageIndicator';
import { useCancelImageExceptionDrawer } from '../_hooks/useCancelImageExceptionDrawer';
import { useCreateImageExceptionDrawer } from '../_hooks/useCreateImageExceptionDrawer';
import { useDeleteImagesDialog } from '../_hooks/useDeleteImagesDialog';

const secondarySort: SortBy<SecurityInsightsContainerImage>[] = [
  {
    accessor: (image) => (image.status === ImageStatus.Running ? 1 : 0),
    direction: 'desc',
  },
  ...vulnerabilityManagementSecondarySort,
];

const NameSorter = makeMultiSorter({
  sortBy: [
    {
      name: ['Running first', 'Not running first'],
      accessor: 'status',
      testId: 'image-status',
    },
    {
      name: ['Image name A-Z', 'Image name Z-A'],
      order: ['asc', 'desc'],
      accessor: getImageName,
      testId: 'image-name',
    },
  ],
});

type Props = {
  exceptionFilter: string;
  isWorkloadView?: boolean;
};

export const ImagesTable = ({ exceptionFilter, isWorkloadView }: Props) => {
  const searchCtx = useSearchContext<SecurityInsightsContainerImage>();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const { openCreateImageExceptionDrawer } = useCreateImageExceptionDrawer();
  const { openCancelImageExceptionDrawer } = useCancelImageExceptionDrawer();
  const { openDeleteImagesDialog } = useDeleteImagesDialog(() =>
    setSelectedRows([])
  );

  const [canEdit] = useRBAC(PermissionType.EDIT);
  const isImageDeletionEnabled =
    flags.standalone['security-image-deletion'].value;

  return (
    <Stack flexGrow={1} data-hc="success">
      <TourStep {...vulnerabilityManagementTourSteps.imagesTable}></TourStep>
      <SearchableTable
        cacheKey={
          isWorkloadView
            ? tableCacheKeys.SECURITY.WORKLOAD_IMAGES
            : tableCacheKeys.SECURITY.IMAGES
        }
        maxHeight="calc(100vh - 155px)"
        components={{
          noData: <NoData icon={<Icons.ShieldCheck />} entity="image" />,
          noResults: <NoResults icon={<Icons.ShieldCheck />} entity="image" />,
          failed: (
            <FailedToLoad
              title="Failed to load images"
              refresh={searchCtx?.refetch}
            />
          ),
        }}
        secondarySort={secondarySort}
        selectedRows={selectedRows}
        onRowsSelected={canEdit && !isDemoMode() ? setSelectedRows : undefined}
        outerHeader={
          <TableHeader
            recordsCount={
              <SearchableTableRecordsCount
                count={selectedRows.length}
                title="image repository"
              />
            }
            bulkActions={
              <TableBulkActions visible={!!selectedRows.length}>
                {exceptionFilter === 'active' && (
                  <TableActionButton
                    startIcon={<Icons.ShieldSlash />}
                    onClick={() =>
                      openCreateImageExceptionDrawer(
                        selectedRows
                          .map((tagId) =>
                            searchCtx?.data?.find(
                              (image) => getImageTagId(image) === tagId
                            )
                          )
                          .filter(Boolean) as SecurityInsightsContainerImage[],
                        () => setSelectedRows([])
                      )
                    }
                    testId="except-button"
                  >
                    Except repository
                  </TableActionButton>
                )}
                {exceptionFilter === 'excepted' && (
                  <TableActionButton
                    startIcon={<Icons.Shield />}
                    onClick={() =>
                      openCancelImageExceptionDrawer(
                        selectedRows
                          .map((tagId) =>
                            searchCtx?.data?.find(
                              (image) => getImageTagId(image) === tagId
                            )
                          )
                          .filter(Boolean) as SecurityInsightsContainerImage[],
                        () => setSelectedRows([])
                      )
                    }
                    testId="cancel-exception-button"
                  >
                    Cancel exception
                  </TableActionButton>
                )}
                {isImageDeletionEnabled && canEdit && (
                  <TableActionButton
                    startIcon={<Icons.XCircle />}
                    onClick={() =>
                      openDeleteImagesDialog(
                        selectedRows
                          .map((tagId) =>
                            searchCtx?.data?.find(
                              (image) => getImageTagId(image) === tagId
                            )
                          )
                          .map((image) => image?.tags?.[0].name ?? '')
                      )
                    }
                    disruptive
                    testId="remove-button"
                  >
                    Remove from the list
                  </TableActionButton>
                )}
              </TableBulkActions>
            }
          />
        }
        rowKey={getImageTagId}
        testId="images-repository-table"
      >
        <Column
          id="image"
          infoTooltip="These are a collection of container images that share the same name. Click on the image name to see the details."
          header="Image repository"
          minWidth={430}
          minResizeWidth={430}
          accessor={getImageName}
          Sorter={NameSorter}
          renderCell={(image: SecurityInsightsContainerImage) => {
            return (
              <Stack direction="row" alignItems="center" gap={8}>
                <ImageUsageIndicator
                  variant="minimal"
                  status={image.status ?? ImageStatus.NotRunning}
                />

                <Stack flexGrow={1} minWidth={0}>
                  <Link
                    component={RouterLink}
                    to={makeImageDetailsPath(getImageTagId(image))}
                    sx={{ textDecoration: 'none' }}
                    target={isWorkloadView ? '_blank' : undefined}
                  >
                    <LinkBehaviour
                      variant="P12M"
                      noWrap
                      testId="image-repository-name"
                    >
                      {getImageName(image)}
                    </LinkBehaviour>
                  </Link>
                  <Typography
                    variant="P10R"
                    color="grey.400"
                    mt={-4}
                    noWrap
                    component="span"
                  >
                    Tag: {image.tags?.[0].version}
                  </Typography>
                </Stack>
              </Stack>
            );
          }}
          resizable
          sortable
        />
        {!isWorkloadView && (
          <Column
            id="clusters"
            header={
              <Stack>
                <Tooltip title="Clusters" arrow placement="top">
                  <Icons.Hexagon width={16} height={16} />
                </Tooltip>
              </Stack>
            }
            accessor="clusters"
            minWidth={80}
            sortable
          />
        )}
        <Column
          id="affectedResources"
          header="Resources"
          infoTooltip="Resources that contains the assessed images"
          accessor="affectedResources"
          width={160}
          sortable
        />
        <ColumnGroup id="vulnerabilities" title="VULNERABILITIES">
          {availableSeverityLevels.map((severityLevel) => {
            const colors = severityToColorMap[severityLevel];
            const isNotAvailable =
              severityLevel === VulnerabilitySeverity.notAvailable;
            return (
              <Column
                key={severityLevel}
                width={isNotAvailable ? 72 : 61}
                id={severityLevel}
                cellSx={{
                  alignItems: 'center',
                }}
                cellWrapperSx={{
                  padding: '0 8px',
                }}
                accessor={(image: SecurityInsightsContainerImage) =>
                  image.vulnerabilitiesBySeverityLevel?.[severityLevel] || 0
                }
                renderCell={(image: SecurityInsightsContainerImage) => {
                  const count =
                    image.vulnerabilitiesBySeverityLevel?.[severityLevel] || 0;
                  return (
                    <Typography
                      variant="inherit"
                      color={count ? 'grey.900' : 'grey.300'}
                    >
                      {count}
                    </Typography>
                  );
                }}
                header={
                  <Tooltip
                    title={
                      severityToTooltipMap[severityLevel] ||
                      `${capitalize(severityLevel)} severity of vulnerability`
                    }
                    placement="top"
                    arrow
                  >
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="5px"
                      width={isNotAvailable ? 35 : 24}
                      height={24}
                      color={colors?.color}
                      bgcolor={colors?.bgcolor}
                    >
                      {isNotAvailable
                        ? severityToTextMap[severityLevel]
                        : severityLevel[0]}
                    </Box>
                  </Tooltip>
                }
                sortable
                sortingType="number"
              />
            );
          })}
        </ColumnGroup>

        <Column
          id="fixes"
          infoTooltip="Sum of fixes available for Critical and High severity vulnerabilities"
          header="Fixes"
          width={114}
          bodyCellSx={{ fontWeight: 'medium' }}
          sortable
        />

        <Column
          id="status"
          header="Status"
          width={120}
          renderCell={({ scanStatus }: SecurityInsightsContainerImage) => (
            <ImageScanStatusChip
              status={scanStatus ?? ImageScanStatus.Pending}
            />
          )}
          accessor="scanStatus"
          sortable
        />
        {isImageDeletionEnabled &&
          canEdit &&
          !isDemoMode() &&
          makeActionsColumn<SecurityInsightsContainerImage>({
            width: 36,
            cellWrapperSx: { pl: '0px', pr: '8px' },
            action: (image) => (
              <EntityActions
                triggerButtonProps={{
                  children: <Icons.DotsThree />,
                }}
                testId="image-table-actions"
              >
                <EntityAction
                  startAdornment={<Icons.ShieldSlash />}
                  onClick={() => {
                    if (exceptionFilter === 'active') {
                      openCreateImageExceptionDrawer([image]);
                    } else {
                      openCancelImageExceptionDrawer([image]);
                    }
                  }}
                >
                  {exceptionFilter === 'active'
                    ? 'Except repository'
                    : 'Cancel exception'}
                </EntityAction>
                <EntityAction
                  startAdornment={<Icons.XCircle />}
                  disruptive
                  onClick={() =>
                    openDeleteImagesDialog([image.tags?.[0].name ?? ''])
                  }
                >
                  Remove from the list
                </EntityAction>
              </EntityActions>
            ),
          })}
      </SearchableTable>
    </Stack>
  );
};
