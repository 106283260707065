import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { TIME } from '@cast/constants';
import { Tooltip } from '@cast/design-system';
import { Cluster, ClusterDisplayStatus } from '@cast/types';

import { isAKSCluster } from 'types/cluster';

const getTimeFormat = (ms: number) => {
  if (ms < 60_000) {
    return 'ss';
  }

  if (ms < 3_600_000) {
    return 'mm:ss';
  }

  return TIME;
};

type Props = {
  cluster: Cluster;
};

export const ClusterStatusTooltip = ({
  cluster,
  children,
}: PropsWithChildren<Props>) => {
  const [relativeTime, setRelativeTime] = useState<string>(
    dayjs(cluster.agentSnapshotReceivedAt).fromNow(true)
  );

  useEffect(() => {
    let secondsDiff = dayjs().diff(cluster.agentSnapshotReceivedAt, 'ms');

    if (
      secondsDiff < 86_400_000 &&
      cluster.displayStatus === ClusterDisplayStatus.NOT_RESPONDING
    ) {
      const timer = setInterval(() => {
        secondsDiff += 1000;
        setRelativeTime(
          `${dayjs.duration(secondsDiff).format(getTimeFormat(secondsDiff))} ${
            secondsDiff < 60_000 ? 'seconds' : ''
          }`
        );
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [cluster.agentSnapshotReceivedAt, cluster.displayStatus]);

  const tooltipProps = useMemo(() => {
    const isManaged = Boolean(cluster.credentialsId);
    const error = cluster.reconcileError;

    if (cluster.displayStatus === ClusterDisplayStatus.DISCONNECTED) {
      return {
        title: (
          <Typography variant="P12R">
            Disconnected from:{' '}
            <Typography variant="P12B" component="span">
              {dayjs(cluster.agentSnapshotReceivedAt).format('YYYY-MM-DD h:ma')}
            </Typography>
          </Typography>
        ),
      };
    }

    if (cluster.displayStatus === ClusterDisplayStatus.NOT_RESPONDING) {
      return {
        title: (
          <>
            <Typography variant="P12R">
              Unable to reach CAST AI agent for:{' '}
              <Typography variant="P12B" component="span">
                {relativeTime}
              </Typography>
            </Typography>

            <Typography variant="P12R" display="block">
              Please check the status of CAST AI agent pod
            </Typography>
          </>
        ),
      };
    }

    if (
      [
        ClusterDisplayStatus.WAITING_CONNECTION,
        ClusterDisplayStatus.CONNECTING,
      ].includes(cluster.displayStatus) &&
      isManaged
    ) {
      const isAKS = isAKSCluster(cluster);

      return {
        title: isAKS
          ? 'Enabling access and setting up CAST AI. It might take up to 15 minutes. '
          : 'Enabling access and setting up CAST AI',
      };
    }

    return {
      disableHoverListener: !error,
      title: (
        <Stack gap={8}>
          <Typography variant="P12R">
            {isManaged
              ? 'We are trying to fix this recurring issue:'
              : 'There is recurring issue:'}
          </Typography>
          <Typography
            variant="C12"
            lineHeight="18px"
            component="p"
            sx={{
              position: 'relative',
              backgroundColor: 'grey.900',
              pr: 10,
              pl: 20,
              '&:before': {
                content: '""',
                position: 'absolute',
                left: 10,
                top: 2,
                bottom: 2,
                width: 3,
                borderRadius: '10px',
                backgroundColor: 'red.400',
              },
            }}
          >
            {error}
          </Typography>
        </Stack>
      ),
    };
  }, [cluster, relativeTime]);

  return (
    <Tooltip arrow placement="top-start" {...tooltipProps}>
      <div>{children}</div>
    </Tooltip>
  );
};
