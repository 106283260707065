import { ReactNode, useContext } from 'react';

import Box from '@mui/material/Box';

import Button from '../../buttons/Button';
import { DatePickerContext } from '../DatePickerProvider';

type Props = {
  hint?: string | ReactNode;
};

const DatePickerFooter = ({ hint }: Props) => {
  const { handleApply, handleClose } = useContext(DatePickerContext);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={hint ? 'space-between' : 'flex-end'}
      borderTop={(theme) => `1px solid ${theme.palette.grey[100]}`}
      padding="16px"
    >
      {hint}
      <Box display="flex" gap="8px">
        <Button
          size="small"
          variant="ghost"
          onClick={handleClose}
          testId="date-picker-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="primary"
          onClick={handleApply}
          testId="date-picker-apply-btn"
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default DatePickerFooter;
