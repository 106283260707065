import { DateTypes } from '@cast/design-system';

export const defaultFilterPresets: DateTypes.DateRangeFilter[] = [
  DateTypes.DateRangeFilter.ThisMonth,
  DateTypes.DateRangeFilter.Last7Days,
  DateTypes.DateRangeFilter.LastWeek,
  DateTypes.DateRangeFilter.Last2Weeks,
  DateTypes.DateRangeFilter.Last30Days,
  DateTypes.DateRangeFilter.LastMonth,
];

export const shortRangeFilterPresets: DateTypes.DateRangeFilter[] = [
  DateTypes.DateRangeFilter.TODAY,
];

export const workloadsTableKey = 'workloads-cost-report';
export const namespacesTableKey = 'namespaces-cost-report';
