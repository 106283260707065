import { useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Cluster } from '@cast/types';
import { isManagedByTerraform } from '@cast/utils';

import { SwipeableViews } from 'components/boxes';
import { ClusterProvider } from 'core/providers';
import { isAKSCluster } from 'types/cluster';
import { isE2E } from 'utils/isE2E';

import { AssignCredentialsDialogSuccessSlide } from './_components/AssignCredentialsDialogSuccessSlide';
import { AssignCredentialsSetupSlide } from './_components/AssignCredentialsSetupSlide';
import { EnablingCastAiSlide } from './_components/EnablingCastAiSlide';
import { TerraformClusterSlide } from './_components/TerraformClusterSlide';
import { AssignCredentialsDialogContext } from './context';
import { AssignCredentialsDialogSlide } from './types';
import { useNavigate } from '../../../../useNavigate';
import { useDidClusterBecameOperable } from '../../_hooks/useDidClusterBecameOperable';
import { useWasCredentialsAssigned } from '../../_hooks/useWasCredentialsAssigned';
import { useWasSecurityEnabled } from '../../_hooks/useWasSecurityEnabled';

type Props = {
  cluster: Cluster;
  destroy: () => void;
  onSecurityEnabled?: () => void;
  onSlideChange?: (slide: AssignCredentialsDialogSlide) => void;
};

export const AssignCredentialsDialogContent = ({
  destroy,
  cluster,
  onSecurityEnabled,
  onSlideChange,
}: Props) => {
  const [activeSlideState, setActiveSlideState] =
    useState<AssignCredentialsDialogSlide>('setup');
  const location = useLocation();
  const navigate = useNavigate();
  const isInSecurityPage = location.pathname.includes('organization/security');
  const [includeAutoscaler, setIncludeAutoscaler] = useState(true);
  const [includeSecurity, setIncludeSecurity] = useState(true);
  const isOnlySecurityEnabled = includeSecurity && !includeAutoscaler;

  const setActiveSlide = (slide: AssignCredentialsDialogSlide) => {
    if (slide === 'success') {
      // client should stay on security pages if he is onboarding cluster to phase 2 from one of security insights pages
      if (isInSecurityPage || isOnlySecurityEnabled) {
        navigate({
          pathname: '/organization/security/dashboard',
        });
      } else if (!isAKSCluster(cluster)) {
        navigate({
          pathname: `/external-clusters/${cluster.id}/autoscaler`,
        });
      }
    }
    setActiveSlideState(slide);
    onSlideChange?.(slide);
  };

  const wasCredentialsAssigned = useWasCredentialsAssigned(
    cluster.id,
    isAKSCluster(cluster) ? () => setActiveSlide('success') : undefined,
    activeSlideState !== 'success' && includeAutoscaler
  );
  useWasSecurityEnabled(
    cluster.id,
    () => {
      onSecurityEnabled?.();
      if (isAKSCluster(cluster) || !includeAutoscaler) {
        setActiveSlide('success');
      }
    },
    activeSlideState !== 'success' && includeSecurity
  );

  useDidClusterBecameOperable(
    cluster.id,
    () => setActiveSlide('success'),
    !isAKSCluster(cluster) &&
      activeSlideState !== 'success' &&
      wasCredentialsAssigned
  );

  return (
    <AssignCredentialsDialogContext.Provider
      value={{
        dismiss: destroy,
        setActiveSlide,
        includeAutoscaler,
        setIncludeAutoscaler,
        includeSecurity,
        setIncludeSecurity,
      }}
    >
      <ClusterProvider cluster={cluster} isLoading={false}>
        {isManagedByTerraform(cluster.managedBy) ? (
          <TerraformClusterSlide />
        ) : (
          <SwipeableViews
            slides={{
              setup: <AssignCredentialsSetupSlide />,
              inProgress: <EnablingCastAiSlide />,
              success: (
                <AssignCredentialsDialogSuccessSlide cluster={cluster} />
              ),
            }}
            activeSlide={activeSlideState}
            disableAnimations={isE2E()}
            data-testid="assign-credentials-dialog-content"
          />
        )}
      </ClusterProvider>
    </AssignCredentialsDialogContext.Provider>
  );
};
