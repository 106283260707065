import { ChangeEvent, forwardRef, KeyboardEvent, ReactNode } from 'react';

import {
  FormHelperTextProps,
  InputLabelProps,
  styled,
  TextField,
  TextFieldProps,
} from '@mui/material';
import clsx from 'clsx';

import DsFormHelperText from '../form-helper-text/DsFormHelperText';
import { FormLabelProps } from '../form-label';
import DsFormLabel from '../form-label/FormLabel';

const InputWithOutsideAdornmentContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'min-content auto min-content',
  '& > .DS-Input-root': {
    display: 'contents',
  },
  '&.DS-Input-withOutsideStartAdornment': {
    gridTemplateColumns: 'min-content auto',
    '& .MuiInputLabel-root': {
      gridColumn: 1,
    },
    '& .MuiInputBase-root': {
      gridColumn: 2,
    },
    '& .MuiFormHelperText-root': {
      gridColumn: 1,
    },
    '& .DS-Input-startOutsideAdornment': {
      gridColumn: 1,
    },
  },
  '&.DS-Input-withOutsideEndAdornment': {
    gridTemplateColumns: 'auto min-content',
    '& .MuiInputLabel-root': {
      gridColumn: 1,
    },
    '& .MuiInputBase-root': {
      gridColumn: 1,
    },
    '& .MuiFormHelperText-root': {
      gridColumn: 1,
    },
    '& .DS-Input-endOutsideAdornment': {
      gridColumn: 2,
    },
  },
  '&.DS-Input-withOutsideStartAdornment.DS-Input-withOutsideEndAdornment': {
    gridTemplateColumns: 'min-content auto min-content',
    '& .MuiInputLabel-root': {
      gridColumn: 1,
    },
    '& .MuiInputBase-root': {
      gridColumn: 2,
    },
    '& .MuiFormHelperText-root': {
      gridColumn: 1,
    },
    '& .DS-Input-startOutsideAdornment': {
      gridColumn: 1,
    },
    '& .DS-Input-endOutsideAdornment': {
      gridColumn: 3,
    },
  },
  '& .MuiInputLabel-root': {
    gridRow: 1,
  },
  '& .MuiInputBase-root': {
    gridRow: 2,
  },
  '& .MuiFormHelperText-root': {
    gridRow: 3,
  },
  '& .DS-Input-startOutsideAdornment, & .DS-Input-endOutsideAdornment': {
    gridRow: 2,
  },
  '& .DS-Input-endOutsideAdornment, .DS-Input-startOutsideAdornment': {
    display: 'flex',
    alignItems: 'center',
  },
});

const StyledTextField = styled(TextField, {
  name: 'DsInput',
  slot: 'Root',
  overridesResolver: ({ size }, styles) => {
    return [styles.root, styles[size]];
  },
})({});
StyledTextField.displayName = 'StyledTextField';

export type InputProps = {
  hint?: ReactNode;
  error?: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  startOutsideAdornment?: ReactNode;
  endOutsideAdornment?: ReactNode;
  success?: boolean;
  readOnly?: boolean;
  hideEmptyLabel?: boolean;
  size?: 'small' | 'medium' | 'large';
  onEnter?: (event: KeyboardEvent, value: string) => void;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => void;
  hintAbsolute?: boolean;
  emptyLabel?: boolean;
  min?: number;
  max?: number;
  testId?: string;
} & Omit<
  TextFieldProps,
  'error' | 'helperText' | 'variant' | 'size' | 'onChange' | 'InputLabelProps'
> &
  FormLabelProps & {
    InputLabelProps?: Omit<Partial<InputLabelProps>, 'shrink' | 'required'>;
  };

const Input = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      label,
      labelTooltipProps,
      error,
      hint,
      startAdornment,
      endAdornment,
      size = 'medium',
      className,
      success,
      readOnly,
      emptyLabel,
      InputLabelProps = {},
      InputProps = {},
      startOutsideAdornment,
      endOutsideAdornment,
      onKeyUp,
      onEnter,
      onChange,
      hintAbsolute,
      sx,
      testId = 'input-root',
      ...props
    }: InputProps,
    ref
  ) => {
    const hasLabel = !!(label || labelTooltipProps?.title);
    const hasOutsideAdornments =
      !!startOutsideAdornment || !!endOutsideAdornment;

    const input = (
      <StyledTextField
        data-testid={testId}
        ref={ref}
        sx={!hasOutsideAdornments ? sx : undefined}
        className={clsx(
          !hasOutsideAdornments && className,
          'DS-Input-root',
          `DS-Input-size-${size}`,
          readOnly && 'DS-Input-readOnly'
        )}
        label={
          <DsFormLabel
            labelTooltipProps={labelTooltipProps}
            size={size}
            empty={emptyLabel}
          >
            {label}
          </DsFormLabel>
        }
        // Required for placeholder to be visible if input is empty and has no adornments
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
          required: false,
          className: clsx(
            InputLabelProps.className,
            !hasLabel && 'DS-Input-noLabel'
          ),
        }}
        variant="filled"
        helperText={error || hint}
        FormHelperTextProps={
          { component: DsFormHelperText, hintAbsolute } as FormHelperTextProps
        }
        error={!!error}
        InputProps={{
          readOnly,
          startAdornment: startAdornment ? (
            <div className="DS-Input-startAdornment-root DS-Input-adornment-root">
              {startAdornment}
            </div>
          ) : undefined,
          endAdornment: endAdornment ? (
            <div className="DS-Input-endAdornment-root DS-Input-adornment-root">
              {endAdornment}
            </div>
          ) : undefined,
          ...InputProps,
          className: clsx(
            InputProps.className,
            success && 'DS-success',
            readOnly && `DS-Input-readOnly`
          ),
        }}
        size={size}
        onKeyUp={(event) => {
          onKeyUp?.(event);
          if (event.key === 'Enter') {
            onEnter?.(event, (event.target as HTMLInputElement).value);
          }
        }}
        onChange={
          onChange
            ? (event) => {
                const value = event.target.value;
                if (props.type === 'number') {
                  if (!value) {
                    onChange(event, null as any);
                    return;
                  }
                  const number = Number(value);
                  if (!isFinite(number)) {
                    return;
                  }
                  if (
                    (props.min ?? Number.MIN_SAFE_INTEGER) <= number &&
                    (props.max ?? Number.MAX_SAFE_INTEGER) >= number
                  ) {
                    // TODO: add conditional type
                    onChange(event, number as any);
                  }
                } else {
                  onChange(event, event.target.value);
                }
              }
            : undefined
        }
        {...props}
      />
    );
    if (!hasOutsideAdornments) {
      return <>{input}</>;
    }
    return (
      <InputWithOutsideAdornmentContainer
        className={clsx(
          className,
          'DS-Input-withOutsideAdornment',
          startOutsideAdornment && 'DS-Input-withOutsideStartAdornment',
          endOutsideAdornment && 'DS-Input-withOutsideEndAdornment'
        )}
        sx={sx}
        data-testid="input-container"
      >
        {startOutsideAdornment && (
          <div
            className={clsx(
              'DS-Input-outsideAdornment DS-Input-startOutsideAdornment',
              { 'DsInput-disabled': props.disabled }
            )}
            data-testid="input-start-outside-adornment"
          >
            {startOutsideAdornment}
          </div>
        )}
        {input}
        {endOutsideAdornment && (
          <div
            className={clsx(
              'DS-Input-outsideAdornment DS-Input-endOutsideAdornment',
              { 'DsInput-disabled': props.disabled }
            )}
            data-testid="input-end-outside-adornment"
          >
            {endOutsideAdornment}
          </div>
        )}
      </InputWithOutsideAdornmentContainer>
    );
  }
);
Input.displayName = 'Input';

export default Input;
