import { useState } from 'react';

import { readStorage, Storage, writeStorage } from 'utils/storage';

export const usePersistentState = <T>(
  key: string,
  initialState?: T,
  storage: Storage = 'local'
) => {
  const [state, setState] = useState<T>(
    readStorage.bind(null, key, initialState, storage)
  );

  const setPersistedState = (value: T | ((value: T) => T)) => {
    if (typeof value === 'function') {
      const newValue = (value as (value: T) => T)(state);
      writeStorage(key, newValue, storage);
      setState(newValue);
    } else {
      writeStorage(key, value, storage);
      setState(value);
    }
  };

  return [state, setPersistedState] as const;
};
