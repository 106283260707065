import { Divider, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  SecurityInsightsBestPracticesCheckDetailsResponse,
  SecurityInsightsTicketItemType,
} from '@cast/types';
import { isDemoMode } from '@cast/utils';

import { flags } from 'core/flags';
import { PermissionType, useRBAC } from 'core/rbac';
import { QueryKeys } from 'core/react-query';

import { ExceptionsButton } from './ExceptionsButton';
import { JiraButton } from '../../../../_components/JiraButton';

type Props = {
  check?: SecurityInsightsBestPracticesCheckDetailsResponse;
};

export const CheckActions = ({ check }: Props) => {
  const queryClient = useQueryClient();

  const [canEdit] = useRBAC(PermissionType.EDIT);
  const showJiraButton =
    flags.standalone['security-integrations'].value && canEdit;

  return (
    check &&
    !isDemoMode() && (
      <Stack direction="row" gap={4}>
        {showJiraButton && (
          <>
            <JiraButton
              ticket={check.ticket}
              itemType={SecurityInsightsTicketItemType.Check}
              itemName={check.name ?? ''}
              itemId={check.ruleId ?? ''}
              onSuccess={() => {
                queryClient.invalidateQueries({
                  queryKey: [
                    QueryKeys.SECURITY_BEST_PRACTICE_CHECK,
                    check.ruleId,
                  ],
                });
              }}
            />
            <Divider orientation="vertical" flexItem sx={{ my: 4 }} />
          </>
        )}
        <ExceptionsButton check={check} />
      </Stack>
    )
  );
};
