import { ComponentType, useEffect, useState } from 'react';

import { PopoverProps } from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';

import { DropdownInput } from './components/DropdownInput';
import { DropdownPopper } from './components/DropdownPopper';
import { DropdownRoot } from './components/DropdownRoot';
import { DropdownContext } from './DropdownContext';
import {
  AutocompleteOverrides,
  DropdownProps,
  isSingleSelection,
} from './types';
import { useDropdownWithAutocomplete } from './useDropdownWithAutocomplete';
import { ListContext } from '../../lists/List';

export type AutocompleteProps<OptionItem, OptionValue> = DropdownProps<
  OptionItem,
  OptionValue
> &
  Pick<AutocompleteOverrides<OptionItem, OptionValue>, 'searchInputProps'> & {
    PopoverComponent?: ComponentType<PopoverProps>;
  };

export const Autocomplete = <OptionItem extends any, OptionValue extends any>({
  searchInputProps,
  PopoverComponent,
  ...dropdownProps
}: AutocompleteProps<OptionItem, OptionValue>) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'autocomplete',
  });

  const [inputValue, setInputValue] = useState('');
  const autocomplete = useDropdownWithAutocomplete<OptionItem, OptionValue>({
    dropdownProps: {
      ...dropdownProps,
      options: dropdownProps.options || [],
    },
    autocompleteOverrides: {
      onChange: (option: any) => {
        if (isSingleSelection(dropdownProps)) {
          if (option) {
            const valueResult =
              autocomplete.getters.getOptionSelectedValue(option);
            // Check if not a ReactNode
            setInputValue(typeof valueResult === 'string' ? valueResult : '');
          } else {
            setInputValue('');
          }

          dropdownProps.onChange?.(
            option,
            autocomplete.getters.getOptionValue(option)
          );
        }
      },
      open: popupState.isOpen,
      searchInputProps,
    },
  });

  useEffect(() => {
    if (isSingleSelection(dropdownProps)) {
      const newValue =
        dropdownProps.value && autocomplete.states.selectedOption
          ? autocomplete.getters.getOptionSelectedValue(
              autocomplete.states.selectedOption
            )
          : '';
      // Check if not a ReactNode
      setInputValue(typeof newValue === 'string' ? newValue : '');
    }
  }, [autocomplete.getters, autocomplete.states.selectedOption, dropdownProps]);

  return (
    <DropdownContext.Provider
      value={{
        dropdownProps,
        popupState,
        autocomplete,
      }}
    >
      <ListContext.Provider value={{ size: dropdownProps.size }}>
        <DropdownRoot>
          <DropdownInput
            inputProps={{
              value: inputValue,
            }}
          />
        </DropdownRoot>

        <DropdownPopper PopoverComponent={PopoverComponent} />
      </ListContext.Provider>
    </DropdownContext.Provider>
  );
};
