import { useMemo } from 'react';

import {
  AgentStatus,
  Cluster,
  ClusterActions,
  ClusterStatus,
} from '@cast/types';

import { MANAGED_PROVIDERS } from 'features/cluster/constants';
import { useSecurityInsightsAgents } from 'hooks/queries/security-insights';

import { useClusters } from './useClusters';

// prettier-ignore
export const useGetClusterActionsVisibility = (cluster: Cluster): Record<ClusterActions, boolean> => {
  const { clusters } = useClusters();
  const clusterIds = useMemo(() => clusters.map((c) => c.id), [clusters]);
  const { agentsData, isLoading: isLoadingSecurityAgents, error: securityAgentsError } = useSecurityInsightsAgents({ clusterIds });
 
  const isFailed = Boolean(cluster.reconcileError);
  const isManaged = Boolean(cluster.credentialsId);
  const isPaused = cluster.status === ClusterStatus.PAUSED;
  const isPausing = cluster.status === ClusterStatus.PAUSING;
  const isResuming = cluster.status === ClusterStatus.RESUMING;
  const isOnline = cluster.agentStatus === AgentStatus.ONLINE;
  const isDisconnected = cluster.agentStatus === AgentStatus.DISCONNECTED;
  const isConnecting = cluster.status === ClusterStatus.CONNECTING || cluster.agentStatus === AgentStatus.CONNECTING;
  const canBeManaged = MANAGED_PROVIDERS.includes(cluster.providerType);
  const isSecurityAgentEnabled = Boolean(agentsData?.statuses?.[cluster.id]?.installed);
  const wasSecurityLoaded = !isLoadingSecurityAgents || Boolean(securityAgentsError);
  const canConnect = !isFailed && !isDisconnected && !isManaged && !isPaused && !isPausing && !isResuming && !isConnecting && isOnline && canBeManaged;

  return {
    pause: !isFailed && ((isManaged && !isDisconnected && !isPaused) || isResuming),
    resume: !isFailed && ((isManaged && isPaused) || isPausing),
    disconnect: !isFailed && ((isManaged && !isDisconnected) || isOnline),
    delete: (!isFailed && isDisconnected) || isConnecting,
    reconnect: isManaged || isDisconnected || isFailed,
    reconcile: isManaged && !isDisconnected,
    remove: isFailed,
    connectPhase2: canConnect && wasSecurityLoaded && !isSecurityAgentEnabled,
    connectAutoscaler: canConnect && wasSecurityLoaded && isSecurityAgentEnabled,
    connectSecurity: isManaged && !isFailed && !isDisconnected && !isPaused && !isPausing && !isResuming && !isConnecting && isOnline && wasSecurityLoaded && !isSecurityAgentEnabled,
  };
};
