import dayjs from 'dayjs';

import { DateUtils, DateTypes, DateFilters } from '@cast/design-system';
import { DateRange, DateTime } from '@cast/types';
import { clearQueryParamSilently, setQueryParamSilently } from '@cast/utils';

import { RangePickerQueryParamKeys } from './types';

export const defaultFilters = [
  DateTypes.DateRangeFilter.ThisMonth,
  DateTypes.DateRangeFilter.Last7Days,
  DateTypes.DateRangeFilter.LastWeek,
  DateTypes.DateRangeFilter.Last2Weeks,
  DateTypes.DateRangeFilter.Last30Days,
  DateTypes.DateRangeFilter.LastMonth,
  DateTypes.DateRangeFilter.Last15Mins,
  DateTypes.DateRangeFilter.Last30Mins,
];

export const defaultQueryParamKeys: RangePickerQueryParamKeys = {
  fromDate: 'date_from',
  toDate: 'date_to',
  dateRangePreset: 'date_preset',
};

const isValidDateRange = (from: DateTime, to: DateTime, maxDiffInDays = 31) =>
  from.isValid() &&
  to.isValid() &&
  from.isBefore(to) &&
  to.diff(from, 'day') <= maxDiffInDays;

const getDateRangeFromQuery = (
  queryParams: URLSearchParams,
  queryParamKeys: RangePickerQueryParamKeys
): DateRange | undefined => {
  const fromDate = dayjs(queryParams.get(queryParamKeys.fromDate));
  const toDate = dayjs(queryParams.get(queryParamKeys.toDate));
  if (isValidDateRange(fromDate, toDate)) {
    return [fromDate, toDate];
  }
  return undefined;
};

const isValidPresetKey = (
  key: string | null | undefined,
  filters: DateTypes.DateRangeFilter[]
): key is DateTypes.DateRangeFilter => {
  return !!key && filters.includes(key as DateTypes.DateRangeFilter);
};

export const getDateRangeFilterFromQuery = (
  queryParams: URLSearchParams,
  filters: DateTypes.DateRangeFilter[],
  queryParamKeys: RangePickerQueryParamKeys
): DateTypes.DateRangeFilter | undefined => {
  const presetKey = queryParams.get(queryParamKeys.dateRangePreset);

  return isValidPresetKey(presetKey, filters) ? presetKey : undefined;
};

export const getDateRangeFilterFromValue = (
  value: DateRange,
  filters: DateTypes.DateRangeFilter[]
): DateTypes.DateRangeFilter | undefined => {
  return filters.find((key) => {
    const filter = DateFilters.allFilters[key];
    return DateUtils.isSelectedFilterPreset(
      { from: value[0].toDate(), to: value[1].toDate() },
      filter
    );
  });
};

export const checkIfDefaultFilter = (
  value: DateRange,
  defaultFilterKey: DateTypes.DateRangeFilter
): boolean => {
  const defaultFilter = DateFilters.allFilters[defaultFilterKey].date();
  return (
    dayjs(value[0]).isSame(defaultFilter.from) &&
    dayjs(value[1]).isSame(defaultFilter.to)
  );
};

const getDateRangePresetFromQuery = (
  queryParams: URLSearchParams,
  filters: DateTypes.DateRangeFilter[],
  queryParamKeys: RangePickerQueryParamKeys
): DateRange | undefined => {
  const filter = getDateRangeFilterFromQuery(
    queryParams,
    filters,
    queryParamKeys
  );

  if (!filter) {
    return undefined;
  }

  const { from, to } = DateFilters.allFilters[filter].date();

  return [dayjs(from), dayjs(to)];
};

export const createDefaultDateRange = (
  queryParams: URLSearchParams,
  defaultFilterPreset: DateTypes.DateRangeFilter,
  filters: DateTypes.DateRangeFilter[],
  queryParamKeys: RangePickerQueryParamKeys,
  dateRange?: DateRange
): DateRange => {
  if (dateRange) {
    return dateRange;
  }

  let { from, to } = DateFilters.allFilters[defaultFilterPreset].date();

  const presetFromQueryParams = getDateRangePresetFromQuery(
    queryParams,
    filters,
    queryParamKeys
  );
  if (presetFromQueryParams) {
    from = presetFromQueryParams[0].toDate();
    to = presetFromQueryParams[1].toDate();
  } else {
    const dateRangeFromQueryParams = getDateRangeFromQuery(
      queryParams,
      queryParamKeys
    );
    if (dateRangeFromQueryParams) {
      from = dateRangeFromQueryParams[0].toDate();
      to = dateRangeFromQueryParams[1].toDate();
    }
  }

  return [dayjs(from), dayjs(to)];
};

export const setDateRangeToQuery = (
  [from, to]: DateRange,
  queryParamKeys: RangePickerQueryParamKeys
) => {
  if (isValidDateRange(from, to)) {
    setQueryParamSilently(queryParamKeys.toDate, to.toISOString());
    setQueryParamSilently(queryParamKeys.fromDate, from.toISOString());
    clearQueryParamSilently([queryParamKeys.dateRangePreset]);
  }
};

export const setDateRangeFilterToQuery = (
  key: DateTypes.DateRangeFilter | undefined,
  filters: DateTypes.DateRangeFilter[],
  queryParamKeys: RangePickerQueryParamKeys
) => {
  if (isValidPresetKey(key, filters)) {
    setQueryParamSilently(queryParamKeys.dateRangePreset, key);
    clearQueryParamSilently([queryParamKeys.fromDate, queryParamKeys.toDate]);
  }
};

export const clearQueryParams = (queryParamKeys: RangePickerQueryParamKeys) => {
  clearQueryParamSilently([
    queryParamKeys.dateRangePreset,
    queryParamKeys.fromDate,
    queryParamKeys.toDate,
  ]);
};
