import orderBy from 'lodash/orderBy';

import { Cluster } from '@cast/types';

import { useClustersQuery } from 'hooks/queries/cluster/useClustersQuery';

type UseClustersArguments = {
  polling?: boolean;
  enabled?: boolean;
};

type UseClustersHook = {
  isLoading: boolean;
  hasError: boolean;
  error?: unknown;
  clusters: Cluster[];
  refetchClusters: () => void;
};

export const useClusters = ({
  polling = false,
  enabled = true,
}: UseClustersArguments = {}): UseClustersHook => {
  const { isLoading, clusters, error, refetchClusters } = useClustersQuery(
    polling,
    enabled
  );

  return {
    isLoading,
    error,
    hasError: Boolean(error),
    clusters: orderBy(clusters, ['createdAt'], ['desc']),
    refetchClusters,
  };
};
