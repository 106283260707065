import {
  PreviewMarkdown as DsPreviewMarkdown,
  PreviewMarkdownCodeElement,
  PreviewMarkdownProps,
} from '@cast/design-system';

import { notify } from 'core/snackbars';

export const PreviewMarkdown = (props: PreviewMarkdownProps) => {
  return (
    <DsPreviewMarkdown
      {...props}
      components={{
        code: (codeProps) => (
          <PreviewMarkdownCodeElement
            {...codeProps}
            onCopy={() => notify.success('Copied to clipboard')}
          />
        ),
        ...props.components,
      }}
    />
  );
};
