import { Stack, Box, Typography } from '@mui/material';

import { Select, ListItem, Checkbox } from '@cast/design-system';

import { NodeIndicator } from 'components/indicators';
import { NodeType } from 'types/nodes';

import { CostReportChartToggle } from '../../../../cost-report/components/CostReportChartToggle';
import { ComputeSpendMode } from '../../../../cost-report/types/costOverTime';
import { useComputeSpendControls } from '../_hooks';

const optionsMap: Record<ComputeSpendMode, string> = {
  [ComputeSpendMode.DAILY_COST]: 'Daily cost',
  [ComputeSpendMode.CUMULATIVE]: 'Cumulative',
};

export const ComputeSpendControls = () => {
  const { chartNodeType, toggleNodeType, chartMode, changeMode } =
    useComputeSpendControls();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      py={8}
      px={16}
      data-testid="compute-spend-controls"
    >
      <Box display="flex" gap={48}>
        {[
          { label: 'On-demand', type: NodeType.ON_DEMAND },
          { label: 'Fallback', type: NodeType.FALLBACK },
          { label: 'Spot', type: NodeType.SPOT },
        ].map(({ label, type }) => (
          <Box
            key={label}
            display="flex"
            alignItems="center"
            component="label"
            gap={8}
          >
            <Checkbox
              size="small"
              checked={chartNodeType[type]}
              onChange={toggleNodeType.bind(null, type)}
              color="tertiary"
              testId={`toggle-${type}`}
            />
            <Typography variant="P12R">{label}</Typography>
            <NodeIndicator size={12} type={type} />
          </Box>
        ))}
      </Box>
      <Stack direction="row" alignItems="center" gap={8}>
        <Select
          testId="change-chart-type"
          size="small"
          value={chartMode}
          onChange={(value) => changeMode(value)}
          optionLabel={(option) => optionsMap[option]}
          options={[ComputeSpendMode.DAILY_COST, ComputeSpendMode.CUMULATIVE]}
          renderOption={(option, listItemProps) => (
            <ListItem {...listItemProps} component="li">
              <Typography variant="P12R">{optionsMap[option]}</Typography>
            </ListItem>
          )}
        />
        <CostReportChartToggle />
      </Stack>
    </Stack>
  );
};
