import {
  demoClusterDetails,
  demoClusterNode,
  demoClusterNodeList,
} from '@cast/fixtures';

import { ClusterApiAdapter } from '../api-client/cluster-api-adapter';
import {
  getOrgId,
  isDemoClusterRequest,
  rejectDemoClusterRequest,
  resolveDemoClusterRequest,
} from '../utils';

export const ClusterApiProxy = (
  origin: ReturnType<typeof ClusterApiAdapter>
) => {
  return {
    ...origin,
    getClusters: async (...args: Parameters<typeof origin.getClusters>) => {
      const response = await origin.getClusters(...args);

      if (!response.data.items?.length && response.config.headers) {
        const fixture = await demoClusterDetails(getOrgId());
        response.data = {
          items: [fixture],
        };
      }

      return response;
    },
    getCluster: async (...args: Parameters<typeof origin.getCluster>) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterDetails(getOrgId());
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getCluster(...args);
    },
    getNodes: async (...args: Parameters<typeof origin.getNodes>) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterNodeList();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getNodes(...args);
    },
    getNode: async (...args: Parameters<typeof origin.getNode>) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterNode(args[0]?.nodeId);
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getNode(...args);
    },
    getCredentialsScript: (
      ...args: Parameters<typeof origin.getCredentialsScript>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.getCredentialsScript(...args);
    },
    getOffboardingScript: (
      ...args: Parameters<typeof origin.getOffboardingScript>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.getOffboardingScript(...args);
    },
    events: (...args: Parameters<typeof origin.events>) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.events(...args);
    },
    updateCluster: (...args: Parameters<typeof origin.updateCluster>) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.updateCluster(...args);
    },
    deleteCluster: (...args: Parameters<typeof origin.deleteCluster>) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.deleteCluster(...args);
    },
    disconnectCluster: (
      ...args: Parameters<typeof origin.disconnectCluster>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.disconnectCluster(...args);
    },
    deleteNode: (...args: Parameters<typeof origin.deleteNode>) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.deleteNode(...args);
    },
  };
};
