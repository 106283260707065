import { useMemo } from 'react';

import dayjs from 'dayjs';

import { PriceType } from '@cast/types';
import { getPriceMultiplier } from '@cast/utils';

import { useCostOverTime } from 'features/cost-report/hooks/useCostOverTime';
import { useCostReportContext } from 'features/cost-report/hooks/useCostReportContext';
import { getUptimeMultiplier } from 'features/cost-report/utils/costOverTime';
import { getDailyAverageMetrics } from 'features/cost-report/utils/metrics/getDailyAverageMetrics';
import { findNearestDataPointToTimestamp } from 'utils/data/findNearestDataPointToTimestamp';

import {
  ComputeSpendChartData,
  CostReportChartDataByPeriod,
  CostReportChartType,
} from '../../../../cost-report/types/costOverTime';
import { makeCumulativeReducerFn } from '../../utils';

export type UseCumulativeChartData = {
  isLoading: boolean;
  data: ComputeSpendChartData[];
};

const reduceSelectedRateCost = (chartType: CostReportChartType) => {
  const multiplier =
    chartType === CostReportChartType.BAR
      ? getPriceMultiplier(PriceType.DAILY)
      : getPriceMultiplier(PriceType.HOURLY);

  return makeCumulativeReducerFn({
    onDemand: multiplier,
    fallback: multiplier,
    spot: multiplier,
  });
};

const reduceUptimeCost = (
  acc: ComputeSpendChartData[],
  datapoint: CostReportChartDataByPeriod,
  index: number,
  applyUptime: boolean
): ComputeSpendChartData[] => {
  const reducerFn = makeCumulativeReducerFn(
    applyUptime
      ? getUptimeMultiplier(datapoint)
      : { onDemand: 1, spot: 1, fallback: 1 }
  );
  return reducerFn(acc, datapoint, index);
};

export const useCumulativeChartData = (): UseCumulativeChartData => {
  const {
    current,
    projectEndOfDayCost,
    applyUptime,
    timeSeriesStep,
    anomalies,
  } = useCostOverTime();
  const { chartType } = useCostReportContext();

  const data = useMemo(() => {
    const dataPoints =
      chartType === CostReportChartType.BAR &&
      timeSeriesStep !== PriceType.DAILY
        ? getDailyAverageMetrics(current.chartData, {
            includeGenerated: false,
            includeForecasted: true,
          })
        : current.chartData;

    const data = dataPoints
      ? dataPoints?.reduce(
          projectEndOfDayCost
            ? reduceSelectedRateCost(chartType)
            : (data: any[], datapoint, index) =>
                reduceUptimeCost(data, datapoint, index, applyUptime),
          []
        )
      : [];

    anomalies?.forEach((anomaly) => {
      const pointToMark = findNearestDataPointToTimestamp(
        data,
        dayjs(anomaly.timestamp)
      );
      if (pointToMark) {
        pointToMark.anomalies.push(anomaly);
      }
    });

    return data;
  }, [
    anomalies,
    applyUptime,
    chartType,
    current.chartData,
    projectEndOfDayCost,
    timeSeriesStep,
  ]);

  return {
    isLoading: current.isLoading,
    data,
  };
};
