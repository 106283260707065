import { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import * as yup from 'yup';

import { organizationQueryKey } from '@cast/constants';
import { Button, Input, useDrawerInstance } from '@cast/design-system';
import {
  SecurityInsightsIntegrationType,
  SecurityInsightsTicketItemType,
} from '@cast/types';
import { makeValidateSync } from '@cast/utils';

import { orgSecurityEvents } from 'core/analytics';
import { RffForm, RffInput, RffTextArea } from 'core/forms/rff';
import { notify } from 'core/snackbars';
import { useCreateTicketMutation } from 'hooks/mutations/security-insights';
import {
  useIntegrationProjectsQuery,
  useIntegrationsQuery,
} from 'hooks/queries/security-insights';
import { useOrganizations } from 'hooks/useOrganizations';

import {
  makeOrgCompliancePath,
  makeOrgVulnerabilityManagementDetailsPath,
} from '../../../navigation';
import { AdditionalData } from '../types';

const validate = makeValidateSync(
  yup.object().shape({
    summary: yup.string().required('Required field'),
    description: yup.string(),
  })
);

const getInitialValues = (
  itemType: SecurityInsightsTicketItemType,
  name: string,
  additionalData?: AdditionalData
) => {
  let summary = '';
  switch (itemType) {
    case SecurityInsightsTicketItemType.Check: {
      summary = `Non-compliant resources detected for check "${name}"`;
      break;
    }
    case SecurityInsightsTicketItemType.Image: {
      summary = `Update image "${name}"`;
      break;
    }
    case SecurityInsightsTicketItemType.Package: {
      summary = `Update package "${name}" with vulnerabilities in "${additionalData?.imageName}"`;
      break;
    }
    case SecurityInsightsTicketItemType.Vulnerability: {
      summary = `Update vulnerability "${name}" with score ${additionalData?.vulnerabilityScore} in "${additionalData?.packageName}"`;
      break;
    }
  }

  return {
    summary,
    description: '',
  };
};

type Props = {
  itemType: SecurityInsightsTicketItemType;
  itemName: string;
  itemId: string;
  onSuccess?: () => void;
  additionalData?: AdditionalData;
};

export const TicketForm = ({
  itemType,
  itemName,
  itemId,
  onSuccess,
  additionalData,
}: Props) => {
  const { close, setOnCloseGuard } = useDrawerInstance();
  const { currentOrganization } = useOrganizations();

  const { integrations } = useIntegrationsQuery();
  const jiraIntegration = useMemo(
    () =>
      integrations?.find(
        (integration) =>
          integration.type === SecurityInsightsIntegrationType.JIRA
      ),
    [integrations]
  );
  const { projects, isLoading: isLoadingProjects } =
    useIntegrationProjectsQuery(jiraIntegration?.id);

  const { mutate, isPending } = useCreateTicketMutation(() => {
    notify.success(
      <Box>
        <Typography variant="P14B" color="green.200" component="span" mr={4}>
          Success!
        </Typography>
        Jira ticket was created
      </Box>
    );
    onSuccess?.();
  });

  const initialValues = useMemo(() => {
    return getInitialValues(itemType, itemName, additionalData);
  }, [itemType, itemName, additionalData]);

  const getConsoleUrl = () => {
    let path = '';
    switch (itemType) {
      case SecurityInsightsTicketItemType.Check: {
        path = makeOrgCompliancePath({ checkIdForDrawer: itemId });
        break;
      }
      case SecurityInsightsTicketItemType.Image: {
        path = makeOrgVulnerabilityManagementDetailsPath(itemId);
        break;
      }
      case SecurityInsightsTicketItemType.Package: {
        path = makeOrgVulnerabilityManagementDetailsPath(
          additionalData?.imageId ?? '',
          {
            packageIdForDrawer: itemId,
          }
        );
        break;
      }
      case SecurityInsightsTicketItemType.Vulnerability: {
        path = makeOrgVulnerabilityManagementDetailsPath(
          additionalData?.imageId ?? '',
          {
            vulnerabilityIdForDrawer: itemId,
          }
        );
        break;
      }
    }
    const url = new URL(`${window.location.origin}${path}`);
    url.searchParams.set(organizationQueryKey, currentOrganization?.id ?? '');
    return url.href;
  };

  return (
    <RffForm
      mode="create"
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      validate={validate}
      onSubmit={(payload: ReturnType<typeof getInitialValues>) => {
        mutate(
          {
            id: jiraIntegration?.id ?? '',
            body: {
              type: itemType,
              typeIds: [itemId],
              summary: payload.summary,
              description: payload.description,
              consoleUrl: getConsoleUrl(),
              projectKey: projects?.[0] ?? '',
            },
          },
          {
            onSuccess: () => {
              close();
              orgSecurityEvents.jiraTicketCreated(itemType);
            },
          }
        );
      }}
      onDirty={(isDirty) => setOnCloseGuard(isDirty)}
    >
      <Stack gap={16}>
        <Typography variant="P14B">Ticket details:</Typography>
        <Input
          label="Project name"
          readOnly
          value={projects?.[0] ?? ''}
          testId="project-name"
        />
        <Input
          label="Issue type"
          readOnly
          value={itemType}
          testId="issue-type"
        />
        <RffInput
          label="Summary (title)"
          name="summary"
          placeholder="Enter name of the ticket"
          testId="summary"
        />
        <RffTextArea
          label="Description"
          name="description"
          placeholder="Enter description"
          testId="description"
        />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" mt={48} mb={24} gap={24}>
        <Button variant="tertiary" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          loading={isPending || isLoadingProjects}
          testId="create-button"
        >
          Create
        </Button>
      </Stack>
    </RffForm>
  );
};
