import { ComponentType, useContext, useEffect } from 'react';

import { styled, Popover, PopoverProps } from '@mui/material';
import { bindPopover } from 'material-ui-popup-state/hooks';

import { DropdownList } from './DropdownList';
import { ListSearchInput } from '../../../lists';
import { DropdownControls } from '../_components/DropdownControls';
import { DropdownContext } from '../DropdownContext';

const StyledDropdownPopper = styled('div', {
  name: 'DsDropdown',
  slot: 'Popper',
  target: 'DsDropdown-Popper',
  overridesResolver: (_, styles) => {
    return [styles.popper];
  },
})({});

StyledDropdownPopper.displayName = 'StyledDropdownPopper';

type Props = {
  PopoverComponent?: ComponentType<PopoverProps>;
};

export const DropdownPopper = ({ PopoverComponent = Popover }: Props) => {
  const {
    dropdownProps,
    popupState,
    autocomplete: {
      inputRef,
      getters: { getInputProps },
      actions: { clearValue, resetValue },
      states: { allowSearch, searchInputProps },
    },
  } = useContext(DropdownContext);

  const { onClose, ...popoverProps } = bindPopover(popupState);

  useEffect(() => {
    if (popoverProps.open) {
      dropdownProps.onOpen?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownProps.onOpen, popoverProps.open]);

  return (
    <PopoverComponent
      componentsProps={
        { root: { 'data-testid': `${dropdownProps.testId}-popover` } } as any
      }
      {...popoverProps}
      onClose={(e, r) => {
        if (r === 'backdropClick') {
          resetValue();
        }
        onClose?.();
        dropdownProps.onClose?.();
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        ...dropdownProps.popoverProps?.anchorOrigin,
      }}
      transformOrigin={{
        vertical:
          dropdownProps.inputProps?.error || dropdownProps.inputProps?.hint
            ? 18
            : 4,
        horizontal: 'left',
        ...dropdownProps.popoverProps?.transformOrigin,
      }}
      transitionDuration={
        // https://github.com/petyosi/react-virtuoso/issues/542
        // totalListHeightChanged is triggered too soon, while animation is still in transition, resulting with wrong initial height
        dropdownProps.virtualization ? 0 : { enter: 150, exit: 0 }
      }
      disableScrollLock
    >
      {allowSearch && inputRef && (
        <ListSearchInput
          autoFocus
          autoComplete="off"
          size={dropdownProps.size}
          placeholder={searchInputProps?.placeholder ?? 'Search'}
          showClearButton
          inputRef={inputRef}
          inputProps={{
            ...getInputProps(),
            ...searchInputProps?.inputProps,
          }}
          clearButtonProps={{ onClick: () => clearValue() }}
        />
      )}

      <DropdownList />

      {dropdownProps.multiple && <DropdownControls />}
    </PopoverComponent>
  );
};
