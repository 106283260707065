import { Link, Stack, Typography } from '@mui/material';

import { Column, TableHeader } from '@cast/design-system';
import { K8sProvider } from '@cast/types';

import { tableCacheKeys } from 'common/tableCacheKeys';
import { K8sProviderIcon } from 'components/icons';
import { Link as RouterLink } from 'components/router';
import { SearchableTableRecordsCount } from 'components/search';
import { getRowRankClass } from 'components/table';

import { BillingReportTableContainer } from './BillingReportTableContainer';
import { CellWithPostfix } from './CellWithPostfix';
import { useBillingReportContext } from '../../../BillingReportContext';
import { NormalizedBillingReportClusterUsage } from '../../../types';

export const BillingReportPerClusterTable = () => {
  const {
    dailyUsage,
    selectedClusters,
    setSelectedClusters,
    topMostExpensiveClusters,
  } = useBillingReportContext();

  return (
    <BillingReportTableContainer<NormalizedBillingReportClusterUsage>
      searchProviderProps={{
        data: dailyUsage,
      }}
      searchableTableProps={{
        rowKey: ({ cluster }) => cluster?.id,
        selectedRows: selectedClusters,
        onRowsSelected: setSelectedClusters,
        maxHeight: '80vh',
        testId: 'per-cluster-table',
        cacheKey: tableCacheKeys.BILLING_REPORT.PER_CLUSTER,
        getRowClass: ({ cluster }) =>
          getRowRankClass(
            topMostExpensiveClusters?.findIndex(
              (item) => item.id === cluster.id
            )
          ),
        outerHeader: (
          <TableHeader
            recordsCount={
              <SearchableTableRecordsCount
                count={selectedClusters.length}
                title="cluster"
              />
            }
          />
        ),
      }}
    >
      <Column<NormalizedBillingReportClusterUsage>
        id="cluster"
        minWidth={400}
        minResizeWidth={90}
        header="Name"
        renderCell={({ cluster }) => {
          return (
            <Stack>
              <Stack direction="row" alignItems="center" gap={4}>
                <K8sProviderIcon
                  provider={cluster?.providerType as K8sProvider}
                  size={16}
                />
                <Link
                  component={RouterLink}
                  to={`/external-clusters/${cluster?.id}`}
                  sx={{
                    width: '100%',
                    display: 'inline-block',
                    typography: 'P12M',
                  }}
                  underline="none"
                  noWrap
                >
                  {cluster?.name}
                </Link>
              </Stack>
              <Typography variant="P10R" color="grey.400" noWrap>
                {cluster?.providerNamespaceId}
              </Typography>
            </Stack>
          );
        }}
        resizable
        sortable
        accessor="cluster.name"
      />
      <Column<NormalizedBillingReportClusterUsage>
        id="cumulativeCpuHours"
        header="Billable CPU hours"
        infoTooltip="Billable CPU Hours are calculated based on the fraction of the month utilised"
        minWidth={238}
        renderCell={({ cumulativeCpuHours }) => (
          <CellWithPostfix value={cumulativeCpuHours} postfix="CPU/h" />
        )}
        sortable
      />

      <Column<NormalizedBillingReportClusterUsage>
        id="cumulativeBillableCpus"
        header="Billable CPUs"
        infoTooltip="One Billable CPU equals one CPU running continuously throughout the month"
        minWidth={217}
        renderCell={({ cumulativeBillableCpus }) => (
          <CellWithPostfix
            value={cumulativeBillableCpus}
            postfix="CPU"
            variant="P12B"
          />
        )}
        sortable
      />
    </BillingReportTableContainer>
  );
};
