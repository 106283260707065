import { Card, ShadowDivider, Skeleton } from '@cast/design-system';

import { BillingReportChart } from './_components/BillingReportChart';
import { ChartSummaries } from './_components/ChartSummaries';
import { useBillingReportContext } from '../../BillingReportContext';

export const BillingReportChartSection = () => {
  const { isLoading } = useBillingReportContext();

  if (isLoading) {
    return <Skeleton height={250} data-testid="chart-section" />;
  }

  return (
    <Card data-hc="success" sx={{ p: 0 }} testId="chart-section">
      <ChartSummaries />
      <ShadowDivider
        opacity={0.2}
        color="grey.200"
        direction="bottom"
        orientation="horizontal"
      />
      <BillingReportChart />
    </Card>
  );
};
