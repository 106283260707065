import { useContext } from 'react';

import { NodeType } from 'types/nodes';

import { ComputeSpendMode } from '../../../../cost-report/types/costOverTime';
import { ComputeSpendContext } from '../ComputeSpendProvider';

export const useComputeSpendControls = () => {
  const [state, dispatch] = useContext(ComputeSpendContext);

  const toggleNodeType = (chartNodeType: NodeType) => {
    dispatch({ type: 'toggleNodeType', chartNodeType });
  };

  const changeMode = (chartMode: ComputeSpendMode) => {
    dispatch({ type: 'changeMode', chartMode });
  };

  return {
    ...state,
    toggleNodeType,
    changeMode,
  } as const;
};
