import { PropsWithChildren, ReactNode, useContext } from 'react';

import { Box, Popover, Typography, Divider, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import pluralize from 'pluralize';

import { DatePickerTrigger } from './DatePickerTrigger';
import { DsShadow } from '../../../theme';
import { mergeSx } from '../../../utils';
import { InputProps } from '../../controls';
import { CalendarBlank } from '../../icons';
import { DatePickerContext } from '../DatePickerProvider';
import { isPeriodComparisonPicker } from '../types';

export type DatePickerContainerProps = {
  aside?: ReactNode;
  inputSx?: SxProps;
  popoverSx?: SxProps;
  inputProps?: Pick<InputProps, 'size' | 'sx'>;
  testId?: string;
};

const DatePickerContainer = ({
  children,
  aside,
  popoverSx,
  inputProps,
  testId = 'date-picker',
}: PropsWithChildren<DatePickerContainerProps>) => {
  const ctx = useContext(DatePickerContext);

  const isOpen = Boolean(ctx.popoverAnchor);

  return (
    <Box display="flex" sx={{ width: '100%' }}>
      <DatePickerTrigger
        onClick={ctx.handleOpen}
        isOpen={isOpen}
        sx={{ width: '100%' }}
        inputProps={{
          size: 'large',
          readOnly: true,
          focused: isOpen,
          value: ctx.inputValue(),
          startAdornment: isPeriodComparisonPicker(ctx) ? (
            <Stack direction="row" alignItems="center" gap="12px" height="100%">
              <Typography
                variant="P14R"
                color="grey.400"
                noWrap
                data-testid="active-period"
              >
                {ctx.activePeriod} {pluralize('day', ctx.activePeriod)}
              </Typography>
              <Divider orientation="vertical" />
              <CalendarBlank size="20px" />
            </Stack>
          ) : (
            <CalendarBlank size="20px" />
          ),
          ...inputProps,
          sx: mergeSx(
            {
              minWidth: 320,
              '& *:hover': { cursor: 'pointer !important' },
              '& .DS-Input-readOnly': {
                backgroundColor: 'white !important',
              },
            },
            inputProps?.sx
          ),
        }}
        testId={testId}
      />
      <Popover
        sx={mergeSx({ top: 4 }, popoverSx)}
        open={isOpen}
        anchorEl={ctx.popoverAnchor}
        onClose={ctx.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-testid={`${testId}-popover`}
        disableScrollLock
      >
        <Box
          display="flex"
          width="fit-content"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.grey[100]}`,
            borderRadius: '4px',
            boxShadow: theme.shadows[DsShadow.SHADOW],
            backgroundColor: theme.palette.common.white,
          })}
        >
          {aside}

          <div>{children}</div>
        </Box>
      </Popover>
    </Box>
  );
};

export default DatePickerContainer;
