import { getDeserializedQueryParam } from '@cast/utils';

import { checksTableUrlKey } from './compliance/constants';
import {
  checkDetailsDrawerUrlKey,
  imagePackageDetailsDrawerUrlKey,
  vulnerabilityManagementDetailsVulnerabilitiesTabsUrlKey,
  imageVulnerabilityDetailsDrawerUrlKey,
} from './constants';
import { ImageVulnerabilitiesTabEnum } from './types';
import { vulnerabilityManagementTableUrlKey } from './vulnerability-management/constants';

export type OrgSecurityRouteKeys =
  | 'compliance'
  | 'vulnerability-management'
  | 'attack-path'
  | 'runtime'
  | 'workloads'
  | 'settings'
  | 'node-os-updates';

const complianceBasePath = '/organization/security/compliance';
const vulnerabilityManagementBasePath =
  '/organization/security/vulnerability-management';
const attackPathBasePath = '/organization/security/attack-path';
const runtimeBasePath = '/organization/security/runtime';
const workloadsBasePath = '/organization/security/workloads';
const settingsBasePath = '/organization/security/settings';
const nodeReplacementBasePath = '/organization/security/node-os-updates';

const urlKeysMap: Record<string, string> = {
  [complianceBasePath]: checksTableUrlKey,
  [vulnerabilityManagementBasePath]: vulnerabilityManagementTableUrlKey,
};

const orgSecurityPathMap: Record<OrgSecurityRouteKeys, string> = {
  compliance: complianceBasePath,
  'vulnerability-management': vulnerabilityManagementBasePath,
  'attack-path': attackPathBasePath,
  runtime: runtimeBasePath,
  workloads: workloadsBasePath,
  settings: settingsBasePath,
  'node-os-updates': nodeReplacementBasePath,
};

const getInheritedFilters = () => {
  const urlKey = urlKeysMap[window.location.pathname];
  if (!urlKey) {
    return {};
  }
  const { clusterIds, namespaces } = getDeserializedQueryParam(urlKey) ?? {};
  if (clusterIds?.length || namespaces?.length) {
    return { clusterIds, namespaces };
  }
  return {};
};

const getFilterParams = (
  targetUrlKey: string,
  params: { clusterIds: string[]; namespaces: string[] }
) => {
  const urlParams = new URLSearchParams();
  if (params.clusterIds?.length || params.namespaces?.length) {
    urlParams.set(targetUrlKey, encodeURIComponent(JSON.stringify(params)));
  }
  return urlParams;
};

export const makeOrgCompliancePath = (
  options: {
    clusterIds?: string[];
    namespaces?: string[];
    checkIdForDrawer?: string;
  } = {}
) => {
  const { clusterIds, namespaces } = getInheritedFilters();
  const urlParams = getFilterParams(checksTableUrlKey, {
    clusterIds: clusterIds ?? options.clusterIds,
    namespaces: namespaces ?? options.namespaces,
  });
  if (options.checkIdForDrawer) {
    urlParams.set(checkDetailsDrawerUrlKey, options.checkIdForDrawer);
  }
  return `${complianceBasePath}?${urlParams.toString()}`;
};

export const makeOrgVulnerabilityManagementPath = () => {
  const { clusterIds, namespaces } = getInheritedFilters();
  const urlParams = getFilterParams(vulnerabilityManagementTableUrlKey, {
    clusterIds,
    namespaces,
  });
  return `${vulnerabilityManagementBasePath}?${urlParams.toString()}`;
};

export const makeOrgVulnerabilityManagementDetailsPath = (
  id: string,
  options: {
    vulnerabilityIdForDrawer?: string;
    packageIdForDrawer?: string;
  } = {}
) => {
  const urlParams = new URLSearchParams();
  if (options.vulnerabilityIdForDrawer) {
    urlParams.set(
      vulnerabilityManagementDetailsVulnerabilitiesTabsUrlKey,
      ImageVulnerabilitiesTabEnum.VULNERABILITIES
    );
    urlParams.set(
      imageVulnerabilityDetailsDrawerUrlKey,
      options.vulnerabilityIdForDrawer
    );
  }
  if (options.packageIdForDrawer) {
    urlParams.set(
      vulnerabilityManagementDetailsVulnerabilitiesTabsUrlKey,
      ImageVulnerabilitiesTabEnum.PACKAGES
    );
    urlParams.set(imagePackageDetailsDrawerUrlKey, options.packageIdForDrawer);
  }
  return `${vulnerabilityManagementBasePath}/${id}?${urlParams.toString()}`;
};

export const makeAttackPathDetailsPath = (id: string) => {
  return `${attackPathBasePath}/${id}`;
};

export const makeRuntimePath = (tab: 'anomalies' | 'rules') => {
  return `${runtimeBasePath}/${tab}`;
};

export const makeAnomalyDetailsPath = (anomalyId: string) => {
  return `${runtimeBasePath}/anomalies/${anomalyId}`;
};
export const makeWorkloadsPath = (
  id: string,
  tab?: 'compliance' | 'vulnerability-management' | 'runtime' | 'attack-path'
) => {
  return `${workloadsBasePath}/${id}${tab ? `/${tab}` : ''}`;
};

export const makeImageDetailsPath = (id: string) => {
  return `${vulnerabilityManagementBasePath}/${id}`;
};

export const getOrgSecurityRoutePath = (key: OrgSecurityRouteKeys) => {
  return orgSecurityPathMap[key];
};
