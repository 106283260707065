import { cloneElement, ReactElement, useRef } from 'react';

import { stringify } from 'query-string';

type Props = {
  children: ReactElement<{ onClick?: (event: MouseEvent) => void }>;
  screenHint?: string;
};

export const LogoutWrapper = ({ children, screenHint }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <form
      action={`/api/auth/logout?${stringify({
        returnTo: `${window.location.origin}${
          screenHint ? `?screen_hint=${screenHint}` : '/'
        }`,
      })}`}
      ref={formRef}
      method="post"
    >
      {cloneElement(children, {
        onClick: (e: MouseEvent) => {
          children.props.onClick?.(e);
          formRef.current?.submit();
        },
      })}
    </form>
  );
};
