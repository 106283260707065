import Box from '@mui/material/Box';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import range from 'lodash/range';

export type SkeletonTableProps = SkeletonProps<
  'span',
  {
    rowCount?: number;
    rowHeight?: number;
    size: 'small' | 'medium' | 'large' | 'x-large';
  }
>;

export const SkeletonTable = ({
  rowCount = 4,
  size,
  ...skeletonProps
}: SkeletonTableProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      className={`DSuiSkeletonTable-${size}`}
    >
      {range(rowCount).map((item) => (
        <Skeleton key={item} {...skeletonProps} />
      ))}
    </Box>
  );
};
