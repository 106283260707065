import { Stack, Typography } from '@mui/material';

import {
  Button,
  DialogActions,
  DialogContent,
  Skeleton,
} from '@cast/design-system';

import { SecurityVisualSvg } from 'assets/svg';
import { EXTERNAL_LINKS } from 'common/links';
import { useSwipeableViews } from 'components/boxes';
import { AgentCodeSnippet } from 'components/code';
import { ExternalLink } from 'components/router';
import { useCredentialsScriptQuery } from 'hooks/queries/cluster';

import { AssignCredentialsDialogHeader } from '../../../_components/AssignCredentialsDialogHeader';

type Props = {
  onClose: () => void;
  clusterId: string;
};

export const RunTheScriptSlide = ({ onClose, clusterId }: Props) => {
  const swipeableViewsCtx = useSwipeableViews();
  const { isLoading, script, error, refetch } = useCredentialsScriptQuery({
    id: clusterId,
    installSecurityAgent: true,
    installAutoscalerAgent: true,
  });

  return (
    <Stack position="relative">
      <AssignCredentialsDialogHeader dismiss={onClose} />

      <DialogContent sx={{ pb: 0 }}>
        <Stack direction="row" alignItems="center" gap={24} mb={24}>
          <Stack gap={4}>
            <Typography variant="h6">
              Enable kubernetes & cloud security
            </Typography>
            <Typography variant="P12R" color="textSecondary">
              All the key Kubernetes security insights in one place. For CAST AI
              to assess private images please read{' '}
              <ExternalLink
                link={
                  EXTERNAL_LINKS.castai_kvisor_docs_enable_private_image_scan
                }
              >
                documentation
              </ExternalLink>
            </Typography>
          </Stack>
          <SecurityVisualSvg css={{ width: 40, height: 40, flexShrink: 0 }} />
        </Stack>

        <Typography variant="L10M" color="grey.400" mb={8}>
          RUN THE SCRIPT:
        </Typography>
      </DialogContent>

      <DialogContent sx={{ py: 16, backgroundColor: 'grey.100' }}>
        {isLoading || !script ? (
          <Skeleton width="100%" height={70} />
        ) : (
          <AgentCodeSnippet code={script} error={error} refetch={refetch} />
        )}
      </DialogContent>

      <DialogActions sx={{ py: 16 }}>
        <Button
          onClick={() => swipeableViewsCtx?.goToNextSlide()}
          testId="i-ran-script-button"
        >
          I ran the script
        </Button>
      </DialogActions>
    </Stack>
  );
};
