import { forwardRef, useMemo, useState } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';
import round from 'lodash/round';

import { Button, Paper, RatioProgressBar, Tooltip } from '@cast/design-system';
import { isDemoMode, useElementWidth } from '@cast/utils';

import { K8sProviderIcons } from 'components/icons';
import { InfoTooltip } from 'components/tooltip';
import { flags } from 'core/flags';
import { RBACGuard } from 'core/rbac';
import {
  useAssignCredentialsDialog,
  useReconnectClusterDialog,
} from 'hooks/dialogs';
import { useAssignAutoscalerCredentialsDialog } from 'hooks/dialogs/cluster/assign-credentials/useAssignAutoscalerCredentialsDialog';
import { useAvailableSavingsQuery } from 'hooks/queries/available-savings';
import { useSecurityInsightsAgentQuery } from 'hooks/queries/security-insights';
import { useCluster } from 'hooks/useCluster';
import { isKOPSCluster } from 'types/cluster';

import { useAdditionalSavings } from '../../_hooks/useAdditionalSavings';
import { useCurrentPrice } from '../../_hooks/useCurrentPrice';
import { useCombinedAvailableSavings } from '../../hooks/useCombinedAvailableSavings';
import { ClusterNameRow } from '../_components/ClusterNameRow';
import { ColoredPaper } from '../_components/ColoredPaper';
import { MoneySavings } from '../_components/MoneySavings';
import { SavingsPieChart } from '../SavingsPieChart';

const SavingsRow = () => {
  const { openReconnectDialog } = useReconnectClusterDialog();
  const additionalSavings = useAdditionalSavings();
  const {
    cluster,
    isManaged,
    isOnline,
    isDisconnected,
    canBeManaged,
    isTerraformManaged,
  } = useCluster();
  const { hasLoaded } = useAvailableSavingsQuery(cluster.id);

  const showReconnectButton = hasLoaded && isDisconnected && !isDemoMode();
  const showStartSavingButton =
    hasLoaded &&
    !isDisconnected &&
    canBeManaged &&
    !isDemoMode() &&
    !isKOPSCluster(cluster);

  const { openAssignCredentialsDialog } = useAssignCredentialsDialog({
    cluster,
  });
  const { openAssignAutoscalerCredentialsDialog } =
    useAssignAutoscalerCredentialsDialog();
  const {
    agent: securityAgent,
    isLoading: isLoadingSecurityAgent,
    error: securityAgentsError,
  } = useSecurityInsightsAgentQuery(cluster.id);
  const wasSecurityLoaded = !isLoadingSecurityAgent || !!securityAgentsError;

  return (
    <ColoredPaper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={20}
        py={16}
      >
        <Stack direction="row" gap={38} alignItems="center">
          <SavingsPieChart
            size={65}
            value={{
              configurationSavings:
                additionalSavings.configurationSavingsRatioPercentage,
              workloadRightsizing:
                additionalSavings.rightsizingSavingsRatioPercentage,
            }}
            disableTooltip={
              !flags.standalone['available-savings-workload-rightsizing'].value
            }
          />
          <Stack gap={1}>
            <Typography variant="L10R">
              You CAN save
              <InfoTooltip title="We're gathering data to analyze your workloads, cluster makeup, and usage. Some savings estimations will be available instantly, others might take hours or days. Check back here to watch your savings grow." />
            </Typography>
            <Typography variant="h2" data-testid="total-percentage">
              {additionalSavings.totalPercentage
                ? additionalSavings.totalPercentage.toFixed(1)
                : 0}
              %
            </Typography>
          </Stack>
        </Stack>

        <Stack gap={7}>
          <Typography variant="L10R">Monthly savings</Typography>
          <MoneySavings
            savings={additionalSavings.monthlySavings}
            suffix="mo"
            primaryTextProps={{ variant: 'h4' }}
            suffixTextProps={{ variant: 'P14R' }}
            testId="monthly-savings"
          />
        </Stack>

        <Stack gap={7}>
          <Typography variant="L10R">Annual savings</Typography>
          <MoneySavings
            savings={additionalSavings.monthlySavings * 12}
            suffix="y"
            primaryTextProps={{ variant: 'h4' }}
            suffixTextProps={{ variant: 'P14R' }}
            testId="annual-savings"
          />
        </Stack>

        {(showStartSavingButton || showReconnectButton) && (
          <Stack
            direction="row"
            justifyContent={!hasLoaded ? 'flex-end' : 'space-between'}
            alignItems="center"
          >
            <RBACGuard permissionType="edit">
              {(permission) => (
                <>
                  {showStartSavingButton && (
                    <Button
                      variant="secondary"
                      size="large"
                      disabled={!isOnline || !permission || !wasSecurityLoaded}
                      onClick={
                        securityAgent?.installed
                          ? openAssignAutoscalerCredentialsDialog
                          : openAssignCredentialsDialog
                      }
                    >
                      Start saving now
                    </Button>
                  )}
                  {showReconnectButton && (
                    <Tooltip
                      title="To perform this action please use Terraform"
                      disableHoverListener={!isTerraformManaged}
                    >
                      <div>
                        <Button
                          size="large"
                          disabled={!permission || isTerraformManaged}
                          onClick={() =>
                            openReconnectDialog(cluster, isManaged)
                          }
                        >
                          Reconnect
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
            </RBACGuard>
          </Stack>
        )}
      </Stack>
    </ColoredPaper>
  );
};

type ProgressBarProps = {
  savings: number;
  barWidth: number;
  withCast?: boolean;
  testId?: string;
};

const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  ({ savings, barWidth, withCast, testId }: ProgressBarProps, ref) => {
    const { cluster } = useCluster();

    return (
      <Stack direction="row" alignItems="center" gap={16} height={30}>
        <RatioProgressBar
          hideBorders
          size="large"
          sx={{
            width: barWidth,
            height: '16px',
            borderRadius: '8px',
            transition: 'width .4s',
          }}
          bars={
            withCast
              ? [
                  {
                    value: 100,
                    color: 'green.200',
                  },
                ]
              : [
                  {
                    value: 100,
                    color: 'blue.200',
                  },
                ]
          }
        />
        <Stack direction="row" ref={ref}>
          <MoneySavings
            savings={savings}
            primaryTextProps={{ variant: 'h5' }}
            suffix="mo"
            suffixTextProps={{ variant: 'P14R' }}
            testId={testId}
          />
          <Divider
            orientation="vertical"
            sx={{ height: 'auto', mx: 12, borderLeft: 'grey.500' }}
          />
          <K8sProviderIcons
            provider={cluster.providerType}
            castEnabled={withCast}
            variant="large"
          />
        </Stack>
      </Stack>
    );
  }
);
ProgressBar.displayName = 'ProgressBar';

const ProgressBarsRow = () => {
  const { currentMonthlyPrice, hasLoaded } = useCurrentPrice();
  const { monthlyPrice } = useCombinedAvailableSavings();
  const { potentialSavings, totalPercentage } = useAdditionalSavings();

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [beforeCostRef, setBeforeCostRef] = useState<HTMLDivElement | null>(
    null
  );
  const [afterCostRef, setAfterCostRef] = useState<HTMLDivElement | null>(null);

  const containerWidth = useElementWidth(containerRef, hasLoaded);
  const beforeCostWidth = useElementWidth(beforeCostRef, hasLoaded);
  const afterCostWidth = useElementWidth(afterCostRef, hasLoaded);

  const [beforeBarWidth, afterBarWidth] = useMemo(() => {
    if (!containerWidth || !beforeCostWidth || !afterCostWidth || !hasLoaded) {
      return [0, 0];
    }
    const offset = Math.max(beforeCostWidth, afterCostWidth);
    const maxWidth = containerWidth - offset;

    const after = maxWidth * ((100 - totalPercentage) / 100);
    return [Math.max(2, round(maxWidth, 2)), Math.max(2, round(after, 2))];
  }, [
    containerWidth,
    beforeCostWidth,
    afterCostWidth,
    hasLoaded,
    totalPercentage,
  ]);

  return (
    <Box px={20} pt={16} pb={20}>
      <Stack gap={8} ref={setContainerRef}>
        <Typography variant="h6">Cluster compute cost</Typography>
        <ProgressBar
          savings={currentMonthlyPrice}
          barWidth={beforeBarWidth}
          ref={setBeforeCostRef}
          testId="current-monthly-cost"
        />
        <ProgressBar
          savings={monthlyPrice - potentialSavings}
          barWidth={afterBarWidth}
          withCast
          ref={setAfterCostRef}
          testId="potential-monthly-cost"
        />
      </Stack>
    </Box>
  );
};

export const Phase1NonOptimal = () => {
  return (
    <Paper
      className="tour-highlight"
      data-hc="success"
      data-testid="phase1-non-optimal"
    >
      <ClusterNameRow />
      <SavingsRow />
      <ProgressBarsRow />
    </Paper>
  );
};
