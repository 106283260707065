import { forwardRef, ReactNode } from 'react';

import { styled } from '@mui/material';
import { SxProps } from '@mui/system';
import sumBy from 'lodash/sumBy';

import { Size } from './types';
import { makeShouldForwardProp } from '../../../utils/shouldForwardProp';

type BarProps = {
  value: number;
  color?: string;
  sx?: SxProps;
  children?: ReactNode;
};

export type RatioProgressBarProps = {
  bars: BarProps[];
  sx?: SxProps;
  size?: Size;
  total?: number;
  hideBorders?: boolean;
  className?: string;
  testId?: string;
};

const Root = styled('div', {
  name: 'DsRatioProgressBar',
  slot: 'Root',
  target: 'DsRatioProgressBar-Root',
  shouldForwardProp: makeShouldForwardProp('hideBorders'),
  overridesResolver: ({ size = 'large', hideBorders = false }, styles) => {
    return [
      styles.root,
      styles[size || 'large'],
      hideBorders ? styles.noBorders : undefined,
    ];
  },
})<{ size: Size; hideBorders?: boolean }>``;

const BarContainer = styled('div', {
  name: 'DsRatioProgressBar',
  slot: 'BarContainer',
  target: 'DsRatioProgressBar-BarContainer',
  overridesResolver: (_, styles) => {
    return [styles.barContainer];
  },
})``;

const Bar = styled('div', {
  name: 'DsRatioProgressBar',
  slot: 'Bar',
  target: 'DsRatioProgressBar-Bar',
  overridesResolver: (_, styles) => {
    return [styles.bar];
  },
})``;

const getPercentage = (value: number, total: number) => {
  return (value * 100) / (total || 1);
};

export const RatioProgressBar = forwardRef<
  HTMLDivElement,
  RatioProgressBarProps
>(
  (
    {
      sx,
      size = 'large',
      bars,
      total,
      className,
      testId,
      hideBorders = false,
    }: RatioProgressBarProps,
    ref
  ) => {
    let barsSum = total;
    const _bars = [];

    if (barsSum == null) {
      barsSum = sumBy(bars, 'value');
    }

    let current = 0;
    for (const { value, color, ...rest } of bars) {
      current += getPercentage(value, barsSum);
      _bars.push({ value: current, color, ...rest });
    }
    _bars.reverse();

    return (
      <Root
        data-testid={testId}
        sx={sx}
        size={size}
        className={className}
        hideBorders={hideBorders}
        ref={ref}
      >
        {_bars?.map((bar, index) => (
          <BarContainer key={index}>
            <Bar
              sx={{
                flexBasis: bar.value + '%',
                backgroundColor: bar.color,
                ...bar.sx,
              }}
            >
              {bar.children}
            </Bar>
          </BarContainer>
        ))}
      </Root>
    );
  }
);

RatioProgressBar.displayName = 'RatioProgressBar';
