import { useRef } from 'react';

import clsx from 'clsx';
import {
  DayProps,
  useDayRender,
  Button as DayPickerButton,
} from 'react-day-picker';

const DayPickerDayWrapper = (props: DayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);

  if (dayRender.isHidden) {
    return null;
  }

  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  // prettier-ignore
  return (
    <div
      className={clsx(
        'rdp-day-container',
        props.date.getDay() === 1 && 'rdp-day-container--first_day_of_week',
        props.date.getDay() === 0 && 'rdp-day-container--last_day_of_week',
        dayRender.activeModifiers.hovered && 'rdp-day-container--hovered',
        dayRender.activeModifiers.hoverTrail && 'rdp-day-container--hover_trail',
        dayRender.activeModifiers.hoverTrailStart && 'rdp-day-container--hover_trail_start',
        dayRender.activeModifiers.hoverTrailEnd && 'rdp-day-container--hover_trail_end',
        dayRender.activeModifiers.selected && 'rdp-day-container_selected',
        dayRender.activeModifiers.selectedHoverTrail && 'rdp-day-container__selected--hover_trail',
        dayRender.activeModifiers.selectedHoverTrailStart && 'rdp-day-container__selected--hover_trail_start',
        dayRender.activeModifiers.selectedHoverTrailEnd && 'rdp-day-container__selected--hover_trail_end',
        dayRender.activeModifiers.today && 'rdp-day-container_today',
        dayRender.activeModifiers.range_start && 'rdp-day-container_range_start',
        dayRender.activeModifiers.range_end && 'rdp-day-container_range_end'
      )}
    >
      <DayPickerButton {...dayRender.buttonProps} ref={buttonRef} />
    </div>
  );
};

export default DayPickerDayWrapper;
