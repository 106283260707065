/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AutoscalerAPIGenerateRebalancingPlanRequest } from '../models';
// @ts-ignore
import { AutoscalerAPIGetAgentConfigRequest } from '../models';
// @ts-ignore
import { AutoscalerAPIIngestComponentLogsRequest } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GenerateRebalancingPlanResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GetAgentConfigResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GetClusterAgentInfoResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GetClusterSettingsResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GetClusterWorkloadsResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GetEvictorConfigResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GetKarpenterMigrationIntentResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GetProblematicNodesResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GetProblematicWorkloadsResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1GetRebalancedWorkloadsResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1ListRebalancingPlansResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1MigrateKarpenterDefinitionsResponse } from '../models';
// @ts-ignore
import { CastaiAutoscalerV1beta1RebalancingPlanResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { GoogleApiHttpBody } from '../models';
/**
 * AutoscalerAPIApi - axios parameter creator
 * @export
 */
export const AutoscalerAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Triggers and executes the rebalancing plan.
         * @summary Trigger the rebalancing plan
         * @param {string} clusterId The id of the cluster which should be rebalanced.
         * @param {string} rebalancingPlanId The id of the rebalancing plan to use for rebalancing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIExecuteRebalancingPlan: async (clusterId: string, rebalancingPlanId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIExecuteRebalancingPlan', 'clusterId', clusterId)
            // verify required parameter 'rebalancingPlanId' is not null or undefined
            assertParamExists('autoscalerAPIExecuteRebalancingPlan', 'rebalancingPlanId', rebalancingPlanId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-plans/{rebalancingPlanId}/execute`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"rebalancingPlanId"}}`, encodeURIComponent(String(rebalancingPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiates the cluster rebalancing process which will optimize current node configuration by consolidating existing workloads into fewer bin-packed, right-sized nodes.  This is a long-running operation, the request is queued and the response is immediate.
         * @summary Generate a cluster rebalancing plan
         * @param {string} clusterId ID of the cluster to be rebalanced.
         * @param {AutoscalerAPIGenerateRebalancingPlanRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGenerateRebalancingPlan: async (clusterId: string, body: AutoscalerAPIGenerateRebalancingPlanRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGenerateRebalancingPlan', 'clusterId', clusterId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('autoscalerAPIGenerateRebalancingPlan', 'body', body)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-plans`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
         * @summary Get the Agent configuration
         * @param {string} clusterId ID of the cluster.
         * @param {AutoscalerAPIGetAgentConfigRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentConfig: async (clusterId: string, body: AutoscalerAPIGetAgentConfigRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetAgentConfig', 'clusterId', clusterId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('autoscalerAPIGetAgentConfig', 'body', body)
            const localVarPath = `/v1/agent/config/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
         * @summary Get the Agent configuration
         * @param {string} clusterId ID of the cluster.
         * @param {string} [agentVersion] Agent version.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentConfig2: async (clusterId: string, agentVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetAgentConfig2', 'clusterId', clusterId)
            const localVarPath = `/v1/agent/config/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentVersion !== undefined) {
                localVarQueryParameter['agentVersion'] = agentVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
         * @summary Get the Agent configuration
         * @param {string} clusterId ID of the cluster.
         * @param {AutoscalerAPIGetAgentConfigRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentConfig3: async (clusterId: string, body: AutoscalerAPIGetAgentConfigRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetAgentConfig3', 'clusterId', clusterId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('autoscalerAPIGetAgentConfig3', 'body', body)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/agent-config`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * All parameters are optional. If no parameters are provided, then the script will default to EKS configuration.  The returned install script can be run in your terminal to install the [CASTAI Kubernetes agent](https://github.com/castai/k8s-agent). The script can also be used to update an already running agent in your cluster.  The agent supports automatic discovery of cluster properties. However, if you specify at least one of the properties, then all properties for that provider must be specified.  OpenShift (ROSA) supports autodiscovery so choosing it does not require any OpenShift parameters. However, autodiscovery can be turned off by providing the properties explicitly. When any of the OpenShift parameters are provided, then all OpenShift parameters must be provided, with the exception of `openshift.runAsUser`, `openshift.runAsGroup` and `openshift.fsGroup`, which are optional and can be specified without the other parameters.
         * @summary Get a Kubernetes agent install script
         * @param {string} [eksRegion] AWS region of your EKS cluster.
         * @param {string} [eksAccountId] Your AWS account id. Can be retrieved by executing &#x60;aws sts get-caller-identity&#x60;.
         * @param {string} [eksClusterName] The name of your EKS cluster.
         * @param {string} [gkeRegion] GCP region of your GKE cluster.
         * @param {string} [gkeProjectId] GCP project id in which your GKE cluster is created.
         * @param {string} [gkeClusterName] The name of your GKE cluster.
         * @param {string} [gkeLocation] Location of your GKE cluster.
         * @param {AutoscalerAPIGetAgentScriptProviderEnum} [provider] Provider of the cluster.
         * @param {AutoscalerAPIGetAgentScriptKopsCspEnum} [kopsCsp] The Cloud Service Provider (CSP) of your kOps cluster.  Possible values are: &#x60;aws&#x60;, &#x60;gcp&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
         * @param {string} [kopsRegion] The region of your kOps cluster. Region is CSP specific.
         * @param {string} [kopsClusterName] The name of your kOps cluster.
         * @param {string} [kopsStateStore] The kOps cluster state store. Only remote S3 state is supported at the moment.
         * @param {string} [aksLocation] Azure location of your AKS cluster.
         * @param {string} [aksNodeResourceGroup] Azure resource group where AKS nodes are deployed.
         * @param {string} [aksSubscriptionId] Azure account subscription id.
         * @param {AutoscalerAPIGetAgentScriptOpenshiftCspEnum} [openshiftCsp] The Cloud Service Provider (CSP) of your OpenShift cluster.  Possible values are: &#x60;aws&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
         * @param {string} [openshiftRegion] The region of your OpenShift cluster. Region is CSP specific.
         * @param {string} [openshiftClusterName] The name of your OpenShift cluster.
         * @param {string} [openshiftInternalId] The OpenShift cluster ID. It can be found in the ClusterVersion object.  [Link to docs](https://docs.openshift.com/container-platform/4.8/support/gathering-cluster-data.html#support-get-cluster-id_gathering-cluster-data).
         * @param {string} [openshiftRunAsUser] The uid of the user that runs the agent pod.
         * @param {string} [openshiftRunAsGroup] The gid of the user that runs the agent pod.
         * @param {string} [openshiftFsGroup] The gid of the user that owns the agent pod\&#39;s volumes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentScript: async (eksRegion?: string, eksAccountId?: string, eksClusterName?: string, gkeRegion?: string, gkeProjectId?: string, gkeClusterName?: string, gkeLocation?: string, provider?: AutoscalerAPIGetAgentScriptProviderEnum, kopsCsp?: AutoscalerAPIGetAgentScriptKopsCspEnum, kopsRegion?: string, kopsClusterName?: string, kopsStateStore?: string, aksLocation?: string, aksNodeResourceGroup?: string, aksSubscriptionId?: string, openshiftCsp?: AutoscalerAPIGetAgentScriptOpenshiftCspEnum, openshiftRegion?: string, openshiftClusterName?: string, openshiftInternalId?: string, openshiftRunAsUser?: string, openshiftRunAsGroup?: string, openshiftFsGroup?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/agent.sh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eksRegion !== undefined) {
                localVarQueryParameter['eks.region'] = eksRegion;
            }

            if (eksAccountId !== undefined) {
                localVarQueryParameter['eks.accountId'] = eksAccountId;
            }

            if (eksClusterName !== undefined) {
                localVarQueryParameter['eks.clusterName'] = eksClusterName;
            }

            if (gkeRegion !== undefined) {
                localVarQueryParameter['gke.region'] = gkeRegion;
            }

            if (gkeProjectId !== undefined) {
                localVarQueryParameter['gke.projectId'] = gkeProjectId;
            }

            if (gkeClusterName !== undefined) {
                localVarQueryParameter['gke.clusterName'] = gkeClusterName;
            }

            if (gkeLocation !== undefined) {
                localVarQueryParameter['gke.location'] = gkeLocation;
            }

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            if (kopsCsp !== undefined) {
                localVarQueryParameter['kops.csp'] = kopsCsp;
            }

            if (kopsRegion !== undefined) {
                localVarQueryParameter['kops.region'] = kopsRegion;
            }

            if (kopsClusterName !== undefined) {
                localVarQueryParameter['kops.clusterName'] = kopsClusterName;
            }

            if (kopsStateStore !== undefined) {
                localVarQueryParameter['kops.stateStore'] = kopsStateStore;
            }

            if (aksLocation !== undefined) {
                localVarQueryParameter['aks.location'] = aksLocation;
            }

            if (aksNodeResourceGroup !== undefined) {
                localVarQueryParameter['aks.nodeResourceGroup'] = aksNodeResourceGroup;
            }

            if (aksSubscriptionId !== undefined) {
                localVarQueryParameter['aks.subscriptionId'] = aksSubscriptionId;
            }

            if (openshiftCsp !== undefined) {
                localVarQueryParameter['openshift.csp'] = openshiftCsp;
            }

            if (openshiftRegion !== undefined) {
                localVarQueryParameter['openshift.region'] = openshiftRegion;
            }

            if (openshiftClusterName !== undefined) {
                localVarQueryParameter['openshift.clusterName'] = openshiftClusterName;
            }

            if (openshiftInternalId !== undefined) {
                localVarQueryParameter['openshift.internalId'] = openshiftInternalId;
            }

            if (openshiftRunAsUser !== undefined) {
                localVarQueryParameter['openshift.runAsUser'] = openshiftRunAsUser;
            }

            if (openshiftRunAsGroup !== undefined) {
                localVarQueryParameter['openshift.runAsGroup'] = openshiftRunAsGroup;
            }

            if (openshiftFsGroup !== undefined) {
                localVarQueryParameter['openshift.fsGroup'] = openshiftFsGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the CAST agent yaml ready to be applied on user\'s cluster
         * @param {AutoscalerAPIGetAgentYamlProviderEnum} [provider] Provider of the cluster.
         * @param {string} [eksRegion] AWS region of your EKS cluster.
         * @param {string} [eksAccountId] Your AWS account id. Can be retrieved by executing &#x60;aws sts get-caller-identity&#x60;.
         * @param {string} [eksClusterName] The name of your EKS cluster.
         * @param {string} [gkeRegion] GCP region of your GKE cluster.
         * @param {string} [gkeProjectId] GCP project id in which your GKE cluster is created.
         * @param {string} [gkeClusterName] The name of your GKE cluster.
         * @param {string} [gkeLocation] Location of your GKE cluster.
         * @param {AutoscalerAPIGetAgentYamlKopsCspEnum} [kopsCsp] The Cloud Service Provider (CSP) of your kOps cluster.  Possible values are: &#x60;aws&#x60;, &#x60;gcp&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
         * @param {string} [kopsRegion] The region of your kOps cluster. Region is CSP specific.
         * @param {string} [kopsClusterName] The name of your kOps cluster.
         * @param {string} [kopsStateStore] The kOps cluster state store. Only remote S3 state is supported at the moment.
         * @param {string} [aksLocation] Azure location of your AKS cluster.
         * @param {string} [aksNodeResourceGroup] Azure resource group where AKS nodes are deployed.
         * @param {string} [aksSubscriptionId] Azure account subscription id.
         * @param {AutoscalerAPIGetAgentYamlOpenshiftCspEnum} [openshiftCsp] The Cloud Service Provider (CSP) of your OpenShift cluster.  Possible values are: &#x60;aws&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
         * @param {string} [openshiftRegion] The region of your OpenShift cluster. Region is CSP specific.
         * @param {string} [openshiftClusterName] The name of your OpenShift cluster.
         * @param {string} [openshiftInternalId] The OpenShift cluster ID. It can be found in the ClusterVersion object.  [Link to docs](https://docs.openshift.com/container-platform/4.8/support/gathering-cluster-data.html#support-get-cluster-id_gathering-cluster-data).
         * @param {string} [openshiftRunAsUser] The uid of the user that runs the agent pod.
         * @param {string} [openshiftRunAsGroup] The gid of the user that runs the agent pod.
         * @param {string} [openshiftFsGroup] The gid of the user that owns the agent pod\&#39;s volumes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentYaml: async (provider?: AutoscalerAPIGetAgentYamlProviderEnum, eksRegion?: string, eksAccountId?: string, eksClusterName?: string, gkeRegion?: string, gkeProjectId?: string, gkeClusterName?: string, gkeLocation?: string, kopsCsp?: AutoscalerAPIGetAgentYamlKopsCspEnum, kopsRegion?: string, kopsClusterName?: string, kopsStateStore?: string, aksLocation?: string, aksNodeResourceGroup?: string, aksSubscriptionId?: string, openshiftCsp?: AutoscalerAPIGetAgentYamlOpenshiftCspEnum, openshiftRegion?: string, openshiftClusterName?: string, openshiftInternalId?: string, openshiftRunAsUser?: string, openshiftRunAsGroup?: string, openshiftFsGroup?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/agent.yaml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            if (eksRegion !== undefined) {
                localVarQueryParameter['eks.region'] = eksRegion;
            }

            if (eksAccountId !== undefined) {
                localVarQueryParameter['eks.accountId'] = eksAccountId;
            }

            if (eksClusterName !== undefined) {
                localVarQueryParameter['eks.clusterName'] = eksClusterName;
            }

            if (gkeRegion !== undefined) {
                localVarQueryParameter['gke.region'] = gkeRegion;
            }

            if (gkeProjectId !== undefined) {
                localVarQueryParameter['gke.projectId'] = gkeProjectId;
            }

            if (gkeClusterName !== undefined) {
                localVarQueryParameter['gke.clusterName'] = gkeClusterName;
            }

            if (gkeLocation !== undefined) {
                localVarQueryParameter['gke.location'] = gkeLocation;
            }

            if (kopsCsp !== undefined) {
                localVarQueryParameter['kops.csp'] = kopsCsp;
            }

            if (kopsRegion !== undefined) {
                localVarQueryParameter['kops.region'] = kopsRegion;
            }

            if (kopsClusterName !== undefined) {
                localVarQueryParameter['kops.clusterName'] = kopsClusterName;
            }

            if (kopsStateStore !== undefined) {
                localVarQueryParameter['kops.stateStore'] = kopsStateStore;
            }

            if (aksLocation !== undefined) {
                localVarQueryParameter['aks.location'] = aksLocation;
            }

            if (aksNodeResourceGroup !== undefined) {
                localVarQueryParameter['aks.nodeResourceGroup'] = aksNodeResourceGroup;
            }

            if (aksSubscriptionId !== undefined) {
                localVarQueryParameter['aks.subscriptionId'] = aksSubscriptionId;
            }

            if (openshiftCsp !== undefined) {
                localVarQueryParameter['openshift.csp'] = openshiftCsp;
            }

            if (openshiftRegion !== undefined) {
                localVarQueryParameter['openshift.region'] = openshiftRegion;
            }

            if (openshiftClusterName !== undefined) {
                localVarQueryParameter['openshift.clusterName'] = openshiftClusterName;
            }

            if (openshiftInternalId !== undefined) {
                localVarQueryParameter['openshift.internalId'] = openshiftInternalId;
            }

            if (openshiftRunAsUser !== undefined) {
                localVarQueryParameter['openshift.runAsUser'] = openshiftRunAsUser;
            }

            if (openshiftRunAsGroup !== undefined) {
                localVarQueryParameter['openshift.runAsGroup'] = openshiftRunAsGroup;
            }

            if (openshiftFsGroup !== undefined) {
                localVarQueryParameter['openshift.fsGroup'] = openshiftFsGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent information: version, etc
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetClusterAgentInfo: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetClusterAgentInfo', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/agent`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cluster settings
         * @param {string} clusterId The id of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetClusterSettings: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetClusterSettings', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/settings`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all cluster workloads.
         * @summary Get cluster workloads
         * @param {string} clusterId ID of the cluster that is being rebalanced.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetClusterWorkloads: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetClusterWorkloads', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/workloads`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Evictor requests this endpoint before each action.
         * @summary Get the Evictor configuration
         * @param {string} clusterId ID of the cluster.
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetEvictorConfig: async (clusterId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetEvictorConfig', 'clusterId', clusterId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('autoscalerAPIGetEvictorConfig', 'body', body)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/evictor-config`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get karpenter definitions migration intent
         * @param {string} clusterId The id of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetKarpenterDefinitionsMigrationIntent: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetKarpenterDefinitionsMigrationIntent', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/karpenter/migrate`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets cluster problematic nodes that are currently running in the cluster. These nodes cannot be rebalanced.
         * @summary Get problematic nodes
         * @param {string} clusterId The id of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetProblematicNodes: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetProblematicNodes', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/problematic-nodes`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets cluster problematic workloads that are currently running in the cluster. These workloads cannot be rebalanced.
         * @summary Get problematic workloads
         * @param {string} clusterId The id of the cluster.
         * @param {boolean} [aggressiveMode] When enabled won\&#39;t consider pods without controller, job pods, pods with removal-disabled annotation as problematic pods.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetProblematicWorkloads: async (clusterId: string, aggressiveMode?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetProblematicWorkloads', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/problematic-workloads`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (aggressiveMode !== undefined) {
                localVarQueryParameter['aggressiveMode'] = aggressiveMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the rebalanced workloads. Only relevant when rebalancing execution is in-progress.
         * @summary Get rebalanced workloads
         * @param {string} clusterId The id of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetRebalancedWorkloads: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetRebalancedWorkloads', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalanced-workloads`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will only return a body when the plan\'s generation has fully finished.
         * @summary Get a rebalancing plan
         * @param {string} clusterId ID of the cluster that is being rebalanced.
         * @param {string} rebalancingPlanId ID of the rebalancing plan.
         * @param {boolean} [includeOperations] If include operations is true then operations is returned in response, otherwise is skipped
         * @param {boolean} [includeConfigurations] If include configurations is true then configurations is returned in response, otherwise is skipped
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetRebalancingPlan: async (clusterId: string, rebalancingPlanId: string, includeOperations?: boolean, includeConfigurations?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIGetRebalancingPlan', 'clusterId', clusterId)
            // verify required parameter 'rebalancingPlanId' is not null or undefined
            assertParamExists('autoscalerAPIGetRebalancingPlan', 'rebalancingPlanId', rebalancingPlanId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-plans/{rebalancingPlanId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"rebalancingPlanId"}}`, encodeURIComponent(String(rebalancingPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeOperations !== undefined) {
                localVarQueryParameter['includeOperations'] = includeOperations;
            }

            if (includeConfigurations !== undefined) {
                localVarQueryParameter['includeConfigurations'] = includeConfigurations;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ingest component logs running on managed clusters rather than mothership.
         * @param {string} clusterId ID of the cluster.
         * @param {AutoscalerAPIIngestComponentLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIIngestComponentLogs: async (clusterId: string, body: AutoscalerAPIIngestComponentLogsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIIngestComponentLogs', 'clusterId', clusterId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('autoscalerAPIIngestComponentLogs', 'body', body)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/agent-logs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ingest component logs running on managed clusters rather than mothership.
         * @param {string} clusterId ID of the cluster.
         * @param {AutoscalerAPIIngestComponentLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIIngestComponentLogs2: async (clusterId: string, body: AutoscalerAPIIngestComponentLogsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIIngestComponentLogs2', 'clusterId', clusterId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('autoscalerAPIIngestComponentLogs2', 'body', body)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/component-logs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists cluster rebalancing plans by cluster ID.
         * @summary List cluster rebalancing plans
         * @param {string} clusterId ID of the cluster that is being rebalanced.
         * @param {string} [limit] A limit on the number of objects to be returned, between 1 and 500.
         * @param {string} [cursor] A cursor for use in pagination.  This is a token that defines your place in the list. For instance, if you make a list request - you will receive a &#x60;nextCursor&#x60; field in response metadata. Given that the &#x60;nextCursor&#x60; field is not empty, it can be used as a cursor query parameter to get subsequent items. If &#x60;nextCursor&#x60; is empty - there are no more items to retrieve.
         * @param {Array<AutoscalerAPIListRebalancingPlansFilterStatusesEnum>} [filterStatuses] Status list filter
         * @param {boolean} [includeOperations] If include operations is true then operations is returned in response, otherwise is skipped
         * @param {boolean} [includeConfigurations] If include configurations is true then configurations is returned in response, otherwise is skipped
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIListRebalancingPlans: async (clusterId: string, limit?: string, cursor?: string, filterStatuses?: Array<AutoscalerAPIListRebalancingPlansFilterStatusesEnum>, includeOperations?: boolean, includeConfigurations?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIListRebalancingPlans', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-plans`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (includeOperations !== undefined) {
                localVarQueryParameter['includeOperations'] = includeOperations;
            }

            if (includeConfigurations !== undefined) {
                localVarQueryParameter['includeConfigurations'] = includeConfigurations;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Migrate karpenter custom resource definitions to CAST AI configuration
         * @param {string} clusterId The id of the cluster.
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIMigrateKarpenterDefinitions: async (clusterId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('autoscalerAPIMigrateKarpenterDefinitions', 'clusterId', clusterId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('autoscalerAPIMigrateKarpenterDefinitions', 'body', body)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/karpenter/migrate`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutoscalerAPIApi - functional programming interface
 * @export
 */
export const AutoscalerAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AutoscalerAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Triggers and executes the rebalancing plan.
         * @summary Trigger the rebalancing plan
         * @param {string} clusterId The id of the cluster which should be rebalanced.
         * @param {string} rebalancingPlanId The id of the rebalancing plan to use for rebalancing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIExecuteRebalancingPlan(clusterId: string, rebalancingPlanId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1RebalancingPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIExecuteRebalancingPlan(clusterId, rebalancingPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIExecuteRebalancingPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Initiates the cluster rebalancing process which will optimize current node configuration by consolidating existing workloads into fewer bin-packed, right-sized nodes.  This is a long-running operation, the request is queued and the response is immediate.
         * @summary Generate a cluster rebalancing plan
         * @param {string} clusterId ID of the cluster to be rebalanced.
         * @param {AutoscalerAPIGenerateRebalancingPlanRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGenerateRebalancingPlan(clusterId: string, body: AutoscalerAPIGenerateRebalancingPlanRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GenerateRebalancingPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGenerateRebalancingPlan(clusterId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGenerateRebalancingPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
         * @summary Get the Agent configuration
         * @param {string} clusterId ID of the cluster.
         * @param {AutoscalerAPIGetAgentConfigRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetAgentConfig(clusterId: string, body: AutoscalerAPIGetAgentConfigRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetAgentConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetAgentConfig(clusterId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetAgentConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
         * @summary Get the Agent configuration
         * @param {string} clusterId ID of the cluster.
         * @param {string} [agentVersion] Agent version.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetAgentConfig2(clusterId: string, agentVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetAgentConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetAgentConfig2(clusterId, agentVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetAgentConfig2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
         * @summary Get the Agent configuration
         * @param {string} clusterId ID of the cluster.
         * @param {AutoscalerAPIGetAgentConfigRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetAgentConfig3(clusterId: string, body: AutoscalerAPIGetAgentConfigRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetAgentConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetAgentConfig3(clusterId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetAgentConfig3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * All parameters are optional. If no parameters are provided, then the script will default to EKS configuration.  The returned install script can be run in your terminal to install the [CASTAI Kubernetes agent](https://github.com/castai/k8s-agent). The script can also be used to update an already running agent in your cluster.  The agent supports automatic discovery of cluster properties. However, if you specify at least one of the properties, then all properties for that provider must be specified.  OpenShift (ROSA) supports autodiscovery so choosing it does not require any OpenShift parameters. However, autodiscovery can be turned off by providing the properties explicitly. When any of the OpenShift parameters are provided, then all OpenShift parameters must be provided, with the exception of `openshift.runAsUser`, `openshift.runAsGroup` and `openshift.fsGroup`, which are optional and can be specified without the other parameters.
         * @summary Get a Kubernetes agent install script
         * @param {string} [eksRegion] AWS region of your EKS cluster.
         * @param {string} [eksAccountId] Your AWS account id. Can be retrieved by executing &#x60;aws sts get-caller-identity&#x60;.
         * @param {string} [eksClusterName] The name of your EKS cluster.
         * @param {string} [gkeRegion] GCP region of your GKE cluster.
         * @param {string} [gkeProjectId] GCP project id in which your GKE cluster is created.
         * @param {string} [gkeClusterName] The name of your GKE cluster.
         * @param {string} [gkeLocation] Location of your GKE cluster.
         * @param {AutoscalerAPIGetAgentScriptProviderEnum} [provider] Provider of the cluster.
         * @param {AutoscalerAPIGetAgentScriptKopsCspEnum} [kopsCsp] The Cloud Service Provider (CSP) of your kOps cluster.  Possible values are: &#x60;aws&#x60;, &#x60;gcp&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
         * @param {string} [kopsRegion] The region of your kOps cluster. Region is CSP specific.
         * @param {string} [kopsClusterName] The name of your kOps cluster.
         * @param {string} [kopsStateStore] The kOps cluster state store. Only remote S3 state is supported at the moment.
         * @param {string} [aksLocation] Azure location of your AKS cluster.
         * @param {string} [aksNodeResourceGroup] Azure resource group where AKS nodes are deployed.
         * @param {string} [aksSubscriptionId] Azure account subscription id.
         * @param {AutoscalerAPIGetAgentScriptOpenshiftCspEnum} [openshiftCsp] The Cloud Service Provider (CSP) of your OpenShift cluster.  Possible values are: &#x60;aws&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
         * @param {string} [openshiftRegion] The region of your OpenShift cluster. Region is CSP specific.
         * @param {string} [openshiftClusterName] The name of your OpenShift cluster.
         * @param {string} [openshiftInternalId] The OpenShift cluster ID. It can be found in the ClusterVersion object.  [Link to docs](https://docs.openshift.com/container-platform/4.8/support/gathering-cluster-data.html#support-get-cluster-id_gathering-cluster-data).
         * @param {string} [openshiftRunAsUser] The uid of the user that runs the agent pod.
         * @param {string} [openshiftRunAsGroup] The gid of the user that runs the agent pod.
         * @param {string} [openshiftFsGroup] The gid of the user that owns the agent pod\&#39;s volumes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetAgentScript(eksRegion?: string, eksAccountId?: string, eksClusterName?: string, gkeRegion?: string, gkeProjectId?: string, gkeClusterName?: string, gkeLocation?: string, provider?: AutoscalerAPIGetAgentScriptProviderEnum, kopsCsp?: AutoscalerAPIGetAgentScriptKopsCspEnum, kopsRegion?: string, kopsClusterName?: string, kopsStateStore?: string, aksLocation?: string, aksNodeResourceGroup?: string, aksSubscriptionId?: string, openshiftCsp?: AutoscalerAPIGetAgentScriptOpenshiftCspEnum, openshiftRegion?: string, openshiftClusterName?: string, openshiftInternalId?: string, openshiftRunAsUser?: string, openshiftRunAsGroup?: string, openshiftFsGroup?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetAgentScript(eksRegion, eksAccountId, eksClusterName, gkeRegion, gkeProjectId, gkeClusterName, gkeLocation, provider, kopsCsp, kopsRegion, kopsClusterName, kopsStateStore, aksLocation, aksNodeResourceGroup, aksSubscriptionId, openshiftCsp, openshiftRegion, openshiftClusterName, openshiftInternalId, openshiftRunAsUser, openshiftRunAsGroup, openshiftFsGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetAgentScript']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the CAST agent yaml ready to be applied on user\'s cluster
         * @param {AutoscalerAPIGetAgentYamlProviderEnum} [provider] Provider of the cluster.
         * @param {string} [eksRegion] AWS region of your EKS cluster.
         * @param {string} [eksAccountId] Your AWS account id. Can be retrieved by executing &#x60;aws sts get-caller-identity&#x60;.
         * @param {string} [eksClusterName] The name of your EKS cluster.
         * @param {string} [gkeRegion] GCP region of your GKE cluster.
         * @param {string} [gkeProjectId] GCP project id in which your GKE cluster is created.
         * @param {string} [gkeClusterName] The name of your GKE cluster.
         * @param {string} [gkeLocation] Location of your GKE cluster.
         * @param {AutoscalerAPIGetAgentYamlKopsCspEnum} [kopsCsp] The Cloud Service Provider (CSP) of your kOps cluster.  Possible values are: &#x60;aws&#x60;, &#x60;gcp&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
         * @param {string} [kopsRegion] The region of your kOps cluster. Region is CSP specific.
         * @param {string} [kopsClusterName] The name of your kOps cluster.
         * @param {string} [kopsStateStore] The kOps cluster state store. Only remote S3 state is supported at the moment.
         * @param {string} [aksLocation] Azure location of your AKS cluster.
         * @param {string} [aksNodeResourceGroup] Azure resource group where AKS nodes are deployed.
         * @param {string} [aksSubscriptionId] Azure account subscription id.
         * @param {AutoscalerAPIGetAgentYamlOpenshiftCspEnum} [openshiftCsp] The Cloud Service Provider (CSP) of your OpenShift cluster.  Possible values are: &#x60;aws&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
         * @param {string} [openshiftRegion] The region of your OpenShift cluster. Region is CSP specific.
         * @param {string} [openshiftClusterName] The name of your OpenShift cluster.
         * @param {string} [openshiftInternalId] The OpenShift cluster ID. It can be found in the ClusterVersion object.  [Link to docs](https://docs.openshift.com/container-platform/4.8/support/gathering-cluster-data.html#support-get-cluster-id_gathering-cluster-data).
         * @param {string} [openshiftRunAsUser] The uid of the user that runs the agent pod.
         * @param {string} [openshiftRunAsGroup] The gid of the user that runs the agent pod.
         * @param {string} [openshiftFsGroup] The gid of the user that owns the agent pod\&#39;s volumes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetAgentYaml(provider?: AutoscalerAPIGetAgentYamlProviderEnum, eksRegion?: string, eksAccountId?: string, eksClusterName?: string, gkeRegion?: string, gkeProjectId?: string, gkeClusterName?: string, gkeLocation?: string, kopsCsp?: AutoscalerAPIGetAgentYamlKopsCspEnum, kopsRegion?: string, kopsClusterName?: string, kopsStateStore?: string, aksLocation?: string, aksNodeResourceGroup?: string, aksSubscriptionId?: string, openshiftCsp?: AutoscalerAPIGetAgentYamlOpenshiftCspEnum, openshiftRegion?: string, openshiftClusterName?: string, openshiftInternalId?: string, openshiftRunAsUser?: string, openshiftRunAsGroup?: string, openshiftFsGroup?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleApiHttpBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetAgentYaml(provider, eksRegion, eksAccountId, eksClusterName, gkeRegion, gkeProjectId, gkeClusterName, gkeLocation, kopsCsp, kopsRegion, kopsClusterName, kopsStateStore, aksLocation, aksNodeResourceGroup, aksSubscriptionId, openshiftCsp, openshiftRegion, openshiftClusterName, openshiftInternalId, openshiftRunAsUser, openshiftRunAsGroup, openshiftFsGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetAgentYaml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get agent information: version, etc
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetClusterAgentInfo(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetClusterAgentInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetClusterAgentInfo(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetClusterAgentInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get cluster settings
         * @param {string} clusterId The id of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetClusterSettings(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetClusterSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetClusterSettings(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetClusterSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets all cluster workloads.
         * @summary Get cluster workloads
         * @param {string} clusterId ID of the cluster that is being rebalanced.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetClusterWorkloads(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetClusterWorkloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetClusterWorkloads(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetClusterWorkloads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Evictor requests this endpoint before each action.
         * @summary Get the Evictor configuration
         * @param {string} clusterId ID of the cluster.
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetEvictorConfig(clusterId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetEvictorConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetEvictorConfig(clusterId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetEvictorConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get karpenter definitions migration intent
         * @param {string} clusterId The id of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetKarpenterDefinitionsMigrationIntent(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetKarpenterMigrationIntentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetKarpenterDefinitionsMigrationIntent(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetKarpenterDefinitionsMigrationIntent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets cluster problematic nodes that are currently running in the cluster. These nodes cannot be rebalanced.
         * @summary Get problematic nodes
         * @param {string} clusterId The id of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetProblematicNodes(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetProblematicNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetProblematicNodes(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetProblematicNodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets cluster problematic workloads that are currently running in the cluster. These workloads cannot be rebalanced.
         * @summary Get problematic workloads
         * @param {string} clusterId The id of the cluster.
         * @param {boolean} [aggressiveMode] When enabled won\&#39;t consider pods without controller, job pods, pods with removal-disabled annotation as problematic pods.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetProblematicWorkloads(clusterId: string, aggressiveMode?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetProblematicWorkloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetProblematicWorkloads(clusterId, aggressiveMode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetProblematicWorkloads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets the rebalanced workloads. Only relevant when rebalancing execution is in-progress.
         * @summary Get rebalanced workloads
         * @param {string} clusterId The id of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetRebalancedWorkloads(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1GetRebalancedWorkloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetRebalancedWorkloads(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetRebalancedWorkloads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Will only return a body when the plan\'s generation has fully finished.
         * @summary Get a rebalancing plan
         * @param {string} clusterId ID of the cluster that is being rebalanced.
         * @param {string} rebalancingPlanId ID of the rebalancing plan.
         * @param {boolean} [includeOperations] If include operations is true then operations is returned in response, otherwise is skipped
         * @param {boolean} [includeConfigurations] If include configurations is true then configurations is returned in response, otherwise is skipped
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIGetRebalancingPlan(clusterId: string, rebalancingPlanId: string, includeOperations?: boolean, includeConfigurations?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1RebalancingPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIGetRebalancingPlan(clusterId, rebalancingPlanId, includeOperations, includeConfigurations, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIGetRebalancingPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Ingest component logs running on managed clusters rather than mothership.
         * @param {string} clusterId ID of the cluster.
         * @param {AutoscalerAPIIngestComponentLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIIngestComponentLogs(clusterId: string, body: AutoscalerAPIIngestComponentLogsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIIngestComponentLogs(clusterId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIIngestComponentLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Ingest component logs running on managed clusters rather than mothership.
         * @param {string} clusterId ID of the cluster.
         * @param {AutoscalerAPIIngestComponentLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIIngestComponentLogs2(clusterId: string, body: AutoscalerAPIIngestComponentLogsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIIngestComponentLogs2(clusterId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIIngestComponentLogs2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists cluster rebalancing plans by cluster ID.
         * @summary List cluster rebalancing plans
         * @param {string} clusterId ID of the cluster that is being rebalanced.
         * @param {string} [limit] A limit on the number of objects to be returned, between 1 and 500.
         * @param {string} [cursor] A cursor for use in pagination.  This is a token that defines your place in the list. For instance, if you make a list request - you will receive a &#x60;nextCursor&#x60; field in response metadata. Given that the &#x60;nextCursor&#x60; field is not empty, it can be used as a cursor query parameter to get subsequent items. If &#x60;nextCursor&#x60; is empty - there are no more items to retrieve.
         * @param {Array<AutoscalerAPIListRebalancingPlansFilterStatusesEnum>} [filterStatuses] Status list filter
         * @param {boolean} [includeOperations] If include operations is true then operations is returned in response, otherwise is skipped
         * @param {boolean} [includeConfigurations] If include configurations is true then configurations is returned in response, otherwise is skipped
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIListRebalancingPlans(clusterId: string, limit?: string, cursor?: string, filterStatuses?: Array<AutoscalerAPIListRebalancingPlansFilterStatusesEnum>, includeOperations?: boolean, includeConfigurations?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1ListRebalancingPlansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIListRebalancingPlans(clusterId, limit, cursor, filterStatuses, includeOperations, includeConfigurations, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIListRebalancingPlans']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Migrate karpenter custom resource definitions to CAST AI configuration
         * @param {string} clusterId The id of the cluster.
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoscalerAPIMigrateKarpenterDefinitions(clusterId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAutoscalerV1beta1MigrateKarpenterDefinitionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoscalerAPIMigrateKarpenterDefinitions(clusterId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutoscalerAPIApi.autoscalerAPIMigrateKarpenterDefinitions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AutoscalerAPIApi - factory interface
 * @export
 */
export const AutoscalerAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AutoscalerAPIApiFp(configuration)
    return {
        /**
         * Triggers and executes the rebalancing plan.
         * @summary Trigger the rebalancing plan
         * @param {AutoscalerAPIApiAutoscalerAPIExecuteRebalancingPlanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIExecuteRebalancingPlan(requestParameters: AutoscalerAPIApiAutoscalerAPIExecuteRebalancingPlanRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1RebalancingPlanResponse> {
            return localVarFp.autoscalerAPIExecuteRebalancingPlan(requestParameters.clusterId, requestParameters.rebalancingPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiates the cluster rebalancing process which will optimize current node configuration by consolidating existing workloads into fewer bin-packed, right-sized nodes.  This is a long-running operation, the request is queued and the response is immediate.
         * @summary Generate a cluster rebalancing plan
         * @param {AutoscalerAPIApiAutoscalerAPIGenerateRebalancingPlanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGenerateRebalancingPlan(requestParameters: AutoscalerAPIApiAutoscalerAPIGenerateRebalancingPlanRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GenerateRebalancingPlanResponse> {
            return localVarFp.autoscalerAPIGenerateRebalancingPlan(requestParameters.clusterId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
         * @summary Get the Agent configuration
         * @param {AutoscalerAPIApiAutoscalerAPIGetAgentConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentConfig(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetAgentConfigResponse> {
            return localVarFp.autoscalerAPIGetAgentConfig(requestParameters.clusterId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
         * @summary Get the Agent configuration
         * @param {AutoscalerAPIApiAutoscalerAPIGetAgentConfig2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentConfig2(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentConfig2Request, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetAgentConfigResponse> {
            return localVarFp.autoscalerAPIGetAgentConfig2(requestParameters.clusterId, requestParameters.agentVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
         * @summary Get the Agent configuration
         * @param {AutoscalerAPIApiAutoscalerAPIGetAgentConfig3Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentConfig3(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentConfig3Request, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetAgentConfigResponse> {
            return localVarFp.autoscalerAPIGetAgentConfig3(requestParameters.clusterId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * All parameters are optional. If no parameters are provided, then the script will default to EKS configuration.  The returned install script can be run in your terminal to install the [CASTAI Kubernetes agent](https://github.com/castai/k8s-agent). The script can also be used to update an already running agent in your cluster.  The agent supports automatic discovery of cluster properties. However, if you specify at least one of the properties, then all properties for that provider must be specified.  OpenShift (ROSA) supports autodiscovery so choosing it does not require any OpenShift parameters. However, autodiscovery can be turned off by providing the properties explicitly. When any of the OpenShift parameters are provided, then all OpenShift parameters must be provided, with the exception of `openshift.runAsUser`, `openshift.runAsGroup` and `openshift.fsGroup`, which are optional and can be specified without the other parameters.
         * @summary Get a Kubernetes agent install script
         * @param {AutoscalerAPIApiAutoscalerAPIGetAgentScriptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentScript(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentScriptRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.autoscalerAPIGetAgentScript(requestParameters.eksRegion, requestParameters.eksAccountId, requestParameters.eksClusterName, requestParameters.gkeRegion, requestParameters.gkeProjectId, requestParameters.gkeClusterName, requestParameters.gkeLocation, requestParameters.provider, requestParameters.kopsCsp, requestParameters.kopsRegion, requestParameters.kopsClusterName, requestParameters.kopsStateStore, requestParameters.aksLocation, requestParameters.aksNodeResourceGroup, requestParameters.aksSubscriptionId, requestParameters.openshiftCsp, requestParameters.openshiftRegion, requestParameters.openshiftClusterName, requestParameters.openshiftInternalId, requestParameters.openshiftRunAsUser, requestParameters.openshiftRunAsGroup, requestParameters.openshiftFsGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the CAST agent yaml ready to be applied on user\'s cluster
         * @param {AutoscalerAPIApiAutoscalerAPIGetAgentYamlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetAgentYaml(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentYamlRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GoogleApiHttpBody> {
            return localVarFp.autoscalerAPIGetAgentYaml(requestParameters.provider, requestParameters.eksRegion, requestParameters.eksAccountId, requestParameters.eksClusterName, requestParameters.gkeRegion, requestParameters.gkeProjectId, requestParameters.gkeClusterName, requestParameters.gkeLocation, requestParameters.kopsCsp, requestParameters.kopsRegion, requestParameters.kopsClusterName, requestParameters.kopsStateStore, requestParameters.aksLocation, requestParameters.aksNodeResourceGroup, requestParameters.aksSubscriptionId, requestParameters.openshiftCsp, requestParameters.openshiftRegion, requestParameters.openshiftClusterName, requestParameters.openshiftInternalId, requestParameters.openshiftRunAsUser, requestParameters.openshiftRunAsGroup, requestParameters.openshiftFsGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent information: version, etc
         * @param {AutoscalerAPIApiAutoscalerAPIGetClusterAgentInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetClusterAgentInfo(requestParameters: AutoscalerAPIApiAutoscalerAPIGetClusterAgentInfoRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetClusterAgentInfoResponse> {
            return localVarFp.autoscalerAPIGetClusterAgentInfo(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cluster settings
         * @param {AutoscalerAPIApiAutoscalerAPIGetClusterSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetClusterSettings(requestParameters: AutoscalerAPIApiAutoscalerAPIGetClusterSettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetClusterSettingsResponse> {
            return localVarFp.autoscalerAPIGetClusterSettings(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all cluster workloads.
         * @summary Get cluster workloads
         * @param {AutoscalerAPIApiAutoscalerAPIGetClusterWorkloadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetClusterWorkloads(requestParameters: AutoscalerAPIApiAutoscalerAPIGetClusterWorkloadsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetClusterWorkloadsResponse> {
            return localVarFp.autoscalerAPIGetClusterWorkloads(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Evictor requests this endpoint before each action.
         * @summary Get the Evictor configuration
         * @param {AutoscalerAPIApiAutoscalerAPIGetEvictorConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetEvictorConfig(requestParameters: AutoscalerAPIApiAutoscalerAPIGetEvictorConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetEvictorConfigResponse> {
            return localVarFp.autoscalerAPIGetEvictorConfig(requestParameters.clusterId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get karpenter definitions migration intent
         * @param {AutoscalerAPIApiAutoscalerAPIGetKarpenterDefinitionsMigrationIntentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetKarpenterDefinitionsMigrationIntent(requestParameters: AutoscalerAPIApiAutoscalerAPIGetKarpenterDefinitionsMigrationIntentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetKarpenterMigrationIntentResponse> {
            return localVarFp.autoscalerAPIGetKarpenterDefinitionsMigrationIntent(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets cluster problematic nodes that are currently running in the cluster. These nodes cannot be rebalanced.
         * @summary Get problematic nodes
         * @param {AutoscalerAPIApiAutoscalerAPIGetProblematicNodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetProblematicNodes(requestParameters: AutoscalerAPIApiAutoscalerAPIGetProblematicNodesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetProblematicNodesResponse> {
            return localVarFp.autoscalerAPIGetProblematicNodes(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets cluster problematic workloads that are currently running in the cluster. These workloads cannot be rebalanced.
         * @summary Get problematic workloads
         * @param {AutoscalerAPIApiAutoscalerAPIGetProblematicWorkloadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetProblematicWorkloads(requestParameters: AutoscalerAPIApiAutoscalerAPIGetProblematicWorkloadsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetProblematicWorkloadsResponse> {
            return localVarFp.autoscalerAPIGetProblematicWorkloads(requestParameters.clusterId, requestParameters.aggressiveMode, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the rebalanced workloads. Only relevant when rebalancing execution is in-progress.
         * @summary Get rebalanced workloads
         * @param {AutoscalerAPIApiAutoscalerAPIGetRebalancedWorkloadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetRebalancedWorkloads(requestParameters: AutoscalerAPIApiAutoscalerAPIGetRebalancedWorkloadsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1GetRebalancedWorkloadsResponse> {
            return localVarFp.autoscalerAPIGetRebalancedWorkloads(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Will only return a body when the plan\'s generation has fully finished.
         * @summary Get a rebalancing plan
         * @param {AutoscalerAPIApiAutoscalerAPIGetRebalancingPlanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIGetRebalancingPlan(requestParameters: AutoscalerAPIApiAutoscalerAPIGetRebalancingPlanRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1RebalancingPlanResponse> {
            return localVarFp.autoscalerAPIGetRebalancingPlan(requestParameters.clusterId, requestParameters.rebalancingPlanId, requestParameters.includeOperations, requestParameters.includeConfigurations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ingest component logs running on managed clusters rather than mothership.
         * @param {AutoscalerAPIApiAutoscalerAPIIngestComponentLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIIngestComponentLogs(requestParameters: AutoscalerAPIApiAutoscalerAPIIngestComponentLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.autoscalerAPIIngestComponentLogs(requestParameters.clusterId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ingest component logs running on managed clusters rather than mothership.
         * @param {AutoscalerAPIApiAutoscalerAPIIngestComponentLogs2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIIngestComponentLogs2(requestParameters: AutoscalerAPIApiAutoscalerAPIIngestComponentLogs2Request, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.autoscalerAPIIngestComponentLogs2(requestParameters.clusterId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists cluster rebalancing plans by cluster ID.
         * @summary List cluster rebalancing plans
         * @param {AutoscalerAPIApiAutoscalerAPIListRebalancingPlansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIListRebalancingPlans(requestParameters: AutoscalerAPIApiAutoscalerAPIListRebalancingPlansRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1ListRebalancingPlansResponse> {
            return localVarFp.autoscalerAPIListRebalancingPlans(requestParameters.clusterId, requestParameters.limit, requestParameters.cursor, requestParameters.filterStatuses, requestParameters.includeOperations, requestParameters.includeConfigurations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Migrate karpenter custom resource definitions to CAST AI configuration
         * @param {AutoscalerAPIApiAutoscalerAPIMigrateKarpenterDefinitionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoscalerAPIMigrateKarpenterDefinitions(requestParameters: AutoscalerAPIApiAutoscalerAPIMigrateKarpenterDefinitionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAutoscalerV1beta1MigrateKarpenterDefinitionsResponse> {
            return localVarFp.autoscalerAPIMigrateKarpenterDefinitions(requestParameters.clusterId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for autoscalerAPIExecuteRebalancingPlan operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIExecuteRebalancingPlanRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIExecuteRebalancingPlanRequest {
    /**
     * The id of the cluster which should be rebalanced.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIExecuteRebalancingPlan
     */
    readonly clusterId: string

    /**
     * The id of the rebalancing plan to use for rebalancing.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIExecuteRebalancingPlan
     */
    readonly rebalancingPlanId: string
}

/**
 * Request parameters for autoscalerAPIGenerateRebalancingPlan operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGenerateRebalancingPlanRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGenerateRebalancingPlanRequest {
    /**
     * ID of the cluster to be rebalanced.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGenerateRebalancingPlan
     */
    readonly clusterId: string

    /**
     * 
     * @type {AutoscalerAPIGenerateRebalancingPlanRequest}
     * @memberof AutoscalerAPIApiAutoscalerAPIGenerateRebalancingPlan
     */
    readonly body: AutoscalerAPIGenerateRebalancingPlanRequest
}

/**
 * Request parameters for autoscalerAPIGetAgentConfig operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetAgentConfigRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetAgentConfigRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentConfig
     */
    readonly clusterId: string

    /**
     * 
     * @type {AutoscalerAPIGetAgentConfigRequest}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentConfig
     */
    readonly body: AutoscalerAPIGetAgentConfigRequest
}

/**
 * Request parameters for autoscalerAPIGetAgentConfig2 operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetAgentConfig2Request
 */
export interface AutoscalerAPIApiAutoscalerAPIGetAgentConfig2Request {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentConfig2
     */
    readonly clusterId: string

    /**
     * Agent version.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentConfig2
     */
    readonly agentVersion?: string
}

/**
 * Request parameters for autoscalerAPIGetAgentConfig3 operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetAgentConfig3Request
 */
export interface AutoscalerAPIApiAutoscalerAPIGetAgentConfig3Request {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentConfig3
     */
    readonly clusterId: string

    /**
     * 
     * @type {AutoscalerAPIGetAgentConfigRequest}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentConfig3
     */
    readonly body: AutoscalerAPIGetAgentConfigRequest
}

/**
 * Request parameters for autoscalerAPIGetAgentScript operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetAgentScriptRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetAgentScriptRequest {
    /**
     * AWS region of your EKS cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly eksRegion?: string

    /**
     * Your AWS account id. Can be retrieved by executing &#x60;aws sts get-caller-identity&#x60;.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly eksAccountId?: string

    /**
     * The name of your EKS cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly eksClusterName?: string

    /**
     * GCP region of your GKE cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly gkeRegion?: string

    /**
     * GCP project id in which your GKE cluster is created.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly gkeProjectId?: string

    /**
     * The name of your GKE cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly gkeClusterName?: string

    /**
     * Location of your GKE cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly gkeLocation?: string

    /**
     * Provider of the cluster.
     * @type {'invalid_provider' | 'eks' | 'gke' | 'aks' | 'openshift' | 'kops'}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly provider?: AutoscalerAPIGetAgentScriptProviderEnum

    /**
     * The Cloud Service Provider (CSP) of your kOps cluster.  Possible values are: &#x60;aws&#x60;, &#x60;gcp&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
     * @type {'invalid' | 'INVALID' | 'aws' | 'AWS' | 'gcp' | 'GCP' | 'azure' | 'AZURE'}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly kopsCsp?: AutoscalerAPIGetAgentScriptKopsCspEnum

    /**
     * The region of your kOps cluster. Region is CSP specific.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly kopsRegion?: string

    /**
     * The name of your kOps cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly kopsClusterName?: string

    /**
     * The kOps cluster state store. Only remote S3 state is supported at the moment.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly kopsStateStore?: string

    /**
     * Azure location of your AKS cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly aksLocation?: string

    /**
     * Azure resource group where AKS nodes are deployed.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly aksNodeResourceGroup?: string

    /**
     * Azure account subscription id.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly aksSubscriptionId?: string

    /**
     * The Cloud Service Provider (CSP) of your OpenShift cluster.  Possible values are: &#x60;aws&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
     * @type {'invalid' | 'INVALID' | 'aws' | 'AWS' | 'gcp' | 'GCP' | 'azure' | 'AZURE'}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly openshiftCsp?: AutoscalerAPIGetAgentScriptOpenshiftCspEnum

    /**
     * The region of your OpenShift cluster. Region is CSP specific.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly openshiftRegion?: string

    /**
     * The name of your OpenShift cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly openshiftClusterName?: string

    /**
     * The OpenShift cluster ID. It can be found in the ClusterVersion object.  [Link to docs](https://docs.openshift.com/container-platform/4.8/support/gathering-cluster-data.html#support-get-cluster-id_gathering-cluster-data).
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly openshiftInternalId?: string

    /**
     * The uid of the user that runs the agent pod.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly openshiftRunAsUser?: string

    /**
     * The gid of the user that runs the agent pod.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly openshiftRunAsGroup?: string

    /**
     * The gid of the user that owns the agent pod\&#39;s volumes.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentScript
     */
    readonly openshiftFsGroup?: string
}

/**
 * Request parameters for autoscalerAPIGetAgentYaml operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetAgentYamlRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetAgentYamlRequest {
    /**
     * Provider of the cluster.
     * @type {'invalid_provider' | 'eks' | 'gke' | 'aks' | 'openshift' | 'kops'}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly provider?: AutoscalerAPIGetAgentYamlProviderEnum

    /**
     * AWS region of your EKS cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly eksRegion?: string

    /**
     * Your AWS account id. Can be retrieved by executing &#x60;aws sts get-caller-identity&#x60;.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly eksAccountId?: string

    /**
     * The name of your EKS cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly eksClusterName?: string

    /**
     * GCP region of your GKE cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly gkeRegion?: string

    /**
     * GCP project id in which your GKE cluster is created.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly gkeProjectId?: string

    /**
     * The name of your GKE cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly gkeClusterName?: string

    /**
     * Location of your GKE cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly gkeLocation?: string

    /**
     * The Cloud Service Provider (CSP) of your kOps cluster.  Possible values are: &#x60;aws&#x60;, &#x60;gcp&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
     * @type {'invalid' | 'INVALID' | 'aws' | 'AWS' | 'gcp' | 'GCP' | 'azure' | 'AZURE'}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly kopsCsp?: AutoscalerAPIGetAgentYamlKopsCspEnum

    /**
     * The region of your kOps cluster. Region is CSP specific.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly kopsRegion?: string

    /**
     * The name of your kOps cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly kopsClusterName?: string

    /**
     * The kOps cluster state store. Only remote S3 state is supported at the moment.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly kopsStateStore?: string

    /**
     * Azure location of your AKS cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly aksLocation?: string

    /**
     * Azure resource group where AKS nodes are deployed.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly aksNodeResourceGroup?: string

    /**
     * Azure account subscription id.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly aksSubscriptionId?: string

    /**
     * The Cloud Service Provider (CSP) of your OpenShift cluster.  Possible values are: &#x60;aws&#x60;.   - invalid: Invalid.  - aws: Amazon web services.  - gcp: Google cloud provider.  - azure: Microsoft Azure.
     * @type {'invalid' | 'INVALID' | 'aws' | 'AWS' | 'gcp' | 'GCP' | 'azure' | 'AZURE'}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly openshiftCsp?: AutoscalerAPIGetAgentYamlOpenshiftCspEnum

    /**
     * The region of your OpenShift cluster. Region is CSP specific.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly openshiftRegion?: string

    /**
     * The name of your OpenShift cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly openshiftClusterName?: string

    /**
     * The OpenShift cluster ID. It can be found in the ClusterVersion object.  [Link to docs](https://docs.openshift.com/container-platform/4.8/support/gathering-cluster-data.html#support-get-cluster-id_gathering-cluster-data).
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly openshiftInternalId?: string

    /**
     * The uid of the user that runs the agent pod.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly openshiftRunAsUser?: string

    /**
     * The gid of the user that runs the agent pod.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly openshiftRunAsGroup?: string

    /**
     * The gid of the user that owns the agent pod\&#39;s volumes.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetAgentYaml
     */
    readonly openshiftFsGroup?: string
}

/**
 * Request parameters for autoscalerAPIGetClusterAgentInfo operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetClusterAgentInfoRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetClusterAgentInfoRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetClusterAgentInfo
     */
    readonly clusterId: string
}

/**
 * Request parameters for autoscalerAPIGetClusterSettings operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetClusterSettingsRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetClusterSettingsRequest {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetClusterSettings
     */
    readonly clusterId: string
}

/**
 * Request parameters for autoscalerAPIGetClusterWorkloads operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetClusterWorkloadsRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetClusterWorkloadsRequest {
    /**
     * ID of the cluster that is being rebalanced.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetClusterWorkloads
     */
    readonly clusterId: string
}

/**
 * Request parameters for autoscalerAPIGetEvictorConfig operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetEvictorConfigRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetEvictorConfigRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetEvictorConfig
     */
    readonly clusterId: string

    /**
     * 
     * @type {object}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetEvictorConfig
     */
    readonly body: object
}

/**
 * Request parameters for autoscalerAPIGetKarpenterDefinitionsMigrationIntent operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetKarpenterDefinitionsMigrationIntentRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetKarpenterDefinitionsMigrationIntentRequest {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetKarpenterDefinitionsMigrationIntent
     */
    readonly clusterId: string
}

/**
 * Request parameters for autoscalerAPIGetProblematicNodes operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetProblematicNodesRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetProblematicNodesRequest {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetProblematicNodes
     */
    readonly clusterId: string
}

/**
 * Request parameters for autoscalerAPIGetProblematicWorkloads operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetProblematicWorkloadsRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetProblematicWorkloadsRequest {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetProblematicWorkloads
     */
    readonly clusterId: string

    /**
     * When enabled won\&#39;t consider pods without controller, job pods, pods with removal-disabled annotation as problematic pods.
     * @type {boolean}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetProblematicWorkloads
     */
    readonly aggressiveMode?: boolean
}

/**
 * Request parameters for autoscalerAPIGetRebalancedWorkloads operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetRebalancedWorkloadsRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetRebalancedWorkloadsRequest {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetRebalancedWorkloads
     */
    readonly clusterId: string
}

/**
 * Request parameters for autoscalerAPIGetRebalancingPlan operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIGetRebalancingPlanRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIGetRebalancingPlanRequest {
    /**
     * ID of the cluster that is being rebalanced.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetRebalancingPlan
     */
    readonly clusterId: string

    /**
     * ID of the rebalancing plan.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetRebalancingPlan
     */
    readonly rebalancingPlanId: string

    /**
     * If include operations is true then operations is returned in response, otherwise is skipped
     * @type {boolean}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetRebalancingPlan
     */
    readonly includeOperations?: boolean

    /**
     * If include configurations is true then configurations is returned in response, otherwise is skipped
     * @type {boolean}
     * @memberof AutoscalerAPIApiAutoscalerAPIGetRebalancingPlan
     */
    readonly includeConfigurations?: boolean
}

/**
 * Request parameters for autoscalerAPIIngestComponentLogs operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIIngestComponentLogsRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIIngestComponentLogsRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIIngestComponentLogs
     */
    readonly clusterId: string

    /**
     * 
     * @type {AutoscalerAPIIngestComponentLogsRequest}
     * @memberof AutoscalerAPIApiAutoscalerAPIIngestComponentLogs
     */
    readonly body: AutoscalerAPIIngestComponentLogsRequest
}

/**
 * Request parameters for autoscalerAPIIngestComponentLogs2 operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIIngestComponentLogs2Request
 */
export interface AutoscalerAPIApiAutoscalerAPIIngestComponentLogs2Request {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIIngestComponentLogs2
     */
    readonly clusterId: string

    /**
     * 
     * @type {AutoscalerAPIIngestComponentLogsRequest}
     * @memberof AutoscalerAPIApiAutoscalerAPIIngestComponentLogs2
     */
    readonly body: AutoscalerAPIIngestComponentLogsRequest
}

/**
 * Request parameters for autoscalerAPIListRebalancingPlans operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIListRebalancingPlansRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIListRebalancingPlansRequest {
    /**
     * ID of the cluster that is being rebalanced.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIListRebalancingPlans
     */
    readonly clusterId: string

    /**
     * A limit on the number of objects to be returned, between 1 and 500.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIListRebalancingPlans
     */
    readonly limit?: string

    /**
     * A cursor for use in pagination.  This is a token that defines your place in the list. For instance, if you make a list request - you will receive a &#x60;nextCursor&#x60; field in response metadata. Given that the &#x60;nextCursor&#x60; field is not empty, it can be used as a cursor query parameter to get subsequent items. If &#x60;nextCursor&#x60; is empty - there are no more items to retrieve.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIListRebalancingPlans
     */
    readonly cursor?: string

    /**
     * Status list filter
     * @type {Array<'invalid' | 'generating' | 'generated' | 'creating_nodes' | 'preparing_nodes' | 'draining_nodes' | 'deleting_nodes' | 'finished' | 'error' | 'partially_finished'>}
     * @memberof AutoscalerAPIApiAutoscalerAPIListRebalancingPlans
     */
    readonly filterStatuses?: Array<AutoscalerAPIListRebalancingPlansFilterStatusesEnum>

    /**
     * If include operations is true then operations is returned in response, otherwise is skipped
     * @type {boolean}
     * @memberof AutoscalerAPIApiAutoscalerAPIListRebalancingPlans
     */
    readonly includeOperations?: boolean

    /**
     * If include configurations is true then configurations is returned in response, otherwise is skipped
     * @type {boolean}
     * @memberof AutoscalerAPIApiAutoscalerAPIListRebalancingPlans
     */
    readonly includeConfigurations?: boolean
}

/**
 * Request parameters for autoscalerAPIMigrateKarpenterDefinitions operation in AutoscalerAPIApi.
 * @export
 * @interface AutoscalerAPIApiAutoscalerAPIMigrateKarpenterDefinitionsRequest
 */
export interface AutoscalerAPIApiAutoscalerAPIMigrateKarpenterDefinitionsRequest {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AutoscalerAPIApiAutoscalerAPIMigrateKarpenterDefinitions
     */
    readonly clusterId: string

    /**
     * 
     * @type {object}
     * @memberof AutoscalerAPIApiAutoscalerAPIMigrateKarpenterDefinitions
     */
    readonly body: object
}

/**
 * AutoscalerAPIApi - object-oriented interface
 * @export
 * @class AutoscalerAPIApi
 * @extends {BaseAPI}
 */
export class AutoscalerAPIApi extends BaseAPI {
    /**
     * Triggers and executes the rebalancing plan.
     * @summary Trigger the rebalancing plan
     * @param {AutoscalerAPIApiAutoscalerAPIExecuteRebalancingPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIExecuteRebalancingPlan(requestParameters: AutoscalerAPIApiAutoscalerAPIExecuteRebalancingPlanRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIExecuteRebalancingPlan(requestParameters.clusterId, requestParameters.rebalancingPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiates the cluster rebalancing process which will optimize current node configuration by consolidating existing workloads into fewer bin-packed, right-sized nodes.  This is a long-running operation, the request is queued and the response is immediate.
     * @summary Generate a cluster rebalancing plan
     * @param {AutoscalerAPIApiAutoscalerAPIGenerateRebalancingPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGenerateRebalancingPlan(requestParameters: AutoscalerAPIApiAutoscalerAPIGenerateRebalancingPlanRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGenerateRebalancingPlan(requestParameters.clusterId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
     * @summary Get the Agent configuration
     * @param {AutoscalerAPIApiAutoscalerAPIGetAgentConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetAgentConfig(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentConfigRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetAgentConfig(requestParameters.clusterId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
     * @summary Get the Agent configuration
     * @param {AutoscalerAPIApiAutoscalerAPIGetAgentConfig2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetAgentConfig2(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentConfig2Request, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetAgentConfig2(requestParameters.clusterId, requestParameters.agentVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Agent polls this endpoint frequently and refreshes it\'s configuration dynamically based on cluster policy configuration.
     * @summary Get the Agent configuration
     * @param {AutoscalerAPIApiAutoscalerAPIGetAgentConfig3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetAgentConfig3(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentConfig3Request, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetAgentConfig3(requestParameters.clusterId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All parameters are optional. If no parameters are provided, then the script will default to EKS configuration.  The returned install script can be run in your terminal to install the [CASTAI Kubernetes agent](https://github.com/castai/k8s-agent). The script can also be used to update an already running agent in your cluster.  The agent supports automatic discovery of cluster properties. However, if you specify at least one of the properties, then all properties for that provider must be specified.  OpenShift (ROSA) supports autodiscovery so choosing it does not require any OpenShift parameters. However, autodiscovery can be turned off by providing the properties explicitly. When any of the OpenShift parameters are provided, then all OpenShift parameters must be provided, with the exception of `openshift.runAsUser`, `openshift.runAsGroup` and `openshift.fsGroup`, which are optional and can be specified without the other parameters.
     * @summary Get a Kubernetes agent install script
     * @param {AutoscalerAPIApiAutoscalerAPIGetAgentScriptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetAgentScript(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentScriptRequest = {}, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetAgentScript(requestParameters.eksRegion, requestParameters.eksAccountId, requestParameters.eksClusterName, requestParameters.gkeRegion, requestParameters.gkeProjectId, requestParameters.gkeClusterName, requestParameters.gkeLocation, requestParameters.provider, requestParameters.kopsCsp, requestParameters.kopsRegion, requestParameters.kopsClusterName, requestParameters.kopsStateStore, requestParameters.aksLocation, requestParameters.aksNodeResourceGroup, requestParameters.aksSubscriptionId, requestParameters.openshiftCsp, requestParameters.openshiftRegion, requestParameters.openshiftClusterName, requestParameters.openshiftInternalId, requestParameters.openshiftRunAsUser, requestParameters.openshiftRunAsGroup, requestParameters.openshiftFsGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the CAST agent yaml ready to be applied on user\'s cluster
     * @param {AutoscalerAPIApiAutoscalerAPIGetAgentYamlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetAgentYaml(requestParameters: AutoscalerAPIApiAutoscalerAPIGetAgentYamlRequest = {}, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetAgentYaml(requestParameters.provider, requestParameters.eksRegion, requestParameters.eksAccountId, requestParameters.eksClusterName, requestParameters.gkeRegion, requestParameters.gkeProjectId, requestParameters.gkeClusterName, requestParameters.gkeLocation, requestParameters.kopsCsp, requestParameters.kopsRegion, requestParameters.kopsClusterName, requestParameters.kopsStateStore, requestParameters.aksLocation, requestParameters.aksNodeResourceGroup, requestParameters.aksSubscriptionId, requestParameters.openshiftCsp, requestParameters.openshiftRegion, requestParameters.openshiftClusterName, requestParameters.openshiftInternalId, requestParameters.openshiftRunAsUser, requestParameters.openshiftRunAsGroup, requestParameters.openshiftFsGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent information: version, etc
     * @param {AutoscalerAPIApiAutoscalerAPIGetClusterAgentInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetClusterAgentInfo(requestParameters: AutoscalerAPIApiAutoscalerAPIGetClusterAgentInfoRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetClusterAgentInfo(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cluster settings
     * @param {AutoscalerAPIApiAutoscalerAPIGetClusterSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetClusterSettings(requestParameters: AutoscalerAPIApiAutoscalerAPIGetClusterSettingsRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetClusterSettings(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all cluster workloads.
     * @summary Get cluster workloads
     * @param {AutoscalerAPIApiAutoscalerAPIGetClusterWorkloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetClusterWorkloads(requestParameters: AutoscalerAPIApiAutoscalerAPIGetClusterWorkloadsRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetClusterWorkloads(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Evictor requests this endpoint before each action.
     * @summary Get the Evictor configuration
     * @param {AutoscalerAPIApiAutoscalerAPIGetEvictorConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetEvictorConfig(requestParameters: AutoscalerAPIApiAutoscalerAPIGetEvictorConfigRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetEvictorConfig(requestParameters.clusterId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get karpenter definitions migration intent
     * @param {AutoscalerAPIApiAutoscalerAPIGetKarpenterDefinitionsMigrationIntentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetKarpenterDefinitionsMigrationIntent(requestParameters: AutoscalerAPIApiAutoscalerAPIGetKarpenterDefinitionsMigrationIntentRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetKarpenterDefinitionsMigrationIntent(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets cluster problematic nodes that are currently running in the cluster. These nodes cannot be rebalanced.
     * @summary Get problematic nodes
     * @param {AutoscalerAPIApiAutoscalerAPIGetProblematicNodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetProblematicNodes(requestParameters: AutoscalerAPIApiAutoscalerAPIGetProblematicNodesRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetProblematicNodes(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets cluster problematic workloads that are currently running in the cluster. These workloads cannot be rebalanced.
     * @summary Get problematic workloads
     * @param {AutoscalerAPIApiAutoscalerAPIGetProblematicWorkloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetProblematicWorkloads(requestParameters: AutoscalerAPIApiAutoscalerAPIGetProblematicWorkloadsRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetProblematicWorkloads(requestParameters.clusterId, requestParameters.aggressiveMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the rebalanced workloads. Only relevant when rebalancing execution is in-progress.
     * @summary Get rebalanced workloads
     * @param {AutoscalerAPIApiAutoscalerAPIGetRebalancedWorkloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetRebalancedWorkloads(requestParameters: AutoscalerAPIApiAutoscalerAPIGetRebalancedWorkloadsRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetRebalancedWorkloads(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will only return a body when the plan\'s generation has fully finished.
     * @summary Get a rebalancing plan
     * @param {AutoscalerAPIApiAutoscalerAPIGetRebalancingPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIGetRebalancingPlan(requestParameters: AutoscalerAPIApiAutoscalerAPIGetRebalancingPlanRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIGetRebalancingPlan(requestParameters.clusterId, requestParameters.rebalancingPlanId, requestParameters.includeOperations, requestParameters.includeConfigurations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ingest component logs running on managed clusters rather than mothership.
     * @param {AutoscalerAPIApiAutoscalerAPIIngestComponentLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIIngestComponentLogs(requestParameters: AutoscalerAPIApiAutoscalerAPIIngestComponentLogsRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIIngestComponentLogs(requestParameters.clusterId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ingest component logs running on managed clusters rather than mothership.
     * @param {AutoscalerAPIApiAutoscalerAPIIngestComponentLogs2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIIngestComponentLogs2(requestParameters: AutoscalerAPIApiAutoscalerAPIIngestComponentLogs2Request, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIIngestComponentLogs2(requestParameters.clusterId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists cluster rebalancing plans by cluster ID.
     * @summary List cluster rebalancing plans
     * @param {AutoscalerAPIApiAutoscalerAPIListRebalancingPlansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIListRebalancingPlans(requestParameters: AutoscalerAPIApiAutoscalerAPIListRebalancingPlansRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIListRebalancingPlans(requestParameters.clusterId, requestParameters.limit, requestParameters.cursor, requestParameters.filterStatuses, requestParameters.includeOperations, requestParameters.includeConfigurations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Migrate karpenter custom resource definitions to CAST AI configuration
     * @param {AutoscalerAPIApiAutoscalerAPIMigrateKarpenterDefinitionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoscalerAPIApi
     */
    public autoscalerAPIMigrateKarpenterDefinitions(requestParameters: AutoscalerAPIApiAutoscalerAPIMigrateKarpenterDefinitionsRequest, options?: RawAxiosRequestConfig) {
        return AutoscalerAPIApiFp(this.configuration).autoscalerAPIMigrateKarpenterDefinitions(requestParameters.clusterId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AutoscalerAPIGetAgentScriptProviderEnum = {
    invalid_provider: 'invalid_provider',
    eks: 'eks',
    gke: 'gke',
    aks: 'aks',
    openshift: 'openshift',
    kops: 'kops'
} as const;
export type AutoscalerAPIGetAgentScriptProviderEnum = typeof AutoscalerAPIGetAgentScriptProviderEnum[keyof typeof AutoscalerAPIGetAgentScriptProviderEnum];
/**
 * @export
 */
export const AutoscalerAPIGetAgentScriptKopsCspEnum = {
    invalid: 'invalid',
    INVALID: 'INVALID',
    aws: 'aws',
    AWS: 'AWS',
    gcp: 'gcp',
    GCP: 'GCP',
    azure: 'azure',
    AZURE: 'AZURE'
} as const;
export type AutoscalerAPIGetAgentScriptKopsCspEnum = typeof AutoscalerAPIGetAgentScriptKopsCspEnum[keyof typeof AutoscalerAPIGetAgentScriptKopsCspEnum];
/**
 * @export
 */
export const AutoscalerAPIGetAgentScriptOpenshiftCspEnum = {
    invalid: 'invalid',
    INVALID: 'INVALID',
    aws: 'aws',
    AWS: 'AWS',
    gcp: 'gcp',
    GCP: 'GCP',
    azure: 'azure',
    AZURE: 'AZURE'
} as const;
export type AutoscalerAPIGetAgentScriptOpenshiftCspEnum = typeof AutoscalerAPIGetAgentScriptOpenshiftCspEnum[keyof typeof AutoscalerAPIGetAgentScriptOpenshiftCspEnum];
/**
 * @export
 */
export const AutoscalerAPIGetAgentYamlProviderEnum = {
    invalid_provider: 'invalid_provider',
    eks: 'eks',
    gke: 'gke',
    aks: 'aks',
    openshift: 'openshift',
    kops: 'kops'
} as const;
export type AutoscalerAPIGetAgentYamlProviderEnum = typeof AutoscalerAPIGetAgentYamlProviderEnum[keyof typeof AutoscalerAPIGetAgentYamlProviderEnum];
/**
 * @export
 */
export const AutoscalerAPIGetAgentYamlKopsCspEnum = {
    invalid: 'invalid',
    INVALID: 'INVALID',
    aws: 'aws',
    AWS: 'AWS',
    gcp: 'gcp',
    GCP: 'GCP',
    azure: 'azure',
    AZURE: 'AZURE'
} as const;
export type AutoscalerAPIGetAgentYamlKopsCspEnum = typeof AutoscalerAPIGetAgentYamlKopsCspEnum[keyof typeof AutoscalerAPIGetAgentYamlKopsCspEnum];
/**
 * @export
 */
export const AutoscalerAPIGetAgentYamlOpenshiftCspEnum = {
    invalid: 'invalid',
    INVALID: 'INVALID',
    aws: 'aws',
    AWS: 'AWS',
    gcp: 'gcp',
    GCP: 'GCP',
    azure: 'azure',
    AZURE: 'AZURE'
} as const;
export type AutoscalerAPIGetAgentYamlOpenshiftCspEnum = typeof AutoscalerAPIGetAgentYamlOpenshiftCspEnum[keyof typeof AutoscalerAPIGetAgentYamlOpenshiftCspEnum];
/**
 * @export
 */
export const AutoscalerAPIListRebalancingPlansFilterStatusesEnum = {
    invalid: 'invalid',
    generating: 'generating',
    generated: 'generated',
    creating_nodes: 'creating_nodes',
    preparing_nodes: 'preparing_nodes',
    draining_nodes: 'draining_nodes',
    deleting_nodes: 'deleting_nodes',
    finished: 'finished',
    error: 'error',
    partially_finished: 'partially_finished'
} as const;
export type AutoscalerAPIListRebalancingPlansFilterStatusesEnum = typeof AutoscalerAPIListRebalancingPlansFilterStatusesEnum[keyof typeof AutoscalerAPIListRebalancingPlansFilterStatusesEnum];
