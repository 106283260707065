import isEmpty from 'lodash/isEmpty';
import { matchPath, generatePath } from 'react-router-dom';

import { organizationQueryKey } from '@cast/constants';

import { makeSearchQuery } from 'components/search/utils';
import { flags } from 'core/flags';

const _flags = flags['page-modules'].cluster['cost-report'];
// prettier-ignore
const availabilityMatrix = [
  /*                      cluster | workload  | workloads | namespace | namespaces | allocation groups */
  /* compute cost */    [ _flags.cluster['compute-cost'].value, _flags.workload['compute-cost'].value, _flags.workloads['compute-cost'].value,  _flags.namespace['compute-cost'].value, _flags.namespaces['compute-cost'].value,  _flags['allocation-groups']['compute-cost'].value, ],
  /* network cost */    [ _flags.cluster['network-cost'].value, _flags.workload['network-cost'].value, _flags.workloads['network-cost'].value,  _flags.namespace['network-cost'].value, _flags.namespaces['network-cost'].value,  _flags['allocation-groups']['network-cost'].value, ],
  /* efficiency cost */ [ _flags.cluster['efficiency'].value,   _flags.workload['efficiency'].value,   _flags.workloads['efficiency'].value,    _flags.namespace['efficiency'].value, _flags.namespaces['efficiency'].value,    _flags['allocation-groups']['efficiency'].value,  ],
  /* gpu utilization */ [_flags.cluster['gpu-utilization'].value, _flags.workload['gpu-utilization'].value, _flags.workloads['gpu-utilization'].value, _flags.namespace['gpu-utilization'].value, _flags.namespaces['gpu-utilization'].value, _flags['allocation-groups']['gpu-utilization'].value],
];

const BASE_URL = '/external-clusters/:clusterId/cost-report';
const PERSISTENT_QUERY_PARAMS = [
  organizationQueryKey,
  'cost_report_date_preset',
  'cost_report_from',
  'cost_report_to',
];

const customRouteSegments = {
  comparison: 'comparison',
} as const;

// Note: order is important when matching routes and checking availability by index, make sure "details" segments are defined before "list" segments
const routeSegmentsByObject = {
  cluster: 'cluster',
  workload: 'workloads/__report_type__/:workloadName/:namespace/:workloadType',
  workloads: 'workloads',
  namespace: 'namespaces/__report_type__/:namespace',
  namespaces: 'namespaces',
  allocationGroups: 'allocation-groups',
} as const;

// Note: order is important when checking availability by index
const routeSegmentsByReportingType = {
  computeCost: 'compute-cost',
  networkCost: 'network-cost',
  efficiency: 'efficiency',
  gpuUtilization: 'gpu-utilization',
} as const;

export type CustomRouteSegmentKeys = keyof typeof customRouteSegments;
export type ObjectSegmentKeys = keyof typeof routeSegmentsByObject;
export type ReportTypeSegmentKeys = keyof typeof routeSegmentsByReportingType;
export type CostMonitoringRouteKeys =
  | ObjectSegmentKeys
  | CustomRouteSegmentKeys
  | `${ObjectSegmentKeys}.${ReportTypeSegmentKeys}`;

export type NavigationParams = {
  object?: ObjectSegmentKeys;
  reportType?: ReportTypeSegmentKeys;
};

type RoutePath = string;

type RouteMatch = {
  object: ObjectSegmentKeys;
  reportType: ReportTypeSegmentKeys;
  path: RoutePath;
  params: Record<string, string>;
};

export type ClusterId = 'clusterId';
export type WorkloadName = 'workloadName';
export type Namespace = 'namespace';
export type WorkloadType = 'workloadType';

export type LinkParams<T extends string> = Partial<Record<T, string>>;
export type LinkFilterParams<T extends string> = Partial<Record<T, string>>;

const isAvailableRoute = (o: ObjectSegmentKeys, t: ReportTypeSegmentKeys) => {
  return availabilityMatrix[
    Object.keys(routeSegmentsByReportingType).indexOf(t)
  ][Object.keys(routeSegmentsByObject).indexOf(o)];
};

export const COST_MONITORING_PATHS = ((): Record<
  CostMonitoringRouteKeys,
  string
> => {
  const r = {} as Record<CostMonitoringRouteKeys, string>;

  (Object.keys(customRouteSegments) as CustomRouteSegmentKeys[]).forEach(
    (object) => {
      r[object] = `${BASE_URL}/${customRouteSegments[object]}`;
    }
  );

  // prettier-ignore
  (Object.keys(routeSegmentsByObject) as ObjectSegmentKeys[]).forEach(
    (object) => {
      const objectSegment = routeSegmentsByObject[object];
      if (!objectSegment.includes('__report_type__')) {
        r[object] = `${BASE_URL}/${routeSegmentsByObject[object]}`;
      }

      (
        Object.keys(routeSegmentsByReportingType) as ReportTypeSegmentKeys[]
      ).forEach((reportingType) => {
        if (isAvailableRoute(object, reportingType)) {
          const key: CostMonitoringRouteKeys = `${object}.${reportingType}`;
          const reportingTypeSegment =
            routeSegmentsByReportingType[reportingType];
          if (objectSegment.includes('__report_type__')) {
            r[key] = `${BASE_URL}/${routeSegmentsByObject[object]}`.replace(
              '__report_type__',
              reportingTypeSegment
            );
          } else {
            r[
              key
              ] = `${BASE_URL}/${routeSegmentsByObject[object]}/${routeSegmentsByReportingType[reportingType]}`;
          }
        }
      });
    }
  );

  return r;
})();

export const getRoutePath = (key: CostMonitoringRouteKeys): string => {
  if (!(key in COST_MONITORING_PATHS)) {
    return '/404';
  }

  return COST_MONITORING_PATHS[key];
};
const retrieveCurrentRouteContext = (): RouteMatch | undefined => {
  return (
    Object.keys(COST_MONITORING_PATHS) as CostMonitoringRouteKeys[]
  ).reduce((acc, k) => {
    const match = matchPath(COST_MONITORING_PATHS[k], window.location.pathname);

    if (match) {
      const [object, reportType] = k.split('.') as [
        ObjectSegmentKeys,
        ReportTypeSegmentKeys
      ];

      return {
        object,
        reportType,
        path: COST_MONITORING_PATHS[k],
        params: match.params,
      } as RouteMatch;
    }

    return acc;
  }, undefined as RouteMatch | undefined);
};

const mergeQueryParams = (
  q1: URLSearchParams,
  q2: URLSearchParams
): URLSearchParams => {
  const mergerParams = new URLSearchParams();
  q1.forEach((value, key) => {
    mergerParams.append(key, value);
  });

  q2.forEach((value, key) => {
    mergerParams.append(key, value);
  });

  return mergerParams;
};
const appendQueryParams = (path: string, queryParameters: URLSearchParams) => {
  const mergerParams = new URLSearchParams();
  queryParameters.forEach((value, key) => {
    mergerParams.append(key, value);
  });

  const searchParams = new URLSearchParams(window.location.search);
  // Copy query parameters from the current URL
  searchParams.forEach((value, key) => {
    if (PERSISTENT_QUERY_PARAMS.includes(key)) {
      mergerParams.append(key, value);
    }
  });

  return `${path}?${mergerParams.toString()}`;
};

export const buildFilterParams = (
  id: string,
  queryParams: URLSearchParams = new URLSearchParams(),
  filterParams: LinkFilterParams<string>
) => {
  if (filterParams && !isEmpty(filterParams)) {
    return mergeQueryParams(
      queryParams,
      makeSearchQuery({
        id,
        ...filterParams,
      })
    );
  }
  return queryParams;
};

export const buildLinkToReport = (
  destinationPath: string,
  params: Record<string, string> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  // clusterId is a required param for all routes
  const baseMatch = matchPath(
    `/external-clusters/:clusterId/*`,
    window.location.pathname
  );

  // retrieve even more data from the current URL
  const match = retrieveCurrentRouteContext();
  const mergedParams: Record<string, string | undefined> = {
    ...baseMatch?.params,
    ...match?.params,
    ...params,
  };

  if (isEmpty(mergedParams)) {
    throw new Error(`Missing params for route ${destinationPath}`);
  }

  const path = generatePath(destinationPath, mergedParams);

  // Validate whether all params are provided
  const missingParams = path.match(/\/:\w+/g);
  if (missingParams?.length) {
    throw new Error(
      `Missing params ${missingParams
        .join(', ')
        .replaceAll('/:', '')} for route ${path}`
    );
  }

  return appendQueryParams(path, queryParams);
};

export const getLinkToReport = (
  { object, reportType }: NavigationParams,
  params: Record<string, string> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  // retrieve even more data from the current URL
  const match = retrieveCurrentRouteContext();

  // building navigation key by importance weight: given params => existing context => default
  const _object: ObjectSegmentKeys = object ?? match?.object ?? 'cluster';
  const _reportType: ReportTypeSegmentKeys =
    reportType ?? match?.reportType ?? 'computeCost';

  const key: CostMonitoringRouteKeys = isAvailableRoute(_object, _reportType)
    ? `${_object}.${_reportType}`
    : `${_object}.computeCost`;

  const destinationPath = COST_MONITORING_PATHS[key];
  return buildLinkToReport(destinationPath, params, queryParams);
};
