import { IS_PROD } from 'common/constants';

export const fallbackFlags = {
  'external-features': {
    chargebee: {
      value: true,
      reason: 'FALLBACK',
    },
    'google-optimize': {
      value: true,
      reason: 'FALLBACK',
    },
    'google-tag-manager': {
      value: true,
      reason: 'FALLBACK',
    },
    hubspot: {
      value: true,
      reason: 'FALLBACK',
    },
    revenuehero: {
      value: true,
      reason: 'FALLBACK',
    },
    sentry: {
      value: true,
      reason: 'FALLBACK',
    },
  },
  'page-modules': {
    'api-access-keys': {
      value: true,
      reason: 'FALLBACK',
    },
    billing: {
      value: true,
      reason: 'FALLBACK',
    },
    'billing-report': {
      value: false,
      reason: 'FALLBACK',
    },
    cluster: {
      'audit-log': {
        value: true,
        reason: 'FALLBACK',
      },
      autoscaler: {
        'node-configurations': {
          value: true,
          reason: 'FALLBACK',
        },
        'node-templates': {
          value: true,
          reason: 'FALLBACK',
        },
        settings: {
          value: true,
          reason: 'FALLBACK',
        },
      },
      'available-savings': {
        value: true,
        reason: 'FALLBACK',
      },
      'cost-report': {
        comparison: {
          value: true,
          reason: 'FALLBACK',
        },
        cluster: {
          'compute-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          'network-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          efficiency: {
            value: true,
            reason: 'FALLBACK',
          },
          'gpu-utilization': {
            value: false,
            reason: 'FALLBACK',
          },
        },
        workloads: {
          'compute-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          'network-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          efficiency: {
            value: true,
            reason: 'FALLBACK',
          },
          'gpu-utilization': {
            value: true,
            reason: 'FALLBACK',
          },
        },
        workload: {
          'compute-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          'network-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          efficiency: {
            value: true,
            reason: 'FALLBACK',
          },
          'gpu-utilization': {
            value: true,
            reason: 'FALLBACK',
          },
        },
        namespaces: {
          'compute-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          'network-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          efficiency: {
            value: false,
            reason: 'FALLBACK',
          },
          'gpu-utilization': {
            value: false,
            reason: 'FALLBACK',
          },
        },
        namespace: {
          'compute-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          'network-cost': {
            value: false,
            reason: 'FALLBACK',
          },
          efficiency: {
            value: false,
            reason: 'FALLBACK',
          },
          'gpu-utilization': {
            value: false,
            reason: 'FALLBACK',
          },
        },
        'allocation-groups': {
          'compute-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          'network-cost': {
            value: true,
            reason: 'FALLBACK',
          },
          efficiency: {
            value: true,
            reason: 'FALLBACK',
          },
          'gpu-utilization': {
            value: false,
            reason: 'FALLBACK',
          },
        },
      },
      node: {
        value: true,
        reason: 'FALLBACK',
      },
      rebalancer: {
        value: true,
        reason: 'FALLBACK',
      },
      'savings-progress': {
        value: false,
        reason: 'FALLBACK',
      },
      'workload-optimization': {
        value: false,
        reason: 'FALLBACK',
        hpa: {
          value: false,
          reason: 'FALLBACK',
        },
      },
    },
    notifications: {
      value: true,
      reason: 'FALLBACK',
    },
    'organization-management': {
      value: true,
      reason: 'FALLBACK',
    },
    'organization-security': {
      dashboard: {
        value: false,
        reason: 'FALLBACK',
      },
      'best-practices': {
        value: true,
        reason: 'FALLBACK',
      },
      'image-security': {
        value: true,
        reason: 'FALLBACK',
      },
      'attack-path': {
        value: false,
        reason: 'FALLBACK',
      },
      anomalies: {
        value: !IS_PROD,
        reason: 'FALLBACK',
      },
      workloads: {
        value: false,
        reason: 'FALLBACK',
      },
      'node-os-updates': {
        value: true,
        reason: 'FALLBACK',
      },
      settings: {
        value: true,
        reason: 'FALLBACK',
      },
    },
    'organization-cost-report': {
      'clusters-compute-cost': {
        value: true,
        reason: 'FALLBACK',
      },
      'allocation-groups': {
        value: true,
        reason: 'FALLBACK',
      },
    },
    organization: {
      'cpu-usage': {
        value: true,
        reason: 'FALLBACK',
      },
      'scheduled-rebalancing': {
        value: true,
        reason: 'FALLBACK',
      },
      'reserved-instances': {
        value: true,
        reason: 'FALLBACK',
      },
      commitments: {
        value: false,
        reason: 'FALLBACK',
      },
    },
    'organization-llm': {
      'cost-report': {
        value: !IS_PROD,
        reason: 'FALLBACK',
      },
      providers: {
        value: !IS_PROD,
        reason: 'FALLBACK',
      },
    },
  },
  standalone: {
    'arm-available-savings': {
      value: true,
      reason: 'FALLBACK',
    },
    'available-savings-blur-optimized-nodes': {
      value: true,
      reason: 'FALLBACK',
    },
    'available-savings-workload-rightsizing': {
      value: true,
      reason: 'FALLBACK',
    },
    'clusters-rebalancing': {
      value: true,
      reason: 'FALLBACK',
    },
    manifest: {
      value: true,
      reason: 'FALLBACK',
    },
    'security-phase-2': {
      value: true,
      reason: 'FALLBACK',
    },
    'show-instance-prices': {
      value: true,
      reason: 'FALLBACK',
    },
    'security-policy-enforcement': {
      value: !IS_PROD,
      reason: 'FALLBACK',
    },
    'karpenter-migration': {
      value: true,
      reason: 'FALLBACK',
    },
    'cost-monitoring-short-date-range': {
      value: false,
      reason: 'FALLBACK',
    },
    'security-vulnerabilities-and-package-exceptions': {
      value: !IS_PROD,
      reason: 'FALLBACK',
    },
    'security-image-deletion': {
      value: !IS_PROD,
      reason: 'FALLBACK',
    },
    'security-integrations': {
      value: !IS_PROD,
      reason: 'FALLBACK',
    },
    'security-base-images-recommendation': {
      value: !IS_PROD,
      reason: 'FALLBACK',
    },
    'cost-monitoring-storage': {
      value: !IS_PROD,
      reason: 'FALLBACK',
    },
  },
} as const;
