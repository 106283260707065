import { Stack, Typography } from '@mui/material';

import {
  Button,
  DialogActions,
  DialogContent,
  Skeleton,
} from '@cast/design-system';

import { AutoscalerVisualSvg } from 'assets/svg';
import { useSwipeableViews } from 'components/boxes';
import { AgentCodeSnippet } from 'components/code';
import { useCredentialsScriptQuery } from 'hooks/queries/cluster';

import { AssignCredentialsDialogHeader } from '../../../_components/AssignCredentialsDialogHeader';

type Props = {
  onClose: () => void;
  clusterId: string;
};

export const RunTheScriptSlide = ({ onClose, clusterId }: Props) => {
  const swipeableViewsCtx = useSwipeableViews();
  const { isLoading, script, error, refetch } = useCredentialsScriptQuery({
    id: clusterId,
    installAutoscalerAgent: true,
  });

  return (
    <Stack position="relative">
      <AssignCredentialsDialogHeader dismiss={onClose} />

      <DialogContent sx={{ pb: 0 }}>
        <Stack direction="row" alignItems="center" gap={24} mb={24}>
          <Stack gap={4}>
            <Typography variant="h6">Kubernetes automation</Typography>
            <Typography variant="P12R" color="textSecondary">
              Everything you need to keep your cloud costs and savings in check.
            </Typography>
          </Stack>
          <Stack direction="row">
            <AutoscalerVisualSvg
              css={{ width: 40, height: 40, flexShrink: 0 }}
            />
          </Stack>
        </Stack>

        <Typography variant="L10M" color="grey.400" mb={8}>
          RUN THE SCRIPT:
        </Typography>
      </DialogContent>

      <DialogContent sx={{ py: 16, backgroundColor: 'grey.100' }}>
        {isLoading || !script ? (
          <Skeleton width="100%" height={70} />
        ) : (
          <AgentCodeSnippet code={script} error={error} refetch={refetch} />
        )}
      </DialogContent>

      <DialogActions sx={{ py: 16 }}>
        <Button
          onClick={() => swipeableViewsCtx?.goToNextSlide()}
          testId="i-ran-script-button"
        >
          I ran the script
        </Button>
      </DialogActions>
    </Stack>
  );
};
