import { CodeProps } from 'react-markdown/lib/ast-to-react';

import { PreviewMarkdownBaseElement } from './PreviewMarkdownBaseElement';
import { PreviewSnippet, PreviewSnippetProps } from '../code';

type Props = CodeProps & {
  onCopy?: PreviewSnippetProps['onCopy'];
};

export const PreviewMarkdownCodeElement = ({
  children,
  inline,
  onCopy,
}: Props) => {
  const codeString = String(children).replace(/\n$/, '');
  if (inline) {
    return (
      <PreviewMarkdownBaseElement type="codeInline" as="pre">
        {codeString}
      </PreviewMarkdownBaseElement>
    );
  }

  return <PreviewSnippet code={codeString} onCopy={onCopy} />;
};
