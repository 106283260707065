import { IS_PROD } from 'common/constants';

// prettier-ignore
export const EXTERNAL_LINKS = {
  aws_console: 'https://console.aws.amazon.com',
  gcp_console: 'https://console.cloud.google.com',
  azure_console: 'https://portal.azure.com',
  jq: 'https://stedolan.github.io/jq/',
  castai_swagger: IS_PROD ? 'https://docs.cast.ai/reference' : `${window.location.protocol}//${window.location.hostname.replace('console', 'api')}/v1/spec/`,
  castai_terraform: 'https://registry.terraform.io/providers/CastAI/castai/latest',
  castai_slack_community: 'https://join.slack.com/t/castai-community/shared_invite/zt-nwtbsbqq-dWOBiUgLtIGMsUSup7Xksw',
  castai_landing: '',
  castai_book_demo: 'https://cast.ai/book-a-demo/',
  castai_pricing: 'https://cast.ai/pricing/',
  castai_referral_program: 'https://cast.ai/referral-program',
  castai_kvisor_docs_enable_private_image_scan: 'https://docs.cast.ai/docs/kvisor#enable-private-image-scan-v0370',
  castai_kvisor_docs: 'https://docs.cast.ai/docs/kvisor',
  castai_advanced_evictor: 'https://docs.cast.ai/docs/evictor-advanced-configuration',
  docs: 'https://docs.cast.ai/docs/',
  carpenter_docs: 'https://docs.cast.ai/docs/migration-from-karpenter',
  docs_agent: 'https://github.com/castai/k8s-agent',
  docs_agent_upgrade: 'https://docs.cast.ai/docs/helm-charts#eks',
  docs_overview: 'https://docs.cast.ai/docs/getting-started',
  docs_spot: 'https://docs.cast.ai/docs/spot',
  docs_sso: 'https://docs.cast.ai/reference/sso',
  docs_disconnect: 'https://docs.cast.ai/docs/disconnect-your-cluster',
  docs_remove_resource: 'https://docs.cast.ai/docs/disconnect-your-cluster#removing-cast-ai-credentials-and-other-resources',
  docs_evictor: 'https://docs.cast.ai/docs/evictor',
  docs_assign_credentials_eks: 'https://docs.cast.ai/docs/credential-onboarding#eks',
  docs_assign_credentials_gke: 'https://docs.cast.ai/docs/credential-onboarding#gke',
  docs_gpu_config: 'https://docs.cast.ai/docs/gpu#configuration',
  docs_gpu_metrics_exporter: "https://docs.cast.ai/docs/gpu-metrics-exporter",
  docs_unscheduled_pods: 'https://docs.cast.ai/docs/autoscaler-checklist',
  docs_how_it_works: 'https://docs.cast.ai/docs/how-it-works',
  docs_interruption_prediction_model: 'https://docs.cast.ai/docs/spot#interruption-prediction-model',
  docs_autoscaler_scoped: 'https://docs.cast.ai/docs/scoped-autoscaler',
  docs_upgrading_agent: 'https://docs.cast.ai/docs/troubleshooting#upgrading-the-agent',
  docs_egressd: 'https://docs.cast.ai/docs/egressd',
  docs_private_image_scan: "https://docs.cast.ai/docs/kvisor?_gl=1*1cvearm*_gcl_au*MjA2MTQxMTUxNS4xNjkzMzc2NDkx&_ga=2.137198708.51767698.1699854582-646632864.1638359834#enable-private-image-scan",
  docs_policy_enforcement: 'https://docs.cast.ai/docs/kvisor#enable-policy-enforcement',
  docs_kvisor_config: 'https://docs.cast.ai/docs/kvisor#configuring-features',
  docs_woop: 'https://docs.cast.ai/docs/workload-autoscaling-overview',
  docs_woop_upgrading: 'https://docs.cast.ai/docs/workload-autoscaling-configuration#upgrading',
  docs_woop_confidence: 'https://docs.cast.ai/docs/workload-autoscaling-overview#mark-of-recommendation-confidence',
  docs_woop_annotations: 'https://docs.cast.ai/docs/workload-autoscaling-configuration#configuration-via-annotations',
  docs_woop_hpa: 'https://docs.cast.ai/docs/horizontal-pod-autoscaling',
  docs_images_scanning: "https://docs.cast.ai/docs/images-scanning",
  docs_cluster_efficiency: "https://docs.cast.ai/docs/cluster-efficiency-report#how-to-understand-metrics-in-this-report",
  docs_partial_node_template_matching: 'https://docs.cast.ai/docs/node-templates#node-template-matching',
  docs_webhook_examples: 'https://docs.cast.ai/docs/examples',
  docs_kvisor_upgrade: "https://docs.cast.ai/docs/kvisor#upgrade-the-kvisor-agent",
  docs_runtime_security: 'https://docs.cast.ai/docs/runtime-security',
  docs_runtime_rule_resource_selector: 'https://docs.cast.ai/docs/anomaly-rules-engine#resource-selectors',
  docs_runtime_cel_rule_text: 'https://docs.cast.ai/docs/anomaly-rules-engine#user-defined-cel-rules',
  docs_ai_optimizer: 'https://docs.cast.ai/docs/getting-started-ai',
  external_azure_reserved_instances: 'https://portal.azure.com/#view/Microsoft_Azure_Reservations/ReservationsBrowseBlade/productType/Reservations',
  external_docs_docker_deamon_config: 'https://docs.docker.com/engine/reference/commandline/dockerd/#daemon-configuration-file',
  external_docs_kubelet_config: 'https://kubernetes.io/docs/reference/config-api/kubelet-config.v1beta1/#kubelet-config-k8s-io-v1beta1-KubeletConfiguration',
  external_docs_read_only_agent: 'https://cast.ai/blog/how-does-the-read-only-cast-ai-agent-work-and-what-data-can-it-read/',
  external_docs_nsa_cisa: 'https://www.nsa.gov/Press-Room/Cybersecurity-Advisories-Guidance/smdsearch11747/kubernetes/',
  external_docs_pod_security: 'https://kubernetes.io/docs/concepts/security/pod-security-standards/',
  external_docs_cis: 'https://www.cisecurity.org/benchmark/kubernetes',
  external_docs_cvss_vector: 'https://www.first.org/cvss/specification-document#Vector-String',
  external_docs_cvss: 'https://www.first.org/cvss/specification-document#Qualitative-Severity-Rating-Scale',
  external_docs_cvss_score: 'https://www.first.org/cvss/v3.1/specification-document',
  external_docs_graviton: 'https://docs.aws.amazon.com/whitepapers/latest/aws-graviton-performance-testing/what-is-aws-graviton.html',
  external_docs_metrics_server: 'https://github.com/kubernetes-sigs/metrics-server',
  external_docs_metrics_server_download: 'https://github.com/kubernetes-sigs/metrics-server/releases/latest/download/components.yaml',
  external_docs_instance_metadata: 'https://docs.aws.amazon.com/elasticbeanstalk/latest/dg/environments-cfg-ec2-imds.html',
  external_docs_eks_control_plane_costs: 'https://aws.amazon.com/about-aws/whats-new/2024/04/amazon-eks-support-kubernetes-versions/'
};
