import {
  ComponentPropsWithRef,
  createContext,
  ElementType,
  forwardRef,
  ReactNode,
  useContext,
} from 'react';

import { styled, useTheme } from '@mui/material';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import LinearProgress from '@mui/material/LinearProgress';

import { IconContext } from '../icons';

export type ButtonProps<C extends ElementType = 'button'> = ButtonBaseProps<
  C,
  {
    component?: C;
    loading?: boolean;
    variant?:
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'ghost'
      | 'danger'
      | 'text';
    size?: 'large' | 'medium' | 'small';
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    fullWidth?: boolean;
    testId?: string;
  }
>;

export const ButtonContext = createContext<ButtonProps | undefined>(undefined);

const ButtonRoot = styled(
  // eslint-disable-next-line react/display-name
  forwardRef(
    <C extends ElementType = 'button'>(
      {
        children,
        loading,
        disabled,
        startIcon,
        endIcon,
        ...props
      }: ButtonProps<C>,
      ref: ComponentPropsWithRef<C>['ref']
    ) => {
      return (
        <IconContext.Provider
          value={{
            size: '1em',
            weight: props.size === 'small' ? 'regular' : 'bold',
          }}
        >
          <ButtonBase {...props} disabled={disabled || loading} ref={ref}>
            {startIcon && (
              <span className="DsButton-StartIcon">{startIcon}</span>
            )}
            {children}
            {endIcon && <span className="DsButton-EndIcon">{endIcon}</span>}
            {loading && <LinearProgress />}
          </ButtonBase>
        </IconContext.Provider>
      );
    }
  ),
  {
    name: 'DsButton',
    slot: 'Root',
    target: 'DsButton-root',
    overridesResolver: (
      { variant, size, disabled, loading, startIcon, endIcon },
      theme
    ) => {
      return [
        theme.root,
        theme[variant],
        theme[size],
        startIcon ? theme.startIcon : {},
        endIcon ? theme.endIcon : {},
        disabled || loading ? theme.disabled : {},
        loading ? theme.loading : {},
      ];
    },
  }
)({});

const Button = forwardRef(
  <C extends ElementType = 'button'>(
    { testId, ...props }: ButtonProps<C>,
    ref: ComponentPropsWithRef<C>['ref']
  ) => {
    const buttonContext = useContext(ButtonContext);
    const theme = useTheme();

    const _props = {
      ...(theme.components?.DsButton?.defaultProps ?? {}),
      ...buttonContext,
      ...props,
    };

    return <ButtonRoot data-testid={testId} {..._props} ref={ref} />;
  }
);
Button.displayName = 'Button';

export default Button;
