import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { Box } from '@mui/material';

import { useDelayedEvent } from '@cast/utils';

import { DrawerOverlay, DrawerOverlayProps } from './DrawerOverlay';
import { useDrawerInstance } from './hooks';

type ShowOverlayArgs = {
  id?: string;
  content: ReactNode;
  duration?: number;
  onDestroy?: () => void;
  closeOnDestroy?: boolean;
};

type DrawerOverlayState = {
  showOverlay: (args: ShowOverlayArgs) => void;
  isOverlayActive: boolean;
  activeOverlayId?: string;
  destroyOverlay: () => void;
};

const Context = createContext<DrawerOverlayState>({
  showOverlay: () =>
    console.error(
      "Calling 'showOverlay' when content is not wrapped with DrawerOverlayProvider"
    ),
  isOverlayActive: false,
  destroyOverlay: () =>
    console.error(
      "Calling 'destroyOverlay' when content is not wrapped with DrawerOverlayProvider"
    ),
});

type Props = {
  children?: ReactNode | ((state: DrawerOverlayState) => ReactNode);
  overlayProps?: DrawerOverlayProps;
};

export const DrawerOverlayProvider = ({ children, overlayProps }: Props) => {
  const { close } = useDrawerInstance();
  const [activeOverlayId, setActiveOverlayId] = useState<string | undefined>();
  const [overlayContent, setOverlayContent] = useState<ReactNode>(null);
  const isOverlayActive = !!overlayContent;

  const delayedEvent = useDelayedEvent();

  const destroyOverlay = useCallback(() => {
    setOverlayContent(null);
    setActiveOverlayId(undefined);
    if (delayedEvent.isActive) {
      delayedEvent.clear();
    }
  }, [setOverlayContent, delayedEvent]);

  const showOverlay = useCallback(
    ({ content, duration, onDestroy, closeOnDestroy, id }: ShowOverlayArgs) => {
      setOverlayContent(content);
      setActiveOverlayId(id);
      if (duration) {
        delayedEvent.activate({
          milliseconds: duration,
          onEnded: () => {
            onDestroy?.();
            if (closeOnDestroy) {
              close();
            } else {
              destroyOverlay();
            }
          },
        });
      } else {
        setOverlayContent(content);
      }
    },
    [setOverlayContent, delayedEvent, close, destroyOverlay]
  );

  return (
    <Context.Provider
      value={{ showOverlay, isOverlayActive, destroyOverlay, activeOverlayId }}
    >
      <Box height="100vh" position="relative" overflow="auto">
        {typeof children === 'function' ? (
          <Context.Consumer>{children as any}</Context.Consumer>
        ) : (
          <>{children}</>
        )}
      </Box>
      {isOverlayActive && (
        <DrawerOverlay {...overlayProps}>{overlayContent}</DrawerOverlay>
      )}
    </Context.Provider>
  );
};

export const useDrawerOverlay = () => useContext(Context);
