import { Button, Icons, Tooltip, useDrawerInstance } from '@cast/design-system';
import { BestPracticeCheck } from '@cast/types';

import { PermissionType, useRBAC } from 'core/rbac';

import { useHasNoAgentsEnabled } from '../../../../hooks/useHasNoAgentsEnabled';
import { useExceptionsManagementDrawer } from '../../useExceptionsManagementDrawer';

type Props = {
  check: BestPracticeCheck;
  testId?: string;
};

export const ExceptionsButton = ({
  check,
  testId = 'check-details-exceptions-button',
}: Props) => {
  const { close } = useDrawerInstance();
  const { hasNoAgentsEnabled } = useHasNoAgentsEnabled();
  const [canEdit] = useRBAC(PermissionType.EDIT);
  const { openExceptionsManagementDrawer } = useExceptionsManagementDrawer();

  const isDisabled = hasNoAgentsEnabled || !canEdit;

  const button = (
    <Button
      testId={testId}
      variant="ghost"
      size="small"
      startIcon={<Icons.ShieldSlash />}
      onClick={() => {
        openExceptionsManagementDrawer(check);
        close();
      }}
      disabled={isDisabled}
    >
      Exceptions
    </Button>
  );

  if (isDisabled) {
    const tooltipText = hasNoAgentsEnabled
      ? 'Enable CAST AI to use this premium feature.'
      : "You don't have permission for this feature.";
    return (
      <Tooltip title={tooltipText} placement="left" arrow>
        <div>{button}</div>
      </Tooltip>
    );
  }

  return button;
};
