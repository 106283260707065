import { PropsWithChildren, useContext, useEffect } from 'react';

import { Portal as MuiPortal, BoxProps } from '@mui/material';

import { PortalsContext } from './PortalsProvider';
import { Portal } from './types';

type Props = PropsWithChildren<
  {
    portal: `${Portal}`;
  } & BoxProps
>;

export const ToPortal = ({ portal, children, ...rest }: Props) => {
  const portalContext = useContext(PortalsContext);
  const portalRef = portalContext?.portals[portal];

  useEffect(() => {
    portalContext?.setPortalProps(portal, rest);
    return () => {
      portalContext?.clearPortalProps(portal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest]);

  if (portalRef) {
    return <MuiPortal container={portalRef}>{children}</MuiPortal>;
  }
  return null;
};
