import { PropsWithChildren } from 'react';

import isUndefined from 'lodash/isUndefined';

import { useAuth } from 'core/auth';

type Props = PropsWithChildren<{ flag?: boolean }>;

export const WhenAuthenticated = ({ flag, children }: Props) => {
  const { isAuthenticated } = useAuth();
  const canLoad = !isUndefined(flag) && flag;

  if (!isAuthenticated || (flag && !canLoad)) {
    return null;
  }

  return children;
};
