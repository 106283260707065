import * as Sentry from '@sentry/react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientConfig,
} from '@tanstack/react-query';

import { flags } from 'core/flags/FlagsProvider';
import { isAxiosError } from 'types/api';
import { isE2E } from 'utils/isE2E';

export const getQueryClient = (config: QueryClientConfig) =>
  new QueryClient(config);

const clientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 20_000,
      gcTime: 20_000,
      // Disables retries in Cypress E2E/integration tests for faster tests
      // when testing failures
      retry: isE2E() ? false : 4,
    },
  },
};

if (!isE2E() && flags?.['external-features'].sentry) {
  clientConfig.mutationCache = new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      Sentry.withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          if (isAxiosError(err)) {
            scope.setFingerprint([
              `axios-error-${err?.response?.status ?? 'unknown'}`,
            ]);
          } else {
            scope.setFingerprint(
              Array.from(mutation.options.mutationKey) as string[]
            );
          }
        }
        Sentry.captureException(err);
      });
    },
  });

  clientConfig.queryCache = new QueryCache({
    onError: (err, query) => {
      Sentry.withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });
        if (isAxiosError(err)) {
          scope.setFingerprint([
            `axios-error-${err?.response?.status ?? 'unknown'}`,
          ]);
        } else {
          scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')]);
        }
        Sentry.captureException(err);
      });
    },
  });
}

export const queryClient = getQueryClient(clientConfig);
