import { DateTypes } from '@cast/design-system';

import { RangePickerContext } from './context';
import { RangePickerProviderProps } from './types';
import { useRangePickerStateHandler } from '../_hooks/useRangePickerStateHandler';
import { defaultQueryParamKeys, defaultFilters } from '../utils';

export const RangePickerProvider = ({
  children,
  filterPresets = defaultFilters,
  defaultFilterPreset = DateTypes.DateRangeFilter.ThisMonth,
  queryParamKeys = defaultQueryParamKeys,
  applyQueryParams = true,
  maxNumberOfDays = 31,
}: RangePickerProviderProps) => {
  const stateHandler = useRangePickerStateHandler({
    filterPresets,
    defaultFilterPreset,
    queryParamKeys,
    applyQueryParams,
  });

  return (
    <RangePickerContext.Provider
      value={{
        ...stateHandler,
        filterPresets,
        defaultFilterPreset,
        queryParamKeys,
        applyQueryParams,
        maxNumberOfDays,
      }}
    >
      {children}
    </RangePickerContext.Provider>
  );
};
