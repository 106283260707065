import type { SetRequired } from 'type-fest';

import type {
  ExternalclusterV1Cluster as ClusterAPIResponse,
  CastaiUsageV1beta1ResourceUsage as ClusterMetricsAPIResponse,
} from '@cast/openapi';

import { K8sProvider } from './cloud';
export type { ExternalclusterV1ListClustersResponse as ClusterListResponse } from '@cast/openapi';
export type { ExternalclusterV1ListNodesResponse as NodeListResponse } from '@cast/openapi';

export enum ClusterStatus {
  CONNECTING = 'connecting',
  READ_ONLY = 'read-only',
  READY = 'ready',
  PAUSED = 'paused',
  PAUSING = 'pausing',
  RESUMING = 'resuming',
  DELETED = 'deleted',
  DELETING = 'deleting',
  WARNING = 'warning',
  FAILED = 'failed',
}

export enum AgentStatus {
  CONNECTING = 'waiting-connection',
  ONLINE = 'online',
  NOT_RESPONDING = 'non-responding',
  DISCONNECTING = 'disconnecting',
  DISCONNECTED = 'disconnected',
}

export enum ClusterDisplayStatus {
  CONNECTING = ClusterStatus.CONNECTING,
  READ_ONLY = ClusterStatus.READ_ONLY,
  READY = ClusterStatus.READY,
  PAUSED = ClusterStatus.PAUSED,
  PAUSING = ClusterStatus.PAUSING,
  RESUMING = ClusterStatus.RESUMING,
  DELETED = ClusterStatus.DELETED,
  DELETING = ClusterStatus.DELETING,
  WARNING = ClusterStatus.WARNING,
  FAILED = ClusterStatus.FAILED,
  WAITING_CONNECTION = AgentStatus.CONNECTING,
  ONLINE = AgentStatus.ONLINE,
  NOT_RESPONDING = AgentStatus.NOT_RESPONDING,
  DISCONNECTING = AgentStatus.DISCONNECTING,
  DISCONNECTED = AgentStatus.DISCONNECTED,
}

export enum ClusterActions {
  PAUSE = 'pause',
  RESUME = 'resume',
  DISCONNECT = 'disconnect',
  DELETE = 'delete',
  RECONNECT = 'reconnect',
  RECONCILE = 'reconcile',
  REMOVE = 'remove',
  CONNECT_PHASE2 = 'connectPhase2',
  CONNECT_AUTOSCALER = 'connectAutoscaler',
  CONNECT_SECURITY = 'connectSecurity',
}

export type ClusterRegion = {
  name: string;
  displayName: string;
};

// Transform API response type to comply with reality
type ClusterResponse = SetRequired<ClusterAPIResponse, 'id' | 'name'> & {
  providerType: K8sProvider;
  agentStatus: AgentStatus;
  status: ClusterStatus;
  reconcileError?: string;
  region: ClusterRegion;
  metrics?: ClusterMetricsAPIResponse;
};

export type Cluster = ClusterResponse & {
  displayStatus: ClusterDisplayStatus;
};

export type { ClusterResponse, ClusterAPIResponse };

export type {
  ExternalclusterV1ClusterUpdate as ClusterEditParams,
  ExternalclusterV1ClusterReconcileInfo as ClusterReconcileInfo,
  ExternalclusterV1DisconnectConfig as ClusterDisconnectParams,
  ExternalclusterV1GetCredentialsScriptResponse as ClusterCredentialsResponse,
  ExternalclusterV1GetCleanupScriptResponse as ClusterCleanupResponse,
  CastaiUsageV1beta1ClusterMetadata as ClusterMetadata,
} from '@cast/openapi';

export { ExternalclusterV1ClusterReconcileInfoReconcileStatus as ClusterReconcileStatus } from '@cast/openapi';
