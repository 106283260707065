import { useTheme, alpha } from '@mui/material';
import { BarProps } from 'recharts';

import { ChartConfig, ChartType } from 'types/charts';

import { useComputeSpendControls } from './useComputeSpendControls';
import { useCostOverTime } from '../../../../cost-report/hooks/useCostOverTime';
import { useCostReportContext } from '../../../../cost-report/hooks/useCostReportContext';
import { CostReportChartType } from '../../../../cost-report/types/costOverTime';

const minBarSize = 8;
const maxBarSize = 20;

export const useComputeSpendChart = (
  barSize: number = minBarSize
): ChartConfig[] => {
  const theme = useTheme();
  const { chartType } = useCostReportContext();
  const { chartNodeType } = useComputeSpendControls();
  const { projectEndOfDayCost } = useCostOverTime();

  if (chartType === CostReportChartType.AREA) {
    return [
      {
        type: ChartType.AREA,
        enabled: chartNodeType.onDemand,
        config: { dataKey: 'onDemand', fill: theme.palette.blue[300]! },
        stackId: 'a',
      },
      {
        type: ChartType.AREA,
        enabled: chartNodeType.fallback,
        config: {
          dataKey: 'fallback',
          fill: theme.palette.yellow[200]!,
        },
        stackId: 'a',
      },
      {
        type: ChartType.AREA,
        enabled: chartNodeType.spot,
        config: {
          dataKey: 'spot',
          fill: theme.palette.indigo[800]!,
        },
        stackId: 'a',
      },
    ];
  }

  const barConfigDefaults: Partial<BarProps> = {
    maxBarSize,
    barSize: barSize > minBarSize ? barSize : minBarSize,
  };

  return [
    {
      type: ChartType.BAR,
      enabled: chartNodeType.spot,
      config: {
        ...barConfigDefaults,
        dataKey: 'spot',
        fill: theme.palette.indigo[800]!,
      },
      stackId: 'a',
      xAxisId: 0,
    },
    {
      type: ChartType.BAR,
      enabled: projectEndOfDayCost && chartNodeType.spot,
      config: {
        ...barConfigDefaults,
        dataKey: 'spotForecast',
        fill: 'none',
        stroke: alpha(theme.palette.indigo[800]!, 0.4),
      },
      stackId: 'b',
      xAxisId: 1,
    },
    {
      type: ChartType.BAR,
      enabled: chartNodeType.fallback,
      config: {
        ...barConfigDefaults,
        dataKey: 'fallback',
        stroke: theme.palette.yellow[200]!,
      },
      stackId: 'a',
      xAxisId: 0,
    },
    {
      type: ChartType.BAR,
      enabled: projectEndOfDayCost && chartNodeType.fallback,
      config: {
        ...barConfigDefaults,
        dataKey: 'fallbackForecast',
        fill: 'none',
        stroke: alpha(theme.palette.yellow[200]!, 0.7),
      },
      stackId: 'b',
      xAxisId: 1,
    },
    {
      type: ChartType.BAR,
      enabled: chartNodeType.onDemand,
      config: {
        ...barConfigDefaults,
        dataKey: 'onDemand',
        fill: theme.palette.blue[300]!,
      },
      stackId: 'a',
      xAxisId: 0,
    },
    {
      type: ChartType.BAR,
      enabled: projectEndOfDayCost && chartNodeType.onDemand,
      config: {
        ...barConfigDefaults,
        dataKey: 'onDemandForecast',
        fill: 'none',
        stroke: alpha(theme.palette.blue[300]!, 0.6),
      },
      stackId: 'b',
      xAxisId: 1,
    },
  ];
};
