/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AioptimizerV1Data } from '../models';
// @ts-ignore
import { AioptimizerV1GetCategorizedPromptsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsAPIKeyUsageReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsAPIKeysSavingsReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsCategoryRecommendationsReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsCategoryUsageReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsRecommendationsReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsUsageReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetModelsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetRoutingEventsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetSupportedProvidersResponse } from '../models';
/**
 * AIOptimizerAPIApi - axios parameter creator
 * @export
 */
export const AIOptimizerAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GetCategorizedPrompts returns a list of categorized prompts from the AI Optimizer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetCategorizedPrompts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/categorized-prompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific api key.
         * @param {string} apiKeyId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport: async (apiKeyId: string, fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport', 'apiKeyId', apiKeyId)
            const localVarPath = `/v1/llm/openai/chat-completions/reports/api-keys/{apiKeyId}/usage`
                .replace(`{${"apiKeyId"}}`, encodeURIComponent(String(apiKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a recommendations report for the API keys used in chat completions.
         * @param {string} apiKeyId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport: async (apiKeyId: string, fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport', 'apiKeyId', apiKeyId)
            const localVarPath = `/v1/llm/openai/chat-completions/reports/api-keys/{apiKeyId}/recommendations`
                .replace(`{${"apiKeyId"}}`, encodeURIComponent(String(apiKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a savings report for the API keys used in chat completions.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport: async (fromTime?: string, toTime?: string, categoryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/chat-completions/reports/api-keys-savings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a recommendations report for the category used in chat completions.
         * @param {string} categoryId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport: async (categoryId: string, fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport', 'categoryId', categoryId)
            const localVarPath = `/v1/llm/openai/chat-completions/reports/categories/{categoryId}/recommendations`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific category.
         * @param {string} categoryId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsCategoryUsageReport: async (categoryId: string, fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('aIOptimizerAPIGetChatCompletionsCategoryUsageReport', 'categoryId', categoryId)
            const localVarPath = `/v1/llm/openai/chat-completions/reports/categories/{categoryId}/usage`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a recommendations report of chat completions data.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsRecommendationsReport: async (fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/chat-completions/reports/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsUsageReport: async (fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/chat-completions/reports/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of models available for routing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetModels: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetRoutingEvents returns a list of routing events.
         * @param {string} promptId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<AIOptimizerAPIGetRoutingEventsTypeEnum>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetRoutingEvents: async (promptId: string, fromTime?: string, toTime?: string, pageLimit?: string, pageCursor?: string, type?: Array<AIOptimizerAPIGetRoutingEventsTypeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promptId' is not null or undefined
            assertParamExists('aIOptimizerAPIGetRoutingEvents', 'promptId', promptId)
            const localVarPath = `/v1/llm/openai/routing-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (promptId !== undefined) {
                localVarQueryParameter['promptId'] = promptId;
            }

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of supported providers along with the supported models.
         * @param {boolean} [supportedInPlayground] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetSupportedProviders: async (supportedInPlayground?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/supported-providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (supportedInPlayground !== undefined) {
                localVarQueryParameter['supportedInPlayground'] = supportedInPlayground;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ReportChatCompletions reports OpenAI chat completions data to the AI Optimizer.
         * @param {AioptimizerV1Data} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIReportChatCompletions: async (data: AioptimizerV1Data, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aIOptimizerAPIReportChatCompletions', 'data', data)
            const localVarPath = `/v1/llm/openai/chat-completions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateCategorizedPrompt updates the categorized prompt.
         * @param {string} categorizedPromptId 
         * @param {AIOptimizerAPIUpdateCategorizedPromptFeedbackScoreEnum} feedbackScore 
         * @param {string} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIUpdateCategorizedPrompt: async (categorizedPromptId: string, feedbackScore: AIOptimizerAPIUpdateCategorizedPromptFeedbackScoreEnum, feedback: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categorizedPromptId' is not null or undefined
            assertParamExists('aIOptimizerAPIUpdateCategorizedPrompt', 'categorizedPromptId', categorizedPromptId)
            // verify required parameter 'feedbackScore' is not null or undefined
            assertParamExists('aIOptimizerAPIUpdateCategorizedPrompt', 'feedbackScore', feedbackScore)
            // verify required parameter 'feedback' is not null or undefined
            assertParamExists('aIOptimizerAPIUpdateCategorizedPrompt', 'feedback', feedback)
            const localVarPath = `/v1/llm/openai/categorized-prompts/{categorizedPromptId}`
                .replace(`{${"categorizedPromptId"}}`, encodeURIComponent(String(categorizedPromptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (feedbackScore !== undefined) {
                localVarQueryParameter['feedbackScore'] = feedbackScore;
            }

            if (feedback !== undefined) {
                localVarQueryParameter['feedback'] = feedback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIOptimizerAPIApi - functional programming interface
 * @export
 */
export const AIOptimizerAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIOptimizerAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GetCategorizedPrompts returns a list of categorized prompts from the AI Optimizer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetCategorizedPrompts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetCategorizedPromptsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetCategorizedPrompts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetCategorizedPrompts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific api key.
         * @param {string} apiKeyId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport(apiKeyId: string, fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsAPIKeyUsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport(apiKeyId, fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a recommendations report for the API keys used in chat completions.
         * @param {string} apiKeyId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport(apiKeyId: string, fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsRecommendationsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport(apiKeyId, fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a savings report for the API keys used in chat completions.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport(fromTime?: string, toTime?: string, categoryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsAPIKeysSavingsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport(fromTime, toTime, categoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a recommendations report for the category used in chat completions.
         * @param {string} categoryId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport(categoryId: string, fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsCategoryRecommendationsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport(categoryId, fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific category.
         * @param {string} categoryId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetChatCompletionsCategoryUsageReport(categoryId: string, fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsCategoryUsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetChatCompletionsCategoryUsageReport(categoryId, fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetChatCompletionsCategoryUsageReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a recommendations report of chat completions data.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetChatCompletionsRecommendationsReport(fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsRecommendationsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetChatCompletionsRecommendationsReport(fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetChatCompletionsRecommendationsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetChatCompletionsUsageReport(fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsUsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetChatCompletionsUsageReport(fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetChatCompletionsUsageReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of models available for routing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetModels(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetModels(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetRoutingEvents returns a list of routing events.
         * @param {string} promptId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<AIOptimizerAPIGetRoutingEventsTypeEnum>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetRoutingEvents(promptId: string, fromTime?: string, toTime?: string, pageLimit?: string, pageCursor?: string, type?: Array<AIOptimizerAPIGetRoutingEventsTypeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetRoutingEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetRoutingEvents(promptId, fromTime, toTime, pageLimit, pageCursor, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetRoutingEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of supported providers along with the supported models.
         * @param {boolean} [supportedInPlayground] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIGetSupportedProviders(supportedInPlayground?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetSupportedProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIGetSupportedProviders(supportedInPlayground, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIGetSupportedProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ReportChatCompletions reports OpenAI chat completions data to the AI Optimizer.
         * @param {AioptimizerV1Data} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIReportChatCompletions(data: AioptimizerV1Data, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIReportChatCompletions(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIReportChatCompletions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary UpdateCategorizedPrompt updates the categorized prompt.
         * @param {string} categorizedPromptId 
         * @param {AIOptimizerAPIUpdateCategorizedPromptFeedbackScoreEnum} feedbackScore 
         * @param {string} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerAPIUpdateCategorizedPrompt(categorizedPromptId: string, feedbackScore: AIOptimizerAPIUpdateCategorizedPromptFeedbackScoreEnum, feedback: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerAPIUpdateCategorizedPrompt(categorizedPromptId, feedbackScore, feedback, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerAPIApi.aIOptimizerAPIUpdateCategorizedPrompt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AIOptimizerAPIApi - factory interface
 * @export
 */
export const AIOptimizerAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIOptimizerAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary GetCategorizedPrompts returns a list of categorized prompts from the AI Optimizer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetCategorizedPrompts(options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetCategorizedPromptsResponse> {
            return localVarFp.aIOptimizerAPIGetCategorizedPrompts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific api key.
         * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeyUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeyUsageReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsAPIKeyUsageReportResponse> {
            return localVarFp.aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport(requestParameters.apiKeyId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a recommendations report for the API keys used in chat completions.
         * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsRecommendationsReportResponse> {
            return localVarFp.aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport(requestParameters.apiKeyId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a savings report for the API keys used in chat completions.
         * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysSavingsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysSavingsReportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsAPIKeysSavingsReportResponse> {
            return localVarFp.aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport(requestParameters.fromTime, requestParameters.toTime, requestParameters.categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a recommendations report for the category used in chat completions.
         * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryRecommendationsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryRecommendationsReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsCategoryRecommendationsReportResponse> {
            return localVarFp.aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport(requestParameters.categoryId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific category.
         * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsCategoryUsageReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryUsageReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsCategoryUsageReportResponse> {
            return localVarFp.aIOptimizerAPIGetChatCompletionsCategoryUsageReport(requestParameters.categoryId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a recommendations report of chat completions data.
         * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsRecommendationsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsRecommendationsReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsRecommendationsReportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsRecommendationsReportResponse> {
            return localVarFp.aIOptimizerAPIGetChatCompletionsRecommendationsReport(requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data.
         * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetChatCompletionsUsageReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsUsageReportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsUsageReportResponse> {
            return localVarFp.aIOptimizerAPIGetChatCompletionsUsageReport(requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of models available for routing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetModels(options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetModelsResponse> {
            return localVarFp.aIOptimizerAPIGetModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetRoutingEvents returns a list of routing events.
         * @param {AIOptimizerAPIApiAIOptimizerAPIGetRoutingEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetRoutingEvents(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetRoutingEventsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetRoutingEventsResponse> {
            return localVarFp.aIOptimizerAPIGetRoutingEvents(requestParameters.promptId, requestParameters.fromTime, requestParameters.toTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of supported providers along with the supported models.
         * @param {AIOptimizerAPIApiAIOptimizerAPIGetSupportedProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIGetSupportedProviders(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetSupportedProvidersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetSupportedProvidersResponse> {
            return localVarFp.aIOptimizerAPIGetSupportedProviders(requestParameters.supportedInPlayground, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ReportChatCompletions reports OpenAI chat completions data to the AI Optimizer.
         * @param {AIOptimizerAPIApiAIOptimizerAPIReportChatCompletionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIReportChatCompletions(requestParameters: AIOptimizerAPIApiAIOptimizerAPIReportChatCompletionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIOptimizerAPIReportChatCompletions(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateCategorizedPrompt updates the categorized prompt.
         * @param {AIOptimizerAPIApiAIOptimizerAPIUpdateCategorizedPromptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerAPIUpdateCategorizedPrompt(requestParameters: AIOptimizerAPIApiAIOptimizerAPIUpdateCategorizedPromptRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIOptimizerAPIUpdateCategorizedPrompt(requestParameters.categorizedPromptId, requestParameters.feedbackScore, requestParameters.feedback, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeyUsageReportRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeyUsageReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeyUsageReport
     */
    readonly apiKeyId: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeyUsageReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeyUsageReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport
     */
    readonly apiKeyId: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysSavingsReportRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysSavingsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport
     */
    readonly toTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport
     */
    readonly categoryId?: string
}

/**
 * Request parameters for aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryRecommendationsReportRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryRecommendationsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport
     */
    readonly categoryId: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIOptimizerAPIGetChatCompletionsCategoryUsageReport operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryUsageReportRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryUsageReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryUsageReport
     */
    readonly categoryId: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryUsageReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryUsageReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIOptimizerAPIGetChatCompletionsRecommendationsReport operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsRecommendationsReportRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsRecommendationsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsRecommendationsReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsRecommendationsReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIOptimizerAPIGetChatCompletionsUsageReport operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsUsageReportRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsUsageReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsUsageReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsUsageReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIOptimizerAPIGetRoutingEvents operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIGetRoutingEventsRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIGetRoutingEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetRoutingEvents
     */
    readonly promptId: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetRoutingEvents
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetRoutingEvents
     */
    readonly toTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetRoutingEvents
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetRoutingEvents
     */
    readonly pageCursor?: string

    /**
     * 
     * @type {Array<'EVENT_TYPE_INVALID' | 'EVENT_TYPE_PROMPT_RECEIVED' | 'EVENT_TYPE_PROMPT_CATEGORIZED' | 'EVENT_TYPE_LOOKUP_PROVIDERS_AND_MODELS' | 'EVENT_TYPE_ORDER_PROVIDERS_AND_MODELS' | 'EVENT_TYPE_PICK_PROVIDER_AND_MODEL' | 'EVENT_TYPE_ROUTE_REQUEST'>}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetRoutingEvents
     */
    readonly type?: Array<AIOptimizerAPIGetRoutingEventsTypeEnum>
}

/**
 * Request parameters for aIOptimizerAPIGetSupportedProviders operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIGetSupportedProvidersRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIGetSupportedProvidersRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIGetSupportedProviders
     */
    readonly supportedInPlayground?: boolean
}

/**
 * Request parameters for aIOptimizerAPIReportChatCompletions operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIReportChatCompletionsRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIReportChatCompletionsRequest {
    /**
     * 
     * @type {AioptimizerV1Data}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIReportChatCompletions
     */
    readonly data: AioptimizerV1Data
}

/**
 * Request parameters for aIOptimizerAPIUpdateCategorizedPrompt operation in AIOptimizerAPIApi.
 * @export
 * @interface AIOptimizerAPIApiAIOptimizerAPIUpdateCategorizedPromptRequest
 */
export interface AIOptimizerAPIApiAIOptimizerAPIUpdateCategorizedPromptRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIUpdateCategorizedPrompt
     */
    readonly categorizedPromptId: string

    /**
     * 
     * @type {'FEEDBACK_SCORE_UNSPECIFIED' | 'BAD' | 'GOOD'}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIUpdateCategorizedPrompt
     */
    readonly feedbackScore: AIOptimizerAPIUpdateCategorizedPromptFeedbackScoreEnum

    /**
     * 
     * @type {string}
     * @memberof AIOptimizerAPIApiAIOptimizerAPIUpdateCategorizedPrompt
     */
    readonly feedback: string
}

/**
 * AIOptimizerAPIApi - object-oriented interface
 * @export
 * @class AIOptimizerAPIApi
 * @extends {BaseAPI}
 */
export class AIOptimizerAPIApi extends BaseAPI {
    /**
     * 
     * @summary GetCategorizedPrompts returns a list of categorized prompts from the AI Optimizer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetCategorizedPrompts(options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetCategorizedPrompts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a report of chat completions usage data for a specific api key.
     * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeyUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeyUsageReportRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetChatCompletionsAPIKeyUsageReport(requestParameters.apiKeyId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a recommendations report for the API keys used in chat completions.
     * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetChatCompletionsAPIKeysRecommendationsReport(requestParameters.apiKeyId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a savings report for the API keys used in chat completions.
     * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysSavingsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsAPIKeysSavingsReportRequest = {}, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetChatCompletionsAPIKeysSavingsReport(requestParameters.fromTime, requestParameters.toTime, requestParameters.categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a recommendations report for the category used in chat completions.
     * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryRecommendationsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryRecommendationsReportRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetChatCompletionsCategoryRecommendationsReport(requestParameters.categoryId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a report of chat completions usage data for a specific category.
     * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetChatCompletionsCategoryUsageReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsCategoryUsageReportRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetChatCompletionsCategoryUsageReport(requestParameters.categoryId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a recommendations report of chat completions data.
     * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsRecommendationsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetChatCompletionsRecommendationsReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsRecommendationsReportRequest = {}, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetChatCompletionsRecommendationsReport(requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a report of chat completions usage data.
     * @param {AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetChatCompletionsUsageReport(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetChatCompletionsUsageReportRequest = {}, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetChatCompletionsUsageReport(requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of models available for routing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetModels(options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetRoutingEvents returns a list of routing events.
     * @param {AIOptimizerAPIApiAIOptimizerAPIGetRoutingEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetRoutingEvents(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetRoutingEventsRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetRoutingEvents(requestParameters.promptId, requestParameters.fromTime, requestParameters.toTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of supported providers along with the supported models.
     * @param {AIOptimizerAPIApiAIOptimizerAPIGetSupportedProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIGetSupportedProviders(requestParameters: AIOptimizerAPIApiAIOptimizerAPIGetSupportedProvidersRequest = {}, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIGetSupportedProviders(requestParameters.supportedInPlayground, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ReportChatCompletions reports OpenAI chat completions data to the AI Optimizer.
     * @param {AIOptimizerAPIApiAIOptimizerAPIReportChatCompletionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIReportChatCompletions(requestParameters: AIOptimizerAPIApiAIOptimizerAPIReportChatCompletionsRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIReportChatCompletions(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateCategorizedPrompt updates the categorized prompt.
     * @param {AIOptimizerAPIApiAIOptimizerAPIUpdateCategorizedPromptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerAPIApi
     */
    public aIOptimizerAPIUpdateCategorizedPrompt(requestParameters: AIOptimizerAPIApiAIOptimizerAPIUpdateCategorizedPromptRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerAPIApiFp(this.configuration).aIOptimizerAPIUpdateCategorizedPrompt(requestParameters.categorizedPromptId, requestParameters.feedbackScore, requestParameters.feedback, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AIOptimizerAPIGetRoutingEventsTypeEnum = {
    INVALID: 'EVENT_TYPE_INVALID',
    PROMPT_RECEIVED: 'EVENT_TYPE_PROMPT_RECEIVED',
    PROMPT_CATEGORIZED: 'EVENT_TYPE_PROMPT_CATEGORIZED',
    LOOKUP_PROVIDERS_AND_MODELS: 'EVENT_TYPE_LOOKUP_PROVIDERS_AND_MODELS',
    ORDER_PROVIDERS_AND_MODELS: 'EVENT_TYPE_ORDER_PROVIDERS_AND_MODELS',
    PICK_PROVIDER_AND_MODEL: 'EVENT_TYPE_PICK_PROVIDER_AND_MODEL',
    ROUTE_REQUEST: 'EVENT_TYPE_ROUTE_REQUEST'
} as const;
export type AIOptimizerAPIGetRoutingEventsTypeEnum = typeof AIOptimizerAPIGetRoutingEventsTypeEnum[keyof typeof AIOptimizerAPIGetRoutingEventsTypeEnum];
/**
 * @export
 */
export const AIOptimizerAPIUpdateCategorizedPromptFeedbackScoreEnum = {
    FEEDBACK_SCORE_UNSPECIFIED: 'FEEDBACK_SCORE_UNSPECIFIED',
    BAD: 'BAD',
    GOOD: 'GOOD'
} as const;
export type AIOptimizerAPIUpdateCategorizedPromptFeedbackScoreEnum = typeof AIOptimizerAPIUpdateCategorizedPromptFeedbackScoreEnum[keyof typeof AIOptimizerAPIUpdateCategorizedPromptFeedbackScoreEnum];
