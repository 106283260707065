import { useContext } from 'react';

import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import gb from 'date-fns/locale/en-GB';
import {
  DayPicker,
  DayPickerProps,
  SelectMultipleEventHandler,
  SelectRangeEventHandler,
  SelectSingleEventHandler,
} from 'react-day-picker';

import DayPickerDayWrapper from './DayPickerDayWrapper';
import { DatePickerContext } from '../DatePickerProvider';
import { dayPickerStyles } from '../dayPickerStyles';
import {
  isMultiDatePicker,
  isPeriodComparisonPicker,
  isRangeDatePicker,
  isSingleDatePicker,
} from '../types';

const blueTheme = (theme: Theme) =>
  dayPickerStyles({
    typography: theme.typography,
    text: theme.palette.grey[600]!,
    textSecondary: theme.palette.grey[400]!,
    textDisabled: theme.palette.grey[300]!,
    label: theme.palette.grey[900]!,
    accentLight: theme.palette.blue[50]!,
    accentContrast: theme.palette.blue[200]!,
    accent: theme.palette.blue[300]!,
    accentDark: theme.palette.blue[500]!,
    accentExtraDark: theme.palette.blue[700]!,
    background: theme.palette.grey[100]!,
    backgroundTrail: theme.palette.grey[100]!,
    activeBackground: theme.palette.blue[600]!,
    disabledBackground: theme.palette.grey[200]!,
  });

const greenTheme = (theme: Theme) =>
  dayPickerStyles({
    typography: theme.typography,
    text: theme.palette.grey[600]!,
    textSecondary: theme.palette.grey[400]!,
    textDisabled: theme.palette.grey[300]!,
    label: theme.palette.grey[900]!,
    accentLight: theme.palette.green[50]!,
    accentContrast: theme.palette.green[200]!,
    accent: theme.palette.green[300]!,
    accentDark: theme.palette.green[500]!,
    accentExtraDark: theme.palette.green[700]!,
    background: theme.palette.grey[100]!,
    backgroundTrail: theme.palette.grey[100]!,
    activeBackground: theme.palette.blue[600]!,
    disabledBackground: theme.palette.grey[200]!,
  });

const DayPickerWrapper = ({
  variant = 'main',
  ...props
}: Partial<DayPickerProps> & { variant?: 'main' | 'secondary' }) => {
  const ctx = useContext(DatePickerContext);

  const buildTheme = variant === 'main' ? blueTheme : greenTheme;

  if (isRangeDatePicker(ctx)) {
    const handleOnSelect: SelectRangeEventHandler = (range, selectedDay) => {
      if (!range) {
        return;
      }

      const previousRangeWasFull = ctx.selected?.from && ctx.selected?.to;

      if (previousRangeWasFull) {
        ctx.setSelected({ from: selectedDay, to: undefined });
      } else {
        if (ctx.maxNumberOfDays && range.from && range.to) {
          const diffInDays = differenceInDays(range.to, range.from);
          if (diffInDays < ctx.maxNumberOfDays) {
            ctx.setSelected(range);
          }
        } else {
          ctx.setSelected(range);
        }
      }
    };

    return (
      <Box sx={buildTheme}>
        <DayPicker
          mode="range"
          locale={gb}
          formatters={{
            formatWeekdayName: (date: Date) => format(date, 'EEE'),
          }}
          className={`rdp-mode_${ctx.mode}`}
          components={{ Day: DayPickerDayWrapper }}
          numberOfMonths={2}
          selected={ctx.selected}
          // We have different type guards than react-day-picker,
          // TS cant narrow down to proper type without our help,
          // and we cant use type guards from react-day-picker lib
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSelect={handleOnSelect}
          toDate={
            typeof ctx.toDate === 'function'
              ? ctx.toDate(ctx.selected)
              : ctx.toDate
          }
          fromDate={
            typeof ctx.fromDate === 'function'
              ? ctx.fromDate(ctx.selected)
              : ctx.fromDate
          }
          defaultMonth={ctx.selected?.from}
          {...props}
        />
      </Box>
    );
  }

  if (isMultiDatePicker(ctx)) {
    const handleOnSelect: SelectMultipleEventHandler = (dates) => {
      if (dates) {
        ctx.setSelected(dates);
      }
    };

    return (
      <Box sx={buildTheme}>
        <DayPicker
          mode="multiple"
          locale={gb}
          formatters={{
            formatWeekdayName: (date: Date) => format(date, 'EEE'),
          }}
          className={`rdp-mode_${ctx.mode}`}
          components={{ Day: DayPickerDayWrapper }}
          numberOfMonths={2}
          selected={ctx.selected}
          // We have different type guards than react-day-picker,
          // TS cant narrow down to proper type without our help,
          // and we cant use type guards from react-day-picker lib
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSelect={handleOnSelect}
          toDate={ctx.toDate}
          fromDate={ctx.fromDate}
          defaultMonth={ctx.selected?.[0]}
          {...props}
        />
      </Box>
    );
  }

  if (isSingleDatePicker(ctx)) {
    const handleOnSelect: SelectSingleEventHandler = (date) => {
      if (date) {
        ctx.setSelected(date);
      }
    };

    return (
      <Box sx={buildTheme}>
        <DayPicker
          mode="single"
          locale={gb}
          formatters={{
            formatWeekdayName: (date: Date) => format(date, 'EEE'),
          }}
          className={`rdp-mode_${ctx.mode}`}
          components={{ Day: DayPickerDayWrapper }}
          numberOfMonths={2}
          selected={ctx.selected}
          // We have different type guards than react-day-picker,
          // TS cant narrow down to proper type without our help,
          // and we cant use type guards from react-day-picker lib
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSelect={handleOnSelect}
          toDate={ctx.toDate}
          fromDate={ctx.fromDate}
          defaultMonth={ctx.selected}
          {...props}
        />
      </Box>
    );
  }

  if (isPeriodComparisonPicker(ctx)) {
    const handleOnSelect: SelectSingleEventHandler = (date) => {
      if (date) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ctx.setSelected(date);
      }
    };

    return (
      <Box sx={buildTheme}>
        <DayPicker
          mode="single"
          locale={gb}
          formatters={{
            formatWeekdayName: (date: Date) => format(date, 'EEE'),
          }}
          className={`rdp-mode_${ctx.mode}`}
          components={{ Day: DayPickerDayWrapper }}
          numberOfMonths={2}
          selected={ctx.selected}
          // We have different type guards than react-day-picker,
          // TS cant narrow down to proper type without our help,
          // and we cant use type guards from react-day-picker lib
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSelect={handleOnSelect}
          toDate={ctx.toDate}
          fromDate={ctx.fromDate}
          defaultMonth={ctx.selected?.from}
          {...props}
        />
      </Box>
    );
  }

  return null;
};

export default DayPickerWrapper;
