import { Stack, Typography } from '@mui/material';

import {
  Icons,
  Tooltip,
  Tabs,
  Tab,
  TabsProvider,
  SkeletonTable,
  TabContent,
} from '@cast/design-system';

import { BillingReportPerClusterTable } from './_components/BillingReportPerClusterTable';
import { BillingReportTotalDailyTable } from './_components/BillingReportTotalDailyTable';
import { useBillingReportContext } from '../../BillingReportContext';

export const BillingReportClustersTableSection = () => {
  const { isLoading, currentTab, setCurrentTab, showEmptyComponents } =
    useBillingReportContext();

  if (showEmptyComponents && !isLoading) {
    return null;
  }

  return (
    <TabsProvider currentTab={currentTab} setCurrentTab={setCurrentTab}>
      <Stack gap={16} data-testid="tables-section">
        <Tabs size="small" fullWidth>
          <Tab
            label="Per cluster"
            value="perCluster"
            sx={{ textTransform: 'none' }}
          />
          <Tab
            label={
              <Typography
                variant="inherit"
                component="div"
                display="flex"
                alignItems="center"
                gap="4px"
              >
                Daily
                <Tooltip
                  title="Calculated once at the end of the day"
                  placement="top"
                  arrow
                >
                  <Icons.Info />
                </Tooltip>
              </Typography>
            }
            value="totalDaily"
            sx={{ textTransform: 'none' }}
          />
        </Tabs>
        {isLoading ? (
          <SkeletonTable size="small" rowCount={8} />
        ) : (
          <>
            <TabContent value="perCluster">
              <BillingReportPerClusterTable />
            </TabContent>
            <TabContent value="totalDaily">
              <BillingReportTotalDailyTable />
            </TabContent>
          </>
        )}
      </Stack>
    </TabsProvider>
  );
};
