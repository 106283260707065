import { isValidElement, useContext } from 'react';

import clsx from 'clsx';

import { DropdownContext } from '../DropdownContext';
import { isMultipleSelection } from '../types';

const SimpleStartAdornment = () => {
  const {
    dropdownProps: { startAdornment },
    autocomplete: {
      getters: { getOptionIcon, getOptionSelectedValue },
      states: { value, selectedOption },
    },
  } = useContext(DropdownContext);

  const startAdornmentResult =
    startAdornment && typeof startAdornment === 'function'
      ? startAdornment(selectedOption)
      : startAdornment;
  const iconResult = value && getOptionIcon?.(value);
  const selectedValue = selectedOption
    ? getOptionSelectedValue(selectedOption)
    : undefined;
  const selectedValueResult = isValidElement(selectedValue)
    ? selectedValue
    : undefined;

  return (
    <>
      {startAdornmentResult}
      {iconResult}
      {selectedValueResult}
    </>
  );
};

export const StartAdornment = () => {
  const {
    dropdownProps,
    autocomplete: {
      states: { value },
    },
  } = useContext(DropdownContext);
  const { inputText, disabled, startAdornment } = dropdownProps;
  if (inputText && !startAdornment) {
    const selectedValues = Array.isArray(value) ? value.length : undefined;
    const isMultiSelect = isMultipleSelection(dropdownProps);
    const hasValue = !!(value as any)?.length;
    return (
      <div
        className="DS-Dropdown-startAdornmentWrapper"
        style={{ width: 'max-content' }}
      >
        <SimpleStartAdornment />
        <span className="DS-Dropdown-multiValueTextWrapper">
          {(isMultiSelect || !hasValue) && (
            <span
              className={clsx(
                'DS-Dropdown-multiValueText',
                !selectedValues && !hasValue && 'DS-Dropdown-emptyDropdown',
                !!selectedValues && 'selected',
                disabled && 'disabled'
              )}
            >
              {inputText}
            </span>
          )}
          {isMultiSelect &&
            !!selectedValues &&
            !dropdownProps.hideMultipleChip && (
              <span
                className={clsx(
                  'DS-Dropdown-multiValueCount',
                  disabled && 'disabled'
                )}
                data-testid="dropdown-multi-value-count"
              >
                {selectedValues}
              </span>
            )}
        </span>
      </div>
    );
  }

  return (
    <div
      className="DS-Dropdown-startAdornmentWrapper"
      style={{ width: 'max-content' }}
    >
      <SimpleStartAdornment />
    </div>
  );
};
