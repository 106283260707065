import { Stack, Typography } from '@mui/material';

import { Skeleton } from '@cast/design-system';

import { AgentCodeSnippet } from 'components/code';
import { useOffboardingScriptQuery } from 'hooks/queries/cluster';

type Props = {
  clusterId: string;
};

const OffboardingScript = ({ clusterId }: Props) => {
  const { isLoading, script, error, refetch } =
    useOffboardingScriptQuery(clusterId);

  if (isLoading) {
    return <Skeleton width="100%" height={70} />;
  }

  return (
    <AgentCodeSnippet
      code={script}
      error={error}
      refetch={refetch}
      errorMessage="Failed to load offboarding script"
      testId="phase2-offboarding-script"
    />
  );
};

export const Phase2OffboardingStep = ({ clusterId }: Props) => {
  return (
    <Stack width={464} gap={8}>
      <Stack gap={4}>
        <Typography variant="h6">
          Clean up the cloud resources that CAST AI created
        </Typography>

        <Typography variant="P12R" color="textSecondary">
          Copy the script below and run it in your cloud shell or terminal.
        </Typography>
      </Stack>

      <OffboardingScript clusterId={clusterId} />
    </Stack>
  );
};
