import { theme } from '@cast/design-system';
import {
  ComputeResourceType,
  ComputeResourceTypeExtended,
  PriceType,
} from '@cast/types';

import { NodeType } from 'types/nodes';

type ResourceType = `${ComputeResourceTypeExtended}`;

export const computeResourceNameMap: Record<ResourceType, string> = {
  [ComputeResourceType.CPU]: 'CPU',
  [ComputeResourceType.MEMORY]: 'Memory',
  [ComputeResourceTypeExtended.GPU]: 'GPU',
};

export const computeResourceKeyMap: Record<
  ResourceType,
  'Cpu' | 'Ram' | 'Gpu'
> = {
  [ComputeResourceTypeExtended.CPU]: 'Cpu',
  [ComputeResourceTypeExtended.MEMORY]: 'Ram',
  [ComputeResourceTypeExtended.GPU]: 'Gpu',
} as const;

export const computeResourceUnitsMap: Record<ResourceType, string> = {
  [ComputeResourceTypeExtended.CPU]: 'CPU',
  [ComputeResourceTypeExtended.MEMORY]: 'GiB',
  [ComputeResourceTypeExtended.GPU]: 'GPU',
};

export const fullPriceTypeMap: Record<PriceType, string> = {
  [PriceType.HOURLY]: 'hourly',
  [PriceType.DAILY]: 'daily',
  [PriceType.MONTHLY]: 'monthly',
};

export const priceTypeMap: Record<PriceType, string> = {
  [PriceType.HOURLY]: 'h',
  [PriceType.DAILY]: 'd',
  [PriceType.MONTHLY]: 'mo',
};

export const promos: Record<string, string> = {
  eks: '/external-clusters/new#eks',
  gke: '/external-clusters/new#gke',
  kops: '/external-clusters/new#kops',
};

export const nodeTypeColors = {
  [NodeType.ON_DEMAND]: theme.palette.blue[300],
  [NodeType.SPOT]: theme.palette.indigo[800],
  [NodeType.FALLBACK]: theme.palette.yellow[400],
};
