import { useState, MouseEvent, useCallback } from 'react';

import { Box, Popover, Stack, Typography } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemText,
  ListSearchInput,
  Scroller,
  Icons,
  DsShadow,
} from '@cast/design-system';
import { Cluster } from '@cast/types';

import { nestedPaths, premiumFeaturePaths } from 'common/constants';
import { K8sProviderIcon } from 'components/icons';
import { Link as RouterLink } from 'components/router';

import { useMainLayout } from '../hooks';

const buildLink = (
  location: ReturnType<typeof useLocation>,
  activeId: string,
  cluster: Cluster
) => {
  const isPremiumPath = premiumFeaturePaths.some((path) => {
    return matchPath(path, location.pathname);
  });

  const isNestedPath = nestedPaths.some((path) => {
    return matchPath(path, location.pathname);
  });

  if ((isPremiumPath && !cluster.credentialsId) || isNestedPath) {
    return `/external-clusters/${cluster.id}/dashboard`;
  }

  return location.pathname.replace(activeId, cluster.id);
};

export type ClusterSidebarItemProps = {
  data: Array<{ cluster: Cluster; selected?: boolean }>;
};

export const ClusterSidebarItem = ({ data = [] }: ClusterSidebarItemProps) => {
  const location = useLocation();
  const { sidebarState } = useMainLayout();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isPopoverOpen = Boolean(anchorEl);
  const isMinimized = sidebarState && sidebarState === 'minimized';
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closePopover = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  }, []);

  const selectedOption = data.find((item) => item.selected);

  const filteredData = data.filter(
    ({ cluster }) =>
      cluster.name.includes(searchInputValue) ||
      cluster.providerNamespaceId?.includes(searchInputValue)
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={isMinimized ? 'center' : 'stretch'}
        marginBottom="12px"
        sx={{ cursor: 'pointer', px: isMinimized ? '0px' : '8px' }}
      >
        <Typography
          variant="L10M"
          color="grey.500"
          textTransform="uppercase"
          pl={isMinimized ? 0 : 12}
        >
          cluster
        </Typography>

        {isMinimized ? (
          <Box
            onClick={handleOpen}
            borderRadius="4px"
            display="flex"
            justifyContent="center"
            width="44px"
            marginTop="8px"
            height="40px"
            bgcolor="white"
            alignItems={isMinimized ? 'center' : 'stretch'}
            sx={(theme) => ({
              border: `1px solid ${theme.palette.grey[200]}`,
              ...(isPopoverOpen && {
                border: `1px solid ${theme.palette.blue[500]}`,
                boxShadow: theme.shadows[DsShadow.ACTIVE_GLOW],
              }),
            })}
            data-testid="cluster-chooser-trigger"
          >
            {selectedOption && (
              <K8sProviderIcon
                provider={selectedOption.cluster.providerType}
                size={14}
              />
            )}
          </Box>
        ) : (
          <Box
            alignItems="center"
            borderRadius="4px"
            bgcolor="white"
            height="40px"
            padding="0px 8px 0px 12px"
            marginTop="10px"
            onClick={handleOpen}
            display="flex"
            justifyContent="space-between"
            sx={(theme) => ({
              maxWidth: '216px',
              border: `1px solid ${theme.palette.grey[200]}`,
              ...(isPopoverOpen && {
                border: `1px solid ${theme.palette.blue[500]}`,
                boxShadow: theme.shadows[DsShadow.ACTIVE_GLOW],
              }),
              '&:hover': {
                cursor: 'pointer',
              },
            })}
            data-testid="cluster-chooser-trigger"
          >
            {selectedOption && (
              <Stack direction="row" gap="8px" width="calc(100% - 16px)">
                <K8sProviderIcon
                  provider={selectedOption.cluster.providerType}
                  size={12}
                />

                <Stack gap="4px" overflow="hidden" justifyContent="center">
                  <Typography variant="P12M" lineHeight="12px" noWrap>
                    {selectedOption.cluster.name}
                  </Typography>
                  {selectedOption.cluster.providerNamespaceId && (
                    <Typography
                      variant="P10R"
                      lineHeight="10px"
                      color="textSecondary"
                      noWrap
                    >
                      {selectedOption.cluster.providerNamespaceId}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}

            {isPopoverOpen ? (
              <Icons.CaretUp size={16} />
            ) : (
              <Icons.CaretDown size={16} />
            )}
          </Box>
        )}
      </Box>

      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={
          isMinimized
            ? {
                vertical: 'top',
                horizontal: 'right',
              }
            : {
                vertical: 'bottom',
                horizontal: 'left',
              }
        }
        sx={{
          marginLeft: '8px',
          ...(!isMinimized && {
            marginTop: '4px',
            marginLeft: '-8px',
          }),
          padding: '4px 0px',
        }}
        disableScrollLock
      >
        <Box width="216px">
          <ListSearchInput
            autoFocus
            autoComplete="off"
            size="small"
            placeholder="Enter cluster name"
            listItemProps={{
              sx: { '&.Mui-focusVisible': { background: 'none' } },
            }}
            value={searchInputValue}
            onChange={(_, v) => setSearchInputValue(v)}
            showClearButton
            clearButtonProps={{
              onClick: () => setSearchInputValue(''),
            }}
          />

          <List size="medium" disablePadding testId="cluster-chooser-list">
            {filteredData.length ? (
              <Scroller
                sx={{ gap: '2px', overflow: 'auto', maxHeight: '225px' }}
              >
                {filteredData.map((option) => {
                  const link = selectedOption
                    ? buildLink(
                        location,
                        selectedOption.cluster.id,
                        option.cluster
                      )
                    : '';

                  return (
                    <ListItem
                      key={option.cluster.id}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      component={RouterLink}
                      to={link}
                      onClick={closePopover}
                      selected={option.selected}
                      startAdornment={
                        <K8sProviderIcon
                          provider={option.cluster.providerType}
                          size={14}
                        />
                      }
                      startAdornmentSx={{
                        alignSelf: 'flex-start',
                        marginTop: '2px',
                      }}
                      multiline
                    >
                      <ListItemText
                        primary={option.cluster.name}
                        primaryTypographyProps={{ variant: 'P12M' }}
                        secondary={option.cluster.providerNamespaceId}
                      />
                    </ListItem>
                  );
                })}
              </Scroller>
            ) : (
              <ListItem
                startAdornment={<Icons.Nut size={14} />}
                startAdornmentSx={{
                  alignSelf: 'flex-start',
                  marginTop: '2px',
                }}
                multiline
              >
                <ListItemText
                  primary="No clusters found"
                  secondary="Adjust your search criteria"
                />
              </ListItem>
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};
