import { PropsWithChildren, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import {
  DEMO_CLUSTER_ID,
  isDemoMode,
  removeDemoMode,
  setDemoMode,
} from '@cast/utils';

import { ANALYTICS_ENABLED } from 'common/constants';
import { promos } from 'common/maps';
import { LogoLoader } from 'components/loaders';
import { useInitAnalytics } from 'core/analytics';
import { useAuth } from 'core/auth';
import { useFlags } from 'core/flags';
import { useClusters } from 'hooks/useClusters';
import { useInitSupportChat } from 'hooks/useInitSupportChat';
import { useNavigate } from 'hooks/useNavigate';
import { useOrganizations } from 'hooks/useOrganizations';

import { useNavigateToPageOfInterest } from '../_hooks/useNavigateToPageOfInterest';

export const Initializer = ({ children }: PropsWithChildren<unknown>) => {
  const { isLoading: isLoadingFlags, hasError, flags } = useFlags();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { isReady: isOrganizationReady, currentOrganization } =
    useOrganizations();
  const { isAnalyticsEnabled } = useInitAnalytics(isAuthenticated);
  const [isDemoDeterminationProcessDone, setDemoDeterminationStatus] =
    useState(false);

  const flagsAreLoaded = (!isLoadingFlags || hasError) && !isEmpty(flags);

  const criticalDataState = [
    flagsAreLoaded,
    isAuthenticated,
    isOrganizationReady,
    isDemoDeterminationProcessDone,
  ];

  if (ANALYTICS_ENABLED) {
    criticalDataState.push(isAnalyticsEnabled);
  }

  const applicationLoading = criticalDataState.some((isReady) => !isReady);

  const { clusters } = useClusters({
    polling: false,
    enabled: isAuthenticated && isOrganizationReady,
  });

  useEffect(() => {
    if (!isDemoDeterminationProcessDone && clusters && currentOrganization) {
      const orgHasOnlyDemoCluster =
        clusters.length === 1 && clusters[0].id === DEMO_CLUSTER_ID;

      if (isDemoMode()) {
        if (!orgHasOnlyDemoCluster) {
          removeDemoMode();
        }
      } else {
        if (orgHasOnlyDemoCluster) {
          setDemoMode(currentOrganization?.id);
        }
      }

      if (clusters.length > 0) {
        setDemoDeterminationStatus(true);
      }
    }
  }, [clusters, isDemoDeterminationProcessDone, currentOrganization]);

  useNavigateToPageOfInterest(applicationLoading);

  useInitSupportChat();

  if (applicationLoading) {
    return (
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="white"
        sx={{ inset: 0 }}
        aria-label="loading-spinner"
      >
        <LogoLoader />
      </Box>
    );
  }

  const promoQuery = new URLSearchParams(window.location.search).get('dest');
  if (promoQuery && Object.keys(promos).includes(promoQuery)) {
    navigate(promos[promoQuery], { replace: true });
  }

  return <>{children}</>;
};
