import Big from 'big.js';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';

import { DATE_SIMPLE } from '@cast/constants';
import {
  BillingReportClusterUsage,
  ClusterCpuUsageReport,
  DateRange,
} from '@cast/types';
import { dateRangeIterator } from '@cast/utils';

import { MOST_EXPENSIVE_ITEM_COLOR_MAP } from 'common/constants';
import {
  TopMostExpensiveGroup,
  TopMostExpensiveGroupDatapoint,
} from 'types/top-items';
import { fillGaps } from 'utils/metrics';

import { NormalizedBillingReportClusterUsage } from '../types';

export const sumTotalsForClusters = (
  dailyUsage: NormalizedBillingReportClusterUsage[],
  clusters: string[]
) => {
  return dailyUsage
    .filter((clusterReport) =>
      clusters.includes(clusterReport.cluster?.id ?? '')
    )
    .reduce(
      (acc, currentCluster) => {
        acc.totalBillableCpus = Big(acc.totalBillableCpus)
          .add(currentCluster.cumulativeBillableCpus || 0)
          .toNumber();
        acc.totalCpuHours = Big(acc.totalCpuHours)
          .add(currentCluster.cumulativeCpuHours || 0)
          .toNumber();
        return acc;
      },
      { totalBillableCpus: 0, totalCpuHours: 0 }
    );
};

export const normalizeBillingReport = (
  billingReport?: BillingReportClusterUsage[]
): NormalizedBillingReportClusterUsage[] => {
  if (!billingReport) {
    return [];
  }

  return billingReport.map((report) => ({
    cluster: report.cluster,
    cumulativeBillableCpus: Big(report.cumulativeBillableCpus || 0).toNumber(),
    cumulativeCpuHours: Big(report.cumulativeCpuHours || 0).toNumber(),
    dailyUsages: (report.dailyUsages ?? []).map((d) => ({
      timestamp: dayjs(d.day).format(DATE_SIMPLE),
      cpuHours: Big(d.cpuHours || 0).toNumber(),
      billableCpus: Big(d.billableCpus || 0).toNumber(),
    })),
  }));
};

export const normalizeCpuUsageReport = (
  dateRange: DateRange,
  cpuUsageReport?: ClusterCpuUsageReport[]
): NormalizedBillingReportClusterUsage[] => {
  if (!cpuUsageReport) {
    return [];
  }

  const daysInRange = dayjs.duration(dateRange[1].diff(dateRange[0])).asDays();

  return cpuUsageReport.map((report) => ({
    cluster: report.cluster,
    cumulativeBillableCpus: Big(
      sumBy(report.entries, (item) => Big(item.billableCpus || 0).toNumber())
    )
      .div(daysInRange ?? 1)
      .toNumber(),
    cumulativeCpuHours: Big(
      sumBy(report.entries, (item) => Big(item.cpuHours || 0).toNumber())
    ).toNumber(),
    dailyUsages: (report.entries ?? []).map((d) => ({
      timestamp: dayjs(d.day).format(DATE_SIMPLE),
      cpuHours: Big(d.cpuHours || 0).toNumber(),
      billableCpus: Big(d.billableCpus || 0).toNumber(),
    })),
  }));
};

const aggregateTopClustersData = (
  clusters: NormalizedBillingReportClusterUsage[],
  dateRange: DateRange
): NormalizedBillingReportClusterUsage[] => {
  if (clusters.length < 5) {
    return clusters;
  }

  const today = dayjs();

  const topClusters = clusters.slice(0, 4);
  const otherClustersAggregatedData = clusters.slice(4).reduce(
    (acc, currentCluster) => {
      acc.cumulativeBillableCpus = acc.cumulativeBillableCpus.add(
        currentCluster.cumulativeBillableCpus || 0
      );
      acc.cumulativeCpuHours = acc.cumulativeCpuHours.add(
        currentCluster.cumulativeCpuHours || 0
      );

      const { dailyUsages, cluster } = currentCluster;

      if (!dailyUsages || !cluster) {
        return acc;
      }

      const groupedByDay = groupBy(dailyUsages, 'timestamp');

      for (const date of dateRangeIterator(dateRange[0], dateRange[1])) {
        const timestamp = date.format(DATE_SIMPLE);

        if (dayjs(timestamp).isAfter(today)) {
          continue;
        }

        const timestampData = acc.dataByDay[timestamp] || {
          billableCpus: Big(0),
          cpuHours: Big(0),
        };
        const dayData = groupedByDay[timestamp];

        if (dayData && dayData.length) {
          timestampData.billableCpus = timestampData.billableCpus.add(
            dayData[0].billableCpus || 0
          );
          timestampData.cpuHours = timestampData.cpuHours.add(
            dayData[0].cpuHours || 0
          );
          acc.dataByDay[timestamp] = timestampData;
        }
      }

      return acc;
    },
    {
      cumulativeBillableCpus: Big(0),
      cumulativeCpuHours: Big(0),
      dataByDay: {} as Record<string, Record<string, Big>>,
    }
  );

  const otherClustersAsOne: NormalizedBillingReportClusterUsage = {
    cluster: { id: 'all-others', name: 'All other' },
    dailyUsages: Object.entries(otherClustersAggregatedData.dataByDay).map(
      ([timestamp, values]) => ({
        timestamp: timestamp,
        billableCpus: values.billableCpus.toNumber(),
        cpuHours: values.cpuHours.toNumber(),
      })
    ),
    cumulativeBillableCpus:
      otherClustersAggregatedData.cumulativeBillableCpus.toNumber(),
    cumulativeCpuHours:
      otherClustersAggregatedData.cumulativeCpuHours.toNumber(),
  };

  return [...topClusters, otherClustersAsOne];
};

const mapTopExpensiveClusters = (
  clusters: NormalizedBillingReportClusterUsage[]
): TopMostExpensiveGroup[] => {
  return clusters.map((item, index) => ({
    id: item.cluster!.id!,
    name: item.cluster!.name!,
    color:
      item.cluster?.id === 'all-others'
        ? MOST_EXPENSIVE_ITEM_COLOR_MAP.insignificant
        : MOST_EXPENSIVE_ITEM_COLOR_MAP.colorByUsageRank[index],
    cost: item.cumulativeBillableCpus,
  }));
};

const clustersToDatapoints = (
  clusters: NormalizedBillingReportClusterUsage[]
) => {
  const datapointsByTimestamp: Record<string, Record<string, number>> = {};

  for (const { dailyUsages, cluster } of clusters) {
    if (!dailyUsages || !cluster) {
      continue;
    }
    for (const { timestamp, billableCpus } of dailyUsages) {
      const timestampData = datapointsByTimestamp[timestamp] || {};
      timestampData[cluster.id!] = billableCpus;
      datapointsByTimestamp[timestamp] = timestampData;
    }
  }

  return orderBy(
    Object.entries(datapointsByTimestamp).map(([timestamp, values]) => ({
      timestamp,
      isForecast: false,
      values,
    })),
    'timestamp'
  );
};

const mapTopExpensiveClustersToDatapoints = (
  clusters: NormalizedBillingReportClusterUsage[],
  range: DateRange
): TopMostExpensiveGroupDatapoint => {
  if (!clusters.length) {
    return [];
  }

  const [from] = range;

  const gapsFiller = clusters.reduce(
    (acc, { cluster }) => ({
      ...acc,
      [cluster!.id!]: null,
    }),
    { 'all-others': null }
  );

  const datapoints = clustersToDatapoints(clusters);

  const lastTimestamp = dayjs(datapoints?.at(-1)?.timestamp);
  return fillGaps({
    timeSeries: datapoints,
    dateRange: [from, lastTimestamp],
    filler: { values: gapsFiller },
    unit: 'day',
    getKey: (day) => dayjs(day).format(DATE_SIMPLE),
  });
};

export const prepareTopMostExpensiveClusters = (
  clusters: NormalizedBillingReportClusterUsage[],
  dateRange: DateRange
) => {
  const aggregatedClusters = aggregateTopClustersData(clusters, dateRange);

  const topMostExpensiveClusters = mapTopExpensiveClusters(aggregatedClusters);
  const topMostExpensiveClustersDatapoints =
    mapTopExpensiveClustersToDatapoints(aggregatedClusters, dateRange);

  return { topMostExpensiveClusters, topMostExpensiveClustersDatapoints };
};
