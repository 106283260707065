import { ReactElement, ReactNode } from 'react';

import { Stack } from '@mui/material';

import { Button, Icons } from '@cast/design-system';

import { HexIconNotification } from 'components/hex-icons';

import { StatusMessage } from './StatusMessage';

export type DestroyFormGuardProps = {
  title?: ReactNode;
  subtitle?: ReactNode;
  onLeave?: () => void;
  onContinue?: () => void;
  icon?: ReactElement;
};

export const DestroyFormGuard = ({
  title = 'Are you sure you want to leave without saving?',
  subtitle = 'By leave without saving all the changes will be lost',
  onLeave,
  onContinue,
  icon = <HexIconNotification icon={<Icons.Question />} />,
}: DestroyFormGuardProps) => {
  return (
    <StatusMessage
      icon={icon}
      title={title}
      body={subtitle}
      actions={
        <Stack direction="row" gap={16}>
          <Button
            variant="tertiary"
            onClick={() => onLeave?.()}
            testId="form-guard-leave-btn"
          >
            Leave
          </Button>
          <Button
            onClick={() => onContinue?.()}
            testId="form-guard-continue-btn"
          >
            Continue editing
          </Button>
        </Stack>
      }
    />
  );
};
