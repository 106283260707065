import { styled } from '@mui/material';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';

import { Chip, ChipProps } from '@cast/design-system';
import {
  NodeMigrationStatusMigrationStatusEnum,
  WorkloadMigrationStatusMigrationStatusEnum,
} from '@cast/types';

import { RebalanceNodeEnum } from 'types/rebalance';

type MigrationStatus =
  | NodeMigrationStatusMigrationStatusEnum
  | WorkloadMigrationStatusMigrationStatusEnum
  | RebalanceNodeEnum
  | 'not-ready';

type OwnerState = {
  status: MigrationStatus;
};

type Props = ChipProps & {
  status: MigrationStatus;
};

const StyledStatusChipBase = styled(Chip, {
  target: 'RebalanceMigrationStatusChip-root',
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<
  ChipProps & {
    ownerState?: OwnerState;
  }
>(({ theme, ownerState }) => {
  return [
    ownerState &&
      (
        [
          NodeMigrationStatusMigrationStatusEnum.ready,
          WorkloadMigrationStatusMigrationStatusEnum.ready,
          RebalanceNodeEnum.READY,
        ] as MigrationStatus[]
      ).includes(ownerState.status) && {
        backgroundColor: theme.palette.green[50],
        color: theme.palette.green[500],
      },
    ownerState &&
      [
        NodeMigrationStatusMigrationStatusEnum.not_ready,
        WorkloadMigrationStatusMigrationStatusEnum.not_ready,
        RebalanceNodeEnum.NOT_READY,
        'not-ready',
      ].includes(ownerState.status) && {
        backgroundColor: theme.palette.red[50],
        color: theme.palette.red[500],
      },
  ];
});

export const RebalanceMigrationStatusChip = ({ status, ...props }: Props) => {
  return (
    <StyledStatusChipBase size="small" {...props} ownerState={{ status }}>
      {capitalize(startCase(status as string))}
    </StyledStatusChipBase>
  );
};
