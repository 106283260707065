import { useCallback, useEffect, useMemo } from 'react';

import { format } from 'date-fns';
import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';

import {
  RangePicker as RangePickerFromDS,
  RangePickerProps as RangePickerPropsFromDS,
  DateFilters,
  DateConstants,
  DateTypes,
} from '@cast/design-system';
import { DateRange as CastDateRange } from '@cast/types';

import { RangePickerOptions } from 'components/date/RangePicker/providers/types';

import { useRangePickerStateHandler } from './_hooks';
import { useRangePickerContext } from './providers';
import {
  checkIfDefaultFilter,
  setDateRangeFilterToQuery,
  setDateRangeToQuery,
  clearQueryParams,
  getDateRangeFilterFromValue,
} from './utils';

export type RangePickerProps = Partial<
  Omit<RangePickerPropsFromDS, 'selectedRange'>
> &
  Partial<RangePickerOptions> & {
    dateRange?: CastDateRange;
  };

const useNormalizedProps = (props: RangePickerProps) => {
  const {
    filterPresets: ctxFilterPresets,
    defaultFilterPreset: ctxDefaultFilterPreset,
    queryParamKeys: ctxQueryParamKeys,
    applyQueryParams: ctxApplyQueryParams,
    maxNumberOfDays: ctxMaxNumberOfDays,
    ...restCtx
  } = useRangePickerContext() ?? {};
  const {
    filterPresets = ctxFilterPresets,
    defaultFilterPreset = ctxDefaultFilterPreset,
    queryParamKeys = ctxQueryParamKeys,
    applyQueryParams = ctxApplyQueryParams,
    maxNumberOfDays = ctxMaxNumberOfDays,
    dateRange: dateRangeProp,
    ...rest
  } = props;

  // Omitting filterPresets from stateHandler
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { filterPresets: _filterPresets, ...stateHandler } =
    useRangePickerStateHandler({
      filterPresets,
      defaultFilterPreset,
      queryParamKeys,
      applyQueryParams,
      dateRange: dateRangeProp,
    });

  return {
    filterPresets,
    defaultFilterPreset,
    queryParamKeys,
    applyQueryParams,
    dateRangeProp,
    maxNumberOfDays,
    ...stateHandler,
    ...restCtx,
    ...rest,
  };
};

export const RangePicker = (props: RangePickerProps) => {
  const {
    filterPresets,
    queryParamKeys,
    applyQueryParams,
    currentDateRange,
    currentDateRangeFilter,
    defaultFilterPreset,
    dateRangeProp,
    maxNumberOfDays,
    setCurrentDateRange,
    setCurrentDateRangeFilter,
    setIsDefaultFilter,
    setFilterPresets,
    ...rest
  } = useNormalizedProps(props);

  const handleChange = useCallback(
    ({ from, to }: DateRange, preset?: DateTypes.DateRangeFilter) => {
      setCurrentDateRange([dayjs(from), dayjs(to)]);
      setCurrentDateRangeFilter(preset);
      rest.onChange?.({ from, to }, preset);
    },
    [rest, setCurrentDateRange, setCurrentDateRangeFilter]
  );

  useEffect(() => {
    if (!dateRangeProp?.length) {
      return;
    }

    const preset = getDateRangeFilterFromValue(
      [dayjs(dateRangeProp?.[0]), dayjs(dateRangeProp?.[1])],
      filterPresets
    );
    setCurrentDateRange(dateRangeProp);
    setCurrentDateRangeFilter(preset);
  }, [
    dateRangeProp,
    filterPresets,
    setCurrentDateRange,
    setCurrentDateRangeFilter,
  ]);

  useEffect(() => {
    const newStatus = checkIfDefaultFilter(
      currentDateRange,
      defaultFilterPreset
    );
    setIsDefaultFilter(newStatus);
  }, [currentDateRange, defaultFilterPreset, setIsDefaultFilter]);

  useEffect(() => {
    setFilterPresets(filterPresets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterPresets)]);

  useEffect(() => {
    if (!applyQueryParams) {
      return;
    }

    if (currentDateRangeFilter) {
      setDateRangeFilterToQuery(
        currentDateRangeFilter,
        filterPresets,
        queryParamKeys
      );
    } else if (currentDateRange) {
      setDateRangeToQuery(currentDateRange, queryParamKeys);
    }

    return () => {
      clearQueryParams(queryParamKeys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentDateRange,
    currentDateRangeFilter,
    applyQueryParams,
    // eslint-disable-next-line no-restricted-globals
    location.pathname,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(filterPresets),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(queryParamKeys),
  ]);

  const currentRangeAsString = `${currentDateRange?.[0]?.toISOString()} ${currentDateRange?.[1]?.toISOString()}`;

  return (
    <RangePickerFromDS
      initialSelectedDay={{
        from: currentDateRange[0].toDate(),
        to: currentDateRange[1].toDate(),
      }}
      inputValue={() => {
        if (currentDateRangeFilter) {
          return DateFilters.allFilters[currentDateRangeFilter]?.label || '';
        }
        const from = currentDateRange[0].toDate();
        const to = currentDateRange[1].toDate();
        return `${format(from, DateConstants.defaultDateFormat)} - ${format(
          to,
          DateConstants.defaultDateFormat
        )}`;
      }}
      toDate={DateFilters.allFilters.THIS_MONTH.date().to}
      maxNumberOfDays={maxNumberOfDays || 31}
      filterPresets={filterPresets}
      selectedRange={useMemo(
        () => ({
          from: currentDateRange?.[0].toDate(),
          to: currentDateRange?.[1].toDate(),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentRangeAsString]
      )}
      {...rest}
      onChange={handleChange}
    />
  );
};
