import { useMemo, ReactNode } from 'react';

import { Stack, SxProps, Typography, useTheme } from '@mui/material';
import round from 'lodash/round';
import pluralize from 'pluralize';
import NumberFormat from 'react-number-format';

import { mergeSx } from '@cast/design-system';

import { FooterBox } from 'components/boxes';
import { PageFooter } from 'components/portals';

import { sumTotalsForClusters } from '../_utils/utils';
import { useBillingReportContext } from '../BillingReportContext';

type MetricsProps = {
  title: ReactNode;
  value: number;
  sx?: SxProps;
  testId?: string;
};

const Metrics = ({ sx, title, value, testId }: MetricsProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      flexWrap="nowrap"
      alignItems="center"
      px={12}
      gap={8}
      sx={mergeSx(
        {
          minHeight: '22px',
          borderLeft: `1px solid ${theme.palette.grey[200]}`,
        },
        sx
      )}
    >
      <Typography variant="L10R" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="h6" fontSize={14} lineHeight={22}>
        <NumberFormat
          value={round(value, 2)}
          thousandSeparator
          displayType="text"
          data-testid={testId}
        />
      </Typography>
    </Stack>
  );
};

export const BillingReportFooter = () => {
  const { selectedClusters, currentTab, dailyUsage } =
    useBillingReportContext();

  const totals = useMemo(() => {
    if (!selectedClusters.length) {
      return;
    }
    return sumTotalsForClusters(dailyUsage, selectedClusters);
  }, [selectedClusters, dailyUsage]);

  if (!selectedClusters?.length || !totals || currentTab !== 'perCluster') {
    return null;
  }

  return (
    <PageFooter>
      <FooterBox>
        <Stack
          direction="row"
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            minHeight: '32px',
          }}
        >
          <Typography variant="L12R">Total for selection</Typography>
          <Stack direction="row" flexWrap="nowrap" alignItems="center">
            <Metrics
              title={pluralize('cluster', selectedClusters.length)}
              value={selectedClusters.length}
              testId="selected-clusters"
            />
            <Metrics
              title="CPU hours"
              value={totals.totalCpuHours}
              testId="total-cpu-hours"
            />
            <Metrics
              title="Billable CPU"
              value={totals.totalBillableCpus}
              testId="total-billable-cpus"
            />
          </Stack>
        </Stack>
      </FooterBox>
    </PageFooter>
  );
};
