import { useMemo } from 'react';

import { Typography } from '@mui/material';
import Big from 'big.js';
import dayjs from 'dayjs';
import round from 'lodash/round';
import NumberFormat from 'react-number-format';

import { DATE_SIMPLE } from '@cast/constants';
import { DataPopper, DataPopperRow } from '@cast/design-system';

import {
  TopMostExpensiveGroup,
  TopMostExpensiveGroupDatapoint,
} from 'types/top-items';

type ChartTooltipProps = {
  payload?: { payload: TopMostExpensiveGroupDatapoint[number] }[];
  topItems: TopMostExpensiveGroup[];
};

export const ChartTooltip = ({ payload, topItems }: ChartTooltipProps) => {
  const entry = payload?.[0]?.payload;

  const billableCpus = useMemo(() => {
    return topItems.reduce((acc, curr) => {
      const value = entry?.values[curr.id];

      return Big(acc)
        .add(value || 0)
        .toNumber();
    }, 0);
  }, [topItems, entry?.values]);

  if (!entry) {
    return null;
  }

  const { timestamp, values } = entry;

  return (
    <DataPopper sx={{ width: 298 }} size="small">
      <DataPopperRow
        left={
          <Typography variant="L10M" color="grey.400">
            {dayjs(timestamp).format(DATE_SIMPLE)}
          </Typography>
        }
      />
      <DataPopperRow
        left={
          <Typography variant="L10M" color="grey.700">
            BILLABLE CPUS
          </Typography>
        }
        right={
          <Typography variant="P10M" color="grey.800">
            <NumberFormat
              thousandSeparator
              displayType="text"
              suffix=" CPU"
              value={round(billableCpus, 2)}
            />
          </Typography>
        }
        dividerBottom
      />

      {topItems.map((item) => {
        return (
          <DataPopperRow
            key={item.id}
            indicator={{
              color: item.color,
            }}
            left={
              <Typography noWrap variant="P10R">
                {item.name}
              </Typography>
            }
            right={
              <Typography variant="P10R">
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  suffix=" CPU"
                  value={round(values[item.id] || 0, 2)}
                />
              </Typography>
            }
          />
        );
      })}
    </DataPopper>
  );
};
