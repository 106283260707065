/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AioptimizerV1GetSettingsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1UpsertSettingsRequest } from '../models';
// @ts-ignore
import { AioptimizerV1UpsertSettingsResponse } from '../models';
/**
 * AIOptimizerSettingsAPIApi - axios parameter creator
 * @export
 */
export const AIOptimizerSettingsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the settings of the LLM Optimizer. If the apiKey query parameter is specified, fetches the settings for that apiKey. Otherwise, fetches the settings for the current organization. If there are no apiKey-specific settings, returns organization settings.
         * @param {string} [apiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerSettingsAPIGetSettings: async (apiKey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiKey !== undefined) {
                localVarQueryParameter['apiKey'] = apiKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the settings of the LLM Optimizer.
         * @param {AioptimizerV1UpsertSettingsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerSettingsAPIUpsertSettings: async (body: AioptimizerV1UpsertSettingsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aIOptimizerSettingsAPIUpsertSettings', 'body', body)
            const localVarPath = `/v1/llm/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIOptimizerSettingsAPIApi - functional programming interface
 * @export
 */
export const AIOptimizerSettingsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIOptimizerSettingsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the settings of the LLM Optimizer. If the apiKey query parameter is specified, fetches the settings for that apiKey. Otherwise, fetches the settings for the current organization. If there are no apiKey-specific settings, returns organization settings.
         * @param {string} [apiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerSettingsAPIGetSettings(apiKey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerSettingsAPIGetSettings(apiKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerSettingsAPIApi.aIOptimizerSettingsAPIGetSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the settings of the LLM Optimizer.
         * @param {AioptimizerV1UpsertSettingsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerSettingsAPIUpsertSettings(body: AioptimizerV1UpsertSettingsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1UpsertSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerSettingsAPIUpsertSettings(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerSettingsAPIApi.aIOptimizerSettingsAPIUpsertSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AIOptimizerSettingsAPIApi - factory interface
 * @export
 */
export const AIOptimizerSettingsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIOptimizerSettingsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the settings of the LLM Optimizer. If the apiKey query parameter is specified, fetches the settings for that apiKey. Otherwise, fetches the settings for the current organization. If there are no apiKey-specific settings, returns organization settings.
         * @param {AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIGetSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerSettingsAPIGetSettings(requestParameters: AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIGetSettingsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetSettingsResponse> {
            return localVarFp.aIOptimizerSettingsAPIGetSettings(requestParameters.apiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the settings of the LLM Optimizer.
         * @param {AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIUpsertSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerSettingsAPIUpsertSettings(requestParameters: AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIUpsertSettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1UpsertSettingsResponse> {
            return localVarFp.aIOptimizerSettingsAPIUpsertSettings(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aIOptimizerSettingsAPIGetSettings operation in AIOptimizerSettingsAPIApi.
 * @export
 * @interface AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIGetSettingsRequest
 */
export interface AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIGetSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIGetSettings
     */
    readonly apiKey?: string
}

/**
 * Request parameters for aIOptimizerSettingsAPIUpsertSettings operation in AIOptimizerSettingsAPIApi.
 * @export
 * @interface AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIUpsertSettingsRequest
 */
export interface AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIUpsertSettingsRequest {
    /**
     * 
     * @type {AioptimizerV1UpsertSettingsRequest}
     * @memberof AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIUpsertSettings
     */
    readonly body: AioptimizerV1UpsertSettingsRequest
}

/**
 * AIOptimizerSettingsAPIApi - object-oriented interface
 * @export
 * @class AIOptimizerSettingsAPIApi
 * @extends {BaseAPI}
 */
export class AIOptimizerSettingsAPIApi extends BaseAPI {
    /**
     * 
     * @summary Returns the settings of the LLM Optimizer. If the apiKey query parameter is specified, fetches the settings for that apiKey. Otherwise, fetches the settings for the current organization. If there are no apiKey-specific settings, returns organization settings.
     * @param {AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIGetSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerSettingsAPIApi
     */
    public aIOptimizerSettingsAPIGetSettings(requestParameters: AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIGetSettingsRequest = {}, options?: RawAxiosRequestConfig) {
        return AIOptimizerSettingsAPIApiFp(this.configuration).aIOptimizerSettingsAPIGetSettings(requestParameters.apiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the settings of the LLM Optimizer.
     * @param {AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIUpsertSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerSettingsAPIApi
     */
    public aIOptimizerSettingsAPIUpsertSettings(requestParameters: AIOptimizerSettingsAPIApiAIOptimizerSettingsAPIUpsertSettingsRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerSettingsAPIApiFp(this.configuration).aIOptimizerSettingsAPIUpsertSettings(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

